// Yup is a JavaScript schema builder for value parsing and validation
import * as Yup from 'yup';
import getConstants from '../../util/constants';
import { calculateAgeLuxon } from '@merchant-portal/util/helper';

const {
  TITLE_VALIDATION_REGEX,
  FIRSTNAME_VALIDATION_REGEX,
  MIDDLENAME_VALIDATION_REGEX,
  LASTNAME_VALIDATION_REGEX,
  JOBTITLE_VALIDATION_REGEX,
  EMAIL_VALIDATION_REGEX,
  PHONE_VALIDATION_REGEX,
  MOBILE_VALIDATION_REGEX,
  MOBILE_NUMBER_FORMAT_VALIDATION,
  PHONE_NUMBER_FORMAT_VALIDATION,
} = getConstants();

const title = Yup.string()
  .required('Title is required')
  .matches(TITLE_VALIDATION_REGEX, 'Title must be valid.');

const firstName = Yup.string()
  .required('First Name is required')
  .matches(FIRSTNAME_VALIDATION_REGEX, 'First name must be valid.');

const middleName = Yup.string().matches(MIDDLENAME_VALIDATION_REGEX, {
  message: 'Middle name must be valid.',
  excludeEmptyString: true,
});

const lastName = Yup.string()
  .required('Last name is required')
  .matches(LASTNAME_VALIDATION_REGEX, 'Last name must be valid.');

const jobTitle = Yup.string().matches(JOBTITLE_VALIDATION_REGEX, {
  message: 'Job title must have valid characters.',
  excludeEmptyString: true,
});

const email = Yup.string()
  .required('Email is required')
  .matches(EMAIL_VALIDATION_REGEX, 'Please enter a valid email address.');

const confirmEmail = Yup.string().oneOf([Yup.ref('email'), null], 'E-mail address must match.');

const phoneMobile = Yup.string()
  .required('Mobile No. is required')
  .matches(MOBILE_VALIDATION_REGEX, {
    message: MOBILE_NUMBER_FORMAT_VALIDATION,
  });

const phoneWork = Yup.string().matches(PHONE_VALIDATION_REGEX, {
  message: PHONE_NUMBER_FORMAT_VALIDATION,
  excludeEmptyString: true,
});

const dateOfBirth = Yup.string().test(
  'dateOfBirth',
  'Must be at least 18 years old.',
  function (value) {
    const receivedDob: any = value!;
    if (value !== null) {
      return calculateAgeLuxon(receivedDob);
    } else {
      return false;
    }
  }
);

// Define stronger form validation rules - not related to in-built ControlledInputField 'rules' property

// General staff information - form validation schema
// Applies to:
// 1. Profile page
// 2. Add staff page
// 3. Update staff page
export const generalStaffInfoValidationSchema = Yup.object().shape({
  firstName: firstName,
  lastName: lastName,
  jobTitle: jobTitle,
  email: email,
  phoneMobile: phoneMobile,
  phoneWork: phoneWork,
});

// Existing customer search - form validation schema
export const existingCustSearchValidationSchema = Yup.object().shape({
  firstName: firstName,
  lastName: lastName,
  email: email,
  dateOfBirth: dateOfBirth,
});

// TODO PW-794 Warning: Not in use, due to complexity of current customerDetailsBlock form.
// Customer Details Block - form validation schema (warning: the name properties' values are different)
export const custDetailsBlockValidationSchema = Yup.object().shape({
  'customerDetails.title': title,
  'customerDetails.firstName': firstName,
  'customerDetails.middleName': middleName,
  'customerDetails.lastName': lastName,
  'customerDetails.email': email,
  'customerDetails.confirmEmail': confirmEmail,
  'customerDetails.phone': phoneMobile,
  'customerDetails.dateOfBirth': dateOfBirth,
});

export const purchaseDetailsValidationSchema = Yup.object({
  purposeOfLoan: Yup.string().required('Description of Goods / Services is required'),
});
