import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import payment from 'payment';
import { AppDispatch } from '@merchant-portal/util/store';
import { RootState } from '@merchant-portal/slices';
import {
  addCard,
  doPayment,
  addCardFailure,
  doUpdatePaymentStatus,
} from '@merchant-portal/slices/payment';
import { doUpdatePaymentDate } from '@merchant-portal/slices/customer';
import { doMakeCardActive, getActiveCard } from '@merchant-portal/slices/payment-method';
import encrypt from '@merchant-portal/util/encrypt';

import PaymentDetails from '@merchant-portal/components/payment-details';
import PaymentStartDate from '@merchant-portal/components/payment/payment-start-date';
import ConfirmationMessage from './confirmation-message';
import SaveButton from './save-button';

type EwayFormProps = {
  successCallback?: () => void;
};

type FormData = {
  selectedPaymentDate: Date | null;
  cardHolderName: string | null;
  csv: string | null;
  expiry: string | null;
  selectedMonthlyDate: string | null;
};

const EwayForm = ({ successCallback }: EwayFormProps) => {
  const dispatch: AppDispatch = useDispatch();

  const checkout = useSelector((state: RootState) => state.checkout);
  const customer = useSelector((state: RootState) => state.customer);
  const repeatCustomer = customer?.accountDetails?.isReturnCustomer;

  const { addCardLoading } = useSelector((state: RootState) => state.payment);
  const { paymentMethods } = useSelector((state: RootState) => state.paymentMethod);
  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const checkoutId = checkout.checkout?.id || '';
  const planId = checkout.checkout?.planId || '';
  const customerId = checkout.checkout?.customerId || '';

  const depositAmount = checkout.checkout?.attributes?.depositPayable || 0;
  const depositTaker = storeConfig?.depositTaker || false;

  const activeCardId = getActiveCard(paymentMethods);
  const [selectedCardId, setSelectedCardId] = useState<string>(activeCardId);
  const [expiryMonth, setExpiryMonth] = useState<string>();
  const [expiryYear, setExpiryYear] = useState<string>();
  const [cardNumber, setCardNumber] = useState<string>();
  const [clickChangeCard, setClickChangeCard] = useState<boolean>(false);
  const [mode, setMode] = useState<'add' | 'existing'>('add');

  const handleCardChange = (cardId: string) => {
    setSelectedCardId(cardId);
    setClickChangeCard(true);
  };

  useEffect(() => {
    if (Object.keys(paymentMethods).length === 0) {
      setMode('add');
    } else {
      setMode('existing');
    }
  }, [paymentMethods]);

  const formSubmitHandler = async (formData: any) => {
    try {
      if (mode === 'add') {
        // Retrieve customer details from customer slice
        const { customerNumber } = customer;
        const { firstName, lastName } = customer.customerDetails;
        if (customerNumber === null) {
          dispatch(
            addCardFailure({ errorMessage: 'Unable to add card -- customer record incomplete' })
          );
          throw Error('Unable to add card -- customer record incomplete');
        }

        const expiry = `${('0' + expiryMonth).slice(-2)}/${expiryYear}`;
        const cardDetails = {
          card_holder: formData.cardHolderName,
          customer_id: customerId,
          card_type: payment.fns.cardType(formData.cardNo),
          payment_provider: 'Eway',
          number: encrypt(formData.cardNo.replace(/\s/g, '')),
          card_expiry: expiry,
          cvn: encrypt(formData.csv.replace(/\s/g, '')),
          customer_number: customerNumber,
          payment_collected_by_sugar: storeConfig?.paymentsProcessedBySugar || true,
          customer_first_name: firstName,
          customer_last_name: lastName,
          checkoutId: checkoutId,
        };

        await dispatch(addCard(cardDetails));
      } else {
        if (clickChangeCard) {
          await dispatch(doMakeCardActive(selectedCardId, customerId));
        }
      }

      if (!depositTaker && depositAmount > 0) {
        await dispatch(doPayment(customerId, planId));
      }

      dispatch(doUpdatePaymentStatus(planId, depositAmount, depositTaker));

      /* Only set the payment start date for new customers */
      if (repeatCustomer === false) {
        const formattedDate = formData.selectedPaymentDate
          ? moment(formData.selectedPaymentDate).format('YYYY-MM-DD')
          : formData.selectedMonthlyDate;

        const paymentDateData = {
          checkoutId,
          paymentDetails: {
            paymentStartDate: formattedDate,
          },
        };

        dispatch(doUpdatePaymentDate(paymentDateData, customerId));
      }

      if (typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // error messages are dispatched via redux
    }
  };

  const { handleSubmit } = useFormContext<FormData>();

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <PaymentDetails
        provider="eway"
        onChange={handleCardChange}
        selectedCardId={selectedCardId}
        setExpiryMonth={setExpiryMonth}
        expiryMonth={expiryMonth}
        setExpiryYear={setExpiryYear}
        expiryYear={expiryYear}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
        mode={mode}
        setMode={setMode}
      />

      <PaymentStartDate></PaymentStartDate>

      <hr />

      <ConfirmationMessage depositAmount={depositAmount} depositTaker={depositTaker} />

      <hr />
      <SaveButton
        loading={addCardLoading}
        depositAmount={depositAmount}
        depositTaker={depositTaker}
      />
    </form>
  );
};

export default EwayForm;
