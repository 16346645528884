import React from 'react';
import styled from 'styled-components';
import { media, Checkbox } from '@payright/web-components';

interface DisclosureProps {
  /** Icon for the alert */
  icon?: React.ReactElement;

  marketingOptInText: string;

  selected: boolean;
  /** Handle the on click event */
  handleClick?: Function;
}

const MarketingOpt = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 1em 0.5em 2em 0;
  background-color: transparent !important;
  div.marketing-text {
    font-size: 0.93rem;
    color: ${(props) => props.theme.colours.blue.base};
  }
  .check-wrapper > div.marketing-text {
    line-height: 2.5;
  }

  .check-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-left: 3.25em;
  }

  ${media.max.tablet} {
    padding: 2.44em 2.44em 2.44em 0;
    div.marketing-text {
      font-size: 1em;
    }
    .check-wrapper {
      // padding-left: 3.25em;
    }
  }

  ${media.max.small} {
    padding: 1.5em 1.5em 1.5em 0;
    div.marketing-text {
      font-size: 0.8em;
    }
    .check-wrapper {
      align-items: flex-start;
      // padding-left: 3.25em;
    }
  }
`;

export type EventHandlerProps = {
  onClick: (e: React.MouseEvent) => void;
};

const MerchantMarketing = ({
  icon,
  marketingOptInText,
  selected,
  handleClick,
}: DisclosureProps) => {
  return (
    <MarketingOpt>
      <div className="check-wrapper">
        <Checkbox
          icon={icon}
          value="Agree"
          className="marketingOptIn"
          handleClick={handleClick}
          checked={selected}
          name="marketingOptIn"
        >
          {marketingOptInText && <div className="marketing-text">{marketingOptInText}</div>}
        </Checkbox>
      </div>
    </MarketingOpt>
  );
};

export default MerchantMarketing;
