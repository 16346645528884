import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../util/hooks';
import scrollToComponent from 'react-scroll-to-component';

/// IMPORTING WEB COMPONENTS
import {
  media,
  HeaderMerchant,
  Button,
  Alert,
  IconAttention,
  ModalLargerDepositRequired,
  ModalWrapper,
  PaymentCalculatorData,
  fixNameCase,
} from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';

/// REDUX TOOLKIT IMPORTING THE SLICES ROOT SLICE && CUSTOMER SLICES
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../util/store';
import { RootState } from '../../../slices';
import {
  customerSearch,
  setMode,
  saveCustomerDetails,
  clearCustomerSearchError,
} from '../../../slices/customer';
import {
  fetchCheckoutAttributes,
  runPlanPreApprovalCheck,
  acceptDepositBumping,
  attachCustomer,
  sendApplicationLink,
  sendVerificationCode,
} from '../../../slices/checkout';

// NESTED COMPONENTS USED WITHING THE FORM
import CustomerDetailsBlock, {
  CustomerDetailsData,
} from '../../../components/customer-data/customer-details-block';
import CustomerAddress, {
  AddressDetailsData,
} from '../../../components/customer-data/address-details';
import EmploymentDetails, {
  EmploymentDetailsData,
} from '../../../components/customer-data/employment-details';
import IdentificationDetails, {
  IdentificationDetailsData,
  IdentificationType,
} from '../../../components/customer-data/identification-details';
import FormSection from '../../../components/form-section';
import CustomerTypeSelector, {
  FormCustomerType,
} from '../../../components/customer-data/customer-type-selector';
import ExistingCustomerSearch from '../../../components/existing-customer-search';
import CustomerLedBlock from '../../../components/customer-data/customer-led-block';
import getConstants from '../../../util/constants';

/// import the generic spinner
import Spinner from '../../../components/spinner';
import { CustomerResource } from '@merchant-portal/types/customer';
import { NextStep } from '@merchant-portal/types/checkout';
import { ApplicationCompletedBy } from '@merchant-portal/types/plan';
import { useConfig } from '@merchant-portal/providers/config-provider';
import { ProductLine } from '@merchant-portal/util/constants/enums';

// Yup is a JavaScript schema builder for value parsing and validation
// import { yupResolver } from '@hookform/resolvers/yup';
// import { custDetailsBlockValidationSchema } from '../../../util/validation/form-validation';

const { NEW_CUSTOMER_DETAIL, CUSTOMER_IDENTIFICATION } = getConstants();

type CustomerDetailsProps = {};

type FormData = {
  customerDetails: CustomerDetailsData;
  addressDetails: AddressDetailsData;
  employmentDetails: EmploymentDetailsData;
  identificationDetails: IdentificationDetailsData;
};

const SCCustomerDetails = styled.div`
  background: ${(props) => props.theme.colours.white};
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.altlight};
    margin: 3em 0;
  }

  .customerdetails-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 3em;

    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    ${media.max.ewide} {
      padding: 0 1.6em;
    }

    h4 {
      color: ${(props) => props.theme.colours.blue.base};
    }
  }

  .button-actiongroup-wrapper {
    padding: 30px 0px 30px 0px;
    display: flex;
    justify-content: space-between;
    .save-for-later {
      margin-right: 0.5em;
    }
    ${media.min.medium} {
      .save-for-later {
        margin-right: 1em;
        margin-left: 1.5em;
        width: 250px;
      }
    }
    ${media.min.large} {
      .save-for-later {
        margin-right: 1em;
        margin-left: 0em;
        width: 400px;
      }
    }
  }

  .alert-container {
    margin-bottom: 3em;
  }
`;

const CustomerDetails = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();

  const [displayDepositBumping, setDepositBumpingDisplay] = useState(false);
  const [requireDepositBump, setRequireDepositBump] = useState(false);

  // Simple state for certain form elements that do not need to be handled by react-hook-form
  const [selectedCustomerType, setSelectedCustomerType] = useState<FormCustomerType>('newCustomer');
  const [identificationType, setIdentificationType] = useState<IdentificationType>('license');
  const [depositBumpingPaymentDetails, setDepositBumpingPaymentDetails] =
    useState<PaymentCalculatorData>();
  const [preApprovalDepositAmount, setPreApprovalDepositAmount] = useState<number>(0);
  const [preApprovalDepositPercentage, setPreApprovalDepositPercentage] = useState<number>(0.0);

  /// RETURNS A PART OF THE STATE - CUSTOMER
  const globalFormState = useSelector((state: RootState) => state.customer);

  const [customerApplicationLink, setCustomerApplicationLink] = useState(false);
  const [showApplicationButton, setShowApplicationButton] = useState(false);

  const isExistingCustomer = globalFormState.customerId !== null;

  const config = useConfig();

  const {
    customerTypeSelection,
    mode,
    customerMatch,
    customerId,
    errorMessage: customerErrorMessage,
    loading: loadingCustomer,
  } = useSelector((state: RootState) => state.customer);

  const customerLedOnly = useSelector(
    (state: RootState) => state.auth.storeConfig?.customerLedOnly
  );

  /// RETURNS A PART OF THE STATE - CHECKOUT
  const {
    loading: loadingCheckout,
    checkout,
    paymentDetails,
    errorMessage: checkoutErrorMessage,
  } = useSelector((state: RootState) => state.checkout);

  const loggedInUser = useSelector((state: RootState) => state.auth);
  const checkoutId = checkout?.id; // The checkout id in Redux
  const saleAmount = checkout?.attributes?.saleAmount || 0;
  const depositAmount = checkout?.attributes?.depositPayable || 0;
  const loanAmount = saleAmount - depositAmount;
  const planId = checkout?.planId || '';
  const preApprovalStatus = checkout?.attributes?.planPreApprovalDetails?.status;

  const { establishmentFees, otherFees } = loggedInUser.merchantRates;
  //const {paymentFrequency, paymentPeriod } = paymentDetails;
  const queryParamCheckoutId = query.get('checkoutId'); // The checkout id in the URL

  //set form for default customer type
  useEffect(() => {
    if (selectedCustomerType !== null) {
      if (selectedCustomerType === 'newCustomer') {
        dispatch(setMode('data-entry'));
      } else {
        dispatch(setMode('customer-search'));
      }
    }
  }, [dispatch, selectedCustomerType]);

  // Set requiredDepositBump on load, this is to prevent customers from updating employment if a deposit bump is needed
  useEffect(() => {
    setRequireDepositBump(preApprovalStatus === 'NEED_TO_INCREASE_DEPOSIT');
  }, [preApprovalStatus]);

  //set the customer type as existing customer when comes from when comes from plan list
  useEffect(() => {
    if (queryParamCheckoutId && checkout?.customerId !== null) {
      setSelectedCustomerType('existingCustomer');
    }
  }, [queryParamCheckoutId, dispatch, checkout]);

  // Load latest checkout resource from server
  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [checkoutId, dispatch, queryParamCheckoutId]);

  /// CUSTOMER LOOKUP VALIDATION
  const reactHookFormCustomerLookup = useForm<{ customerDetails: CustomerDetailsData }>({
    defaultValues: {
      customerDetails: { ...globalFormState.customerDetails },
    },
    // resolver: yupResolver(custDetailsBlockValidationSchema),
  });

  const {
    handleSubmit: handleSubmitCustomerLookup,
    setValue: setCustomerLookupValue,
    clearErrors,
  } = reactHookFormCustomerLookup;

  /// REST OF THE FORM VALIDATION  LOOKUP VALIDATION
  const reactHookFormCustomerDetails = useForm<FormData>({
    defaultValues: {
      addressDetails: { ...globalFormState.customerAddressDetails },
      employmentDetails: { ...globalFormState.employmentDetails },
      identificationDetails: { ...globalFormState.identificationDetails },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const {
    handleSubmit: handleSubmitCustomerDetails,
    setValue: setCustomerDetailsValue,
    reset,
    getValues,
  } = reactHookFormCustomerDetails;

  const reactHookFormCustomerLed = useForm({
    shouldFocusError: true,
  });

  const { handleSubmit: handleSubmitCustomerLed } = reactHookFormCustomerLed;

  //  Customer led scenario - sends an application link to the specified email address
  const formSubmitCustomerLedForNewCustomer = () => {
    const fixedName = fixNameCase(
      globalFormState.customerDetails.firstName,
      globalFormState.customerDetails.middleName,
      globalFormState.customerDetails.lastName
    );

    // Legacy - Create a plan and customer in order to send the customer to the legacy applicant portal
    const customer = {
      id: customerId || null,
      customerDetails: {
        ...(globalFormState.customerDetails || undefined),
        firstName: fixedName.firstName,
        middleName: fixedName.middleName,
        lastName: fixedName.lastName,
      },
      applicationCompletedBy: ApplicationCompletedBy.CUSTOMER,
    };
    if (checkoutId) {
      dispatch(saveCustomerDetails(customer, ProductLine.BNPL, checkoutId)).then(
        (customerSaveResult) => {
          formSubmitCustomerLedForExistingCustomer({
            checkoutId: checkoutId,
            customerId: customerSaveResult.id as string,
          });
        }
      );
    }
  };

  const formSubmitCustomerLedForExistingCustomer = ({
    checkoutId,
    customerId,
  }: {
    checkoutId: string;
    customerId: string;
  }) => {
    dispatch(attachCustomer(checkoutId, customerId, ApplicationCompletedBy.CUSTOMER))
      .then(() => {
        dispatch(sendApplicationLink(checkoutId));
        dispatch(sendVerificationCode(checkoutId));
        history.push(`/plans/new/plan-sent-to-customer`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Update form values when data from redux is populated/modified
  useEffect(() => {
    Object.keys(globalFormState.customerDetails).forEach((key) => {
      clearErrors(`customerDetails.${key}`);
      setCustomerLookupValue(
        `customerDetails.${key}`,
        Reflect.get(globalFormState.customerDetails, key)
      );
    });

    Object.keys(globalFormState.customerAddressDetails).forEach((key) => {
      clearErrors(`addressDetails.${key}`);
      setCustomerDetailsValue(
        `addressDetails.${key}`,
        Reflect.get(globalFormState.customerAddressDetails, key)
      );
    });

    // if (globalFormState.identificationType !== null) {
    //   setIdentificationType(globalFormState.identificationType);
    // }

    setIdentificationType(globalFormState.identificationType);

    Object.keys(globalFormState.employmentDetails).forEach((key) => {
      setCustomerDetailsValue(
        `employmentDetails.${key}`,
        Reflect.get(globalFormState.employmentDetails, key)
      );
    });

    Object.keys(globalFormState.identificationDetails).forEach((key) => {
      clearErrors(`identificationDetails.${key}`);

      setCustomerDetailsValue(
        `identificationDetails.${key}`,
        Reflect.get(globalFormState.identificationDetails, key),
        { shouldDirty: false }
      );
    });
  }, [
    globalFormState.customerDetails,
    globalFormState.customerAddressDetails,
    globalFormState.employmentDetails,
    setCustomerLookupValue,
    setCustomerDetailsValue,
    setIdentificationType,
    clearErrors,
    globalFormState.identificationType,
    globalFormState.identificationDetails,
  ]);

  // DISPLAY AND SCROLL TO ADDRESS DETAILS IF CUSTOMER SEARCH SUCCESS
  let hiddenBlockRef: HTMLDivElement | null = null;
  useEffect(() => {
    if (customerMatch && !showApplicationButton) {
      scrollToComponent(hiddenBlockRef, {
        offset: -80,
        align: 'top',
        duration: 600,
      });
    }
  }, [customerMatch, hiddenBlockRef, showApplicationButton]);

  /// FORM SUBMIT HANDLER CUSTOMER SEARCH
  const formSubmitHandlerCustomerSearch = (formData: { customerDetails: CustomerDetailsData }) => {
    dispatch(customerSearch(formData.customerDetails, customerTypeSelection));
    setCustomerApplicationLink(true);
  };

  // TODO - handle is we get BSO back? Maybe we should handle this in Merchant API??
  const handlePageRedirection = (
    nextSteps: Array<NextStep>,
    checkoutId: string,
    planId: string
  ) => {
    const firstStep = nextSteps[0];

    switch (firstStep) {
      case NextStep.SUMMARY:
        dispatch(sendVerificationCode(checkoutId));
        history.push(`/plans/new/summary?checkoutId=${checkoutId}`);
        break;
      case NextStep.DEPOSIT_BUMP:
        setDepositBumpingDisplay(true);
        break;
      case NextStep.BANK_STATEMENT:
        // This should never be returned from merchant-api - send to summary instead
        dispatch(sendVerificationCode(checkoutId));
        history.push(`/plans/new/summary?checkoutId=${checkoutId}`);
        break;
      case NextStep.DECLINED_MESSAGE:
        history.push(`/plans/new/plan-result/${planId}`);
        break;
    }
  };

  const ref = useRef(true);

  useEffect(() => {
    // TODO DatePicker using onChange causing isDirty = true. Temp fix below.
    // TODO Load once, if customer-details component loaded, but upon forms init, isDirty init is true
    // console.log('form', reactHookFormCustomerDetails.formState.dirtyFields); // TODO DatePicker using onChange causing isDirty = true

    // Only for new customer, as they have zero identification details initialised
    if (globalFormState.identificationDetails) {
      // Proceed with initialising temporary hotfix
      const formsUsingDatePickerBug =
        globalFormState.identificationType === 'license' ||
        globalFormState.identificationType === 'passport';

      if (
        reactHookFormCustomerDetails.formState.isDirty &&
        formsUsingDatePickerBug &&
        ref.current
      ) {
        reset(
          {
            ...getValues(),
          },
          { isDirty: false }
        );
        ref.current = false;
      } else {
        console.log('form updated', reactHookFormCustomerDetails.formState.isDirty); // DO NOT REMOVE!!!!
      }
    }
  }, [reactHookFormCustomerDetails.formState.isDirty]);

  /// FORM SUBMIT HANDLER CUSTOMER DETAILS
  const formSubmitHandlerCustomerDetails = (formData: FormData) => {
    const fixedName = fixNameCase(
      globalFormState.customerDetails.firstName,
      globalFormState.customerDetails.middleName,
      globalFormState.customerDetails.lastName
    );

    const customer: CustomerResource = {
      ...formData,
      id: customerId || null,
      customerDetails: {
        ...(globalFormState.customerDetails || undefined),
        firstName: fixedName.firstName,
        middleName: fixedName.middleName,
        lastName: fixedName.lastName,
      },
    };

    // TODO: Error handling, and check if customer is already linked
    if (checkoutId) {
      dispatch(saveCustomerDetails(customer, ProductLine.BNPL, checkoutId)).then(
        (customerSaveResult) => {
          dispatch(
            attachCustomer(
              checkoutId,
              customerSaveResult.id as string,
              ApplicationCompletedBy.MERCHANT
            )
          )
            .then((planId) => {
              dispatch(
                runPlanPreApprovalCheck({
                  checkoutId,
                  isCheckoutUpdated: reactHookFormCustomerDetails.formState.isDirty,
                  isExistingCustomer: isExistingCustomer,
                  onSuccessHandleRedirection: (
                    nextSteps: Array<NextStep>,
                    depositPercentage: number,
                    depositAmount: number
                  ) => {
                    if (paymentDetails !== undefined) {
                      setDepositBumpingPaymentDetails({
                        ...paymentDetails,
                        depositAmount: depositAmount,
                        depositPercent: depositPercentage,
                      });

                      // Set Pre-Approval Deposit Amount
                      setPreApprovalDepositAmount(depositAmount);
                      // Set Pre-Approval Deposit Percentage
                      setPreApprovalDepositPercentage(depositPercentage);
                      handlePageRedirection(nextSteps, checkoutId, planId as string);
                    }
                  },
                })
              );
            })
            .catch((err) => {
              // Main error displayed to user
              console.log(err);
            });
        }
      );
    }
  };

  const customerTypeHandler = (selectedCustomerType: FormCustomerType) => {
    setSelectedCustomerType(selectedCustomerType);
  };

  // HANDLE DEPOSIT BUMPING CANCEL BUTTON
  const handleCancelDepositBumping = () => {
    setDepositBumpingDisplay(false);
    setRequireDepositBump(true);
  };

  const handleDepositBumpingApproval = () => {
    if (checkoutId) {
      dispatch(
        acceptDepositBumping({
          checkoutId,
          depositAmount: preApprovalDepositAmount,
          depositPercentage: preApprovalDepositPercentage,
          onSuccessHandleRedirection: (nextSteps: Array<NextStep>) => {
            handlePageRedirection(nextSteps, checkoutId, planId);
          },
        })
      );
    }
  };

  const handleApplicationClickEvent = (applicationButton: boolean) => {
    setShowApplicationButton(applicationButton);
  };

  // Clear error messages, when alternating between 'tabs' (watching 'mode' changes)
  useEffect(() => {
    dispatch(clearCustomerSearchError());
  }, [dispatch, mode]);

  return (
    <SCCustomerDetails>
      <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
        <HeaderMerchant
          title="New Plan"
          activeStep={1}
          setActiveStep={(step: number) => {
            if (step === 0) {
              history.push(`/plans/new/sale-details?checkoutId=${checkoutId}`);
            }
          }}
        />

        {/* DEPOSIT BUMPING PAGE */}
        <ModalWrapper
          open={displayDepositBumping}
          handleClose={() => setDepositBumpingDisplay(false)}
          background="dark"
        >
          <ModalLargerDepositRequired
            paymentDetails={depositBumpingPaymentDetails}
            establishmentFees={establishmentFees}
            otherFees={otherFees}
            handleCancel={handleCancelDepositBumping}
            handleSubmit={handleDepositBumpingApproval}
          />
        </ModalWrapper>

        <div className="customerdetails-body">
          <FormSection title="Customer Type" text="">
            <>
              <CustomerTypeSelector
                value={selectedCustomerType}
                handleSelect={customerTypeHandler}
              />
              <hr />
            </>
          </FormSection>

          {customerErrorMessage && (
            <div className="alert-container">
              <Alert
                title={'Application Initiation error'}
                body={customerErrorMessage}
                outcome="error"
                icon={<IconAttention />}
              />
            </div>
          )}

          <div style={{ display: mode === 'data-entry' ? 'block' : 'none' }}>
            <FormSection title="Customer Details" text={NEW_CUSTOMER_DETAIL}>
              <FormProvider {...reactHookFormCustomerLookup}>
                <form
                  noValidate
                  onSubmit={handleSubmitCustomerLookup(formSubmitHandlerCustomerSearch)}
                >
                  <CustomerDetailsBlock
                    readOnly={isExistingCustomer}
                    showNextButton={customerMatch === false}
                  />
                </form>
              </FormProvider>
            </FormSection>

            {customerApplicationLink &&
              !customerErrorMessage &&
              customerMatch &&
              !isExistingCustomer && (
                <FormSection>
                  {checkoutErrorMessage && (
                    <div className="alert-container">
                      <Alert
                        title={'Application Initiation error'}
                        body={checkoutErrorMessage}
                        outcome="error"
                        icon={<IconAttention />}
                      />
                    </div>
                  )}
                  <FormProvider {...reactHookFormCustomerLed}>
                    <form onSubmit={handleSubmitCustomerLed(formSubmitCustomerLedForNewCustomer)}>
                      <CustomerLedBlock
                        handleClickEvent={handleApplicationClickEvent}
                        showApplicationLinkButton={showApplicationButton}
                      />
                    </form>
                  </FormProvider>
                </FormSection>
              )}

            {customerMatch &&
              !showApplicationButton &&
              !customerLedOnly &&
              !config.forceCustomerLed && (
                <FormProvider {...reactHookFormCustomerDetails}>
                  <form
                    onSubmit={handleSubmitCustomerDetails(formSubmitHandlerCustomerDetails)}
                    autoComplete="off"
                  >
                    <div
                      ref={(section: HTMLDivElement) => {
                        hiddenBlockRef = section;
                      }}
                    ></div>

                    <FormSection>
                      <hr />
                      <CustomerAddress existingCustomer={isExistingCustomer} editing />
                      <hr />
                    </FormSection>

                    <FormSection title="Employment, Benefits & Income">
                      <EmploymentDetails
                        existingCustomer={isExistingCustomer}
                        editing
                        allowIncomeEditing={!requireDepositBump}
                      />
                      <hr />
                    </FormSection>

                    <FormSection title="Customer Identification" text={CUSTOMER_IDENTIFICATION}>
                      <IdentificationDetails
                        existingCustomer={isExistingCustomer}
                        handleIdentificationType={(type: IdentificationType) => {
                          clearErrors('identificationDetails');
                          setIdentificationType(type);
                        }}
                        identificationType={identificationType}
                        editing
                      />
                    </FormSection>

                    <FormSection>
                      {checkoutErrorMessage && (
                        <div className="alert-container">
                          <Alert
                            title={'Application Initiation error'}
                            body={checkoutErrorMessage}
                            outcome="error"
                            icon={<IconAttention />}
                          />
                        </div>
                      )}
                      <div className="button-actiongroup-wrapper">
                        {/* <Button outline colour="blue" className="save-for-later">
                        Save for later
                      </Button>  */}
                        <Button
                          maxWidth="100%"
                          type="submit"
                          iconPosition="right"
                          icon={loadingCustomer || loadingCheckout ? <Spinner /> : undefined}
                          disabled={loadingCustomer || loadingCheckout}
                          colour="primary"
                          className="next"
                        >
                          Next
                        </Button>
                      </div>
                    </FormSection>
                  </form>
                </FormProvider>
              )}
          </div>
          <div style={{ display: mode === 'customer-search' ? 'block' : 'none' }}>
            <ExistingCustomerSearch
              handleChangeMode={customerTypeHandler}
              formSubmitCustomerLed={formSubmitCustomerLedForExistingCustomer}
              loanAmount={loanAmount}
              productLine={ProductLine.BNPL}
            />
          </div>
        </div>
      </LoggedInLayout>
    </SCCustomerDetails>
  );
};

export default CustomerDetails;
