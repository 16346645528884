import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCStep3 = styled.div`
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.altlight};
    margin: 2.23em 0 3em;
  }

  .card-details {
    & > div {
      margin-top: 1.6em;
    }
  }

  .header {
    margin: 0em 0;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        color: ${(props) => props.theme.colours.blue.base};
      }
      button {
        font-size: 0.86em;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
  }

  .payment-details,
  .payment-start-date {
    margin-bottom: 3em;

    ${media.min.medium} {
      margin-bottom: 5.5em;
    }
  }

  .save-payment-details {
    margin-bottom: 1.5em;

    ${media.min.medium} {
      margin-bottom: 1em;
      float: right;
    }
  }

  .expiry-csv {
    display: flex;

    .card-expiry {
      flex: 0.6;
      margin-right: 1em;
    }
    .card-csv {
      flex: 0.4;
      margin-left: 1em;
    }
    ${media.max.medium} {
      flex-direction: column;
      .card-expiry {
        margin-right: 0;
        margin-bottom: 1.6em;
      }
      .card-csv {
        margin-left: 0;
      }
    }
  }

  .back-to-step-1 {
    margin: 3em 0;
    text-align: center;
  }
`;

export default SCStep3;
