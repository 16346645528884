import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@payright/web-components';
import { SCMerchantDDOConsent } from './css';
import { updateMerchantDDOAcceptance } from '../../slices/merchant';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { RootState } from '@merchant-portal/slices';
import { AppDispatch } from '../../util/store';
import { useStoryblok } from '@merchant-portal/providers/storyblok-provider';

interface MerchantDDOConsentProps {
  merchantName?: String;
  closeModalProp: Function;
  showConsent?: Boolean;
  showCancel?: Boolean;
  showConfirm?: Boolean;
}

const MerchantDDOConsent = ({
  merchantName,
  closeModalProp,
  showConsent = true,
  showCancel = true,
  showConfirm = true,
}: MerchantDDOConsentProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { ddoUpdateErrorMessage, submitSucceeded, DDOLoading } = useSelector(
    (state: RootState) => state.merchant
  );
  const { dashboard, ddoTmd } = useStoryblok();

  const reactHookForm = useForm();
  const { handleSubmit } = reactHookForm;

  // form submit method
  const onSubmit = () => {
    dispatch(updateMerchantDDOAcceptance()).catch((errorMessage: string) => {
      console.log(errorMessage);
    });
  };

  useEffect(() => {
    if (submitSucceeded && !ddoUpdateErrorMessage && !DDOLoading) {
      closeModalProp();
    }
  }, [submitSucceeded, DDOLoading, ddoUpdateErrorMessage]);

  const [merchantDDOConsentPoints, setMerchantDDOConsentPoints] = useState<any | null>(null);

  const [DDOConsent1, setDDOConsent1] = useState(false);
  const [DDOConsent2, setDDOConsent2] = useState(false);
  const [DDOConsent3, setDDOConsent3] = useState(false);

  const handleClickConsent1 = () => {
    setDDOConsent1((prev) => !prev);
  };

  const handleClickConsent2 = () => {
    setDDOConsent2((prev) => !prev);
  };

  const handleClickConsent3 = () => {
    setDDOConsent3((prev) => !prev);
  };

  return (
    <SCMerchantDDOConsent>
      <FormProvider {...reactHookForm}>
        {showConsent && (
          <div className="consent-text-wrapper">
            <div className="first-check-consent">
              <Checkbox
                value="Agree"
                handleClick={handleClickConsent1}
                checked={DDOConsent1}
                name="agreement"
              >
                <p>
                  {dashboard?.merchant_ddo[0].ddo_consent_1.replace(
                    'merchant_name',
                    `${merchantName}`
                  )}
                </p>
              </Checkbox>
            </div>

            <div className="second-check-consent">
              <Checkbox
                value="Agree"
                handleClick={handleClickConsent2}
                checked={DDOConsent2}
                name="agreement"
              >
                <p>{dashboard?.merchant_ddo[0].ddo_consent_2}</p>
              </Checkbox>
            </div>

            <div className="third-check-consent">
              <Checkbox
                value="Agree"
                handleClick={handleClickConsent3}
                checked={DDOConsent3}
                name="agreement"
              >
                <p>{dashboard?.merchant_ddo[0].ddo_consent_3}</p>
              </Checkbox>
            </div>

            <div className="tmd">
              <p>{ddoTmd}</p>
            </div>
          </div>
        )}

        <div className={showCancel || showConfirm ? 'oneButtonDiv' : 'twoButtonDiv'}>
          {showCancel && (
            <div>
              <Button
                outline
                colour="blue"
                handleClick={() => closeModalProp()}
                className="actions"
              >
                {' '}
                Cancel{' '}
              </Button>
            </div>
          )}
          {showConfirm && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Button disabled={!DDOConsent1 || !DDOConsent2 || !DDOConsent3} className="actions">
                  {' '}
                  Confirm{' '}
                </Button>
              </div>
            </form>
          )}
        </div>
      </FormProvider>
    </SCMerchantDDOConsent>
  );
};

export default MerchantDDOConsent;
