import React, { useEffect } from 'react';
import {
  merchantCancelCheckout,
  resetMobileCheckout,
} from '@merchant-portal/slices/mobile-checkout';
import { useSelector, useDispatch } from '@merchant-portal/util/store';
import {
  Complete,
  ModalCustomerCancelled,
  ModalPayDifference,
  PollingLoader,
  SCMobileCheckout,
  useViewNavigator,
  View,
} from './index';

import MobileCheckoutForm, { useMobileCheckoutForm } from './forms/mobile-checkout-form';

const MobileCheckout = () => {
  const dispatch = useDispatch();

  const { hasErrors, errorMessage, preApproval } = useSelector((state) => state.mobileCheckout);

  const {
    view,
    showCancelledMessage,
    setShowCancelledMessage,
    showPayDifferenceMessage,
    setShowPayDifferenceMessage,
  } = useViewNavigator(preApproval);

  const {
    merchantRates: { rates },
  } = useSelector((state) => state.auth);

  const reactHookForm = useMobileCheckoutForm(view, preApproval, rates);
  const { reset, clearErrors } = reactHookForm;

  // useWebSocket(preApproval);

  // Reset the whole form on mount (i.e. when clicking on App Checkout, the whole form should start fresh without any stale values)
  useEffect(() => {
    dispatch(resetMobileCheckout());
  }, [dispatch]);

  // In case of cancel checkout we are setting the step back to checkout:merchant-action-required
  const cancelCheckout = () => {
    if (preApproval?.id) {
      dispatch(merchantCancelCheckout(preApproval.id)); // user will be taken back to View.ENTER_SALE_DETAILS screen
    }
  };

  // clear form
  const resetEntireFormAndState = () => {
    reset(); // Reset the React Hook Form to default values
    clearErrors();
    dispatch(resetMobileCheckout()); // Reset the Redux state
  };

  if (view === View.COMPLETE) {
    return (
      <SCMobileCheckout>
        <div className="checkout">
          <Complete resetHandler={resetEntireFormAndState} />
        </div>
      </SCMobileCheckout>
    );
  }

  return (
    <SCMobileCheckout>
      <div className="checkout">
        <ModalPayDifference
          show={showPayDifferenceMessage}
          closeHandler={() => {
            setShowPayDifferenceMessage(false);
          }}
        />
        <ModalCustomerCancelled
          show={showCancelledMessage}
          closeHandler={() => {
            setShowCancelledMessage(false);
          }}
        />
        <div className="checkout-heading">App checkout</div>
        <div className="checkout-text">
          For customers using the Payright mobile app to complete a purchase. Simply scan the
          barcode/QR code or enter the checkout code shown on their app.
        </div>
        <hr />
        <div>
          {errorMessage !== null && hasErrors && <div className="custom-error">{errorMessage}</div>}

          {(view === View.ENTER_CHECKOUT_CODE || view === View.ENTER_SALE_DETAILS) && (
            <MobileCheckoutForm view={view} reactHookForm={reactHookForm} />
          )}

          {/* Poll the server continuously for an update if the step is checkout:customer-action-required. 
            Hide the loader and stop polling if hasErrors is true i.e. network error. */}
          {view === View.WAITING_FOR_CUSTOMER && preApproval?.id && !hasErrors && (
            <PollingLoader planId={preApproval.id} handleCancelCheckout={cancelCheckout} />
          )}
        </div>
      </div>
    </SCMobileCheckout>
  );
};

export default MobileCheckout;
