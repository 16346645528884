import React from 'react';
import styled from 'styled-components';
import { Button, Card } from '@payright/web-components';

interface CtaCardProps {
  cardTitle?: string;
  buttonHref: string;
  buttonLabel: string;
}

const SCCtaCard = styled.div`
  .content {
    text-align: center;
  }
`;

const CtaCard = ({ cardTitle, buttonHref, buttonLabel }: CtaCardProps) => {
  return (
    <SCCtaCard>
      <Card title={cardTitle}>
        <div className="top"></div>
        <div className="center"></div>
        <div className="bottom">
          <a target="_blank" rel="noopener noreferrer" href={buttonHref}>
            <Button type="button">{buttonLabel}</Button>
          </a>
        </div>
      </Card>
    </SCCtaCard>
  );
};

export default CtaCard;
