import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayLoans, activateLoan, resendVerificationCode } from '@merchant-portal/slices/loans';
import {
  LoanListSettings,
  LoansListFilterDropdowns,
  LoansListFilterOptions,
  LoansListQueryParams,
} from '@merchant-portal/types/loans';
import { clientTimeZone, lowerCaseKeys, showHideItemsAndMenu } from '@merchant-portal/util/helper';
import { isEmptyObject } from '@merchant-portal/util/helper/checks';
import { useDebounce, usePrevious, useQuery } from '@merchant-portal/util/hooks';
import { AppDispatch } from '@merchant-portal/util/store';
import * as yup from 'yup';
import getConstants from '../../util/constants';
import { RootState } from '@merchant-portal/slices';
import LoggedInLayout from '@merchant-portal/layouts/logged-in-layout';
import SCMerchantLoans, { SCFilterMessage, SCMerchantNoLoans } from './css';
import {
  Button,
  IconActivate,
  IconAdd,
  MessageBox,
  ModalGeneric,
  ModalWrapper,
  Pagination,
  Search,
} from '@payright/web-components';
import Loader from '../../components/loader';
import {
  LOAN_NOT_FOUND,
  MERCHANT_LOANS_TABLE_HEADERS,
  LOAN_ACTIVATE,
  LOAN_ACTIVATION_CONFIRMATION,
  LOAN_ACTIVATION_MODAL_TITLE,
  LOAN_APPROVED_STATUS,
  LOAN_PENDING_STATUS,
  SUCCESSFUL_LOAN_ACTIVATION,
  REQUEST_FAILURE,
  LOAN_FILTER_MESSAGE,
  RESEND_VERIFICATION_CODE,
  SUCCESSFUL_RESEND_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE_CONFIRMATION,
} from '@merchant-portal/util/constants/defaults';
import { FilterDropdownOptions } from '@payright/web-components';
import { initNewLoan } from '@merchant-portal/slices/pplus-checkout';
import { Link, useHistory } from 'react-router-dom';
import MerchantLoansTable from '@merchant-portal/components/table/MerchantLoansTable';
import _, { isNumber } from 'lodash';
import SCSpinner from '@merchant-portal/components/spinner';
import { getMerchantDDODetails } from '@merchant-portal/slices/merchant';

const { PLAN_FILTER_OPTIONS, TIMEFRAMES } = getConstants();

const LoansList = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const loanTableStateKey = 'loansTableState';

  const querySearchParameter = query.get('search');
  const storedSettings: LoanListSettings = JSON.parse(
    localStorage.getItem(loanTableStateKey) ?? '{}'
  );

  const { accessItems } = showHideItemsAndMenu();

  const { submitSucceeded, errorMessage, merchantLoans, metadata, loading } = useSelector(
    (state: RootState) => state.loans
  );

  const defaultFilterParams: LoansListQueryParams = { page: 1 };

  const defaultInitState = {
    options: { ...(PLAN_FILTER_OPTIONS as LoansListFilterOptions) },
    dropdowns: {},
    searchQuery: '',
    params: defaultFilterParams,
  };

  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedLoanAction, setSelectedLoanAction] = useState('');
  const [selectedLoanId, setSelectedLoanId] = useState('');
  const [selectedCheckoutId, setSelectedCheckoutId] = useState('');

  const modalMessage = showActionMessage(selectedLoanAction);

  let showMessage;

  const [initialState] = useState<LoanListSettings & { params: LoansListQueryParams }>(() => {
    return getInitialState(querySearchParameter, storedSettings, defaultInitState);
  });

  const [selectedDropDowns, setSelectedDropDowns] = useState<LoansListFilterDropdowns>(
    initialState.dropdowns
  );
  const [selectedOptions, setSelectedOptions] = useState<LoansListFilterOptions>(
    initialState.options
  );
  const [searchQuery, setSearchQuery] = useState<string>(initialState.searchQuery);

  const [params, setParams] = useState<LoansListQueryParams>(initialState.params);

  const [searchTerm, setSearchTerm] = useState<string>(initialState.searchQuery ?? '');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const prevSearchTerm = usePrevious(debouncedSearchTerm);

  const prevParams = usePrevious(params);

  const prepareStateForLocalStorage = (): string => {
    let data: LoanListSettings = {
      options: selectedOptions,
      dropdowns: selectedDropDowns,
      searchQuery,
    };

    return JSON.stringify(data);
  };

  const [showDDOModal, setShowDDOModal] = useState(false);
  const { DDOLoading } = useSelector((state: RootState) => state.merchant);

  // Reload the displayed loans when ever the filter params change
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prevParams)) {
      return;
    }

    localStorage.setItem(loanTableStateKey, prepareStateForLocalStorage());
    dispatch(displayLoans(params));
  }, [params]);

  // Method to close Loan Activation Modal after message display
  const handleCloseActionModal = () => {
    setShowActionModal(false);
    dispatch(displayLoans(params));
  };

  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={modalMessage.successfulMessageText}
        message=""
        handleOnClick={handleCloseActionModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={REQUEST_FAILURE}
        message=""
        handleOnClick={handleCloseActionModal}
        error={true}
      />
    );
  }

  const loanList = merchantLoans;

  const handleDetailClick = (loanId: string) => {
    history.push(`/loan-detail/${loanId}`);
  };

  // Method to open the Action Modal
  const handleOpenActionModal = (loanAction: string, loanId: string, checkoutId?: string) => {
    setShowActionModal(true);
    setSelectedLoanId(loanId);
    setSelectedCheckoutId(checkoutId ?? '');
    setSelectedLoanAction(loanAction);
  };

  // set the current page number
  const handlePageChange = (selectedPage: any) => {
    setParams({
      ...params,
      page: selectedPage.selected + 1,
    });
  };

  const restructuredLoans = loanList.map((loan): any => {
    let displayActivateButton;

    let actions: Array<object> = [];

    const resendVerificationHandleClick = () =>
      handleOpenActionModal('Resend verification', loan.id, loan.checkoutId);

    // Build actions dropdown
    if (loan.status.toLowerCase() === LOAN_PENDING_STATUS) {
      actions.push({
        label: RESEND_VERIFICATION_CODE,
        icon: <IconActivate />,
        handleClick: resendVerificationHandleClick,
        handleClickId: loan.id,
      });
    }

    // Show 'Activate' button
    if (loan.status.toLowerCase() === LOAN_APPROVED_STATUS) {
      displayActivateButton = (
        <Button
          colour="green"
          handleClick={() => handleOpenActionModal('Activate', loan.id)}
          className="button-activate"
          disabled={!accessItems.activatePlans && !accessItems.administrator}
        >
          Activate
        </Button>
      );
    }

    // Show disabled 'Activate' button,
    // if loan has been approved,
    // 'outstanding establishment fee is not 0',
    // no payment start date, and
    // no consent approval
    if (
      loan.status.toLowerCase() === LOAN_APPROVED_STATUS &&
      (parseFloat(loan.establishmentFeeOutstanding) !== 0 ||
        !loan.paymentStartDate ||
        !loan.consentApproval)
    ) {
      displayActivateButton = (
        <Button colour="red" className="button-activate" disabled={true}>
          Activate
        </Button>
      );
    }

    const showActions = {
      handleDetailClick: () => {
        handleDetailClick(loan.id);
      },
      activationButton: displayActivateButton,
      otherActions: actions,
      tooltip: 'View Loan Details',
    };

    return {
      ID: loan.id,
      merchantLoanName: loan.name,
      merchantReference: loan.merchantReference,
      dateCreated: DateTime.fromISO(loan.dateCreated, { zone: clientTimeZone() }).toFormat(
        'yyyy-MM-dd'
      ),
      customerName: loan.customerName,
      staffName: loan.staffName,
      status: loan.status.toLowerCase(),
      actions: showActions,
      expandMerchant: true,
    };
  });

  // A new map is running to create a new array object for subrows
  const restructuredSubLoanRows = loanList.map((loan): any => {
    return {
      purchasePrice: loan.saleAmount,
      establishmentFee: loan.establishmentFee,
      creditAmount: loan.creditAmount,
      loanSettled: loan.merchantSettled,
      settledDate: loan.finalSettlementDate,
      repaymentFrequency: loan.repaymentFrequency,
      MSF: loan.msf,
    };
  });

  // reset the filters
  const handleResetFiltersAndSearch = () => {
    // handleUpdateFilters({});
    setSearchTerm('');
  };

  // Search
  // - Don't search if the searchTerm if it is equal to previouse
  // - Set the searchQuery via the beginPlanSearch action - this kicks off a useEffect
  // for the search function

  useEffect(() => {
    if (debouncedSearchTerm !== prevSearchTerm) handleSearch(searchTerm);
  }, [debouncedSearchTerm, prevSearchTerm, searchTerm]);

  // Builds the searchParams and sets the 'params' state if the it's not empty
  const handleSearch = (search: string) => {
    setSearchQuery(search);
    const searchParams = mapSearchQueryToFilters(search);
    if (search !== '') {
      setParams({
        ...params,
        page: 1,
        ...searchParams,
      });
    } else {
      const cleanParams = _.omit(params, Object.keys(searchParams));
      setParams({
        ...cleanParams,
        page: 1,
      });
    }
  };

  return (
    <>
      <SCMerchantLoans>
        <LoggedInLayout activePage="/loans" contentMarginTop={2}>
          <div className="loans-body">
            <div className="header">
              <h2>PayrightPlus Loans</h2>

              <div className="actions">
                {accessItems.createLoan ? (
                  <Button
                    withShadow
                    icon={DDOLoading ? <SCSpinner /> : <IconAdd />}
                    handleClick={() => {
                      //show popup if a merchant's ddo details are blank, and if the end date has passed
                      dispatch(getMerchantDDODetails())
                        .then((ddoDetails) => {
                          if (ddoDetails.shouldBlockLoanCreation) {
                            setShowDDOModal(true);
                          } else {
                            setShowDDOModal(false);
                            dispatch(initNewLoan());
                            history.push('/loans/new');
                          }
                        })
                        .catch((errorMessage: string) => {
                          console.log(errorMessage);
                        });
                    }}
                  >
                    New Loan
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>

            <Search
              placeholder="Search Loans"
              searchText={searchTerm}
              inputChangeHandler={setSearchTerm}
              // filterCheckboxes={selectedOptions}
              // filterDropdowns={filterDropDown(selectedDropDowns)}
              // updateFiltersHandler={handleUpdateFilters}
              // resetFiltersHandler={handleResetFilters}
            />

            {loading ? (
              <Loader />
            ) : (
              <>
                {/* add the seach params to the show/hide */}
                {Object.values(selectedOptions).includes(true) ||
                JSON.stringify(selectedDropDowns) !== '{}' ||
                searchQuery !== '' ? (
                  <SCFilterMessage>
                    {LOAN_FILTER_MESSAGE}
                    <Link to="#" onClick={handleResetFiltersAndSearch}>
                      {''}
                      click here
                    </Link>
                  </SCFilterMessage>
                ) : (
                  ''
                )}
                {loanList.length > 0 ? (
                  [
                    <>
                      {/* // Activate loan modal */}
                      <div>
                        <ModalWrapper
                          open={showActionModal}
                          handleClose={() => setShowActionModal(false)}
                          background="dark"
                        >
                          {!submitSucceeded && !errorMessage ? (
                            <ModalGeneric
                              title={modalMessage.ModalTitle}
                              text={modalMessage.confirmationMessageText}
                              primaryButton={
                                <Button
                                  handleClick={() => {
                                    selectedLoanAction !== LOAN_ACTIVATE
                                      ? dispatch(resendVerificationCode(selectedCheckoutId))
                                      : dispatch(activateLoan('activate', selectedLoanId));
                                  }}
                                >
                                  {selectedLoanAction !== LOAN_ACTIVATE ? 'Resend' : 'Activate'}
                                </Button>
                              }
                              secondaryButton={
                                <Button
                                  outline
                                  colour="blue"
                                  handleClick={() => setShowActionModal(false)}
                                >
                                  Cancel
                                </Button>
                              }
                            ></ModalGeneric>
                          ) : (
                            <div className="message">{showMessage}</div>
                          )}
                        </ModalWrapper>
                      </div>

                      <MerchantLoansTable
                        tableRows={restructuredLoans}
                        subTableRows={restructuredSubLoanRows}
                        tableHeaders={MERCHANT_LOANS_TABLE_HEADERS}
                        tableSelect={false}
                      />
                      <div>
                        {metadata.count > 1 && (
                          <Pagination
                            pageCount={metadata.lastPage}
                            handlePageChange={handlePageChange}
                            currentPage={metadata.currentPage - 1}
                          />
                        )}
                      </div>
                    </>,
                  ]
                ) : (
                  <SCMerchantNoLoans>{LOAN_NOT_FOUND}</SCMerchantNoLoans>
                )}
              </>
            )}
          </div>
        </LoggedInLayout>
      </SCMerchantLoans>
    </>
  );
};

/** Method to create a filter drop down in search */
const filterDropDown = (
  // staffList: Array<any>,
  selectedDropDowns: LoansListFilterDropdowns
): Array<FilterDropdownOptions> => {
  return [
    {
      id: 'timeFrames',
      label: 'Settled',
      placeholder: 'All Time',
      options: TIMEFRAMES,
      selected: selectedDropDowns?.timeFrames ?? '',
    },
  ];
};

// Todo: create type for options
const mapOptionsToFilters = (options: LoansListFilterOptions): LoansListQueryParams => {
  if (options) {
    const filterOptions = lowerCaseKeys(options);

    Object.keys(filterOptions).forEach((obj) => {
      if (filterOptions[obj] === true) {
        return obj;
      } else {
        delete filterOptions[obj];
      }
    });

    let validKeys: any = Object.keys(filterOptions);

    return { ['filter[status][in]']: validKeys };
  }

  return {};
};

// Todo: create type for options
const mapDropdownsToFilters = (dropdowns: LoansListFilterDropdowns): LoansListQueryParams => {
  let filterParams: LoansListQueryParams = {};

  if (dropdowns?.staffMember) {
    filterParams['filter[staffId][equals]'] = dropdowns.staffMember;
  }

  if (dropdowns?.timeFrames) {
    var dt = DateTime.now();
    filterParams['filter[createdAt][gte]'] = dt
      .minus({ days: parseInt(dropdowns.timeFrames) })
      .toISODate();
  }

  return filterParams;
};

const mapSearchQueryToFilters = (searchQuery: string | undefined): LoansListQueryParams => {
  let searchParams: any = {};
  searchParams['filter[0][or][0][customerFirstName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][customerLastName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][loanName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][reference][contains]'] = searchQuery;
  searchParams['filter[0][or][0][staffFirstName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][staffLastName][contains]'] = searchQuery;

  return searchParams;
};

const getInitialState = (
  querySearchParameter: string | null,
  storedSettings: LoanListSettings,
  defaultInitState: LoanListSettings & { params: LoansListQueryParams }
): LoanListSettings & { params: LoansListQueryParams } => {
  // Use query string search if it's present
  const queryOptions = { ...defaultInitState.options };
  Object.keys(queryOptions).forEach((obj) => {
    queryOptions[obj as keyof LoansListFilterOptions] = obj === querySearchParameter ? true : false;
  });

  // Use query string 'search' for initial state of search/filter
  if (
    Object.keys(queryOptions).some(
      (key) => queryOptions[key as keyof LoansListFilterOptions] === true
    )
  ) {
    const optionsFilter = mapOptionsToFilters(queryOptions);

    return {
      options: queryOptions,
      dropdowns: {},
      searchQuery: '',
      params: {
        ...defaultInitState.params,
        ...optionsFilter,
      },
    };
  }

  // Restore localStorage filter/search settings
  if (!isEmptyObject(storedSettings)) {
    const safeSettings = validateStoredSettings(storedSettings, defaultInitState);

    const { options, dropdowns, searchQuery } = safeSettings;
    const storedFilters = mapFilters(safeSettings);

    return {
      options,
      dropdowns,
      searchQuery,
      params: {
        ...defaultInitState.params,
        ...storedFilters,
      },
    };
  }

  // Use default
  return defaultInitState;
};

const mapFilters = (state: {
  options: LoansListFilterOptions;
  dropdowns: LoansListFilterDropdowns;
  searchQuery: string;
}): LoansListQueryParams => {
  const { options, dropdowns, searchQuery } = state;

  const optionsFilter = mapOptionsToFilters(options);
  const dropdownFilters = mapDropdownsToFilters(dropdowns);
  const searchFilter = searchQuery !== '' ? mapSearchQueryToFilters(searchQuery) : {};

  return {
    ...optionsFilter,
    ...dropdownFilters,
    ...searchFilter,
  };
};

const validateStoredSettings = (
  storedSettings: LoanListSettings,
  defaultSettings: LoanListSettings
): LoanListSettings => {
  const { options, dropdowns, searchQuery } = storedSettings;
  const optionsSchema: yup.SchemaOf<LoansListFilterOptions> = yup.object({
    Active: yup.boolean().required(),
    Approved: yup.boolean().required(),
    Pending: yup.boolean().required(),
    Declined: yup.boolean().required(),
    Closed: yup.boolean().required(),
    Cancelled: yup.boolean().required(),
    Review: yup.boolean().required(),
    'Approved Pending ID': yup.boolean().required(),
    'Approved Statement Requested': yup.boolean().required(),
  });

  const dropdownSchema: yup.SchemaOf<LoansListFilterDropdowns> = yup.object({
    staffMember: yup.string().optional(),
    timeFrames: yup
      .string()
      .transform((value) => (isNumber(value) ? value.toString() : value))
      .optional(),
  });

  // Validations
  const validOptions = optionsSchema.isValidSync(options, {
    abortEarly: true,
  })
    ? options
    : defaultSettings.options;

  const validDropdowns = dropdownSchema.isValidSync(dropdowns, {})
    ? dropdowns
    : defaultSettings.dropdowns;

  const validSearchQuery = yup.string().isValidSync(searchQuery)
    ? searchQuery
    : defaultSettings.searchQuery;

  return {
    options: { ...validOptions },
    dropdowns: { ...validDropdowns },
    searchQuery: validSearchQuery,
  };
};

/** Method to select the message text/title/confirmation on the basis of loan action*/
const showActionMessage = (loanAction: string) => {
  let confirmationMessageText = '';
  let successfulMessageText = '';
  let ModalTitle = '';
  switch (loanAction) {
    case LOAN_ACTIVATE:
      confirmationMessageText = LOAN_ACTIVATION_CONFIRMATION;
      successfulMessageText = SUCCESSFUL_LOAN_ACTIVATION;
      ModalTitle = LOAN_ACTIVATION_MODAL_TITLE;
      break;
    case RESEND_VERIFICATION_CODE:
      confirmationMessageText = RESEND_VERIFICATION_CODE_CONFIRMATION;
      successfulMessageText = SUCCESSFUL_RESEND_VERIFICATION_CODE;
      ModalTitle = RESEND_VERIFICATION_CODE;
      break;
    default:
  }
  return { confirmationMessageText, successfulMessageText, ModalTitle };
};

export default LoansList;
