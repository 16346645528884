import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCVerificationCode = styled.div`
  .content {
    background-color: ${(props) => props.theme.colours.blue.bg};
    border-radius: 5px;
    padding: 2.8em 2.6em;

    ${media.max.tablet} {
      padding: 1.33em;
    }

    p {
      margin: 1.73em 0;
      line-height: 1.86;
      font-size: 0.93rem;

      ${media.max.tablet} {
        font-size: 0.86em;
      }

      a {
        font-weight: bold;
        text-decoration: none;
        border-bottom: 1px solid ${(props) => props.theme.colours.grey.altmedium};
      }
    }

    h5,
    p {
      color: ${(props) => props.theme.colours.blue.base};
    }

    .verification-code {
      width: 80%;
      padding-right: 10px;
    }

    .section-button {
      margin-top: 10px;
      flex-basis: calc(75% - 110px);
    }

    .summary-text-block {
      margin-bottom: 1.72em;

      .policy {
        color: ${(props) => props.theme.colours.blue.base};
        list-style: disc;
        line-height: 1.86;
        margin-left: 2em;

        div {
          margin-left: 2em;

          ul {
            list-style: disc;
          }
        }
      }
    }
  }

  .cancel-modal-content {
    display: none;
  }

  .submit-button {
    margin-top: 2em;
  }
  .cancel-button-secondary {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .italic {
    font-style: italic;
  }
`;

export default SCVerificationCode;
