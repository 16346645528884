import { AppThunk } from '@merchant-portal/util/store';
import MerchantAPI, {
  LoanStatus,
  LoanDetailsSuccessResponse,
  MerchantLoanData,
} from '@merchant-portal/util/merchant-api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loading, loadingFunctionInterface } from '@merchant-portal/util/loadingFunctionInterface';
import { LoanAction, LoansListMetaData } from '@merchant-portal/types/loans';
import { CustomerResourceBasic } from '@merchant-portal/types/customer';

type InitialState = {
  loading?: boolean;
  activeRequests: Array<loansRequestId>;
  merchantLoans: MerchantLoanData;
  metadata: LoansListMetaData;
  errorMessage?: string | null;
  submitSucceeded?: boolean;
  loanDetails: LoanDetailsSuccessResponse;
  loanCustomer: CustomerResourceBasic;
};

export const initialState: InitialState = {
  loading: false,
  activeRequests: [],
  merchantLoans: [],
  metadata: {
    count: 0,
    currentPage: 1,
    lastPage: 0,
    limit: 10,
  },
  errorMessage: null,
  submitSucceeded: false,
  loanDetails: {
    id: '',
    depositPaymentStatus: '',
    numberOfRepayments: '',
    establishmentFee: '',
    repaymentFrequency: '',
    loanAmountPerPayment: '',
    repaymentAmount: '',
    depositPaid: '',
    totalCreditAmount: '',
    terms: '',
    saleAmount: '',
    customerId: '',
    status: LoanStatus.UNDEFINED,
    depositPaidPercentage: '',
    loanBlockActivation: false,
    loanPurpose: '',
    interestFreeRepayments: '',
    interestBearingRepayments: '',
    interestFreeRepaymentAmount: '',
    interestBearingRepaymentAmount: '',
    loanManagementFee: '',
    establishmentFeeOutstanding: '',
    paymentStartDate: '',
    consentApproval: '',
  },
  loanCustomer: {
    id: null,
    customerNumber: '',
    customerDetails: {
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',
      confirmEmail: '',
      dateOfBirth: '',
      consentPromoMaterial: false,
      isIdVerified: false,
    },
  },
};

type loansRequestId =
  | 'getLoans'
  | 'updateLoanStatus'
  | 'getLoanDetail'
  | 'getCustomerDetail'
  | 'resendVerificationCode';

const startLoading: loadingFunctionInterface<loansRequestId> = (
  activeRequests: Array<loansRequestId>,
  requestId: loansRequestId
): loading<loansRequestId> => {
  activeRequests = [...activeRequests, requestId];
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const finishLoading: loadingFunctionInterface<loansRequestId> = (
  activeRequests: Array<loansRequestId>,
  requestId: loansRequestId
): loading<loansRequestId> => {
  activeRequests = activeRequests.filter((item) => item !== requestId);
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    getLoansBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getLoans');
      return {
        ...state,
        merchantLoans: initialState.merchantLoans,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loanDetails: initialState.loanDetails,
        loanCustomer: initialState.loanCustomer,
      };
    },
    getLoansSuccess: (state, { payload }: PayloadAction<any>) => {
      const loadingState = finishLoading(state.activeRequests, 'getLoans');
      return {
        ...state,
        merchantLoans: payload.loans,
        metadata: payload.metadata,
        submitSucceeded: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getLoansFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getLoans');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    activateLoanBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'updateLoanStatus');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loanDetails: initialState.loanDetails,
        loanCustomer: initialState.loanCustomer,
      };
    },
    activateLoanSuccess: (state) => {
      const loadingState = finishLoading(state.activeRequests, 'updateLoanStatus');
      return {
        ...state,
        submitSucceeded: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    activateLoanFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'updateLoanStatus');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getLoanDetailBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getLoanDetail');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loanDetails: initialState.loanDetails,
        loanCustomer: initialState.loanCustomer,
      };
    },
    getLoanDetailSuccess: (state, action: PayloadAction<LoanDetailsSuccessResponse>) => {
      const loadingState = finishLoading(state.activeRequests, 'getLoanDetail');
      return {
        ...state,
        loanDetails: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    getLoanDetailFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getLoanDetail');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCustomerDetailBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getCustomerDetail');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loanDetails: initialState.loanDetails,
        loanCustomer: initialState.loanCustomer,
      };
    },
    getCustomerDetailSuccess: (state, action: PayloadAction<CustomerResourceBasic>) => {
      const loadingState = finishLoading(state.activeRequests, 'getCustomerDetail');
      return {
        ...state,
        loanCustomer: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getCustomerDetailFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getCustomerDetail');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    resendPplusVerificationCodeBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'resendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    resendPplusVerificationCodeSuccess: (state) => {
      const loadingState = finishLoading(state.activeRequests, 'resendVerificationCode');
      return {
        ...state,
        submitSucceeded: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    resendPplusVerificationCodeFailure: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      const loadingState = finishLoading(state.activeRequests, 'resendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  getLoansBegin,
  getLoansSuccess,
  getLoansFailure,
  activateLoanBegin,
  activateLoanSuccess,
  activateLoanFailure,
  getLoanDetailBegin,
  getLoanDetailSuccess,
  getLoanDetailFailure,
  getCustomerDetailBegin,
  getCustomerDetailSuccess,
  getCustomerDetailFailure,
  resendPplusVerificationCodeBegin,
  resendPplusVerificationCodeSuccess,
  resendPplusVerificationCodeFailure,
} = loansSlice.actions;
export default loansSlice.reducer;

export const displayLoans =
  (params?: any): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(getLoansBegin());

      const fetchLoans: any = await MerchantAPI.loans(params);

      dispatch(getLoansSuccess(fetchLoans));
    } catch (e: any) {
      dispatch(getLoansFailure({ errorMessage: e.message }));
    }
  };

export const activateLoan =
  (action: LoanAction, loanId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(activateLoanBegin());
      await MerchantAPI.activateLoan(action, loanId);
      dispatch(activateLoanSuccess());
    } catch (e: any) {
      dispatch(activateLoanFailure({ errorMessage: e.message }));
    }
  };

export const displayLoanDetails =
  (loanId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getLoanDetailBegin());
      // dispatch(getCustomerDetailBegin());

      const fetchLoanDetails: LoanDetailsSuccessResponse = await MerchantAPI.loanDetails(loanId);
      dispatch(getLoanDetailSuccess(fetchLoanDetails));

      const fetchCustomerDetails: CustomerResourceBasic = await MerchantAPI.getLoanCustomer(loanId);
      dispatch(getCustomerDetailSuccess(fetchCustomerDetails));
    } catch (e: any) {
      dispatch(getLoanDetailFailure({ errorMessage: e.message }));
      dispatch(getCustomerDetailFailure({ errorMessage: e.message }));
    }
  };

export const resendVerificationCode =
  (checkoutId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resendPplusVerificationCodeBegin());
      await MerchantAPI.resendPplusVerificationCode(checkoutId);
      dispatch(resendPplusVerificationCodeSuccess());
    } catch (e: any) {
      dispatch(resendPplusVerificationCodeFailure({ errorMessage: e.message }));
    }
  };
