import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../util/store';
import MerchantAPI, {
  ProfileDetailSuccessResponse,
  ResetPasswordSuccess,
} from '../util/merchant-api';

type InitialState = {
  loading?: boolean;
  hasError?: boolean;
  submitSucceeded?: boolean;
  errorMessage?: string | null;
  profileDetails: ProfileDetailSuccessResponse;
  resetStaffPassword: ResetPasswordSuccess;
};

export const initialState: InitialState = {
  loading: false,
  hasError: false,
  errorMessage: null,
  submitSucceeded: false,
  profileDetails: {
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneMobile: '',
    phoneWork: '',
    email: '',
    password: '',
    confirmPassword: '',
  },
  resetStaffPassword: {
    temporaryPassword: '',
    password: '',
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getMyProfileBegin: (state) => {
      return {
        ...state,
        loading: true,
        submitSucceeded: false,
      };
    },

    getMyProfileDetailSuccess: (state, action: PayloadAction<ProfileDetailSuccessResponse>) => {
      return {
        ...state,
        loading: false,
        profileDetails: action.payload,
        hasErrors: false,
      };
    },

    getMyProfileFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
        submitSucceeded: false,
      };
    },

    updateMyProfileDetailSuccess: (state) => {
      return {
        ...state,
        loading: false,
        submitSucceeded: true,
        hasErrors: false,
      };
    },

    resetPasswordSuccess: (state) => {
      return {
        ...state,
        loading: false,
        submitSucceeded: true,
        hasErrors: false,
      };
    },
  },
});

const {
  getMyProfileBegin,
  getMyProfileFailure,
  updateMyProfileDetailSuccess,
  getMyProfileDetailSuccess,
  resetPasswordSuccess,
} = profileSlice.actions;
export default profileSlice.reducer;

//Thunk to update staff

export const updateMyProfileDetails =
  (updateProfileInfo: any): AppThunk =>
  async (dispatch) => {
    try {
      await MerchantAPI.myProfileUpdate(updateProfileInfo);
      dispatch(updateMyProfileDetailSuccess());
    } catch (e: any) {
      dispatch(getMyProfileFailure({ errorMessage: e.message }));
    }
  };

// Thunk to display staff detail
export const displayMyProfileDetails = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getMyProfileBegin());
    const fetchProfileDetails: ProfileDetailSuccessResponse = await MerchantAPI.myProfileDetails();
    dispatch(getMyProfileDetailSuccess(fetchProfileDetails));
  } catch (e: any) {
    dispatch(getMyProfileFailure({ errorMessage: e.message }));
  }
};

export const resetLoggedInStaffPassword =
  (tempAndNewpassword: { password: string; temporaryPassword: string }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getMyProfileBegin());
      await MerchantAPI.resetPassword(tempAndNewpassword);
      dispatch(resetPasswordSuccess());
    } catch (e: any) {
      dispatch(getMyProfileFailure({ errorMessage: e.message }));
    }
  };
