import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media, SelectableCard } from '@payright/web-components';
import { ControlledInputField, ControlledDatePicker } from '../form-fields';
import LicenceDetails from './licence-details';
import { Row, Col } from 'react-flexbox-grid';
import { useFormContext } from 'react-hook-form';
import {
  IdentificationDetailsData as MerchantApiIdentificationDetailsData,
  IdentificationType as MerchantApiIdentificationType,
} from '../../types/customer';
import getConstants from '../../util/constants';
import { useIsNz } from '@merchant-portal/util/hooks';

const { IDENTIFICATION_YEARS } = getConstants();
const isNz = useIsNz();

export type IdentificationDetailsData = MerchantApiIdentificationDetailsData;
export type IdentificationType = MerchantApiIdentificationType;

export interface IdentificationDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
  handleIdentificationType: Function;
  identificationType: IdentificationType;
}

const SCIdentificationDetails = styled.div`
  width: 100%;
  margin-bottom: 3em;

  .details-body {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }

  .selectedCustomClass {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    .title {
      color: ${(props) => props.theme.colours.blue.base};
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
      flex: 1;
      padding-right: 16px;
    }

    ${media.min.tablet} {
      .title {
        font-size: 0.8em;
        padding: 0;
      }
    }

    ${media.min.large} {
      .title {
        font-size: 1.2em;
      }

      flex-grow: unset;
    }

    .body {
      margin-top: 0.5em;
      line-height: 1.4;
      font-size: 0.86em;
    }
  }

  .radio-button {
    div {
      width: 32px;

      ${media.min.large} {
        width: 24px;
      }
    }

    .checkmark {
      height: 24px;
      width: 24px;

      ${media.min.large} {
        height: 28px;
        width: 28px;
        margin-bottom: unset;
      }
    }

    & .checkmark:after {
      top: 5px;
      left: 6px;
      right: 6px;
      width: 12px;
      height: 12px;
      margin-bottom: 30px;
      border-radius: 50%;
      background: ${(props) => props.theme.colours.blue.base};

      ${media.min.large} {
        top: 5px;
        left: 6px;
        right: 6px;
        margin-bottom: unset;
        width: 16px;
        height: 16px;
      }
    }
  }

  ${media.max.medium} {
    .input-field,
    .dropdown {
      margin-bottom: 1.33em;
    }

    .dropdown {
      max-width: 100%;
    }
  }

  ${media.min.medium} {
    .input-field {
      margin-bottom: 2.46em;
    }

    .passport-expiry-wrapper {
      .input-field {
        flex: calc(50% - 0.75em);
        margin-bottom: 1em;
      }

      & > .dropdown {
        width: calc(50% - 0.75em);
      }
    }
  }

  .identificationtype-wrapper {
    padding: 0px 0px 10px 0px;
    display: flex;
  }

  .identificationtype-item {
    padding: 10px 10px 10px 0px;
  }

  .date-picker__inputs {
    .dropdown__placeholder {
      padding-right: 5px;
    }
  }

  ${media.min.large} {
    .passport-expiry-wrapper {
      width: calc(50% - 0.75em);

      .date-picker {
        width: 100%;
        margin-left: 0;
      }
    }

    .input-field {
      margin-bottom: 2.46em;
    }
  }
`;

const IdentificationDetails = ({
  existingCustomer,
  editing,
  handleIdentificationType,
  identificationType,
}: IdentificationDetailsProps) => {
  const { errors, setValue, register, clearErrors } = useFormContext<{
    identificationType: IdentificationType;
    identificationDetails: IdentificationDetailsData;
  }>();

  useEffect(() => {
    register({ name: 'identificationDetails.identificationType' });
    clearErrors('identificationDetails.identificationType');
    setValue('identificationDetails.identificationType', identificationType);
  }, [identificationType, register, setValue, clearErrors]);

  const xsColWidth = !isNz ? 12 : 12;
  const mdColWidth = !isNz ? 4 : 6;
  const lgColWidth = !isNz ? 4 : 6;

  return (
    <SCIdentificationDetails id="IdentificationSection">
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Row>
            <Col xs={xsColWidth} md={mdColWidth} lg={lgColWidth} style={{ marginTop: '1em' }}>
              <SelectableCard
                selected={identificationType === 'license'}
                handleSelect={handleIdentificationType}
                title="Licence No."
                name={'license' as IdentificationType}
                body=""
                customClass="selectedCustomClass"
              />
            </Col>
            <Col xs={xsColWidth} md={mdColWidth} lg={lgColWidth} style={{ marginTop: '1em' }}>
              <SelectableCard
                selected={identificationType === 'passport'}
                handleSelect={handleIdentificationType}
                title="Passport"
                name={'passport' as IdentificationType}
                body=""
                customClass="selectedCustomClass"
              />
            </Col>
            {!isNz && (
              <Col xs={xsColWidth} md={mdColWidth} lg={lgColWidth} style={{ marginTop: '1em' }}>
                <SelectableCard
                  selected={identificationType === 'other'}
                  handleSelect={handleIdentificationType}
                  title="Other"
                  name={'other' as IdentificationType}
                  body=""
                  customClass="selectedCustomClass"
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {identificationType === 'license' && (
        <LicenceDetails existingCustomer={existingCustomer} editing={editing} />
      )}

      {identificationType === 'passport' && (
        <div className="details-body">
          <ControlledInputField
            name="identificationDetails.passportNumber"
            rules={{
              required: 'Passport number is required',

              pattern: {
                value: /^(?!^0+$)[a-zA-Z0-9]{4,20}$/,
                message: 'Invalid passport number',
              },
            }}
            error={(errors.identificationDetails as any)?.passportNumber?.message as string}
            readOnly={existingCustomer && !editing}
          >
            Number *
          </ControlledInputField>

          <div className="passport-expiry-wrapper">
            <ControlledDatePicker
              name="identificationDetails.passportExpiry"
              rules={{
                required: 'Passport Expiry is required',
                validate: (value: any) => {
                  const expiry = new Date(value);
                  const today = new Date();
                  today.setDate(today.getDate() + 1);
                  if (expiry.getTime() < today.getTime()) {
                    return 'Enter a valid expiry';
                  }
                },
              }}
              error={(errors.identificationDetails as any)?.passportExpiry?.message as string}
              readOnly={existingCustomer && !editing}
              startYear={new Date().getFullYear()}
              endYear={new Date().getFullYear() + IDENTIFICATION_YEARS}
            >
              Expiry *
            </ControlledDatePicker>
          </div>
        </div>
      )}
      {!isNz && identificationType === 'other' && (
        <div className="details-body">
          <ControlledInputField
            rules={{
              required: 'Other ID is required',
              minLength: 2 || 'ID needs to a min of 2 characters',
            }}
            error={(errors.identificationDetails as any)?.otherIdType?.message as string}
            name="identificationDetails.otherIdType"
          >
            Other ID Type *(Specify type of photo ID being provided)
          </ControlledInputField>
        </div>
      )}
    </SCIdentificationDetails>
  );
};
export default IdentificationDetails;
