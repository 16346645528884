import React from 'react';
import LoggedInLayout from '../../layouts/logged-in-layout';
import { MerchantRatesTable } from '@payright/web-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../slices';
import Loader from '../../components/loader';
import SCMerchantRates, { SCMerchantNoRates } from './css';
import getConstants from '../../util/constants';

const { RATES_NOT_FOUND } = getConstants();

const ratesHeaders = [
  { name: 'Term', id: 'ratesTerm', sortable: true },
  { name: 'Min Amount ($)', id: 'minimumPurchase', sortable: true },
  { name: 'Max Amount ($)', id: 'maximumPurchase', sortable: true },
  { name: 'Min Deposit (%)', id: 'minimumDepositPercentage', sortable: true },
  {
    name: 'Merchant Fees by Deposit ($)',
    id: 'merchantFees',
    sortable: false,
    children: [
      { name: '0%', id: 'rate00' },
      { name: '5%', id: 'rate05' },
      { name: '10%', id: 'rate10' },
      { name: '15%', id: 'rate15' },
      { name: '20%', id: 'rate20' },
      { name: '25%', id: 'rate25' },
      { name: '30%', id: 'rate30' },
      { name: '35%', id: 'rate35' },
      { name: '40%', id: 'rate40' },
      { name: '45%', id: 'rate45' },
      { name: '50%', id: 'rate50' },
    ],
  },
];

const Rates = () => {
  // get the rates and error message from logged in merchant
  const { errorMessage, merchantRates, loading } = useSelector((state: RootState) => state.auth);
  // restructured the api response
  const restucturedRatesObject = merchantRates.rates.map((rate, index) => {
    return {
      ID: index + 1, // have to do as web-componenet is not closing the expand rates if index is 0
      ratesTerm: rate.term + ' Months',
      minimumPurchase: rate.minimumPurchase,
      maximumPurchase: rate.maximumPurchase,
      minimumDepositPercentage: rate.minimumDepositPercentage + '%',
      merchantFees: {
        rate00: rate.rate00,
        rate05: rate.rate05,
        rate10: rate.rate10,
        rate15: rate.rate15,
        rate20: rate.rate20,
        rate25: rate.rate25,
        rate30: rate.rate30,
        rate35: rate.rate35,
        rate40: rate.rate40,
        rate45: rate.rate45,
        rate50: rate.rate50,
      },
    };
  });

  return (
    <SCMerchantRates>
      <LoggedInLayout activePage="/rates">
        <div className="rates-body">
          <div className="header">
            <h2>Rates</h2>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="errorMessage">{errorMessage === null ? errorMessage : ''}</div>
              {restucturedRatesObject.length > 0 ? (
                <MerchantRatesTable
                  tableHeaders={ratesHeaders}
                  tableRows={restucturedRatesObject}
                  doubleHeader
                  merchantRates
                />
              ) : (
                <SCMerchantNoRates>{RATES_NOT_FOUND}</SCMerchantNoRates>
              )}
            </>
          )}
        </div>
      </LoggedInLayout>
    </SCMerchantRates>
  );
};

export default Rates;
