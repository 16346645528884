import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCMerchantLoans = styled.div`
  background: ${(props) => props.theme.colours.grey.altlight};

  body {
    background: ${(props) => props.theme.colours.grey.dark};
  }
  .alert {
    margin-bottom: 2em;
  }
  .loans-body {
    width: 100%;
    margin: 4em auto 0;

    ${media.min.large} {
      padding: 0 10px 0 10px;

      .row {
        display: table-row;
      }
    }
    .paid-pill {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
      background-color: #defbe7;
      border: 1px solid #40ea77;
      border-radius: 1rem;
    }
    .button-activate {
      font-weight: bold;
      height: 40px;
      padding: 0 0.7em;
      display: flex;
      align-items: center;
      position: relative;
      max-width: 130px;
      width: 125px;

      span {
        color: black;
      }
    }
    .button-other-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 100px;
      width: 100px;
      padding-right: 10px;
      text-align: center;
      font-size: 0.8em;

      ${media.min.large} {
        font-size: 0.8em;
        max-width: unset;
        width: unset;
      }
    }
    .message {
      text-align: center;

      .title {
        font-size: 20px;
        padding: 10px 10px 10px 10px;

        svg {
          fill: ${(props) => props.theme.colours.blue.base};
          margin-right: 0.5em;
          margin-top: 0.1em;
        }
      }
    }
    .errorMessage {
      text-align: center;
      margin: 4em 0 2em 0;
      color: red;
      font-size: 1em;
    }
    /* web-components overrides */
    thead {
      tr {
        th:nth-child(1) > span {
          max-width: 100px;
          width: 100px;
        }
        /* depositStatus column */
        th:nth-child(6) {
          max-width: 150px;
          width: 150px;
        }
        /* expandMerchant column */
        th:nth-child(8) {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td.merchantLoanName > div {
          max-width: 200px;
          white-space: pre-wrap;
          word-break: initial;
          overflow-wrap: break-word;
        }
        td.customerName > div,
        td.staffName > div {
          max-width: 200px;
          white-space: pre-wrap;
          word-break: initial;
          word-wrap: break-word;
        }
        td.dateCreated > div {
          max-width: 150px;
          width: 100px;
        }
        td.status > div > h6 {
          max-width: 150px;
          width: 50%;
          word-break: initial;
        }
        /* PT-73 Override margin-top on larger screens for depositStatus column only */
        td.depositStatus > div {
          margin-top: 0 !important;
        }
      }
    }
    ${media.max.large} {
      padding: 0 1.6em 4em 1.6em;
      tbody {
        tr {
          td {
            padding: 1em 0.5em 0em 0.1em;

            &.customerName {
              order: 1;
              flex-basis: 70%;
              flex-grow: 2;
              padding: 1em 0 0 1.5em;
            }
            &.actions {
              padding: 1em 1.5em 0 0;
              order: 2;
              flex-basis: auto;
            }
            &.merchantLoanName {
              order: 3;
              flex-basis: 35%;
              padding: 1em 0 0 1.5em;
            }
            &.dateCreated {
              order: 4;
              flex-basis: 20%;
            }
            &.merchantReference {
              display: none;
            }
            &.staffName {
              order: 5;
              flex-basis: 25%;
            }
            &.status {
              order: 6;
              flex-basis: 20%;
            }
            &.expandMerchant {
              padding: 1em 1.2em 1em 1.5em;
              display: flex;
              order: 7;
              flex-basis: 100%;

              .subhead {
                display: none;
              }
              h5 {
                flex-basis: 70%;
                flex-grow: 1;
              }
              a {
                flex-basis: auto;
              }
            }
          }
        }
      }
    }
    ${media.max.medium} {
      tbody {
        h5 {
          font-size: 1em;
        }
        a {
          color: ${(props) => props.theme.colours.primary3};
          text-decoration: none;
        }
        tr {
          td {
            &.merchantLoanName {
              order: 3;
              flex-basis: 50%;
            }
            &.dateCreated {
              display: none;
            }
            &.customerName,
            &.actions {
              padding-bottom: 0;
            }
            &.status,
            &.merchantLoanName {
              padding-top: 10px;

              .subhead {
                margin-bottom: 0.3em;
              }
            }
            &.staffName {
              display: none;
            }
            &.status {
              order: 4;
              padding-left: 20px;
              flex-basis: 50%;
            }
            &.actions {
              order: 6;
              padding-left: 15px;
            }
            &.expandMerchant {
              .mobile-heading {
                display: flex;
                align-items: center;
                font-size: 0.93rem;

                span {
                  color: ${(props) => props.theme.colours.blue.base};
                }
              }
            }
          }
        }
      }
    }
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3em;

      ${media.max.medium} {
        margin-bottom: 1em;
      }
      h2 {
        color: ${(props) => props.theme.colours.blue.base};
        flex: 1;

        ${media.max.medium} {
          font-size: 1.6em;
        }
      }
      .actions {
        display: flex;
        justify-content: space-between;
        align-items: middle;
        max-width: 100%;
        width: 100%;
        background-color: ${(props) => props.theme.colours.grey.altlight};
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        padding: 1em;

        .icon {
          margin-right: 12px;
          margin-left: 1px;
          padding-top: 15px;
        }
        button:first-of-type {
          width: 50%;
        }
        button:last-of-type {
          box-shadow: unset;
          width: 50%;
          margin-left: 1.4em;
        }
        ${media.min.medium} {
          position: relative;
          background-color: ${(props) => props.theme.colours.grey.altlight};
          flex: 1;
          justify-content: flex-end;
          max-width: 35%;

          .icon {
            margin-right: 15px;
            margin-left: 2px;
            padding-top: 15px;
          }
          button {
            max-width: unset;
            width: unset;
            padding: 0 1em;

            &:first-of-start {
              margin-right: 1.9em;
            }
            &:last-of-type {
              margin-left: 1.4em;
            }
          }
        }
        ${media.min.large} {
          max-width: 35%;
        }
      }
    }
    .search {
      margin-bottom: 3em;

      ${media.max.medium} {
        margin-bottom: 1em;
      }
    }
    .pagination {
      padding: 4em 0 4em 0;
      ${media.max.medium} {
        padding: 2em 0 6em 0;
      }
    }
  }
`;

export const SCMerchantNoLoans = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 5em auto 0;
  text-align: center;
  color: ${(props) => props.theme.colours.blue.base};
  font-size: 1.2em;
  font-weight: bold;
`;

export const SCFilterMessage = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto;
  text-align: center;
  color: ${(props) => props.theme.colours.blue.base};
  font-size: 1.1em;
  font-weight: bold;
`;

export default SCMerchantLoans;
