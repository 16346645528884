/** https://www.typescriptlang.org/docs/handbook/enums.html */

/** Plans List **/

export enum ApplicationCompletedBy {
  ECOMMERCE = 'Ecommerce',
  CUSTOMER = 'Customer',
  MERCHANT = 'Merchant',
}

export enum SugarPlanStatus {
  APPROVED = 'Approved',
  ACTIVE = 'Active',
  APPROVED_PENDING_ID = 'Approved_Pending_ID',
  PENDING = 'Pending',
}

export enum DepositPaymentStatues {
  ZERO_DEPOSIT = 'Zero Deposit',
  PAID_BY_CUSTOMER = 'Paid by Customer',
  COLLECTED_BY_MERCHANT = 'Collected by Merchant',
  PAID_TO_MERCHANT = 'Paid to Merchant',
}

export enum ProductLine {
  PPLUS = 'PPLUS',
  BNPL = 'BNPL',
}
