import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../util/store';
import MerchantAPI, {
  PlansSuccessResponse,
  PlansDetailSuccessResponse,
  StaffSuccessResponse,
  PlansStatsSuccessResponse,
  PlanStatus,
} from '../util/merchant-api';
import { CustomerResourceBasic } from '@merchant-portal/types/customer';
import { resetCheckout } from './checkout';
import { resetCustomer } from './customer';
import { resetPayment } from './payment';
import { resetPaymentMethod } from './payment-method';
import { PlanAction, PlansListMetaData } from '../types/plan';
import { loading, loadingFunctionInterface } from '../util/loadingFunctionInterface';
import PlansAPI from '../util/plans-api';

type InitialState = {
  loading?: boolean;
  activeRequests: Array<plansRequestId>;
  hasErrors?: boolean;
  errorMessage?: string | null;
  submitSucceeded?: boolean;
  merchantPlans: PlansSuccessResponse;
  planDetails: PlansDetailSuccessResponse;
  planCustomer: CustomerResourceBasic;
  stats: PlansStatsSuccessResponse;
  merchantStaff: StaffSuccessResponse;
  metadata: PlansListMetaData;
};

export const initialState: InitialState = {
  loading: false,
  activeRequests: [],
  hasErrors: false,
  errorMessage: null,
  submitSucceeded: false,
  merchantPlans: [],
  merchantStaff: [],
  stats: {
    plans: {
      total: '',
      pending: '',
      pendingSumTotal: '',
      cancelled: '',
      cancelledSumTotal: '',
      approved: '',
      approvedSumTotal: '',
    },
  },

  planDetails: {
    depositPaymentStatus: '',
    numberOfRepayments: '',
    establishmentFee: '',
    repaymentFrequency: '',
    loanAmountPerPayment: '',
    repaymentAmount: '',
    depositPaid: '',
    totalCreditAmount: '',
    terms: '',
    saleAmount: '',
    customerId: '',
    status: PlanStatus.UNDEFINED,
    depositPaidPercentage: '',
    planBlockActivation: false,
  },
  planCustomer: {
    id: null,
    customerNumber: '',
    customerDetails: {
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',
      confirmEmail: '',
      dateOfBirth: '',
      consentPromoMaterial: false,
      isIdVerified: false,
    },
  },
  metadata: {
    count: 0,
    currentPage: 1,
    lastPage: 0,
    limit: 10,
  },
};

type plansRequestId =
  | 'getPlans'
  | 'getStaff'
  | 'getPlanStats'
  | 'updatePlanStatus'
  | 'getPlanDetail'
  | 'getCustomerDetail'
  | 'exportPlans';

const startLoading: loadingFunctionInterface<plansRequestId> = (
  activeRequests: Array<plansRequestId>,
  requestId: plansRequestId
): loading<plansRequestId> => {
  activeRequests = [...activeRequests, requestId];
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const finishLoading: loadingFunctionInterface<plansRequestId> = (
  activeRequests: Array<plansRequestId>,
  requestId: plansRequestId
): loading<plansRequestId> => {
  activeRequests = activeRequests.filter((item) => item !== requestId);
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    getPlansBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getPlans');
      return {
        ...state,
        merchantPlans: initialState.merchantPlans,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        planDetails: initialState.planDetails,
        planCustomer: initialState.planCustomer,
      };
    },
    getPlansSuccess: (state, { payload }: PayloadAction<any>) => {
      const loadingState = finishLoading(state.activeRequests, 'getPlans');
      return {
        ...state,
        merchantPlans: payload.data,
        metadata: payload.meta,
        submitSucceeded: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getPlansFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getPlans');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getStaffBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getStaff');
      return {
        ...state,
        merchantPlans: initialState.merchantPlans,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        planDetails: initialState.planDetails,
        planCustomer: initialState.planCustomer,
      };
    },
    getStaffSuccess: (state, action: PayloadAction<StaffSuccessResponse>) => {
      const loadingState = finishLoading(state.activeRequests, 'getStaff');
      return {
        ...state,
        merchantStaff: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getStaffFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getStaff');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getPlanStatsBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getPlanStats');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getPlanStatsSuccess: (state, action: PayloadAction<PlansStatsSuccessResponse>) => {
      const loadingState = finishLoading(state.activeRequests, 'getPlanStats');
      return {
        ...state,
        stats: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getPlanStatsFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getPlanStats');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    updatePlanStatusBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'updatePlanStatus');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        planDetails: initialState.planDetails,
        planCustomer: initialState.planCustomer,
      };
    },
    updatePlanStatusSuccess: (state) => {
      const loadingState = finishLoading(state.activeRequests, 'updatePlanStatus');
      return {
        ...state,
        submitSucceeded: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    updatePlanStatusFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'updatePlanStatus');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getPlanDetailBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getPlanDetail');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        planDetails: initialState.planDetails,
        planCustomer: initialState.planCustomer,
      };
    },
    getPlanDetailSuccess: (state, action: PayloadAction<PlansDetailSuccessResponse>) => {
      const loadingState = finishLoading(state.activeRequests, 'getPlanDetail');
      return {
        ...state,
        planDetails: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    getPlanDetailFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getPlanDetail');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCustomerDetailBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'getCustomerDetail');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        planDetails: initialState.planDetails,
        planCustomer: initialState.planCustomer,
      };
    },
    getCustomerDetailSuccess: (state, action: PayloadAction<CustomerResourceBasic>) => {
      const loadingState = finishLoading(state.activeRequests, 'getCustomerDetail');
      return {
        ...state,
        planCustomer: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getCustomerDetailFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'getCustomerDetail');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    exportPlansBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'exportPlans');
      return {
        ...state,
        metadata: initialState.metadata,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    exportPlansSuccess: (state) => {
      const loadingState = finishLoading(state.activeRequests, 'exportPlans');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
      };
    },
    exportPlansFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'exportPlans');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    cancelCheckoutBegin: (state) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
      };
    },
    cancelCheckoutSuccess: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    cancelCheckoutFailed: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  getPlansBegin,
  getPlansSuccess,
  getPlansFailure,
  getStaffBegin,
  getStaffSuccess,
  getStaffFailure,
  getPlanStatsBegin,
  getPlanStatsSuccess,
  getPlanStatsFailure,
  updatePlanStatusBegin,
  updatePlanStatusSuccess,
  updatePlanStatusFailure,
  getPlanDetailBegin,
  getPlanDetailSuccess,
  getPlanDetailFailure,
  getCustomerDetailBegin,
  getCustomerDetailSuccess,
  getCustomerDetailFailure,
  exportPlansBegin,
  exportPlansSuccess,
  exportPlansFailure,
  cancelCheckoutBegin,
  cancelCheckoutSuccess,
  cancelCheckoutFailed,
} = plansSlice.actions;
export default plansSlice.reducer;

// Thunks to show the plans on plan page

export const displayPlans =
  (params?: any): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(getPlansBegin());
      dispatch(getStaffBegin());

      const fetchPlans: any = await PlansAPI.plans(params);
      dispatch(getPlansSuccess(fetchPlans.plans));

      const fetchStaff: StaffSuccessResponse = await MerchantAPI.staff();
      dispatch(getStaffSuccess(fetchStaff));
    } catch (e: any) {
      dispatch(getPlansFailure({ errorMessage: e.message }));
      dispatch(getStaffFailure({ errorMessage: e.message }));
    }
  };

export const displayPlansStatistics = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getPlanStatsBegin());
    const fetchStatistics = await MerchantAPI.plansStatistics();
    dispatch(getPlanStatsSuccess(fetchStatistics));
  } catch (e: any) {
    dispatch(getPlanStatsFailure({ errorMessage: e.message }));
  }
};

export const updatePlanStatusAndRefresh =
  (action: PlanAction, planId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updatePlanStatusBegin());
      await MerchantAPI.updatedPlans(action, planId);
      dispatch(updatePlanStatusSuccess());
    } catch (e: any) {
      dispatch(updatePlanStatusFailure({ errorMessage: e.message }));
    }
  };

export const displayPlanDetails =
  (planId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPlanDetailBegin());
      dispatch(getCustomerDetailBegin());

      const fetchPlanDetails: PlansDetailSuccessResponse = await MerchantAPI.planDetails(planId);
      dispatch(getPlanDetailSuccess(fetchPlanDetails));

      const fetchCustomerDetails: CustomerResourceBasic = await MerchantAPI.getPlanCustomer(planId);
      dispatch(getCustomerDetailSuccess(fetchCustomerDetails));
    } catch (e: any) {
      dispatch(getPlanDetailFailure({ errorMessage: e.message }));
      dispatch(getCustomerDetailFailure({ errorMessage: e.message }));
    }
  };

export const exportPlansAsCSV =
  (
    planStatus: string,
    clientTimeZone: string,
    filterSelectedDropDown: {
      staffMember?: string;
      timeFrames?: string;
    }
  ): AppThunk =>
  async (dispatch) => {
    dispatch(exportPlansBegin());

    try {
      const data = await MerchantAPI.exportPlans(
        planStatus,
        clientTimeZone,
        filterSelectedDropDown
      );
      if (data.exportCSVURL) {
        const link = document.createElement('a');
        link.href = data.exportCSVURL;
        link.setAttribute('download', `plans_${new Date().getTime()}.csv`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();

        dispatch(exportPlansSuccess());
      }
    } catch (e: any) {
      dispatch(exportPlansFailure({ errorMessage: e.message }));
    }
  };

export const fetchPlanDetailsFromMobileCheckout =
  (planId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPlanDetailBegin());
      const fetchPlanDetails: PlansDetailSuccessResponse = await MerchantAPI.planDetails(planId);
      dispatch(getPlanDetailSuccess(fetchPlanDetails));
    } catch (e: any) {
      dispatch(getPlanDetailFailure({ errorMessage: e.message }));
    }
  };

export const updateMobileCheckout =
  (action: PlanAction, planId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updatePlanStatusBegin());
      await MerchantAPI.updatedPlans(action, planId);
      dispatch(updatePlanStatusSuccess());
    } catch (e: any) {
      dispatch(updatePlanStatusFailure({ errorMessage: e.message }));
    }
  };

export const initNewPlan = (): AppThunk => async (dispatch) => {
  dispatch(resetCheckout());
  dispatch(resetCustomer());
  dispatch(resetPayment());
  dispatch(resetPaymentMethod());
};

export const cancelCheckout =
  (checkoutId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(cancelCheckoutBegin());
      await MerchantAPI.cancelCheckout(checkoutId);

      dispatch(cancelCheckoutSuccess());
    } catch (error: any) {
      dispatch(cancelCheckoutFailed({ errorMessage: error.message }));
    }
  };
