import * as Yup from 'yup';
import { View } from '../useViewNavigator';
import { RatesSuccessResponse } from '@merchant-portal/util/merchant-api';

import getConstants from '@merchant-portal/util/constants';
const { CHECKOUT_CODE_REGEX, CHECKOUT_CODE_FORMAT_VALIDATION } = getConstants();

/**
 * Validate the final sale amount. The merchant should be able to key in a sale amount that is within the min/max of
 * the rate card (up to 90% deposit) and only within the same initial term as the pre-approval.
 * @param rates      Merchant rate card
 * @param saleAmount Sale amount entered
 * @param term       The term of the customer's pre-approval
 */
const validateFinalAmount = (
  saleAmount: number | undefined,
  rates: RatesSuccessResponse['rates'] | undefined,
  term: number | undefined
): boolean => {
  const MAX_DEPOSIT_PERCENTAGE = 90;

  if (typeof rates === 'undefined' || typeof saleAmount === 'undefined') {
    console.error('Unable to validate final sale amount due to incomplete data');
    return false;
  }

  let allRates = [];

  if (typeof term === 'undefined' || term === null) {
    // Premium Limit - go through all the rates across all terms for frontend validation.
    // Server side will do a final, more precise calculation to determine if the sale amount is valid
    allRates = rates;
  } else {
    // Get all the rates that match the pre-approval's term (no term change allowed)
    allRates = rates.filter((rate) => term === rate.term);
  }

  if (allRates.length === 0) {
    return false;
  }

  // Find minimum sale amount for that term (Sale amount minus minimum deposit should fit within the rate card)
  // i.e. Rate card has $1,501 as the minimum, 10% as the minimum deposit. A sale amount of $1,667.78 is valid
  const minSaleAmountForTerm = Math.min(...allRates.map((rate) => rate.minimumSaleAmount));

  const maxLoanAmountForTerm = Math.max(...allRates.map((rate) => rate.maximumPurchase));
  // Calculate maximum sale amount for that term (Sale amount minus 90% deposit should fit within the rate card)
  // i.e. Rate card has $3,000 as the maximum. A sale amount of $30,000 is valid
  //      $3000 / 0.1 = $30000
  const maxSaleAmountForTerm = maxLoanAmountForTerm / ((100 - MAX_DEPOSIT_PERCENTAGE) / 100);

  if (saleAmount >= minSaleAmountForTerm && saleAmount <= maxSaleAmountForTerm) {
    return true;
  } else {
    return false;
  }
};

/**
 * Wraps a given schema with some conditionals so that validation only applies when the view is View.ENTER_SALE_DETAILS
 * Example input:
 *   {
 *      saleAmount: Yup.number().required('Sale Amount is required'),
 *      amountPaidInStore: Yup.number(),
 *      paymentPlanAmount: Yup.number()
 *   }
 *
 * Example output:
 *   {
 *      saleAmount: Yup.mixed().when('$view', {
 *        is: View.ENTER_SALE_DETAILS,
 *        then: Yup.number().required('Sale amount is required')
 *      },
 *      amountPaidInStore: Yup.mixed().when('$view', {
 *        is: View.ENTER_SALE_DETAILS,
 *        then: Yup.number().required('Sale amount is required')
 *      }
 *      paymentPlanAmount: Yup.mixed().when('$view', {
 *        is: View.ENTER_SALE_DETAILS,
 *        then: Yup.number()
 *      }
 *   }
 * @param schemas
 */
const whenViewIsEnterSaleDetails = (schemas: Record<string, any>) => {
  const compiledSchema = Object.assign(schemas, {});

  for (const key in schemas) {
    compiledSchema[key] = Yup.mixed().when('$view', {
      is: View.ENTER_SALE_DETAILS,
      then: schemas[key],
    });
  }

  return compiledSchema;
};

export const mobileCheckoutValidationSchema = Yup.object().shape({
  checkoutCode: Yup.string()
    .required("Customer's Barcode/QR Code or Checkout Code is required")
    .matches(CHECKOUT_CODE_REGEX, CHECKOUT_CODE_FORMAT_VALIDATION),
  ...whenViewIsEnterSaleDetails({
    saleAmount: Yup.number()
      .typeError('Sale Amount must be a number')
      .moreThan(0, 'Sale Amount must be more than $0')
      .required('Sale Amount is required'),
    amountPaidInStore: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number()
            .min(0, 'Amount paid up front must be $0 or more')
            .typeError('Please enter a valid $ amount')
    ),
    paymentPlanAmount: Yup.number()
      .required()
      .typeError('Please enter a valid $ amount')
      .test(
        'is-final-amount-valid',
        'We do not offer finance for this amount',
        (value, context) => {
          const result = validateFinalAmount(
            value,
            context.options.context?.rates,
            context.options.context?.term
          );
          return result;
        }
      ),
    merchantReference: Yup.string(),
  }),
});
