import React, { useContext } from 'react';
import { envIsTrue } from '@merchant-portal/util/helper';

export type Config = {
  forceCustomerLed: boolean;
};

type ConfigProviderProps = {
  children?: React.ReactNode;
};

const Context = React.createContext<Config | undefined>(undefined);

export const useConfig = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const config: Config = {
    forceCustomerLed: envIsTrue('REACT_APP_FEATURE_FLAG_CCCFA'),
  };

  return <Context.Provider value={config}>{children}</Context.Provider>;
};
