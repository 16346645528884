import * as React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
// import { CursorClose } from '../../../theme/cursors';
import { IconChevron, IconInfo } from '@payright/web-components';
import Tooltip from 'react-tooltip-lite';

import 'react-datepicker/dist/react-datepicker.css';

//////////////////////////////////////////
//               Calendar               //
//////////////////////////////////////////

const SCCalendar = styled.div`
  max-width: 337px;
  input {
    -webkit-appearance: none;
  }
  .date-picker__label {
    font-weight: bold;
    margin-bottom: 0.5em;
    display: block;
    & .tooltip {
      padding-left: 0.5em;
    }
  }
  .date-picker__wrapper {
    position: relative;
  }
  .icon-chevron {
    position: absolute;
    top: 0;
    right: 1.5em;
    height: 100%;
    display: flex;
    align-items: center;
    transition: transform 200ms;
    &.open {
      transform: rotate(180deg);
    }
    path {
      fill: ${(props) => props.theme.colours.blue.base};
    }
  }
  .react-datepicker {
    width: 337px;
    box-shadow: 0 2px 20px 0 rgba(66, 77, 96, 0.15);
    border: 0;
    font-family: 'Quicksand';
    position: absolute;
    font-weight: bold;
    &-wrapper {
      width: 100%;
    }
    &__input-container {
      width: 100%;
      input {
        outline: 0;
        padding: 0;
        width: 100%;
        height: ${(props) => props.theme.dimensions.inputHeight};
        border-radius: 3px;
        box-shadow: inset 0 1px 6px 0 rgba(175, 187, 208, 0.39);
        border: solid 1px ${(props) => props.theme.colours.grey.light};
        background-color: ${(props) => props.theme.colours.white};
        color: ${(props) => props.theme.colours.blue.base};
        font-weight: bold;
        font-size: 0.93em;
        padding-left: 1em;
        transition: 200ms border ease-in;
        &::placeholder {
          color: ${(props) => props.theme.colours.grey.dark};
          font-weight: 500;
        }
        &:focus {
          border: 2px solid ${(props) => props.theme.colours.blue.base};
        }
      }
    }

    &__triangle {
      display: none;
    }
    &-popper[data-placement^='bottom'] {
      margin-top: 0;
    }
    &__header {
      background-color: white;
      border-bottom: 0;
      padding-top: 27px;
    }
    &__navigation {
      height: unset;
      width: unset;
      text-indent: unset;
      width: 11px;
      height: 13px;
      margin-top: 1.4em;
      &::before {
        border-style: solid;
        border-color: ${(props) => props.theme.colours.grey.dark};
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
      }
      &--next {
        right: 32px;
        border: 0;
        &:before {
          left: 0;
          transform: rotate(45deg);
        }
      }
      &--previous {
        right: 63px;
        left: unset;
        border: 0;
        &:before {
          left: 0.25em;
          transform: rotate(-135deg);
        }
      }
    }
    &__current-month {
      text-align: left;
      padding-left: 31px;
      font-size: 14px;
      color: #6b7897;
    }
    &__month {
      margin: 0 0.8rem 0.4rem 0.8rem;
    }
    &__month-container {
      width: 100%;
      padding-bottom: 28px;
    }
    &__day-names {
      padding: 0.4rem 0.4rem 0 0.4rem;
      margin-top: 12px;
    }
    &__day {
      color: ${(props) => props.theme.colours.blue.base};
      &--disabled {
        cursor: not-allowed;
        color: ${(props) => props.theme.colours.secondary3};
      }
    }
    &__day,
    &__day-name {
      width: 2.93em;
    }
    &__week {
      .react-datepicker__day {
        margin-top: 0;
      }
    }
    &__day-name {
      color: ${(props) => props.theme.colours.grey.dark};
      visibility: hidden;
      font-size: 0;
      width: 37px;
      &:first-letter {
        visibility: visible;
        font-size: 14px;
      }
    }

    &__day,
    &__month-text {
      position: relative;
      transition: color 300ms;
      &:after {
        transition: all 300ms;
        content: '';
        border-radius: 50%;
        position: absolute;
        width: 3px;
        height: 3px;
        left: 18px;
        bottom: 13px;
      }
    }

    &__day:hover,
    &__month-text:hover {
      background: none;
      border-radius: unset;
      z-index: 9;
      color: ${(props) => props.theme.colours.blue.base};
      &::after {
        content: '';
        background-color: ${(props) => props.theme.colours.grey.xlight};
        border-radius: 50%;
        position: absolute;
        width: 24px;
        height: 24px;
        left: 6px;
        bottom: 1px;
        z-index: -1;
      }
    }

    &__day--disabled:hover {
      color: ${(props) => props.theme.colours.secondary3};
      &:after {
        display: none;
      }
    }

    &__day--selected,
    &__day--keyboard-selected {
      background-color: unset;
      position: relative;
      z-index: 9;
      color: ${(props) => props.theme.colours.white};
      &:hover {
        background-color: unset;
      }
      &::after {
        content: '';
        background-color: ${(props) => props.theme.colours.blue.base};
        border-radius: 50%;
        position: absolute;
        width: 34px;
        height: 34px;
        left: 2px;
        bottom: -4px;
        z-index: -1;
      }
    }
  }
`;

interface CalendarProps {
  /** Current date value (defaults to today if not set) */
  value: Date;
  /** Tooltip */
  tooltip?: string;
  /** Callback to receive the new date */
  handleDateChange(date: Date): void;
  /** Minimum accepted date */
  minDate?: Date;
  /** Maximum accepted date */
  maxDate?: Date;
  /** Label */
  label?: string;
}

interface CalendarState {
  open: boolean;
}

class Calendar extends React.Component<CalendarProps, CalendarState> {
  state = { open: false };

  static defaultProps = {
    value: new Date(),
  };

  datePickerRef: React.RefObject<DatePicker>;

  constructor(props: CalendarProps) {
    super(props);
    this.datePickerRef = React.createRef();
  }

  setDate = (date: Date) => {
    this.props.handleDateChange(date);
  };

  setOpenState = () => {
    const open = true;

    this.setState({ open });
  };

  setClosedState = () => {
    const open = false;

    this.setState({ open });
  };

  render() {
    const { open } = this.state;
    const { minDate, maxDate, label, value, tooltip } = this.props;

    return (
      <SCCalendar>
        <label className="date-picker__label">
          {label}
          {tooltip && (
            <Tooltip content={tooltip} className="tooltip">
              <IconInfo />
            </Tooltip>
          )}
        </label>
        <div className="date-picker__wrapper">
          <DatePicker
            selected={value}
            dateFormat="dd/MM/yyyy"
            onChange={this.setDate}
            onFocus={this.setOpenState}
            onBlur={this.setClosedState}
            minDate={minDate}
            maxDate={maxDate}
            ref={this.datePickerRef}
          />
          <IconChevron
            className={`icon-chevron ${open ? 'open' : 'closed'}`}
            onClick={() => {
              // Progamatically open the calendar
              this.datePickerRef.current!.setFocus();

              // Rotate the chevron to open state
              this.setOpenState();
            }}
          />
        </div>
      </SCCalendar>
    );
  }
}

export default Calendar;
