import { media } from '@payright/web-components';
import styled from 'styled-components';

const Dashboard = styled.div`
  .content {
    margin-top: 0px;
  }
  .navigation-header .mobile-header .notifications {
    display: none;
  }
  .dashboard-body {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 4em;
    padding: 0 20px;

    ${media.min.ewide} {
      padding: 0;
    }
  }
  h4 {
    margin: 0px;
    color: #4b576b;
  }
  .heading {
    margin: 2em 0 2em 0;
  }
  .portal-v1 {
    margin-bottom: 15px;
    float: right;
  }
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.light};
    margin: 0 0 30px;
  }
  .card {
    max-width: 1200px;
    width: 100%;

    > .content > .title {
      color: #4b576b;
    }
  }
  .announcement-card {
    .content {
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
    .title {
      display: none;
    }
    p.main-text {
      text-align: justify;
      strong {
        font-weight: 800;
      }
    }
    .body {
      display: flex;
      flex-direction: row;
      align-items: center;
      /* evenly space child items by 30px */
      margin: 0 -15px;

      > * {
        margin: 0 15px;
      }
      .card-text {
        flex-grow: 2;

        h4 {
          color: ${(props) => props.theme.colours.blue.base};
          margin-bottom: 0.3em;
        }
      }
    }
  }
  .dashboard-row {
    .dashboard-col {
      > div {
        margin-bottom: 30px;
        .tabs {
          .tab-list {
            font-size: 1.4em;
            button {
              max-width: 320px;
              width: 100%;
            }
            display: flex;
          }
        }
      }
    }

    display: flex;
    flex-direction: column-reverse;

    ${media.min.large} {
      flex-direction: row;
      justify-content: space-between;

      .dashboard-col {
        width: calc(50% - 15px);
      }
      &--actions {
        .dashboard-col:first-child {
          width: 430px;
        }
        .dashboard-col:nth-child(2) {
          width: calc(100% - 430px - 30px);
        }
      }
    }
  }
  .stats-card {
    margin-bottom: 30px;

    .hideText {
      filter: blur(0.8rem);
      color: transparent;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    .card-controls {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .toggleSwitch span span {
        display: none;
      }

      @media only screen {
        .toggleSwitch {
          display: inline-block;
          height: 18px;
          position: relative;
          overflow: visible;
          padding: 0;
          margin-left: 50px;
          cursor: pointer;
          width: 40px;
        }
        .toggleSwitch * {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        .toggleSwitch label,
        .toggleSwitch > span {
          line-height: 20px;
          height: 20px;
          vertical-align: middle;
        }
        .toggleSwitch input:focus ~ a,
        .toggleSwitch input:focus + label {
          outline: none;
        }
        .toggleSwitch label {
          position: relative;
          z-index: 3;
          display: block;
          width: 100%;
        }
        .toggleSwitch input {
          position: absolute;
          opacity: 0;
          z-index: 5;
        }
        .toggleSwitch > span {
          position: absolute;
          left: -50px;
          width: 100%;
          margin: 0;
          padding-right: 50px;
          text-align: left;
          white-space: nowrap;
        }
        .toggleSwitch > span span {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          display: block;
          width: 50%;
          margin-left: 50px;
          text-align: left;
          font-size: 0.9em;
          width: 100%;
          left: 15%;
          top: -1px;
          opacity: 0;
        }
        .toggleSwitch .circle {
          position: absolute;
          right: 50%;
          z-index: 4;
          display: block;
          height: 100%;
          padding: 0;
          left: 2px;
          width: 18px;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        }
        .toggleSwitch > span span:first-of-type {
          color: #f9f9f9;
          opacity: 1;
          left: 45%;
        }
        .toggleSwitch > span:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50px;
          top: -2px;
          background-color: #cecece;
          border: 1px solid #ccc;
          border-radius: 30px;
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
        }
        .toggleSwitch input:checked ~ .circle {
          border-color: #fff;
          left: 100%;
          margin-left: -8px;
        }
        .toggleSwitch input:checked ~ span:before {
          border-color: #5431ff;
          box-shadow: inset 0 0 0 30px #5431ff;
        }
        .toggleSwitch input:checked ~ span span:first-of-type {
          opacity: 0;
        }
        .toggleSwitch input:checked ~ span span:last-of-type {
          opacity: 1;
          color: #fff;
        }
        /* Switch Sizes */
        .toggleSwitch.large {
          width: 80px;
          height: 27px;
        }
        .toggleSwitch.large .circle {
          width: 27px;
        }
        .toggleSwitch.large > span {
          height: 29px;
          line-height: 28px;
        }
        .toggleSwitch.large input:checked ~ .circle {
          left: 61px;
        }
        .toggleSwitch.large > span span {
          font-size: 1.1em;
        }
        .toggleSwitch.large > span span:first-of-type {
          left: 50%;
        }
      }
    }

    .card-top {
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;

      > div {
        text-align: center;
      }
      .stats-divider {
        border-left: 1px solid #f1f3f7;
        height: 85px;
      }
      .stats-sum {
        height: 100%;
        padding-top: 3em;
      }
    }
    .card-bottom {
      margin-top: 30px;
      text-align: center;
    }
  }
  .contact-card {
    .title {
      display: none;
    }
    .card-top {
      display: flex;
      margin-bottom: 1.5em;

      .contact-icon {
        margin-right: 30px;
      }
    }
    .card-bottom {
      display: flex;
      justify-content: center;

      .card-bottom-buttons {
        width: 100%;
        max-width: 337px;
        display: grid;
        grid-template-columns: auto;
        row-gap: 15px;
      }

      @media (min-width: 480px) {
        .card-bottom-buttons {
          column-gap: 15px;
          grid-template-columns: repeat(2, minmax(10px, 1fr));
        }
      }
    }
  }

  .checkout {
    .actions button {
      max-width: 100%;
    }
    .checkout-heading {
      padding-left: 0.5em;
      font-size: 1.4em;
      font-weight: bold;
    }
    .checkout-text {
      padding-bottom: 2em;
      padding-top: 1em;
      padding-left: 0.5em;
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  .poll {
    .question-count {
      margin-bottom: 0.6em;
    }
    .question {
      font-size: 1.6em;
      font-weight: bold;
      color: ${(props) => props.theme.colours.blue.base};
    }
    .center {
      padding: 1em;
      text-align: center;

      > svg {
        margin-right: 2em;
      }
    }
    .card {
      .content {
        padding: 2.4em;
      }
    }
  }
`;

export const SCModalPassword = styled.div`
   {
    .contact {
      margin-top: 10px;
    }
    .modal-head {
      margin-left: 0px;
      margin-right: 0px;
      max-width: 380px;
      width: 380px;
    }
    ${media.min.large} {
      .modal-head {
        margin-left: 20px;
      }
    }
  }
`;

export const SCModalMerchantDDO = styled.div`
   {
    .modal-inner > p.text {
      .importantMessage {
        font-size: 0.8em;
        padding-bottom: 10px;
      }
      h3 {
        padding-top: 20px;
        padding-bottom: 10px;
      }
      .title {
        font-size: 0.9em;
      }
      .red {
        color: red;
      }
      p {
        margin-bottom: 10px;
        font-size: 1em;
      }
    }

    i {
      font-style: italic;
    }

    .modal-inner {
      .content {
        margin-left: 0px;
      }
    }
    .merchantDDO {
      padding: 0em 2em 0em 2em;
    }
    .modal-head {
      margin-left: 0px;
      margin-right: 0px;
      max-width: 380px;
      width: 380px;

      margin-top: 0px;
    }
    ${media.min.large} {
      .modal-head {
        margin-left: 0px;
        width: 100%;
        max-width: 100%;
      }
    }
    .alerts {
      /* max-width: 600px; */
      min-width: 350px;
      margin: 50px 25px 15px 15px;
      vertical-align: middle;
      font-size: 10px;
    }
  }
`;

export default Dashboard;
