import React from 'react';
import styled from 'styled-components';
import { ModalWrapper, MessageBox } from '@payright/web-components';

type ModalCustomerCancelledProps = {
  show: boolean;
  closeHandler: () => void;
};

const SCModalCustomerCancelled = styled.div`
  text-align: center;

  .modal-content {
    max-width: 25rem;
  }

  .title,
  .message {
    text-align: left;
  }
`;

const ModalCustomerCancelled = ({ show, closeHandler }: ModalCustomerCancelledProps) => {
  return (
    <SCModalCustomerCancelled>
      <ModalWrapper open={show} handleClose={closeHandler} background="dark">
        <div className="modal-content">
          <MessageBox
            title={'Customer cancelled the checkout'}
            message="Customer cancelled the checkout. If you would like to start over, please finalise and enter the sale amount again."
            handleOnClick={closeHandler}
          />
        </div>
      </ModalWrapper>
    </SCModalCustomerCancelled>
  );
};

export default ModalCustomerCancelled;
