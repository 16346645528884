import React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCFormSection = styled.section`
  display: flex;
  flex-direction: column;

  .section-intro {
    h4 {
      color: ${(props) => props.theme.colours.blue.base};
    }
    p {
      margin: 30px 0;
    }
  }

  ${media.min.tablet} {
    flex-direction: row;
    .section-intro {
      flex-basis: 25%; // 300px
      margin-right: 60px;
    }
    .section-content {
      flex-basis: calc(75% - 60px);
    }
  }

  ${media.min.wide} {
    .section-intro {
      margin-right: 110px;
    }
    .section-content {
      flex-basis: calc(75% - 110px);
    }
  }
`;

type FormSectionProps = {
  title?: string;
  text?: string | React.ReactNode;
  children?: React.ReactNode;
};

const FormSection = ({ title, text, children }: FormSectionProps) => {
  return (
    <SCFormSection>
      <div className="section-intro">
        {title && <h4>{title}</h4>}
        {text && <p>{text}</p>}
      </div>
      <div className="section-content">{children}</div>
    </SCFormSection>
  );
};

export default FormSection;
