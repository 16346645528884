import { PayrightPlusCalculatorData } from '@payright/web-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { resetCustomer } from './customer';
import MerchantAPI, {
  CreatePplusCheckoutParams,
  PplusCheckoutResource,
} from '../util/merchant-api';
import { AppThunk } from '../util/store';
import { loading, loadingFunctionInterface } from '../util/loadingFunctionInterface';
import { PaymentFrequency } from '@merchant-portal/types/customer';

type InitialState = {
  loading?: boolean;
  activeRequests: Array<checkoutRequestId>;
  hasErrors?: boolean;
  paymentDetails?: PayrightPlusCalculatorData;
  paymentDetailsEdit?: boolean;
  errorMessage?: string | null;
  checkout?: PplusCheckoutResource | null;
  hasErrorsUpdateCheckout?: boolean;
};

export const initialState: InitialState = {
  loading: false,
  activeRequests: [],
  hasErrors: false,
  paymentDetails: {
    loanAmount: 0,
    paymentFrequency: 'Fortnightly',
    paymentPeriod: 12,
    originationFee: 0,
  },
  paymentDetailsEdit: false,
  errorMessage: null,
  checkout: null,
};

type checkoutRequestId =
  | 'createCheckout'
  | 'attachCustomer'
  | 'updateCheckout'
  | 'sendApplicationLink'
  | 'sendVerificationCode'
  | 'getCurrentCheckoutSession';

const startLoading: loadingFunctionInterface<checkoutRequestId> = (
  activeRequests: Array<checkoutRequestId>,
  requestId: checkoutRequestId
): loading<checkoutRequestId> => {
  activeRequests = [...activeRequests, requestId];
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const finishLoading: loadingFunctionInterface<checkoutRequestId> = (
  activeRequests: Array<checkoutRequestId>,
  requestId: checkoutRequestId
): loading<checkoutRequestId> => {
  activeRequests = activeRequests.filter((item) => item !== requestId);
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const payrightPlusSlice = createSlice({
  name: 'payrightPlus',
  initialState,
  reducers: {
    resetCheckout: () => initialState,
    updateSaleDetails(
      state,
      action: PayloadAction<{
        paymentDetails: {
          loanAmount: number;
          /** Percentage of deposit to pay */
          depositPercent: number;
          /** Percentage of deposit to pay */
          depositAmount?: number;
          /** Repayment every x amount of weeks */
          paymentFrequency: PaymentFrequency;
          /** Repayment over x number of months */
          paymentPeriod: number;
          /** Origination fee */
          originationFee: number;
        };
      }>
    ) {
      return {
        ...state,
        paymentDetails: action.payload.paymentDetails,
      };
    },
    createCheckoutBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    createCheckoutSuccess(
      state,
      action: PayloadAction<{
        checkout: PplusCheckoutResource;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: action.payload.checkout,
        errorMessage: null,
        hasErrorsUpdateCheckout: false,
      };
    },
    createCheckoutFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
        hasErrorsUpdateCheckout: false,
      };
    },
    getCurrentCheckoutSessionBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getCurrentCheckoutSession');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getCurrentCheckoutSessionSuccess(state, action: PayloadAction<PplusCheckoutResource>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckoutSession');
      const { payload } = action;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          ...payload,
        },
        paymentDetails: payload.attributes,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrorsUpdateCheckout: false,
      };
    },
    getCurrentCheckoutSessionFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckoutSession');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    attachCustomerBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    attachCustomerSuccess(
      state,
      action: PayloadAction<{
        checkout: PplusCheckoutResource;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: action.payload.checkout,
      };
    },
    attachCustomerFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    sendApplicationLinkBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'sendApplicationLink');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendApplicationLinkSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'sendApplicationLink');
      return {
        ...state,
        paymentDetailsEdit: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrorsUpdateCheckout: false,
      };
    },
    sendApplicationLinkFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'sendApplicationLink');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
        hasErrorsUpdateCheckout: true,
      };
    },
    sendVerificationCodeBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendVerificationCodeSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendVerificationCodeFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

/// exporting the checkout slice actions
export const { resetCheckout, updateSaleDetails } = payrightPlusSlice.actions;

const {
  createCheckoutBegin,
  createCheckoutSuccess,
  createCheckoutFailure,
  getCurrentCheckoutSessionBegin,
  getCurrentCheckoutSessionSuccess,
  getCurrentCheckoutSessionFailure,
  attachCustomerBegin,
  attachCustomerSuccess,
  attachCustomerFailure,
  sendApplicationLinkBegin,
  sendApplicationLinkSuccess,
  sendApplicationLinkFailure,
  sendVerificationCodeBegin,
  sendVerificationCodeSuccess,
  sendVerificationCodeFailure,
} = payrightPlusSlice.actions;

/// exporting the payright plus slice reducers
export default payrightPlusSlice.reducer;

export const createCheckout =
  ({
    paymentDetails,
    purposeOfLoan,
    merchantReference,
    successCallback,
  }: {
    paymentDetails: {
      loanAmount: number;
      /** Repayment every x amount of weeks */
      paymentFrequency: PaymentFrequency;
      /** Repayment over x number of months */
      paymentPeriod: number;
      /** Origination fee */
      originationFee: number;
      numberOfRepayments: number;
      repaymentsAmount: string | number;
    };
    purposeOfLoan: string;
    merchantReference?: string;
    successCallback?: (checkoutId: string) => void;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCheckoutBegin());

      const paymentFrequency = paymentDetails.paymentFrequency;

      const createCheckoutParams: CreatePplusCheckoutParams = {
        attributes: {
          saleAmount: paymentDetails.loanAmount,
          terms: paymentFrequency,
          depositPaidPercentage: 0,
          depositPayable: 0,
          paymentPeriod: paymentDetails.paymentPeriod,
          purposeOfLoan: purposeOfLoan,
          depositPaid: 0,
          minimumDepositPercentage: 0,
          paymentDetails: {
            depositAmount: 0,
            depositPercent: 0,
            establishmentFee: paymentDetails.originationFee,
            loanAmount: paymentDetails.loanAmount,
            saleAmount: paymentDetails.loanAmount,
            paymentFrequency: paymentDetails.paymentFrequency,
            paymentPeriod: paymentDetails.paymentPeriod,
            numberOfRepayments: paymentDetails.numberOfRepayments,
            repaymentsAmount: paymentDetails.repaymentsAmount,
            totalAmount: paymentDetails.loanAmount + paymentDetails.originationFee,
          },
        },
        merchantReference: merchantReference,
      };

      const checkout: PplusCheckoutResource = await MerchantAPI.createPplusCheckout(
        createCheckoutParams
      );

      dispatch(
        updateSaleDetails({
          paymentDetails: { ...paymentDetails, depositPercent: 0, depositAmount: 0 },
        })
      );

      dispatch(
        createCheckoutSuccess({
          checkout: checkout,
        })
      );
      if (successCallback && checkout.id) {
        successCallback(checkout.id);
      }
    } catch (err: any) {
      dispatch(createCheckoutFailure({ errorMessage: err.message }));
    }
  };

export const fetchCheckoutAttributes =
  (checkoutId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCurrentCheckoutSessionBegin());
      const currentCheckout: PplusCheckoutResource = await MerchantAPI.getCurrentPplusCheckout(
        checkoutId
      );
      dispatch(getCurrentCheckoutSessionSuccess(currentCheckout));
    } catch (error: any) {
      dispatch(getCurrentCheckoutSessionFailure(error.message));
    }
  };

// Attaches a customer id to a checkout. This results in a loan being created
export const attachCustomer =
  (
    checkoutId: string,
    customerId: string,
    isNewCustomer: boolean = false
  ): AppThunk<Promise<string | null>> =>
  async (dispatch, getState) => {
    const checkout = getState()?.payrightPlus?.checkout || null;
    if (checkout?.loanId && checkout?.customerId) {
      console.log('Skip attaching');
      return checkout?.loanId as string;
    }

    dispatch(attachCustomerBegin());
    LogRocket.track('Attaching Customer for Pplus');
    try {
      const result = await MerchantAPI.customerToPplusCheckout(
        customerId,
        checkoutId,
        isNewCustomer
      );
      dispatch(attachCustomerSuccess({ checkout: result }));
      LogRocket.track('Attaching Customer for Pplus Successful');
      return result.loanId;
    } catch (err: any) {
      dispatch(attachCustomerFailure({ errorMessage: err.message }));
      LogRocket.track(`Attaching Customer for Pplus Failed - Error: ${err.message}`);
      throw new Error(err);
    }
  };

// Customer led scenario - send application link to customer
export const sendApplicationLink =
  (checkoutId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(sendApplicationLinkBegin());
      await MerchantAPI.sendPplusApplicationLink(checkoutId);
      dispatch(sendApplicationLinkSuccess());
    } catch (e: any) {
      dispatch(sendApplicationLinkFailure({ errorMessage: e.message }));
    }
  };

// Send verification code sms
export const sendVerificationCode =
  (checkoutId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(sendVerificationCodeBegin());
      await MerchantAPI.sendPplusVerificationCode(checkoutId);
      dispatch(sendVerificationCodeSuccess());
    } catch (e: any) {
      dispatch(sendVerificationCodeFailure({ errorMessage: e.message }));
    }
  };

export const initNewLoan = (): AppThunk => async (dispatch) => {
  dispatch(resetCheckout());
  dispatch(resetCustomer());
};
