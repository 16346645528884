import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { IconQuestion } from '@payright/web-components';
import Tooltip from 'react-tooltip-lite';

const Container = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;

type LabelWithTooltipProps = PropsWithChildren<{
  tooltipContent: string;
}>;

const LabelWithTooltip = ({ children, tooltipContent }: LabelWithTooltipProps) => {
  return (
    <Container>
      {children}
      <Tooltip content={tooltipContent}>
        <IconQuestion /> &nbsp;&nbsp;
      </Tooltip>
    </Container>
  );
};

export default LabelWithTooltip;
