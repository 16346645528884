import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import { ControlledInputField } from './form-fields';
import LabelWithTooltip from './label-with-tooltip';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  ${media.max.tablet} {
    font-size: 0.86em;
  }

  a {
    color: black;
    font-size: 11px;
  }

  .ref {
    text-decoration: underline;
  }
`;

type FormInputWithTooltipProps = PropsWithChildren<{
  name: string;
  placeholder: string;
  tooltipContent: string;
  readOnly?: boolean;
  required?: boolean;
}>;

export const FormInputWithTooltip = ({
  name,
  placeholder,
  tooltipContent,
  readOnly,
  required,
  children,
}: FormInputWithTooltipProps) => {
  return (
    <Container>
      <ControlledInputField
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
      >
        <LabelWithTooltip tooltipContent={tooltipContent}>{children}</LabelWithTooltip>
      </ControlledInputField>
    </Container>
  );
};
