import React from 'react';
import { Card, Tabs } from '@payright/web-components';
import PlanCalculator from './plan-calculator';
import { MobileCheckout } from '@merchant-portal/components/mobile-checkout';
import { showHideItemsAndMenu } from '../../../util/helper';
import PayrightPlus from './payright-plus';
import { StoreSuccessResponse } from '@merchant-portal/util/merchant-api';

type PaymentPlanToolsCardProps = {
  storeDetail: {
    showMobileCheckout: boolean;
    hasBnpl: boolean;
    hasRegulatedProduct: boolean;
  };
};

const PaymentPlanToolsCard = ({ storeDetail }: PaymentPlanToolsCardProps) => {
  const { accessItems } = showHideItemsAndMenu();
  const userHasPermissionsToCreatePlan = accessItems.createPlan;
  const hasMoreThanOneEnabled =
    [storeDetail.showMobileCheckout, storeDetail.hasRegulatedProduct, storeDetail.hasBnpl].filter(
      (i) => i === true
    ).length > 1;

  const getTabs = () => {
    let tabs = [];
    if (storeDetail.hasBnpl) {
      tabs.push({
        label: 'Plan Calculator',
        children: <PlanCalculator />,
      });
    }

    if (storeDetail.hasBnpl && storeDetail.showMobileCheckout && userHasPermissionsToCreatePlan) {
      tabs.push({
        label: 'App Checkout',
        children: <MobileCheckout />,
      });
    }

    if (storeDetail.hasRegulatedProduct) {
      tabs.push({
        label: 'PayrightPlus',
        pill: 'NEW',
        children: <PayrightPlus />,
      });
    }

    return tabs;
  };

  const getCalculator = () => {
    if (hasMoreThanOneEnabled && userHasPermissionsToCreatePlan) {
      return (
        <div className="tabs">
          <Tabs tabs={getTabs()}></Tabs>
        </div>
      );
    } else if (storeDetail.hasBnpl) {
      return <PlanCalculator />;
    } else if (storeDetail.hasRegulatedProduct) {
      return <PayrightPlus />;
    }
  };

  return (
    <>
      <Card title={hasMoreThanOneEnabled ? 'Payment Plan Tools' : ''}>{getCalculator()}</Card>
    </>
  );
};

export default PaymentPlanToolsCard;
