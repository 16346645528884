import './init';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './util/store';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { envIsTrue } from './util/helper';
import { PayrightThemeProvider, media } from '@payright/web-components';
import { createGlobalStyle } from 'styled-components';
import { ConfigProvider } from '@merchant-portal/providers/config-provider';
import { FeaturesProvider } from '@merchant-portal/providers/features-provider';

import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { StoryblokProvider } from '@merchant-portal/providers/storyblok-provider';

if (envIsTrue('REACT_APP_ENABLE_LOGGING')) {
  if (process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
  }

  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    });
  }
}

const GlobalStyle = createGlobalStyle`
  // Reduce the extra large heading sizes for smaller devices
  ${media.max.large} {
    h1 {
      font-size: 2.2em;
    }
    h2 {
      font-size: 1.93em;
    }
    h3 {
      font-size: 1.65em;
    }
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PayrightThemeProvider>
        <GlobalStyle />
        <ConfigProvider>
          <FeaturesProvider>
            <StoryblokProvider>
              <App />
            </StoryblokProvider>
          </FeaturesProvider>
        </ConfigProvider>
      </PayrightThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
