import * as React from 'react';
import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { media } from '@payright/web-components';
import {
  ControlledDropdown,
  ControlledInputField,
  ControlledDropdownWithInput,
} from '../form-fields';
import { EmploymentDetailsData as MerchantApiEmploymentDetailsData } from '../../types/customer';
import getConstants from '../../util/constants';
import { envIsTrue } from '../../util/helper';

const {
  TIME_IN_JOB,
  EMPLOYMENT_TYPES,
  BUSINESS_NUMBER,
  INCOME_FREQUENCIES,
  MOBILE_VALIDATION_REGEX,
  MOBILE_NUMBER_FORMAT_VALIDATION,
  PHONE_VALIDATION_REGEX,
  PHONE_NUMBER_FORMAT_VALIDATION,
} = getConstants();

export type EmploymentDetailsData = MerchantApiEmploymentDetailsData;

export interface EmploymentDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
  allowIncomeEditing?: boolean;
}

const SCEmploymentDetails = styled.div`
  width: 100%;
  .details-body {
    .input-field,
    .dropdown {
      margin-bottom: 2.46em;
    }
    .dropdown-with-input {
      .dropdown,
      .input-field {
        margin-bottom: 0;
      }
      margin-bottom: 2.46em;
    }
    .current-employer {
      margin-top: 1em;
      width: 100%;

      .error {
        text-align: left;
      }

      ${media.min.medium} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & > div {
          width: calc(50% - 0.75em);
          flex-grow: 0;
        }
      }
      ${media.min.large} {
        & > div {
          width: calc(33% - 0.75em);
        }
      }
      .income-details {
        & > div {
          margin-top: 2em;
        }
      }
    }
    .income-and-wages {
      color: #6e6e6e;
    }
  }
`;

const EmploymentDetails = ({
  existingCustomer,
  editing,
  allowIncomeEditing = true,
}: EmploymentDetailsProps) => {
  const { errors, watch, clearErrors } = useFormContext<{
    employmentDetails: EmploymentDetailsData;
  }>();

  const employmentType = watch(
    'employmentDetails.employmentType'
  ) as EmploymentDetailsData['employmentType'];

  let additionalEmploymentDetailsBlock: 'employed' | 'self-employed' | 'others' = 'employed';

  switch (employmentType) {
    case 'Full time':
    case 'Part Time':
    case 'Casual':
      additionalEmploymentDetailsBlock = 'employed';
      break;
    case 'Self Employed':
      additionalEmploymentDetailsBlock = 'self-employed';
      break;
    default:
      additionalEmploymentDetailsBlock = 'others';
  }

  useEffect(() => {
    clearErrors('employmentDetails');
  }, [employmentType, clearErrors]);

  const sectionRef = useRef<HTMLDivElement>(null);

  // Scroll to block (by ID) when user submits and validation errors
  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors.employmentDetails && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  return (
    <SCEmploymentDetails id="EmploymentSection">
      <div ref={sectionRef} style={{ position: 'relative', top: '-2em' }} />
      <div className="details-body">
        <ControlledDropdown
          name="employmentDetails.employmentType"
          readOnly={existingCustomer && !editing}
          options={EMPLOYMENT_TYPES}
          rules={{ required: 'Employment Type is required' }}
          error={errors.employmentDetails?.employmentType?.message as string}
          maxWidth="100%"
        >
          Employment or Benefit Type *
        </ControlledDropdown>

        <div>
          {additionalEmploymentDetailsBlock === 'employed' && (
            <div className="current-employer">
              <ControlledInputField
                name="employmentDetails.employerName"
                readOnly={existingCustomer && !editing}
                rules={{ required: 'Employer Name is required' }}
                error={(errors.employmentDetails as any)?.employerName?.message as string}
              >
                Employer name *
              </ControlledInputField>

              <ControlledInputField
                rules={{
                  required: 'Phone number is required',
                  pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_MOBILE_VALIDATION')
                    ? undefined
                    : {
                        value: PHONE_VALIDATION_REGEX,
                        message: PHONE_NUMBER_FORMAT_VALIDATION,
                      },
                }}
                name="employmentDetails.employerPhone"
                error={(errors.employmentDetails as any)?.employerPhone?.message as string}
                readOnly={existingCustomer && !editing}
                className="phone"
              >
                Employer Phone No. *
              </ControlledInputField>

              <ControlledDropdown
                name="employmentDetails.timeInJob"
                options={TIME_IN_JOB}
                readOnly={existingCustomer && !editing}
                rules={{ required: 'Time in Job is required' }}
                error={(errors.employmentDetails as any)?.timeInJob?.message as string}
              >
                Time in job *
              </ControlledDropdown>
            </div>
          )}

          {additionalEmploymentDetailsBlock === 'self-employed' && (
            <div className="current-employer">
              <ControlledInputField
                name="employmentDetails.tradingName"
                readOnly={existingCustomer && !editing}
                rules={{ required: 'Trading Name is required' }}
                error={(errors.employmentDetails as any)?.tradingName?.message as string}
              >
                Trading Name
              </ControlledInputField>

              <ControlledInputField
                name="employmentDetails.tradingPhone"
                readOnly={existingCustomer && !editing}
                rules={{
                  required: 'Phone number is required',
                  pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_MOBILE_VALIDATION')
                    ? undefined
                    : {
                        value: MOBILE_VALIDATION_REGEX,
                        message: MOBILE_NUMBER_FORMAT_VALIDATION,
                      },
                }}
                error={(errors.employmentDetails as any)?.tradingPhone?.message as string}
              >
                Phone No.
              </ControlledInputField>

              <ControlledInputField
                name="employmentDetails.abn"
                readOnly={existingCustomer && !editing}
                rules={{ required: `${BUSINESS_NUMBER} is required` }}
                error={(errors.employmentDetails as any)?.abn?.message as string}
              >
                {BUSINESS_NUMBER}
              </ControlledInputField>
            </div>
          )}
          <hr />
          <div className="income-details">
            <ControlledDropdownWithInput
              dropdownName="employmentDetails.incomeFrequency"
              inputName="employmentDetails.incomeAmount"
              inputPrefix="$"
              readOnly={(existingCustomer && !editing) || !allowIncomeEditing}
              options={INCOME_FREQUENCIES}
              inputRules={{
                required: 'Personal income is required',
                validate: (value: any) => {
                  if (isNaN(value)) {
                    return 'Personal income must be a number';
                  }
                },
              }}
              error={(errors.employmentDetails as any)?.incomeAmount?.message as string}
            >
              Wages & Salary (After Tax) *
            </ControlledDropdownWithInput>

            <ControlledDropdownWithInput
              dropdownName="employmentDetails.otherIncomeFrequency"
              inputName="employmentDetails.otherIncomeAmount"
              inputPrefix="$"
              readOnly={(existingCustomer && !editing) || !allowIncomeEditing}
              options={INCOME_FREQUENCIES}
              inputRules={{
                required: 'Other income is required',
                validate: (value: any) => {
                  if (isNaN(value)) {
                    return 'Other income must be a number';
                  }
                },
              }}
              error={(errors.employmentDetails as any)?.otherIncomeAmount?.message as string}
            >
              Other Income *{' '}
              <span className="income-and-wages">
                (Such as Centrelink, Superannuation, Rental income)
              </span>
            </ControlledDropdownWithInput>
          </div>
        </div>
      </div>
    </SCEmploymentDetails>
  );
};

export default EmploymentDetails;
