import { DepositPaymentStatues, SugarPlanStatus } from '@merchant-portal/util/constants/enums';
/** This file is dedicated to helper functions and tools, for package web-components usage ONLY.
 *  These functions are subject to change, when either no longer needed, or web-components core features updated.
 */

/**
 * Filter 'Deposit Payment Status' field, to display "Deposit Paid / Deposit Unpaid / Not Required" labels.
 * Note: depositStatus (via plan.depositPaymentStatus field) is received as 'lowercase' in web-components, for switch statement logic.
 * Such as, depositpaid, depositunpaid, notrequired.
 *
 * @param depositPaymentStatus
 * @param depositPaid
 */
export function filterDepositPaymentStatus(depositPaymentStatus: any, planStatus: string) {
  if (planStatus === SugarPlanStatus.APPROVED && depositPaymentStatus === '') {
    return 'depositUnpaid';
  }

  if (
    planStatus === SugarPlanStatus.APPROVED &&
    [
      DepositPaymentStatues.PAID_BY_CUSTOMER,
      DepositPaymentStatues.COLLECTED_BY_MERCHANT,
      DepositPaymentStatues.PAID_TO_MERCHANT,
    ].includes(depositPaymentStatus)
  ) {
    return 'depositPaid';
  }

  if (
    planStatus === SugarPlanStatus.APPROVED &&
    depositPaymentStatus === DepositPaymentStatues.ZERO_DEPOSIT
  ) {
    return 'notRequired';
  }

  return '';
}

/**
 * Filter 'Deposit Paid Status' field, to display "Paid / Unpaid" labels.
 *
 * @param depositPaymentStatus
 * @param planStatus
 */
export function filterDepositPaidStatus(depositPaymentStatus: any, planStatus: string) {
  if (
    filterDepositPaymentStatus(depositPaymentStatus, planStatus) === 'depositUnpaid' ||
    filterDepositPaymentStatus(depositPaymentStatus, planStatus) === 'depositPaid' ||
    filterDepositPaymentStatus(depositPaymentStatus, planStatus) === ''
  ) {
    return depositPaymentStatus;
  }
  return '';
}
