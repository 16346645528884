import React from 'react';
import styled from 'styled-components';
import { RootState } from '../../slices';
import { useSelector } from 'react-redux';
import { Button, media } from '@payright/web-components';
import getConstants from '../../util/constants';
import Spinner from '../../components/spinner';
import { ProductLine } from '@merchant-portal/util/constants/enums';

const { MESSAGE_SEND_APPLICATION_LINK_TO_CUSTOMER, LABEL_SEND_TO_CUSTOMER_TO_COMPLETE } =
  getConstants();

const SCCustomerLedDetails = styled.div`
  .send-to-customer {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    color: #531dff;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1em;
  }
  .section-content {
    margin-bottom: 2em;
    background-color: ${(props) => props.theme.colours.secondary3};
    border-radius: 5px 5px 0 0;
    padding: 1em;
    ${media.min.large} {
      padding: 2.66em;
    }

    .button-actiongroup-wrapper {
      &.button-actiongroup-wrapper--full-width {
        width: 100%;
        max-width: 100%;
        padding-bottom: 0;
      }

      .send-to-customer,
      .complete-on-cutomer-behalf {
        flex-grow: 1;
        button {
          width: 100%;
          max-width: 100%;
          height: 90px;
          ${media.min.large} {
            height: 50px;
          }
        }
      }

      .complete-on-cutomer-behalf {
        margin-left: 20px;
      }
    }

    .message {
      display: flex;
      justify-content: center;
      color: #531dff;
      font-size: 1em;
      font-weight: bold;
      margin-top: 2em;
    }

    .customer-detail-wrapper {
      margin: 0px auto;
      max-width: 350px;
      width: 350px;
      padding: 10px;
      justify-content: center;

      .details {
        color: #531dff;
        font-size: 1em;
        font-weight: bold;
        margin-top: 1em;
        flex: 0 1 auto;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: center;
        display: flex;
        white-space: nowrap;
        padding: 5px 15px 5px 65px;
      }
    }
  }
`;

interface modalProps {
  handleClickEvent: Function;
  showApplicationLinkButton: boolean;
  productLine?: ProductLine;
}

const CustomerLedBlock = ({
  handleClickEvent,
  showApplicationLinkButton,
  productLine,
}: modalProps) => {
  const { customerDetails, loading } = useSelector((state: RootState) => state.customer);
  const loadingCheckout = useSelector((state: RootState) =>
    productLine === ProductLine.PPLUS ? state.payrightPlus.loading : state.checkout.loading
  );
  return (
    <SCCustomerLedDetails>
      {' '}
      {!showApplicationLinkButton ? (
        <div className="send-to-customer">
          <Button
            handleClick={() => handleClickEvent(true)}
            maxWidth="100%"
            type="submit"
            iconPosition="right"
            colour="primary"
            className="next"
          >
            {LABEL_SEND_TO_CUSTOMER_TO_COMPLETE}
          </Button>
        </div>
      ) : (
        <>
          <div className="section-content">
            <div className="message">{MESSAGE_SEND_APPLICATION_LINK_TO_CUSTOMER}</div>
            <div className="customer-detail-wrapper">
              <div className="details">Email: {customerDetails.email}</div>
              <div className="details">Mobile: {customerDetails.phone}</div>
              <div className="button-actiongroup-wrapper button-actiongroup-wrapper--full-width">
                <div className="send-to-customer">
                  <Button
                    outline
                    type="button"
                    colour="blue"
                    handleClick={() => handleClickEvent(false)}
                  >
                    Cancel
                  </Button>
                </div>

                <div className="complete-on-cutomer-behalf">
                  <Button
                    type="submit"
                    icon={loading || loadingCheckout ? <Spinner /> : undefined}
                    disabled={loading || loadingCheckout}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </SCCustomerLedDetails>
  );
};

export default CustomerLedBlock;
