import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@merchant-portal/util/hooks';

/// IMPORTING WEB COMPONENTS
import { media, Alert, IconAttention, fixNameCase, Header } from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';

/// REDUX TOOLKIT IMPORTING THE SLICES ROOT SLICE && CUSTOMER SLICES
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@merchant-portal/util/store';
import { RootState } from '../../../slices';
import {
  customerSearch,
  setMode,
  saveCustomerDetails,
  clearCustomerSearchError,
} from '../../../slices/customer';
import {
  fetchCheckoutAttributes,
  attachCustomer,
  sendApplicationLink,
  sendVerificationCode,
} from '../../../slices/pplus-checkout';

// NESTED COMPONENTS USED WITHING THE FORM
import CustomerDetailsBlock, {
  CustomerDetailsData,
} from '../../../components/customer-data/customer-details-block';
import FormSection from '../../../components/form-section';
import CustomerTypeSelector, {
  FormCustomerType,
} from '../../../components/customer-data/customer-type-selector';
import ExistingCustomerSearch from '../../../components/existing-customer-search';
import CustomerLedBlock from '../../../components/customer-data/customer-led-block';
import getConstants from '../../../util/constants';

import { ProductLine } from '@merchant-portal/util/constants/enums';
import scrollToComponent from 'react-scroll-to-component';

const { NEW_CUSTOMER_DETAIL } = getConstants();

type FormData = {
  customerDetails: CustomerDetailsData;
};

const SCCustomerDetails = styled.div`
  background: ${(props) => props.theme.colours.white};
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.altlight};
    margin: 3em 0;
  }

  .customerdetails-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 3em;

    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    ${media.max.ewide} {
      padding: 0 1.6em;
    }

    h4 {
      color: ${(props) => props.theme.colours.blue.base};
    }
  }

  .button-actiongroup-wrapper {
    padding: 30px 0px 30px 0px;
    display: flex;
    justify-content: space-between;
    .save-for-later {
      margin-right: 0.5em;
    }
    ${media.min.medium} {
      .save-for-later {
        margin-right: 1em;
        margin-left: 1.5em;
        width: 250px;
      }
    }
    ${media.min.large} {
      .save-for-later {
        margin-right: 1em;
        margin-left: 0em;
        width: 400px;
      }
    }
  }

  .alert-container {
    margin-bottom: 3em;
  }
`;

const CustomerDetails = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();

  // Simple state for certain form elements that do not need to be handled by react-hook-form
  const [selectedCustomerType, setSelectedCustomerType] = useState<FormCustomerType>('newCustomer');

  /// RETURNS A PART OF THE STATE - CUSTOMER
  const globalFormState = useSelector((state: RootState) => state.customer);

  const [customerApplicationLink, setCustomerApplicationLink] = useState(false);

  const isExistingCustomer = globalFormState.customerId !== null;

  const {
    customerTypeSelection,
    mode,
    customerMatch,
    customerId,
    errorMessage: customerErrorMessage,
  } = useSelector((state: RootState) => state.customer);

  /// RETURNS A PART OF THE STATE - CHECKOUT
  const { checkout, errorMessage: checkoutErrorMessage } = useSelector(
    (state: RootState) => state.payrightPlus
  );

  const checkoutId = checkout?.id; // The checkout id in Redux
  const saleAmount = checkout?.attributes?.saleAmount || 0;
  const depositAmount = checkout?.attributes?.depositAmount || 0;
  const loanAmount = saleAmount - depositAmount;
  //const {paymentFrequency, paymentPeriod } = paymentDetails;
  const queryParamCheckoutId = query.get('checkoutId'); // The checkout id in the URL

  //set form for default customer type
  useEffect(() => {
    if (selectedCustomerType !== null) {
      if (selectedCustomerType === 'newCustomer') {
        dispatch(setMode('data-entry'));
      } else {
        dispatch(setMode('customer-search'));
      }
    }
  }, [dispatch, selectedCustomerType]);

  //set the customer type as existing customer when comes from when comes from loan list
  useEffect(() => {
    if (queryParamCheckoutId && !!checkout?.customerId) {
      setSelectedCustomerType('existingCustomer');
    }
  }, [queryParamCheckoutId, dispatch, checkout]);

  // Load latest checkout resource from server
  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [checkoutId, dispatch, queryParamCheckoutId]);

  /// CUSTOMER LOOKUP VALIDATION
  const reactHookFormCustomerLookup = useForm<{ customerDetails: CustomerDetailsData }>({
    defaultValues: {
      customerDetails: { ...globalFormState.customerDetails },
    },
    // resolver: yupResolver(custDetailsBlockValidationSchema),
  });

  const {
    handleSubmit: handleSubmitCustomerLookup,
    setValue: setCustomerLookupValue,
    clearErrors,
  } = reactHookFormCustomerLookup;

  /// REST OF THE FORM VALIDATION  LOOKUP VALIDATION
  const reactHookFormCustomerDetails = useForm<FormData>({
    defaultValues: {},
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const { setValue: setCustomerDetailsValue } = reactHookFormCustomerDetails;

  const reactHookFormCustomerLed = useForm({
    shouldFocusError: true,
  });

  const { handleSubmit: handleSubmitCustomerLed } = reactHookFormCustomerLed;

  const formSubmitCustomerLedForNewCustomer = () => {
    const fixedName = fixNameCase(
      globalFormState.customerDetails.firstName,
      globalFormState.customerDetails.middleName,
      globalFormState.customerDetails.lastName
    );

    const customer = {
      id: customerId,
      customerDetails: {
        ...(globalFormState.customerDetails || undefined),
        firstName: fixedName.firstName,
        middleName: fixedName.middleName,
        lastName: fixedName.lastName,
      },
    };

    if (checkoutId) {
      dispatch(saveCustomerDetails(customer, ProductLine.PPLUS, checkoutId)).then(
        (customerSaveResult) =>
          attachCustomerToCheckout({
            checkoutId: checkoutId,
            customerId: customerSaveResult.id as string,
            isNewCustomer: true,
          })
      );
    }
  };

  const attachCustomerToCheckout = ({
    checkoutId,
    customerId,
    isNewCustomer,
  }: {
    checkoutId: string;
    customerId: string;
    isNewCustomer: boolean;
  }) => {
    dispatch(attachCustomer(checkoutId, customerId, isNewCustomer))
      .then(() => {
        dispatch(sendApplicationLink(checkoutId));
        dispatch(sendVerificationCode(checkoutId));
        history.push(`/loans/new/application-sent-to-customer`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Update form values when data from redux is populated/modified
  useEffect(() => {
    Object.keys(globalFormState.customerDetails).forEach((key) => {
      clearErrors(`customerDetails.${key}`);
      setCustomerLookupValue(
        `customerDetails.${key}`,
        Reflect.get(globalFormState.customerDetails, key)
      );
    });
  }, [
    globalFormState.customerDetails,
    setCustomerLookupValue,
    setCustomerDetailsValue,
    clearErrors,
  ]);

  /// FORM SUBMIT HANDLER CUSTOMER SEARCH
  const formSubmitHandlerCustomerSearch = (formData: { customerDetails: CustomerDetailsData }) => {
    dispatch(customerSearch(formData.customerDetails, customerTypeSelection, ProductLine.PPLUS));
    setCustomerApplicationLink(true);
  };

  const customerTypeHandler = (selectedCustomerType: FormCustomerType) => {
    setSelectedCustomerType(selectedCustomerType);
  };

  const handleApplicationClickEvent = (applicationButton: boolean) => {
    setCustomerApplicationLink(applicationButton);
  };

  // Clear error messages, when alternating between 'tabs' (watching 'mode' changes)
  useEffect(() => {
    dispatch(clearCustomerSearchError());
  }, [dispatch, mode]);

  let hiddenBlockRef: HTMLDivElement | null = null;
  useEffect(() => {
    if (customerMatch && customerApplicationLink) {
      scrollToComponent(hiddenBlockRef, {
        offset: -80,
        align: 'top',
        duration: 600,
      });
    }
  }, [customerMatch, hiddenBlockRef, customerApplicationLink]);

  return (
    <SCCustomerDetails>
      <LoggedInLayout contentMarginTop={0} activePage="/loans/new">
        <Header title="New Loan" />
        <div className="customerdetails-body">
          <FormSection title="Customer Type" text="">
            <>
              <CustomerTypeSelector
                value={selectedCustomerType}
                handleSelect={customerTypeHandler}
              />
              <hr />
            </>
          </FormSection>

          {customerErrorMessage && (
            <div className="alert-container">
              <Alert
                title={'Application Initiation error'}
                body={customerErrorMessage}
                outcome="error"
                icon={<IconAttention />}
              />
            </div>
          )}

          <div style={{ display: mode === 'data-entry' ? 'block' : 'none' }}>
            <FormSection title="Customer Details" text={NEW_CUSTOMER_DETAIL}>
              <FormProvider {...reactHookFormCustomerLookup}>
                <form
                  noValidate
                  onSubmit={handleSubmitCustomerLookup(formSubmitHandlerCustomerSearch)}
                >
                  <CustomerDetailsBlock
                    readOnly={isExistingCustomer}
                    showNextButton={!customerApplicationLink || !customerMatch}
                  />
                </form>
              </FormProvider>
            </FormSection>

            {customerApplicationLink &&
              !customerErrorMessage &&
              customerMatch &&
              !isExistingCustomer && (
                <FormSection>
                  {checkoutErrorMessage && (
                    <div className="alert-container">
                      <Alert
                        title={'Application Initiation error'}
                        body={checkoutErrorMessage}
                        outcome="error"
                        icon={<IconAttention />}
                      />
                    </div>
                  )}
                  <FormProvider {...reactHookFormCustomerLed}>
                    <form onSubmit={handleSubmitCustomerLed(formSubmitCustomerLedForNewCustomer)}>
                      <CustomerLedBlock
                        handleClickEvent={handleApplicationClickEvent}
                        showApplicationLinkButton={true}
                        productLine={ProductLine.PPLUS}
                      />
                      <div
                        ref={(section: HTMLDivElement) => {
                          hiddenBlockRef = section;
                        }}
                      ></div>
                    </form>
                  </FormProvider>
                </FormSection>
              )}
          </div>
          <div style={{ display: mode === 'customer-search' ? 'block' : 'none' }}>
            <ExistingCustomerSearch
              handleChangeMode={customerTypeHandler}
              productLine={ProductLine.PPLUS}
              formSubmitCustomerLed={attachCustomerToCheckout}
              loanAmount={loanAmount}
            />
          </div>
        </div>
      </LoggedInLayout>
    </SCCustomerDetails>
  );
};

export default CustomerDetails;
