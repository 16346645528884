import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../util/store';
import MerchantAPI from '../util/merchant-api';

type InitialState = {
  loading?: boolean;
  hasError?: boolean;
  submitSucceeded?: boolean;
  errorMessage?: string | null;
};

export const initialState: InitialState = {
  loading: false,
  hasError: false,
  errorMessage: null,
  submitSucceeded: false,
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    forgotPasswordBegin: (state) => ({
      ...state,
      loading: true,
      errorMessage: null,
      hasErrors: false,
    }),
    forgotPasswordSuccess: (state) => {
      return {
        ...state,
        loading: false,
        submitSucceeded: true,
        hasErrors: false,
      };
    },
    forgotPasswordFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
        submitSucceeded: false,
      };
    },
  },
});

const { forgotPasswordBegin, forgotPasswordSuccess, forgotPasswordFailure } =
  forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;

//Thunk to generate the temporary password and update the password status

export const tempPasswordAndStatusUpdate =
  (userCredentials: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(forgotPasswordBegin());
      await MerchantAPI.forgotPassword(userCredentials);
      dispatch(forgotPasswordSuccess());
    } catch (e: any) {
      dispatch(forgotPasswordFailure({ errorMessage: e.message }));
    }
  };
