import React from 'react';
import { FormInputWithTooltip } from './form-input-with-tooltip';

type MerchantReferenceProps = {
  readOnly?: boolean;
};

export const MerchantReference = ({ readOnly }: MerchantReferenceProps) => {
  return (
    <FormInputWithTooltip
      name="merchantRef"
      placeholder="#345545454"
      readOnly={readOnly}
      tooltipContent="This field is for your reference only, and will not be visible to the customer."
    >
      Merchant Reference
    </FormInputWithTooltip>
  );
};
