import { SugarStaffPermission } from '@merchant-portal/types/staff';

export class StaffPermissionLabelMaker {
  private storeDetail: { hasBnpl: boolean; hasRegulatedProduct: boolean } | undefined;

  public getLabel(key: SugarStaffPermission): string {
    this.loadStoreDetail();

    const hasBnpl = this.storeDetail?.hasBnpl ?? false;
    const hasRegulatedProduct = this.storeDetail?.hasRegulatedProduct ?? false;

    const loanSlashPlan = this.getLoanSlashPlan(hasBnpl, hasRegulatedProduct, false);
    const loansSlashPlans = this.getLoanSlashPlan(hasBnpl, hasRegulatedProduct, true);

    switch (key) {
      case 'Activate Plans':
        return `Activate ${loansSlashPlans}`;
      case 'Create Plan':
        return `Create ${loanSlashPlan}`;
      case 'Export Plans And View Plan MSF':
        return 'Export & View MSF';
      case 'View Other Staff Plans':
        return `View Other Staff ${loansSlashPlans}`;
      case 'View Plans':
        return `View ${loansSlashPlans}`;
      case 'View Rates':
        return 'View Rates';
      case 'View Staff':
        return 'View Staff';
      case 'Administrator':
        return 'Administrator';
    }
  }

  private getLoanSlashPlan(hasBnpl: boolean, hasRegulatedProduct: boolean, plural: boolean) {
    const loan = plural ? 'Loans' : 'Loan';
    const plan = plural ? 'Plans' : 'Plan';

    if (hasBnpl && hasRegulatedProduct) {
      return `${loan} / ${plan}`;
    }

    if (hasRegulatedProduct) {
      return loan;
    }

    return plan;
  }

  private loadStoreDetail() {
    if (!!this.storeDetail) {
      return;
    }

    this.storeDetail = JSON.parse(localStorage.getItem('merchantStoreInfo') || '{}');
  }
}
