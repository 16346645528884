import { media, styled } from '@payright/web-components';

export const SCCenterContentLayout = styled.div`
  .content {
    margin-top: 0px;
    .tab-list {
      background: white;
    }
  }
  .title {
    h4 {
      word-spacing: normal;
      margin-right: 2em;
      font-size: 1.1em;
    }
  }
`;

export const LandingPage = styled.div`
  .main-content {
    display: flex;
    flex-direction: column;

    .section-one {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-around;
      padding: 0 2em 0 2em;
      ${media.min.large} {
        flex-direction: row;
      }
      .left {
        ${media.min.large} {
          float: left;
          width: 40%;
        }
        > h5 {
          color: #5431ff;
          padding-bottom: 1em;
        }

        > h3 {
          color: #240052;
          padding-bottom: 1em;
        }

        > div {
          padding-bottom: 2em;
        }

        > button {
          margin-top: 2em;
          margin-bottom: 2em;
        }
        > p > svg {
          margin-right: 1em;
        }
      }

      .right {
        margin-bottom: 2em;
        ${media.min.large} {
          float: right;
          width: 50%;
        }

        > svg {
          float: right;
          margin: -3.5em 0 -3.5em 0;
          padding: 0px;
        }
      }
    }

    .section-two {
      display: flex;
      flex-direction: column;
      text-align: center;
      background: white;
      padding: 2em;
      align-items: center;
      ${media.min.large} {
        flex-direction: row;
      }

      .whypayright {
        padding: 1em;
        > h4 {
          color: #240052;
        }
        > p {
          padding-top: 1em;
        }
      }
    }

    .section-three {
      padding: 2em;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      ${media.min.large} {
        flex-direction: row;
      }
      > svg {
        margin: 1em;
        ${media.min.large} {
          margin-right: 2em;
          margin-left: 2em;
        }
      }
    }

    .section-four {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #5431ff;
      color: white;
      padding: 4em;

      ${media.min.large} {
        text-align: end;
        flex-direction: row;
      }
      .sales-icon {
        ${media.min.large} {
          width: 40%;
          margin-right: 2em;
        }
        > svg {
          border-radius: 50%;
        }
      }
      .sales-testimony {
        color: white;
        ${media.min.large} {
          width: 42%;
          text-align: left;
        }
        > h4 {
          font-size: 1.13em;
          font-weight: 100;
          ${media.min.large} {
            font-size: 1.53em;
          }
        }
      }
    }

    .section-five {
      padding: 2em;
      background: #240052;
      color: white;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      ${media.min.large} {
        display: block;
      }

      > h4 {
        font-size: 1em;
        margin-bottom: 1em;
        ${media.min.large} {
          float: left;
          font-size: 1.53em;
        }
      }

      > button {
        float: right;
      }
    }
  }
`;

export const CreatePaymentOption = styled.div`
  hr {
    height: 2px;
    border-width: 0;
    color: white;
    background-color: white;
  }
  .content {
    margin-top: 0px;
  }
  .heading-step-1 {
    margin-bottom: 1em;
  }

  .card {
    margin: 1em;
    width: auto;
    .title {
      margin-bottom: 0px;
    }
  }

  height: 100%;
  .top {
    padding: 2em 2em 0 2em;
    width: 100%;
  }
  .title {
    display: flex;
    align-items: center;
    > h2 {
      float: left;
      width: 100%;
      ${media.min.large} {
        width: 80%;
      }
    }
    > svg {
      margin-right: 1em;
    }
    > span {
      width: 80%;
      float: right;
    }
    width: 100%;
  }

  .icon {
    float: right;
  }
  .step-one {
    padding: 2em;

    .options {
      display: flex;
      flex-direction: column;
      ${media.min.large} {
        flex-direction: row;
        margin-left: -1em;
      }
      > div {
        margin: 1em;
        width: auto;
        .title {
          margin-bottom: 0px;
        }

        .radio-button {
          margin-right: -3.5em;
        }
      }
    }
  }
  .step-two {
    padding: 2em;

    .ecommerce-store {
      display: flex;
      flex-direction: column;

      ${media.min.large} {
        flex-direction: row;
        margin-left: -1em;
        flex-flow: wrap;
      }
      > div {
        margin: 1em;
        width: auto;
        .title {
          margin-bottom: 0px;
        }
        mix-width: 284px;
        max-height: 74px;
        min-height: auto;

        .radio-button {
          margin-right: -3.5em;
        }
      }
    }
  }
  .step-three {
    padding: 2em;
    .checkout-option {
      display: flex;
      flex-direction: column;
      ${media.min.large} {
        margin-left: -1em;
        flex-direction: row;
      }
      > div {
        margin: 1em;
        width: auto;
        .title {
          margin-bottom: 0px;
        }

        .radio-button {
          margin-right: -3.5em;
        }
      }
    }
  }

  .step-four {
    display: flex;
    flex-direction: column;

    ${media.min.large} {
      flex-direction: row;
    }
    .left {
      padding: 2em;
      ${media.min.large} {
        width: 50%;
        float: left;
      }

      .amount {
        flex-direction: row;
        display: flex;

        .saleamount-group {
          width: 50%;
          > div {
            width: 90%;
          }
        }

        .sku-group {
          width: 50%;
        }
      }

      .card {
        max-width: 100%;
        margin: 0em;
        min-height: 60px;
        height: 60px;
        margin-bottom: 4em;
        border-radius: inherit;

        .dynamic-config {
          > h5 {
            display: flex;
            align-items: center;
            float: left;
            width: 90%;
            > svg {
              margin-left: 1em;
            }
          }

          > div {
            float: right;
            width: 10%;
          }
        }
      }
    }

    .right {
      padding: 2em;
      ${media.min.large} {
        width: 50%;
        float: right;
      }
      > div {
        /* width: 100%; */
        max-width: 100%;
        /* max-height: 100%; */
        height: 100%;
      }
    }
  }

  .step-five {
    padding: 2em;
    display: flex;
    flex-direction: column;
    > .card {
      margin-left: 0px;
      max-width: 100%;
    }
    > div {
      > button {
        float: right;
        border-radius: 30px;
      }
      > h5 {
        margin-bottom: 1em;
        ${media.min.large} {
          float: left;
        }
      }
    }
  }

  .integration-steps {
    background: white;
    padding: 2em;

    ${media.min.large} {
      padding: 5em;
    }
    > ol {
      list-style: decimal;
      list-style-position: inside;
    }
  }
`;

export const SCDDOMessage = styled.div`
  color: ${(props) => props.theme.colours.black};
  width: 100%;
  .container-steps {
    display: flex;
    flex-wrap: wrap;
    background-color: ${(props) => props.theme.colours.white};
    margin: 2em;
    padding: 2em;
    p {
      margin: 0.5em 0em;
      ${media.min.small} {
        margin: 1em 0em;
      }
    }
  }
`;

const SCSendLink = styled.div`
  color: ${(props) => props.theme.colours.black};
  width:100%;
  .customise-link {
    padding-left:2em;
    padding-top:2em;
    ${media.min.small} {
      padding-top:2em;
    }
  }
  .parent-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding:0em 2em 2em 2em;
    ${media.min.large} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .container-steps {
    display: flex;
    flex-wrap: wrap;
    background-color: ${(props) => props.theme.colours.white};
    margin:2em;
    h5{
      margin:0em 2em 0.5em 1.75em;
      ${media.min.small} {
        margin:0em 4em 1em 3.5em;
      }
    }
    ol {
        margin:0 2em 0 2em;
        list-style-type:decimal;
        color: ${(props) => props.theme.colours.plum};
        ${media.min.small} {
          margin:0 4em 0 4em;
        }
        li {
            list-style-position: outside;
            line-height:30px;
            margin-left:1em;
            padding-left:20px;
         }
        li::before {
            width: 20px;
            display:inline;
           }
      }
    .heading {
        padding-top: 2em;
        margin:0em 1em 1em 2em;
        ${media.min.small} {
          padding-top:4em;
          margin:0em 2em 2em 4em;
          width: 100%;
        }
        h4 {
          padding-bottom:10px;
        }
      }
    .url-steps {
      width: 100%;
      margin-bottom:2em;
      ${media.min.small} {
        width: 100%;
        margin-bottom:4em;
      }
      ${media.min.large} {
        margin-bottom:2em;
      }
      .steps {
        width: 100%;
      }
    }
  }
.url-generate-block {
    width: 100%;
    background-color: ${(props) => props.theme.colours.white};
    border: 1px solid #d7dde7;
    padding: 1em;
    border-radius: 5px;
    margin-top:2em;
    ${media.min.large} {
      width: 50%;
      margin:unset;
    }
    .filler {
        height: 15px;
    }
    .inner-box-container {
        display: flex;
        background-color: ${(props) => props.theme.colours.secondary3};
        padding: 0em 1em 2em 1em;
        width: 100%;
        flex-direction:column;
        justify-content: space-around;
        ${media.min.large} {
          margin-left: 1em;
          width: 95%;
          justify-content: flex-start;
         }
         .genereted-url-text-box {
            width:100%;
            padding-top: 0.8em;
            ${media.min.large} {
              width: 100%;
              padding-right: 20px;
              padding-left:20px;
            }
          }
        .rounded-button {
          align-self: center;
          text-align: center;
          
          ${media.min.small} {
            width: 160px;
            text-align: left;
            padding: 0;
          }
          ${media.min.large} {
            padding-top: 0px;
          }
        }
        .qr-code-label {
          padding: 0.8em 0px 0px 2px;
          font-weight: bold;
          width: 33%;
          ${media.min.large} {
            width: 33%;
            padding-right: 0;
            padding-left: 20px;
          }
        }
        .qr-container {
          margin-top: 1em;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          gap: 1em;
          ${media.min.small} {
            gap: 0em;
            flex-direction: row;
            margin-top: 2em;
          }
        }
        .qr-code {
          text-align: center;
          ${media.min.large} {
            justify-content: center;
            align-self: center;
          }
        }
        Button {
          width: 11em;
          ${media.min.small} {
            min-width: 115px;
            width: 100%;
          }
        }
      }
    }
  }
}
 .form-fields {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    padding: 2em 0 2em 0;
    width: 100%;
    ${media.min.small} {
      padding-top: 2em 0 0 0;
    }
    ${media.min.large} {
      flex-direction: column;
      flex-wrap: wrap;
      padding: 2em 2em 0 0;
      width: calc(50% - 10px);
    }
    .row-container {
      gap: 5%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      ${media.min.small} {
        flex-direction: row;
      }
      ${media.min.large} {
        gap: 5%;
        width: auto;
        }
      .field {
        width: 100%;
        padding-bottom: 5%;
        ${media.min.small} {
        width: 50%;
        padding-bottom: 0;
        }
        ${media.min.large} {
        width: 80%;
        }
        .input-group {
          max-width: 100%;
          width: 100%;
          ${media.min.large} {
            width: calc(100% - 40px);
          }
        }
        .dropdown {
          width: 100%;
          max-width: 100%;
          ${media.min.small} {
            padding-top: 20px;
            max-width: 100%;
            width: 100%;
            padding-bottom: 30px;
          }
          ${media.min.large} {
            width: calc(100% - 40px);
          }
        }
      }
      .field-button {
        width: 100%;
        padding-top: 3%;
        ${media.min.small} {
          width: 50%;
          padding-bottom: 0%;
          padding-top: 0;
        }
        
        ${media.min.large} {
        width: 100%;
        }
        .button {
          max-width: 100%;
          ${media.min.large} {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }
`;

export default SCSendLink;
