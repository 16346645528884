import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Tabs } from '@payright/web-components';
import Loader from '@merchant-portal/components/loader';
import axios from 'axios';
import moment from 'moment';

const SCNewsWidget = styled.div`
  .news-item {
    display: flex;
    gap: 15px;
    &__thumbnail {
      img {
        border-radius: 10px;
      }
    }
    &__title {
      flex-grow: 2;
      font-weight: bold;
      color: ${(props) => props.theme.colours.blue.base};
    }
    &__date {
      width: 70px;
      text-align: right;
    }
    margin-bottom: 15px;
  }
`;

type NewsItem = {
  id: string;
  url: string;
  title: string;
  date_published: string;
  thumbnail: string;
};

/*
 * TODO Error from Payright Wordpress main website:
 * No 'Access-Control-Allow-Origin' header is present on the requested resource.
 *
 */
const NewsWidget = () => {
  useEffect(() => {
    setLoading(true);
    axios
      .get('https://payright.com.au/feed/json')
      .then((response) => {
        const blogItems = response.data.items
          .map(
            (item: {
              id: string;
              url: string;
              title: string;
              date_published: string;
              thumbnail_url: string;
            }) => {
              return {
                id: item.id,
                url: item.url,
                title: item.title,
                date_published: item.date_published,
                thumbnail: item.thumbnail_url,
              };
            }
          )
          .splice(0, 6);

        setNewsItems(blogItems);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const renderBlogItem = ({ id, url, title, date_published, thumbnail }: NewsItem) => {
    return (
      <div key={id} className="news-item">
        <div className="news-item__thumbnail">
          <img
            src={thumbnail || '/images/news-default-thumbnail.png'}
            height="100"
            width="100"
            alt="News article thumbnail"
          ></img>
        </div>
        <div className="news-item__title">
          <a href={url} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </div>
        <div className="news-item__date">{moment(date_published).format('DD MMM')}</div>
      </div>
    );
  };

  const renderBlogTab = (loading: boolean, newsItems: NewsItem[]) => {
    return (
      <div key="no-content-key" className="details-wrapper-body">
        {loading && <Loader />}
        {!loading && newsItems.length === 0 && 'No news content to display'}
        {newsItems.map((item) => renderBlogItem(item))}
      </div>
    );
  };

  return (
    <SCNewsWidget>
      <div className="news">
        <Card title="News">
          <Tabs
            tabs={[
              {
                label: 'From the blog',
                children: renderBlogTab(loading, newsItems),
              },
            ]}
          ></Tabs>
        </Card>
      </div>
    </SCNewsWidget>
  );
};

export default NewsWidget;
