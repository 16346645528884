import moment from 'moment';
import { DateTime } from 'luxon';
import { PaymentFrequency } from '@merchant-portal/types/customer';

/** Method to convert all keys to lower case */
export const lowerCaseKeys = (obj: any) =>
  Object.keys(obj).reduce((result: any, key) => {
    result[key.toLowerCase()] = obj[key];
    return result;
  }, {});

export const capitaliseFirstLetter = (string: String) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/** Method to return date on the basis of passed time frame and mode*/
export const calculatedDates = (timeFrame: number, mode: 'past' | 'future' | '') => {
  let selectedTimeFrame = '';

  switch (mode) {
    case 'past':
      selectedTimeFrame = moment().subtract(timeFrame, 'day').format('Y-MM-DD');
      break;
    case 'future':
      selectedTimeFrame = moment().add(timeFrame, 'day').format('Y-MM-DD');
      break;
    default:
      selectedTimeFrame = moment().format('Y-MM-DD');
  }

  return selectedTimeFrame;
};

export const calculateAgeLuxon = (date: string) => {
  return DateTime.fromFormat(date, 'yyyy-MM-dd').diffNow('years').years < -18;
};

export const calculateAccountKeepingFee = (
  monthlyAccountKeepingFee: number,
  paymentFrequency: PaymentFrequency
): number => {
  let accountKeepingFees;
  if (paymentFrequency === 'Weekly') {
    accountKeepingFees = (monthlyAccountKeepingFee * 12) / 52;
  } else if (paymentFrequency === 'Fortnightly') {
    accountKeepingFees = (monthlyAccountKeepingFee * 12) / 26;
  } else {
    accountKeepingFees = monthlyAccountKeepingFee;
  }
  return accountKeepingFees;
};

/** Method to remove duplicates from result set */
export const removeDuplicates = (array: Array<any>, parameter: string) => {
  return array.filter((obj: any, position: number, mapedArray: Array<any>) => {
    return mapedArray.map((mapObj: any) => mapObj[parameter]).indexOf(obj[parameter]) === position;
  });
};

export type AccessItems = {
  createPlan: boolean;
  createLoan: boolean;
  viewPlans: boolean;
  viewLoans: boolean;
  viewRates: boolean;
  viewStaff: boolean;
  addNewStaff: boolean;
  activatePlans: boolean;
  viewOtherStaffPlans: boolean;
  exportPlansAndViewPlanMsf: boolean;
  administrator: boolean;
};

export type MerchantMenuItems = Array<{ name: string; link: string; pill?: string }>;

/** Method to show/hide the buttons on permission basis*/
export const showHideItemsAndMenu: () => {
  merchantMenuItems: MerchantMenuItems;
  accessItems: AccessItems;
} = () => {
  const permissions = JSON.parse(localStorage.getItem('loggedInStaffPermission') || '{}');
  const storeDetail = JSON.parse(localStorage.getItem('merchantStoreInfo') || '{}');
  const hasRegulatedProduct = storeDetail.hasRegulatedProduct;
  const hasBnpl = storeDetail.hasBnpl;

  let arrayOfValues = Object.values(permissions);

  let merchantMenuItems: MerchantMenuItems = [];
  let accessItems = {
    createPlan: false,
    createLoan: false,
    viewPlans: false,
    viewLoans: false,
    viewRates: false,
    viewStaff: false,
    addNewStaff: false,
    activatePlans: false,
    viewOtherStaffPlans: false,
    exportPlansAndViewPlanMsf: false,
    administrator: false,
  };

  merchantMenuItems.push({ name: 'Dashboard', link: '/dashboard' });

  arrayOfValues.forEach((key: any) => {
    switch (key) {
      case 'Create Plan':
        if (hasBnpl) {
          merchantMenuItems.push({ name: 'New Plan', link: '/plans/new' });
          accessItems.createPlan = true;
        }

        if (hasRegulatedProduct) {
          accessItems.createLoan = true;
        }
        break;
      case 'Activate Plans':
        accessItems.activatePlans = true;
        break;
      case 'View Other Staff Plan':
        accessItems.viewOtherStaffPlans = true;
        break;
      case 'Export Plans And View Plan MSF':
        accessItems.exportPlansAndViewPlanMsf = true;
        break;

      case 'View Plans':
        if (hasBnpl) {
          merchantMenuItems.push({ name: 'Plans', link: '/plans' });
          accessItems.viewPlans = true;
        }

        if (hasRegulatedProduct) {
          merchantMenuItems.push({ name: 'PayrightPlus', link: '/loans', pill: 'NEW' });
          accessItems.viewLoans = true;
        }
        break;

      case 'View Staff':
        merchantMenuItems.push({ name: 'Staff', link: '/staff' });
        accessItems.viewStaff = true;
        break;

      case 'View Rates':
        merchantMenuItems.push({ name: 'Rates', link: '/rates' });
        accessItems.viewRates = true;
        break;

      case 'Administrator':
        merchantMenuItems = [{ name: 'Dashboard', link: '/dashboard' }];

        if (hasBnpl) {
          merchantMenuItems.push({ name: 'Plans', link: '/plans' });
          accessItems.viewPlans = true;
          accessItems.createPlan = true;
        }

        if (hasRegulatedProduct) {
          merchantMenuItems.push({ name: 'PayrightPlus', link: '/loans', pill: 'NEW' });
          accessItems.viewLoans = true;
          accessItems.createLoan = true;
        }

        merchantMenuItems.push({ name: 'Staff', link: '/staff' });
        merchantMenuItems.push({ name: 'Rates', link: '/rates' });

        accessItems.viewRates = true;
        accessItems.viewStaff = true;
        accessItems.addNewStaff = true;
        accessItems.activatePlans = true;
        accessItems.viewOtherStaffPlans = true;
        accessItems.exportPlansAndViewPlanMsf = true;
        accessItems.administrator = true;
        break;
      default:
    }
  });

  if (envIsTrue('REACT_APP_PILOT_QUICK_LINKS_ENABLED')) {
    merchantMenuItems.push({ name: 'Quick Links', link: '/quick-links' });
  }

  return { merchantMenuItems, accessItems };
};

export const envIsTrue = (envVar: string): boolean => {
  return (process.env[envVar] && process.env[envVar] === 'true') as boolean;
};

// Return array of stings from comma separated string in .env file
export const getEnvArray = (envVar: string): Array<string> => {
  const string = process.env[envVar];
  if (!string) {
    return [];
  }
  const array = string.split(',');
  return array.map((str) => str.trim());
};

export const clientTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
