import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import { media } from '@payright/web-components';
import { ControlledInputField, ControlledDropdown } from '../form-fields';
import PlacesAutocomplete from '../address-autocomplete';
import { AddressDetailsData as MerchantApiAddressDetailsData } from '@merchant-portal/types/customer';
import getConstants from '../../util/constants';

const {
  REGION_STATES,
  RESIDENTIAL_STATUS,
  TIME_IN_ADDRESS,
  LABEL_SUBURB,
  LABEL_STATE,
  LESS_THAN_6_MONTHS,
} = getConstants();

export type AddressDetailsData = MerchantApiAddressDetailsData;

export interface CustomerAddressDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
}

const SCCustomerAddressDetails = styled.div`
  .details-body {
    display: flex;
    flex-wrap: wrap;
  }
  .empty-flex {
    width: 100%;
    position: relative;
    display: flex;
  }

  .address-body {
    ul {
      border-radius: 3px;
      box-shadow: 0 4px 8px 0 rgba(66, 77, 96, 0.1);
      overflow-y: auto;
      overflow-x: hidden;
      font-weight: bold;
      padding: 0.52em;
      li {
        border-bottom: 0px;
        &:hover {
          background-color: ${(props) => props.theme.colours.primary3};
          color: ${(props) => props.theme.colours.white};
        }
      }
    }

    .error {
      text-align: left;
    }
  }

  ${media.max.medium} {
    .input-field,
    .dropdown {
      width: 100%;
      max-width: 100%;
    }
    .title-firstname-wrapper {
      width: 100%;
      & > div:first-of-type {
        margin-bottom: 1.33em;
      }
    }
    .date-picker {
      width: 100%;
    }
    .details-body > div,
    .address-body .input-field,
    .address-body .dropdown {
      margin-bottom: 1.33em;
    }

    .address-body .dropdown.left {
      margin-left: 0;
      margin-right: 0;
    }

    .address-body .dropdown.right {
      margin-left: 0;
      margin-right: 0;
    }
    .address-body {
      ul {
        margin-top: -1.33em;
      }
    }
  }

  ${media.min.medium} {
    hr {
      &#details-hr {
        margin-top: 2.27em;
        margin-bottom: 4.4em;
      }
      margin-top: 1.15em;
      margin-bottom: 2em;
    }
    .address-wrapper {
      display: flex;
      flex-wrap: wrap;
      .input-field,
      .dropdown {
        max-width: calc(50% - 0.75em);
      }
      .input-field {
        margin-right: 0.75em;
      }

      .state,
      .res-status {
        margin-left: 0.75em;
      }
    }

    .details-body > div,
    .address-body .input-field,
    .address-body .dropdown {
      margin-bottom: 1.33em;
    }

    .address-body .dropdown.left {
      margin-left: 0;
      margin-right: 0.75em;
    }

    .address-body .dropdown.right {
      margin-left: 0.75em;
      margin-right: 0;
    }

    .address-body {
      ul {
        margin-top: -1.33em;
      }
    }
  }
`;

const CustomerAddress = ({ existingCustomer, editing }: CustomerAddressDetailsProps) => {
  const { errors, clearErrors, setValue, getValues, watch } = useFormContext<{
    addressDetails: AddressDetailsData;
  }>();

  const timeAtCurrentAddress = watch(
    'addressDetails.residentialTimeAtAddress'
  ) as AddressDetailsData['residentialTimeAtAddress'];

  const handleStreetAutoComplete = (addressDetails: any) => {
    clearErrors('addressDetails');
    setValue('addressDetails.suburb', addressDetails.suburb);
    setValue('addressDetails.street', addressDetails.street);
    setValue('addressDetails.state', addressDetails.states);
    setValue('addressDetails.postcode', addressDetails.postcode);
  };

  const sectionRef = useRef<HTMLDivElement>(null);

  // Scroll to block (by ID) when user submits and validation errors
  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors.addressDetails && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  // const inputRefAddressLookup = useRef<HTMLInputElement>(null);

  return (
    <SCCustomerAddressDetails id="AddressSection">
      <div ref={sectionRef} style={{ position: 'relative', top: '-2em' }} />
      <div className="address-body">
        <Controller
          name="addressDetails.street"
          className="street"
          rules={{ required: 'Street address is required' }}
          render={({ onChange, onBlur, value, ref }) => {
            return (
              <PlacesAutocomplete
                handleInputChange={onChange}
                inputRef={ref}
                onStreetChange={handleStreetAutoComplete}
                error={errors.addressDetails?.street?.message as string}
                clearErrors={clearErrors}
              />
            );
          }}
        />

        <div className="address-wrapper">
          <ControlledInputField
            rules={{ required: 'Suburb is required' }}
            error={errors.addressDetails?.suburb?.message as string}
            name="addressDetails.suburb"
            className="suburb"
          >
            {`${LABEL_SUBURB} *`}
          </ControlledInputField>

          <ControlledDropdown
            className="state"
            rules={{ required: 'State is required' }}
            error={errors.addressDetails?.state?.message as string}
            name="addressDetails.state"
            options={REGION_STATES}
            readOnly={existingCustomer && !editing}
          >
            {`${LABEL_STATE} *`}
          </ControlledDropdown>

          <ControlledInputField
            rules={{ required: 'Postcode is required' }}
            error={errors.addressDetails?.postcode?.message as string}
            name="addressDetails.postcode"
            className="postcode"
          >
            Postcode *
          </ControlledInputField>

          <div className="empty-flex"></div>

          <ControlledDropdown
            name="addressDetails.residentialStatus"
            rules={{ required: 'Residential Status is required' }}
            error={errors.addressDetails?.residentialStatus?.message as string}
            className="left res-status"
            options={RESIDENTIAL_STATUS}
            readOnly={existingCustomer && !editing}
          >
            Residential Status *
          </ControlledDropdown>

          <ControlledDropdown
            name="addressDetails.residentialTimeAtAddress"
            rules={{ required: 'Time in address is required' }}
            error={errors.addressDetails?.residentialTimeAtAddress?.message as string}
            className="right res-timeataddress"
            options={TIME_IN_ADDRESS}
            readOnly={existingCustomer && !editing}
          >
            Time at Current Address *
          </ControlledDropdown>

          {timeAtCurrentAddress === LESS_THAN_6_MONTHS && (
            <>
              <ControlledDropdown
                name="addressDetails.residentialTimeAtPreviousAddress"
                rules={{
                  validate: (value: string) => {
                    if (
                      getValues('addressDetails.residentialTimeAtAddress') === LESS_THAN_6_MONTHS &&
                      !value
                    ) {
                      return 'Time in previous address is required';
                    }
                  },
                }}
                error={errors.addressDetails?.residentialTimeAtPreviousAddress?.message as string}
                className="left res-timeatPreviousaddress"
                options={TIME_IN_ADDRESS}
                readOnly={existingCustomer && !editing}
              >
                Time at Previous Address *
              </ControlledDropdown>
            </>
          )}
        </div>
      </div>
    </SCCustomerAddressDetails>
  );
};

export default CustomerAddress;
