import * as React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import Table, { TableProps } from './index';

interface MerchantLoansTableProps extends TableProps {}

interface SCMerchantLoansTableProps {}

const SCMerchantLoansTable = styled(Table)<SCMerchantLoansTableProps>`
  .paid-pill {
    &--paid {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
      padding: 0.1rem 1rem;
      border-radius: 1rem;
      background-color: #defbe7;
      border: 1px solid #40ea77;
    }
    &--unpaid {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
      padding: 0.1rem 1rem;
      border-radius: 1rem;
      background-color: #ffc73c;
      border: 1px solid #eaed26;
    }
  }

  tbody {       
    tr {
      td.dateCreated {
        width: 20%;
      }
      td.customerName, td.staffName {
        word-break: break-word;
      }
    }
  }

  ${media.max.large} {
    padding 0 1.6em;

     tbody {       
      tr {
        td {
          padding:1em 0.1em 0em 0.1em;
          &.customerName {
            order: 1;
            flex-basis: 70%;
            flex-grow: 2;
            padding:1em 0 0 1.5em;
          }
          &.actions {
            padding:1em 1.5em 0 0;
            order: 2;
            flex-basis: auto;
          }
          &.merchantLoanName {
            order: 3;
            flex-basis: 35%;
            padding:1em 0 0 1.5em;
          }
          &.dateCreated {
            order: 4;
            flex-basis: 20%;
          }
          &.merchantReference {
            display: none;
          }
          &.staffName {
            order: 5;
            flex-basis: 25%;
          }
          &.status {
            order: 6;
            flex-basis: 20%;
          }
          &.expandMerchant {
            display:none
            padding:1em 1.5em 1em 1.5em;
            .subhead {
              display: none;
            }
            display: flex;
            h5 {
              flex-basis: 70%;
              flex-grow: 1;
            }
            a {
              flex-basis: auto;
            }
            order: 7;
            flex-basis: 100%;
          }
        }
      }
    }
   }
  

    ${media.max.medium} {
      margin: 1.6em auto 0;
      tbody {      
        &:nth-child(2) {
          width: auto;
        }
        h5 {
          font-size: 1em;
        }
        a {
          color: ${(props) => props.theme.colours.primary3};
          text-decoration: none;
        }
        tr {
          td {
            &.merchantLoanName {
              order: 3;
              flex-basis: 30%;
            }
            &.dateCreated {
              display: none;
            }
            &.customerName,
            &.actions {
              padding-bottom: 0;
            }
            &.status,
            &.merchantLoanName {
              padding-top: 0;
              .subhead {
                margin-bottom: 0.3em;
              }
            }
            &.staffName {
              display: none;
            }
            &.status {
              order: 6;
              padding-left: 0;
              flex-basis: 50%;
            }
            &.expandMerchant {
              .mobile-heading {
                display: flex;
                align-items: center;
                font-size: 0.93em;
                span {
                  color: ${(props) => props.theme.colours.blue.base};
                }
              }
            }
          }
        }
      }
    }

    ${media.min.large} {
      tbody {
        tr.row {
          height: 80px;
        }
      }
    }
  }
`;

const MerchantLoansTable = ({
  tableRows,
  subTableRows,
  tableHeaders,
  onSortChange,
}: MerchantLoansTableProps) => {
  return (
    <SCMerchantLoansTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      subTableRows={subTableRows}
      onSortChange={onSortChange}
    />
  );
};

export default MerchantLoansTable;
