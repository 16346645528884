import React, { useEffect, useState } from 'react';
import { Button, InputField, ButtonRounded } from '@payright/web-components';
import { ControlledInputField, ControlledDropdown } from '../../components/form-fields';
import { useForm, FormProvider } from 'react-hook-form';
import getConstants from '../../util/constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../slices';
import { ApplicationCompletedBy } from '../../types/plan';
import { createCheckoutLinks } from '../../slices/checkout';
import Spinner from '../../components/spinner';
import QRCode from 'qrcode.react';
import SCSendLink from './css';

const { PAYMENT_PERIODS, CUSTOMISE_LINK_TYPE } = getConstants();

export type FormData = {
  saleAmount: string;
  paymentPeriod: string;
  reference: string;
  linkType: string;
};

// TODO PW-688 To resolve error - 'Property 'gtag' does not exist on type 'Window & typeof globalThis'. TS2339'
declare global {
  interface Window {
    gtag: any;
  }
}

const gtag = window.gtag;
// end TODO PW-688

const SendCustomiseLink = () => {
  const reactHookForm = useForm<FormData>({
    defaultValues: {
      paymentPeriod: 'Select Term',
      saleAmount: '',
      reference: '',
      linkType: '',
    },
  });
  const { setValue, handleSubmit, errors, clearErrors } = reactHookForm;
  const [paymentPeriodOptions, setPaymentPeriodOptions] = useState(PAYMENT_PERIODS);
  const [paymentPeriod, setPaymentPeriod] = useState();
  const [saleAmountError, setSaleAmountError] = useState('');
  const [checkoutId, setCheckoutId] = useState('');
  const [isValid, setIsValid] = useState(true);
  const { loading } = useSelector((state: RootState) => state.checkout);
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const { rates } = globalAuthState.merchantRates;

  const dispatch = useDispatch();

  // TODO PW-688 Google Analytics temp solution
  const gaEvent = (action: string, category: string, label: string, value: number) => {
    if (typeof window !== 'undefined') {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  };

  // form submit method
  const onSubmit = (formData: FormData) => {
    gaEvent('click', 'buttons', 'generate link', 21); // TODO PW-688 GA observe successful 'generate link' form submission

    const generatedLinkType = formData.linkType === 'invoice' ? 'applyAndPay' : 'pre-approval';

    let shouldShowAllAvailableTerms = false;
    let calculatedPaymentPeriod;

    if (parseFloat(formData.paymentPeriod) === 0) {
      shouldShowAllAvailableTerms = true;
      calculatedPaymentPeriod = '';
    } else {
      calculatedPaymentPeriod = formData.paymentPeriod;
    }
    const paymentDetails = {
      paymentPeriod: calculatedPaymentPeriod,
      saleAmount: formData.saleAmount,
      type: generatedLinkType,
      applicationCompletedBy: ApplicationCompletedBy.ECOMMERCE,
      paymentFrequency: 'Fortnightly',
    };
    dispatch(
      createCheckoutLinks({
        paymentDetails,
        merchantReference: formData.reference,
        showAllAvailableTerms: shouldShowAllAvailableTerms,
        quickLinkType: formData.linkType,
        successCallback: (createdCheckoutId) => {
          setCheckoutId(createdCheckoutId);
        },
      })
    );
  };

  // Download he QR code
  const downloadQR = () => {
    let canvas = document.getElementById(`${checkoutId}`) as HTMLCanvasElement;
    const pngUrl = canvas!.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${checkoutId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  // On component init, build the dropdowns based on an initial loan amount that was set,
  // otherwise build dropdowns with all possible values from the rate card
  useEffect(() => {
    buildDropdowns(undefined);
  }, []);
  // handle submit button
  const handleChangeTerm = (event: any) => {
    const saleAmount = parseFloat(event.target.value);

    const dropdowns = buildDropdowns(saleAmount).paymentPeriodOptions;
    if (dropdowns.length > 0) {
      setPaymentPeriod(dropdowns[1]['value']);
    }
  };
  // Build the payment period dropdown
  const buildPaymentPeriodsDropdown = (months: Array<number>) => {
    let paymentPeriodOptions: any;

    if (months.length > 0) {
      paymentPeriodOptions = months.map((month) => ({
        name: `${month} months`,
        value: month,
      }));

      paymentPeriodOptions = [{ name: 'Select Term', value: 0 }, ...paymentPeriodOptions];
      setPaymentPeriodOptions(paymentPeriodOptions);
    }
    return paymentPeriodOptions;
  };
  // Build the term down down
  const buildDropdowns = (saleAmount?: any) => {
    const typeCastSaleAmount = parseFloat(saleAmount);
    // Check if the loan amount falls within the boundaries of the rate card
    const matchedRates = rates.filter((rate) => {
      const minimumDepositPercentage = rate.minimumDepositPercentage;
      const depositAmount = typeCastSaleAmount * (minimumDepositPercentage / 100);
      const loanAmount = typeCastSaleAmount - depositAmount;
      if (isNaN(loanAmount)) {
        // If no loan amount is provided, we'll build our dropdowns based on all possible values of the rate card
        return true;
      }
      return loanAmount >= rate.minimumPurchase && loanAmount <= rate.maximumPurchase;
    });
    const dropdowns = {
      paymentPeriodOptions: [],
    };
    if (matchedRates.length === 0) {
      // No matches found
      setSaleAmountError('We do not offer finance for this amount');
      setIsValid(false);
    } else {
      // Matches Found

      let availablePaymentPeriods = matchedRates.map((rate) => rate.term).sort((a, b) => a - b);
      availablePaymentPeriods = Array.from(new Set(availablePaymentPeriods)); // Transform into unique values array
      dropdowns.paymentPeriodOptions = buildPaymentPeriodsDropdown(availablePaymentPeriods);
      setSaleAmountError('');
      setIsValid(true);
    }

    return dropdowns;
  };

  useEffect(() => {
    setValue('paymentPeriod', 0);
  }, [paymentPeriod, setValue]);

  const clearForm = () => {
    setValue('saleAmount', '');
    setValue('reference', '');
    setValue('paymentPeriod', 0);
    setValue('linkType', 'Select Type');
    setSaleAmountError('');
    setCheckoutId('');
    clearErrors();
  };

  // TODO PW-688 This is for GA UTM tracking, temporary solution. Also, we 'sanitize' merchant name for URL param.
  const utmSource = process.env.REACT_APP_UTM_SOURCE || 'merchant-portal'; // default value is 'merchant-portal'
  const utmMedium = process.env.REACT_APP_UTM_MEDIUM || 'link';
  const utmCampaign = process.env.REACT_APP_UTM_CAMPAIGN || 'quick-links';
  const merchant =
    globalAuthState.storeConfig?.merchantName.replace(/[^A-Z0-9]/gi, '') ||
    globalAuthState.user?.merchantId;

  // Build redirect endpoint link, with required URL parameters.
  const redirectEndpointLink = `${process.env.REACT_APP_REDIRECT_ENDPOINT}/?checkoutId=${checkoutId}&utm_source=${utmSource}-${utmCampaign}&utm_medium=${merchant}&utm_campaign=${checkoutId}&utm_content=${merchant}`;

  return (
    <SCSendLink>
      <div className="customise-link">
        <h5>Customise your link</h5>
      </div>
      <div>
        <FormProvider {...reactHookForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="parent-container">
              <div className="form-fields">
                <div className="row-container">
                  <div className="field">
                    {' '}
                    <ControlledInputField
                      name="saleAmount"
                      handleInputBlur={handleChangeTerm}
                      prefix="$"
                      rules={{
                        required: 'Sale Amount is required',
                        validate: (value: any) => {
                          if (isNaN(value)) {
                            return 'Sale amount must be a number';
                          }
                        },
                      }}
                      error={(errors.saleAmount?.message as string) || saleAmountError}
                    >
                      Sale Amount *
                    </ControlledInputField>
                  </div>
                  <div className="field">
                    <ControlledInputField name="reference">
                      Reference (optional)
                    </ControlledInputField>
                  </div>
                </div>

                <div className="row-container">
                  <div className="field">
                    {' '}
                    <ControlledDropdown name="paymentPeriod" options={paymentPeriodOptions}>
                      Term (optional)
                    </ControlledDropdown>
                  </div>
                  <div className="field">
                    <ControlledDropdown
                      name="linkType"
                      options={CUSTOMISE_LINK_TYPE}
                      rules={{ required: 'Type is required' }}
                      error={errors.linkType?.message as string}
                    >
                      Type *
                    </ControlledDropdown>
                  </div>
                </div>

                <div className="row-container">
                  <div className="field-button">
                    <Button
                      type="button"
                      className="button"
                      handleClick={clearForm}
                      outline
                      colour="blue"
                    >
                      Clear Form
                    </Button>
                  </div>

                  <div className="field-button">
                    {' '}
                    <Button
                      type="submit"
                      maxWidth="250px"
                      disabled={!isValid || loading}
                      icon={loading ? <Spinner /> : undefined}
                      className="button"
                    >
                      Generate Link
                    </Button>
                  </div>
                </div>
              </div>
              {checkoutId && !loading && (
                <div className="url-generate-block">
                  <div className="inner-box-container">
                    <div className="genereted-url-text-box">
                      {' '}
                      <InputField
                        type="text"
                        readOnly={true}
                        value={checkoutId && redirectEndpointLink}
                      >
                        URL
                      </InputField>
                    </div>
                    <div className="qr-container">
                      <div className="rounded-button">
                        {' '}
                        <ButtonRounded
                          handleClick={() => {
                            navigator.clipboard.writeText(redirectEndpointLink);
                          }}
                          colour="primary"
                          selected
                          className="rounded-button"
                        >
                          {' '}
                          Copy Url{' '}
                        </ButtonRounded>
                      </div>
                      <div className="rounded-button">
                        {' '}
                        <ButtonRounded
                          handleClick={() => {
                            window.open(
                              `mailto:email@example.com?subject=Payright Quick Link&body=Check out my Payright Quick Link: ${redirectEndpointLink}`
                            );
                          }}
                          colour="primary"
                          selected
                          className="rounded-button"
                        >
                          {' '}
                          Add to email{' '}
                        </ButtonRounded>
                      </div>
                    </div>
                  </div>

                  <div className="filler"></div>

                  <div className="inner-box-container">
                    <div className="qr-code-label">QR Code</div>
                    <div className="qr-container">
                      <div className="qr-code">
                        <QRCode
                          id={checkoutId}
                          value={redirectEndpointLink}
                          size={150}
                          level={'H'}
                          includeMargin={true}
                        ></QRCode>
                      </div>
                      <div className="rounded-button">
                        <ButtonRounded colour="primary" selected handleClick={downloadQR}>
                          Save QR Code
                        </ButtonRounded>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </SCSendLink>
  );
};

export default SendCustomiseLink;
