import { useState, useEffect } from 'react';
import {
  AppPreApprovalData,
  PreApprovalCheckoutStep,
  ProductType,
} from '@merchant-portal/util/app-api';
import { useDispatch } from '@merchant-portal/util/store';
import { moveToStep, resetMobileCheckout } from '@merchant-portal/slices/mobile-checkout';

// The different views that are rendered in this mobile checkout component
export enum View {
  ENTER_CHECKOUT_CODE, // Default state, an input field to key in the checkout code
  ENTER_SALE_DETAILS, // When a valid checkout/plan is found, display the form to key in the sale amounts
  WAITING_FOR_CUSTOMER, // When the checkout is sent back to the customer, this is a loading screen that polls until the customer is done
  COMPLETE, // Customer paid successfully, show the screen that allows the merchant to activate now/later
}

export const useViewNavigator = (preApproval: AppPreApprovalData | null) => {
  const dispatch = useDispatch();

  const [view, setView] = useState(View.ENTER_CHECKOUT_CODE);
  const [showCancelledMessage, setShowCancelledMessage] = useState(false);
  const [showPayDifferenceMessage, setShowPayDifferenceMessage] = useState(false);

  useEffect(() => {
    if (preApproval === null) {
      setView(View.ENTER_CHECKOUT_CODE);
      return;
    }

    if (preApproval !== null) {
      switch (preApproval.step) {
        case PreApprovalCheckoutStep.MERCHANT_ACTION_REQUIRED:
          setView(View.ENTER_SALE_DETAILS);
          break;
        case PreApprovalCheckoutStep.CUSTOMER_ACTION_REQUIRED:
        case PreApprovalCheckoutStep.PAYMENT_DETAILS:
          setView(View.WAITING_FOR_CUSTOMER);
          break;
        case PreApprovalCheckoutStep.COMPLETE:
          setView(View.COMPLETE);
          break;
        case PreApprovalCheckoutStep.CUSTOMER_ADJUSTED_PAY_DIFFERENCE:
          setShowPayDifferenceMessage(true);
          dispatch(moveToStep(PreApprovalCheckoutStep.MERCHANT_ACTION_REQUIRED));
          break;
        case PreApprovalCheckoutStep.CUSTOMER_CANCELLED:
          setShowCancelledMessage(true);

          // For Pre-Approvals, if the customer presses cancel, bring them back to the partially filled form
          if (preApproval.productType === ProductType.PRE_APPROVAL) {
            dispatch(moveToStep(PreApprovalCheckoutStep.MERCHANT_ACTION_REQUIRED));
          }

          // For Premium Limit, if the customer presses cancel, it actually cancels the plan in Sugar. So, reset
          // the entire form in mobile-checkout.tsx
          if (preApproval.productType === ProductType.PREMIUM_LIMIT) {
            setView(View.ENTER_CHECKOUT_CODE);
            dispatch(resetMobileCheckout());
          }

          break;
        default:
          // If a matching pre-approval was found, but the Step is blank/unknown, move
          // the user to the ENTER_SALE_DETAILS view anyway
          setView(View.ENTER_SALE_DETAILS);
      }
    }
  }, [preApproval, dispatch]);

  return {
    view,
    showCancelledMessage,
    setShowCancelledMessage,
    showPayDifferenceMessage,
    setShowPayDifferenceMessage,
  };
};
