import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Region } from '../types/region';
//@ts-ignore
import 'url-search-params-polyfill';

// A custom hook that builds on useLocation to parse
// the query string for you.
// See: https://reactrouter.com/web/example/query-parameters
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useRegion = (): Region => {
  return process.env.REACT_APP_REGION
    ? (process.env.REACT_APP_REGION.toLowerCase() as Region)
    : 'au';
};

export const useIsNz = (): boolean => {
  return useRegion() === 'nz';
};

export const useIsAu = (): boolean => {
  return useRegion() === 'au';
};

/**
 * Hook for getting previous useState value
 * ref: https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 */
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
