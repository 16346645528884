import React from 'react';
import styled from 'styled-components';
import { media, Checkbox } from '@payright/web-components';

interface DisclosureProps {
  /** Icon for the alert */
  icon?: React.ReactElement;

  termsAndConditionText: string;

  selected: boolean;
  /** Handle the on click event */
  handleClick?: Function;
}

const TermsAndCondition = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 1em 0.5em 2em 0;
  background-color: transparent !important;

  div.terms-and-condition-text {
    font-size: 0.93rem;
    color: ${(props) => props.theme.colours.blue.base};
  }

  .check-wrapper > div.terms-and-condition-text {
    line-height: 2.5;
  }

  .check-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.max.tablet} {
    padding: 2.44em 2.44em 2.44em 0;

    div.terms-and-condition-text {
      font-size: 1em;
    }

    .check-wrapper {
    }
  }

  ${media.max.small} {
    padding: 1.5em 1.5em 1.5em 0;

    div.terms-and-condition-text {
      font-size: 0.8em;
    }

    .check-wrapper {
      align-items: flex-start;
    }
  }
`;

export type EventHandlerProps = {
  onClick: (e: React.MouseEvent) => void;
};

const CustomerTermsAndCondition = ({
  icon,
  termsAndConditionText,
  selected,
  handleClick,
}: DisclosureProps) => {
  return (
    <TermsAndCondition>
      <div className="check-wrapper">
        <Checkbox
          icon={icon}
          value="Agree"
          className="termsAndConditionRead"
          handleClick={handleClick}
          checked={selected}
          name="termsAndConditionRead"
        >
          {termsAndConditionText && (
            <div className="terms-and-condition-text">{termsAndConditionText}</div>
          )}
        </Checkbox>
      </div>
    </TermsAndCondition>
  );
};

export default CustomerTermsAndCondition;
