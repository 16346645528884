import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@merchant-portal/util/store';
import { PlanAction } from '../types/plan';
import { cloudCheckRequestDTO } from '../types/payments';
import MerchantAPI, {
  MakePaymentResponse,
  AddCardData,
  PaymentMethodsResponse,
  PaymentGatewayDetails,
} from '../util/merchant-api';

type InitialState = {
  loading: boolean;
  addCardLoading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  submitSucceeded: boolean;
  payment: {
    status: string;
    transaction_id: string;
  };
  paymentGateway: PaymentGatewayDetails | null;
};

const initialState: InitialState = {
  loading: false,
  addCardLoading: false,
  hasErrors: false,
  errorMessage: '',
  submitSucceeded: false,
  payment: {
    status: '',
    transaction_id: '',
  },
  paymentGateway: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPayment: () => initialState,
    makePaymentSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        payment: payload,
      };
    },
    makePaymentFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    startMakePayment: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
      };
    },
    addCardBegin: (state) => {
      return {
        ...state,
        addCardLoading: true,
        hasErrors: false,
        errorMessage: '',
      };
    },
    addCardSuccess: (state, { payload }) => {
      return {
        ...state,
        addCardLoading: false,
        hasErrors: false,
        paymentMethods: payload,
      };
    },
    addCardFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        addCardLoading: false,
        hasErrors: false,
        errorMessage: action.payload.errorMessage,
      };
    },
    updatePlanStatusSuccess: (state) => {
      return {
        ...state,
        submitSucceeded: true,
        loading: false,
        hasErrors: false,
      };
    },
    updatePlansFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    },
    getPaymentGatewayDetailsBegin: (state) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
      };
    },
    getPaymentGatewayDetailsSuccess: (
      state,
      action: PayloadAction<{ paymentGateway: PaymentGatewayDetails }>
    ) => {
      return {
        ...state,
        loading: false,
        paymentGateway: action.payload.paymentGateway,
      };
    },
    getPaymentGatewayDetailsFailed: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },

    // cloud check return urls
    getCloudCheckAddCardBegin: (state) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
      };
    },
    getCloudCheckAddCardSuccess: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getCloudCheckAddCardFailed: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  resetPayment,
  makePaymentSuccess,
  makePaymentFailure,
  startMakePayment,
  addCardBegin,
  addCardSuccess,
  addCardFailure,
  updatePlansFailure,
  updatePlanStatusSuccess,
  getPaymentGatewayDetailsBegin,
  getPaymentGatewayDetailsSuccess,
  getPaymentGatewayDetailsFailed,
  getCloudCheckAddCardBegin,
  getCloudCheckAddCardSuccess,
  getCloudCheckAddCardFailed,
} = paymentSlice.actions;
export default paymentSlice.reducer;

// ----------------------------------------------
// - Thunks - HANDLING THE FETCH PAYMENT METHODS
// ----------------------------------------------
export const doPayment =
  (customerId: string, planId: string): AppThunk<Promise<MakePaymentResponse>> =>
  async (dispatch) => {
    dispatch(startMakePayment({}));

    try {
      const postData = {
        customerId: customerId,
        paymentType: 'deposit',
        planId: planId,
      };
      const response: MakePaymentResponse = await MerchantAPI.makePayment(postData);
      dispatch(makePaymentSuccess(response));
      return response;
    } catch (err: any) {
      const errorMessage =
        typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
          ? err.response.data.message
          : 'Something went wrong when paying the deposit';
      dispatch(
        makePaymentFailure({
          errorMessage: errorMessage,
        })
      );
      throw err;
    }
  };

export const doUpdatePaymentStatus =
  (planId: string, depositAmount: number, depositTaker?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      let paymentStatus: PlanAction;
      if (depositTaker && depositAmount > 0) {
        // has to be a deposit taker
        paymentStatus = 'deposit-taker';
      } else if (!depositTaker && depositAmount > 0) {
        // Only Application completer
        paymentStatus = 'application-completer';
      } else {
        // Zero deposit
        paymentStatus = 'zero-deposit';
      }

      await MerchantAPI.updatedPlans(paymentStatus, planId);
      dispatch(updatePlanStatusSuccess());
    } catch (e: any) {
      dispatch(updatePlansFailure({ errorMessage: e.message }));
    }
  };

export const addCard =
  (cardDetails: AddCardData): AppThunk<Promise<PaymentMethodsResponse>> =>
  async (dispatch) => {
    try {
      // TODO: Can't show the loading spinner, otherwise it'll unmount the add card component from the DOM
      dispatch(addCardBegin());
      const response: PaymentMethodsResponse = await MerchantAPI.addCard(cardDetails);
      dispatch(addCardSuccess(response));
      return response;
    } catch (error: any) {
      dispatch(
        addCardFailure({
          errorMessage: 'Unable to add a new card. Please verify the card details and try again.',
        })
      );
      throw error;
    }
  };

export const getPaymentGatewayDetails =
  (customerId: string, checkoutId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentGatewayDetailsBegin());
      const paymentGatewayDetails = await MerchantAPI.getPaymentGatewayDetails(
        customerId,
        checkoutId
      );
      dispatch(getPaymentGatewayDetailsSuccess({ paymentGateway: paymentGatewayDetails }));
    } catch (error: any) {
      dispatch(getPaymentGatewayDetailsFailed(error.message));
    }
  };

// $args = array(
//   'token' => $token,
//   'CardHolder' => $cardHolder,
//   'Country' => 'au',
//   'Number' => $cardNumber,
//   'card_number' => $cardNumber, // when fully cut over to using EWAY this wont be sent anymore
//   'ExpiryMonth' => $expiryMonth, //12
//   'ExpiryYear' => $expiryYear, //25
//   'card_provider' => $cardType,
// );

export const cardCardCloudPayments =
  (cloudCheckRequest: cloudCheckRequestDTO): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch(getCloudCheckAddCardBegin());
      await MerchantAPI.addCardCloudPayments(cloudCheckRequest);

      dispatch(getCloudCheckAddCardSuccess());
      return true;
    } catch (error: any) {
      dispatch(getCloudCheckAddCardFailed({ errorMessage: error.message }));
      return false;
    }
  };
