import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../slices';
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { InputField } from '@payright/web-components';
import styled from 'styled-components';
import { useRegion } from '@merchant-portal/util/hooks';

type Suggestion = {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: Array<{
    offset: number;
    value: string;
  }>;
  types: Array<string>;
};

const SCPlacesAutocomplete = styled.div`
  ul {
    border: solid 1px #d7dde7;
  }
  li {
    line-height: 2em;
    border-bottom: solid 1px #d7dde7;
    padding: 0.3em;
  }
`;

const PlacesAutocomplete = ({
  handleInputChange,
  onStreetChange,
  inputRef,
  error,
  clearErrors,
}: any) => {
  const currentCustomerAddress = useSelector(
    (state: RootState) => state.customer.customerAddressDetails
  );

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: useRegion(),
      },
      types: ['address'],
      /* Define search scope here */
    },
    defaultValue: currentCustomerAddress.street,
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  /**
   *
   * @Todo
   * remove "unit" if present for subpremise
   * Add / between index 1 and 2 for subpremise
   *
   */

  const handleSelect =
    ({ description, terms, types }: Suggestion) =>
    () => {
      clearSuggestions();

      let street: string;
      let suburb: string;
      let states: string;

      if (types.includes('subpremise') || terms.length === 6) {
        // Known subpremise (unit, apartment etc) so terms are [unit number, street number, street]
        street = terms[0].value + '/' + terms[1].value + ' ' + terms[2].value;
        suburb = terms[3].value;
        states = terms[4].value;
      } else if ((terms[0].value.match(/ /g) || []).length === 0) {
        // Street checker value (from terms) to parse correct addressDetails in getGeocode
        // If there are no whitespaces from trimmed street, then reallocate street, suburb and states values
        street = terms[0].value + ' ' + terms[1].value;
        suburb = terms[2].value;
        states = terms[3].value;
      } else {
        street = terms[0].value;
        suburb = terms[1].value;
        states = terms[2].value;
      }

      setValue(street, false);

      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => getZipCode(results[0], false))
        .then((postCode) => {
          const addressDetails = {
            street: street,
            suburb: suburb,
            states: states,
            postcode: postCode,
          };
          onStreetChange(addressDetails);
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion: Suggestion, i) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={i} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong>, <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <SCPlacesAutocomplete ref={ref}>
      <InputField
        handleInputChange={(e: any) => {
          clearErrors('addressDetails.street');
          setValue(e.target.value);
          handleInputChange(e.target.value);
        }}
        value={value}
        type="text"
        name="addressDetails.street"
        className="street"
        autoComplete="nope"
        error={error}
      >
        Street Address *
      </InputField>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </SCPlacesAutocomplete>
  );
};

export default PlacesAutocomplete;
