import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';
import SCResetPassword from './css';
import { useForm, FormProvider } from 'react-hook-form';
import { ControlledInputField } from '../../components/form-fields';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Button, IconError, Alert, MessageBox } from '@payright/web-components';
import { resetLoggedInStaffPassword } from '../../slices/profile';
import Loader from '../../components/loader';
import getConstants from '../../util/constants';

const { PASSWORD_SUCCESSFUL_TITLE, PASSWORD_NEXT_LOGIN, UNSUCCESSFUL_UPDATE } = getConstants();

export type resetPassword = {
  password: string;
  confirmPassword: string;
  temporaryPassword: string;
};

interface modalProps {
  closeModalProp: Function;
}

const ResetPassword = ({ closeModalProp }: modalProps) => {
  const dispatch = useDispatch();
  const reactHookForm = useForm<resetPassword>({});
  const { handleSubmit, errors, watch } = reactHookForm;
  const { errorMessage, submitSucceeded, loading } = useSelector(
    (state: RootState) => state.profile
  );
  let showMessage;

  // Form submit
  const onSubmit = (data: any) => {
    dispatch(resetLoggedInStaffPassword(data));
  };

  // set password status blank/Show unsuccessful message
  if (errorMessage) {
    showMessage = (
      <Alert
        title={UNSUCCESSFUL_UPDATE}
        body={errorMessage}
        outcome="negative"
        icon={<IconError />}
      />
    );
  }

  useEffect(() => {
    if (submitSucceeded) {
      localStorage.setItem('loggedInStaffPasswordStatus', JSON.stringify(''));
    }
  }, [submitSucceeded]);

  return (
    <SCResetPassword>
      <FormProvider {...reactHookForm}>
        {submitSucceeded ? (
          <div className="messageBox">
            <MessageBox
              title={PASSWORD_SUCCESSFUL_TITLE}
              message={PASSWORD_NEXT_LOGIN}
              handleOnClick={() => closeModalProp()}
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="reset-password-body">
              <div className="reset-password-wrapper-body">
                <div className="alerts">{showMessage}</div>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Grid fluid>
                      <Row>
                        <Col md={9} xs={11} lg={9}>
                          <Row>
                            <Col xs={12} md={12} lg={12}>
                              <Row start="xs">
                                <Col xs={12} md={5} lg={9}>
                                  <h6 className="label">Temporary Password </h6>
                                  <ControlledInputField
                                    name="temporaryPassword"
                                    rules={{ required: 'Required' }}
                                    error={errors.password && 'Temporary Password is required'}
                                    type="password"
                                  ></ControlledInputField>
                                </Col>
                              </Row>

                              <Row start="xs">
                                <Col xs={12} md={5} lg={12}>
                                  <h6 className="label">New Password </h6>{' '}
                                  <ControlledInputField
                                    name="password"
                                    rules={{
                                      required: true,
                                      minLength: 8,
                                      pattern:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*[\]"';:_\-<>., =+/\\]).{8,}$/,
                                    }}
                                    error={
                                      errors.password &&
                                      'Password strength does not meet the requirements'
                                    }
                                    type="password"
                                  >
                                    {' '}
                                  </ControlledInputField>
                                  <div className="passwordMessage">
                                    <ul>
                                      <li>- Minimum 8 characters</li>
                                      <li>- 1 upper case letter</li>
                                      <li>- 1 lower case letter</li>
                                      <li>- 1 special character</li>
                                    </ul>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={5} lg={12}>
                                  <h6 className="label">Confirm Password</h6>
                                  <ControlledInputField
                                    name="confirmPassword"
                                    rules={{
                                      required: 'Required',
                                      validate: (value: string) => value === watch('password'),
                                    }}
                                    error={
                                      errors.confirmPassword &&
                                      'Confirm Password should be same as Password'
                                    }
                                    type="password"
                                  ></ControlledInputField>

                                  {!errors.confirmPassword && (
                                    <div className="passwordMessage">
                                      Confirm Password should match with Password.
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} lg={9}>
                          <Row>
                            {' '}
                            <Col xs={6} md={6} lg={9}>
                              <div>
                                <Button className="actions" type="submit">
                                  Reset Password
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </form>
        )}
      </FormProvider>
    </SCResetPassword>
  );
};

export default ResetPassword;
