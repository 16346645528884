import { styled, media } from '@payright/web-components';

const SCForgotDetails = styled.div`
  display: flex;
  justify-content: center;
  ${media.max.medium} {
    padding: 1.6em;
    .forgot-password-header {
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
      padding-bottom: 1em;
    }
  }
  ${media.min.medium} {
    align-items: center;
    height: 100vh;
  }
  height: 100%;
  .forgot-password-wrapper {
    max-width: 385px;
    width: 100%;
    .forgot-password-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3em;
      .logo {
        width: 50%;
        display: flex;
        align-items: center;
        svg {
          width: 170px;
          height: 70px;
        }
        ${media.max.medium} {
          svg {
            width: 115px;
            height: 50px;
          }
        }
      }
      h4 {
        width: 50%;
        color: ${(props) => props.theme.colours.blue.base};
        justify-content: flex-end;
        display: flex;
        align-items: center;
        ${media.max.medium} {
          font-size: 1.26em;
          width: unset;
        }
        ${media.min.medium} {
          border-left: 1px solid ${(props) => props.theme.colours.grey.light};
        }
      }
    }
    .forgot-password-body {
      ${media.min.medium} {
        max-width: 365px;
        margin: 0 auto;
      }
      .forgot-password {
        text-align: center;
        margin-bottom: 3.33em;
        font-weight: bold;
        font-size: 0.86em;
      }
      .input-field {
        margin-bottom: 2em;
      }
      button {
        max-width: 100%;
      }
      .filler {
        padding: 10px 10px 10px 10px;
      }
      .buttonClass {
        display: flex;
        justify-content: space-evenly;
      }
    }

    .validation-msg-box {
      background-color: #ffd2d3;
      border-radius: 5px;
      padding: 0.68em;
      color: #ff3b3b;
      margin-bottom: 2em;
      width: 100%;
    }
  }

  .alerts {
    max-width: 600px;
    min-width: 300px;
    margin: 20px 10px 15px 10px;
    vertical-align: middle;
  }
`;

export default SCForgotDetails;
