import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@payright/web-components';
import { ViewWithActions } from '@payright/web-components';
import ViewLoan from './ViewLoan';
import { Button } from '@payright/web-components';
import { IconChevron, IconSort } from '@payright/web-components';
import Status from './Status';
import { media } from '@payright/web-components';
import AnimateHeight from 'react-animate-height';
import _ from 'lodash';
import { Price } from '@payright/web-components';
import { Link } from 'react-router-dom';
import { useState } from 'react';

interface ITableHeader {
  name: string;
  id: string;
  sortable: boolean;
  children?: Array<any>;
}

type TStatus = 'active' | 'approved' | 'inReview' | 'pending' | 'declined' | 'closed' | string;

export interface ITableRow {
  ID?: number;
  status?: TStatus;
  actions?: object;
  expand?: boolean;
  expandMerchant?: boolean;
  merchantLoanName?: string;
  customerName?: string;
  merchantReference?: string;
  staffName?: string;
}

export interface ISubTableRow {
  purchasePrice?: any;
  establishmentFee?: any;
  creditAmount?: any;
  loanSettled?: any;
  settledDate?: any;
  repaymentFrequency?: any;
  MSF?: any;
}

export interface TableProps {
  tableHeaders: Array<ITableHeader>;
  tableRows: Array<ITableRow>;
  subTableRows?: Array<ISubTableRow>;
  tableSelect?: boolean;
  className?: string;
  onSortChange?: Function;
}

interface TableHeaderProps {
  tableHeaders?: Array<ITableHeader>;
  tableSelect?: boolean;
  checkAll: Function;
  sortById: Function;
}

interface TableState {
  data: any; //Array<ITableRow>;
  subData: any;
  rowOpen: number | boolean;
  subRowOpen: string | boolean;
  sortBy: string | boolean;
  sortAsc: boolean;
  selectIDs: Array<any>;
}

const SCTable = styled.table`
  width: 100%;
  .title-block-item {
    font-weight: bold;
    line-height: 1.3;
}
  tr {
    &:first-child {
      th {
        &:first-child {
          border-top-left-radius: 0.4em;
        }

        :last-child {
          border-top-right-radius: 0.4em;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    // full width open row

    &.full-width {
      background-color: transparent;
      margin: 0;

      td {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 100%;

        .inner {
          background-color: ${(props) => props.theme.colours.white};
          margin-bottom: 2rem;
          padding: 1.5rem 1rem;

          ${media.max.medium} {
            margin-top: 2rem;
          }

          ${media.max.large} {
            margin-top: 2rem;
          }
        }

        h4 {
          color: ${(props) => props.theme.colours.blue.base};
        }

        h6.loan-length {
          line-height: 2;
        }

        ul {
          border-radius: 5px;
          padding: 0;

          li {
            padding: 0.8em 1em;
            min-height: 50px;
            display: flex;
            align-items: center;

            h5 {
              display: inline-block;
            }

            h6 {
              margin-bottom: 0.4em;
            }
            .title-block-item {
              margin-bottom: 0.4em;
            }
          }
        }
      }
    }
  }

  // table row
  .row {
    background-color: ${(props) => props.theme.colours.white};

    td {
      padding: 1rem 1rem;
      vertical-align: middle;

      &.select {
        label {
          display: none;
        }
      }

      .title-block {
        display: block;
      }

      .no-break {
        display: block;
        white-space: nowrap;
      }

      .show-hide {
        display: inline-flex;
        transition: background-color 0.5s, transform 0.2s;
        width: 2.4em;
        height: 2.4em;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid #531dff;

        &:hover {
          background-color: ${(props) => props.theme.colours.grey.light};
        }

        &.open {
          transform: rotate(180deg);
        }

        path {
          fill: ${(props) => props.theme.colours.blue.base};
        }
      }
    }
  }


  // table item row
  .items-header {
    margin-top: 1.2em;

    .item-count {
      display: inline-block;
      padding-right: 0.5em;
    }

    .show-hide {
      display: inline-flex;
      transition: background-color 0.5s, transform 0.2s;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  
      &.open {
        transform: rotate(180deg);
      }
  
      path {
        fill: ${(props) => props.theme.colours.blue.base};
      }
    }
  }
  .items-row {
    width: 100%;
    border: 2px solid #f2f2f2;
    border-radius: 1em;
    padding: 1em 1em;
    margin-bottom: 1em;
  }
  .item-img-col {
    width: 20%;
    display: inline-block;
    padding-right: 1em;
    img {
      width: 100%;
    }
  }
  .item-content-col {
    width: 80%;
    display: inline-block;

    h6 {
      color: #531dff;
    }
  }
  .item-small-header {
    color: #531dff;
  }
  .item-product-name {
    margin-top: 1em;
  }
  .item-product-description {
    text-align: justify;
    width: 850px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item-details-row {
    width: 100%;
    display: inline-flex;
    margin-top: 1.5em;
  }
  .item-details-col {
    width: 45%;
    padding-right: 1em;
  }
 .item-details-row {
    dt, dd {
      display: inline-block;
    }
  
    dt {
     width: 65%;
     font-weight:800;
     line-height: 1.5em;
    }
  
    dd {
     min-width: 5%;
    }
 }

  // tablet and mobile

  ${media.max.large} {
    display: block;

    .hide-for-small {
      display: none;
    }

    .mobile-heading {
      color: ${(props) => props.theme.colours.grey.light};
      display: inline;
    }

    thead {
      display: none;
    }

    tbody {
      display: block;

      tr {
        border-bottom: none;
        display: flex;
        flex-wrap: wrap;

        &.row {
          border-top-left-radius: 0.4em;
          border-top-right-radius: 0.4em;
          border-bottom-left-radius: 0.4em;
          border-bottom-right-radius: 0.4em;
          border: 2px solid transparent;

          &.selected {
            border: 2px solid ${(props) => props.theme.colours.blue.base};
          }

          &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &.selected {
              border-bottom: none;
            }
          }
        }

        &.full-width {
          padding-top:25px;
          border-bottom-left-radius: 0.4em;
          border-bottom-right-radius: 0.4em;

          &.selected {
            border: 2px solid ${(props) => props.theme.colours.blue.base};
            border-top: none;
          }

          td {
            .inner {
              border-bottom-left-radius: 0.4em;
              border-bottom-right-radius: 0.4em;
            }

            ul {
              background-color: ${(props) => props.theme.colours.grey.altlight};

              li {
                display: flex;
                align-items: center;
                border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
                padding: 0.8em 1.5em;

                &:last-child {
                  border-bottom: none;
                }

                h6 {
                  justify-self: flex-start;
                  flex-grow: 1;
                  margin-bottom: 0;
                }
                .title-block-item{
                  justify-self: flex-start;
                  flex-grow: 1;
                  margin-bottom: 0; 
                }

                h5 {
                  justify-self: flex-end;
                  flex-grow: 1;
                  text-align: right;
                }
              }
            }
          }
        }

        td {
          order: 99;

          .subhead {
            display: block;
            color: ${(props) => props.theme.colours.grey.medium};
            margin-bottom: 0.5em;
          }

          &.customerLink,
          &.customerName,
          &.fullName,
          &.actions {
            .subhead {
              display: none;
            }
          }

          &.select {
            order: 1;
          }
        }
      }

      .subTableTitle {
        h4 {
          font-size: 1.25em;
        }
      }
    }
  }

  // large only

  ${media.min.large} {
    .head {
      min-height: 60px;

      h6 {
        font-size: 0.93em;
      }
      .title-block-item{
        font-size: 0.93em;
      }
      th {
        background-color: ${(props) => props.theme.colours.grey.light};
        padding: 1rem 1rem;
        text-align: left;
        vertical-align: middle;

        &.select-all {
          label {
            display: none;
          }
        }

        span {
         &.select {
            display: flex;
            align-items: center;
            cursor: pointer;
            max-width: 45px;
            width: 45px;

            svg {
              min-width: 8px;
              flex-basis: 8px;
              margin-right: 0.8em;
            }

            h6 {
              flex-shrink: 1;
            }
            .title-block-item{
              flex-shrink: 1;
            }
          }
        }
      }
    }

    .row {
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};

      &:last-of-type {
        border-bottom: none;
      }

      td {
        padding: 1rem 1rem;

        .title-block {
          &.left {
            text-align: left;
          }

          &.center {
            text-align: center;
          }

          &.right {
            text-align: right;
          }

          h5 {
            font-size: 2rem;
            font-weight: normal;
          }
          .title-block-item{
            font-size: 1rem;
          }

          h6 {
            font-size: 1rem;

            svg {
              vertical-align: middle;
            }
          }
        }

        &.actions {
          padding-right: 0.5em;
        }

        &.expand {
          padding-left: 0.5em;
        }
      }
    }

    .subTableTitle {
      color: ${(props) => props.theme.colours.blue.base};
      margin-top: 0.5rem;
      padding: 1rem 1rem;
      margin-bottom: 0.5rem;
    }

    .subHeaderTable {
      th {
        text-align: left;
        background-color: ${(props) => props.theme.colours.grey.light};
        font-size: 0.93em;
        min-height: 60px;
        padding: 25px 28px;
      }
    }

    .subhead,
    .hide-for-large,
    .mobile-heading {
      display: none;
    }
    .expandMerchant {
      margin: 0 auto;
      > .more-details-controls {
        height: 60px;
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
    }

    tr {
      &.full-width {
        td {
          .inner {
            margin-bottom: 2rem;
            padding: 1.5rem 2rem;
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              display: inline-block;
              padding: 0.8em 1em;
              margin-right: 1em;
              margin-top: 2em;
              min-width: 10em;
              background-color: ${(props) => props.theme.colours.grey.altlight};
              border-radius: 5px;

              &:last-child {
                margin-right: 0;
              }

              h5 {
                display: inline-block;
              }

              h6 {
                color: ${(props) => props.theme.colours.plum.base};
                margin-bottom: 0.4em;
              }
              .title-block-item{
                color: ${(props) => props.theme.colours.plum.base};
                margin-bottom: 0.4em;
              }
            }
          }
        }
      }
    }
  }

  ${media.min.wide} {
    .head {
      th {
        padding: 1rem 1em;

        &:first-of-type {
          padding: 1rem 1.86rem;
        }
      }
    }

    .row {
      td {
        padding: 1rem 1em;
        width: 20%;

        &:first-of-type,
        &:last-of-type {
          padding: 1rem 1.86rem;
        }
      }
    }
  }

  .class-for-gap {
    background-color: ${(props) => props.theme.colours.grey.altlight};
    padding: 4em 4em 4em 4em;
  }

  .inner-merchant {
    background-color: ${(props) => props.theme.colours.white};
    padding: 1em 1em;

    .subTableTitle-merchant {
      display:flex;
      font-size: 0.8em;
      margin-left: 20px;
      word-spacing: nowrap;
      text-align:center;
      padding-top:10px;

      ${media.min.large} {
        font-size: 1.2em;
        margin-left: 12px;
        max-width:30%
        width:30%;
        padding-top:0px;
      }
    }

    .otherActions {
      padding-left:25px;
    }

    .term {
      color: ${(props) => props.theme.colours.black};
      font-weight: bold;
      padding-bottom: 10px;
      margin-left: 20px;

      ${media.min.large} {
        font-size: 13px;
        margin-left: 15px;
      }
    }

    &:first-of-type {
      padding: 1rem 1.86rem;

      ${media.max.medium} {
        padding: 0.5rem 0.5rem;
      }
    }

    h6 {
      justify-self: flex-start;
      flex-grow: 1;
      margin-bottom: 0;
      padding: 10px 7px 0 15px;
      word-spacing: nowrap;

      ${media.max.large} {
        flex-grow: unset;
        padding: 0 0 10px 10px;
        font-size: 15px;
      }
    }
    .title-block-item{
      justify-self: flex-start;
      flex-grow: 1;
      margin-bottom: 0;
      padding: 10px 7px 0 15px;
      word-spacing: nowrap;

      ${media.max.large} {
        flex-grow: unset;
        padding: 0 0 10px 10px;
        font-size: 15px;
      }
    }

    table {
      border-spacing: 10px 0 !important;
      border-collapse: separate !important;
      padding-top: 10px;
      width: 100%;
      max-width: 500px;

      ${media.min.large} {
        // styles for large and above
        max-width: 1200px;
        width: 100%;
      }

      tbody {
        td {
          background-color: ${(props) => props.theme.colours.grey.altlight};

          ${media.max.large} {
            display: flex;
            flex-direction: column;
            margin: 5px;
            padding: 15px 0 5px 0;
          }
        }
      }

      .purchasePrice {
        max-width: 500px;
        width: 100%;

        ${media.min.large} {
          max-width: 150px;
          width: 150px;
        }
      }

      .originationFee {
        max-width: 500px;
        width: 100%;

        ${media.min.large} {
          max-width: 150px;
          width: 150px;
        }
      }

      .creditAmount {
        max-width: 500px;
        width: 100%;

        ${media.min.large} {
          max-width: 150px;
          width: 150px;
        }
      }

      .loanSettled {
        max-width: 500px;
        width: 100%;
        padding-bottom: 0px;

        ${media.min.large} {
          max-width: 150px;
          width: 150px;
        }
      }

      .settledDate {
        max-width: 500px;
        width: 100%;
        padding-bottom: 0px;

        ${media.min.large} {
          max-width: 180px;
          width: 180px;
        }
      }

      .msf {
        max-width: 500px;
        width: 100%px;

        ${media.min.large} {
          max-width: 110px;
          width: 110px;
        }
      }

      h5 {
        justify-self: flex-end;
        flex-grow: 1;
        text-align: right;
        padding: 10px 5px 10px 15px;
        display: inline-block;

        ${media.max.large} {
          padding: 0px 0px 5px 10px;
          text-align: left;
        }
      }

      .subTableHeading {
        margin-left: 30px;

        ${media.max.large} {
          margin-left: 0px;
        }
      }
    }
  }
`;

const TableHeader = (props: TableHeaderProps): JSX.Element => {
  const { tableHeaders, tableSelect, checkAll, sortById } = props;

  return (
    <thead>
      <tr className="head">
        {tableSelect && (
          <th className="select-all">
            <Checkbox checked name="" value="" handleClick={() => checkAll('all')} />
          </th>
        )}
        {tableHeaders &&
          tableHeaders.map((header) => (
            <th key={header.id}>
              {header.sortable ? (
                <span className="select" onClick={(e) => sortById(header.id, e)}>
                  <IconSort />
                  <h6>{header.name}</h6>
                </span>
              ) : (
                <h6>{header.name}</h6>
              )}
            </th>
          ))}
      </tr>
    </thead>
  );
};

const Table = (props: TableProps) => {
  const { tableHeaders, tableRows, subTableRows, tableSelect, className, onSortChange } = props;

  const [state, setState] = useState<TableState>({
    data: tableRows,
    subData: subTableRows || [],
    rowOpen: false,
    subRowOpen: false,
    sortBy: true,
    sortAsc: true,
    selectIDs: [],
  });

  const sortById = (key: string) => {
    const sortAsc = state.sortBy === key ? !state.sortAsc : true;

    let tData = _.orderBy(state.data, [key], ['asc']);

    if (sortAsc === false) {
      tData = _.orderBy(state.data, [key], ['desc']);
    }

    setState({ ...state, sortAsc, sortBy: key, data: tData });

    if (typeof onSortChange === 'function') {
      onSortChange(key, sortAsc ? 'asc' : 'desc');
    }
  };

  const rowExpand = (e: any, row: any) => {
    setState((state) => ({
      ...state,
      rowOpen: state.rowOpen != row.ID ? row.ID : false,
    }));
  };

  const subRowExpand = (e: any, rowId: any) => {
    setState((state) => ({
      ...state,
      subRowOpen: state.subRowOpen !== rowId ? rowId : false,
    }));
  };

  const checkAll = () => {
    const IDs = tableRows.map((row) => row.ID);
    const { selectIDs } = state;

    if (IDs.length === selectIDs.length) {
      setState({ ...state, selectIDs: [] });
    } else {
      setState({ ...state, selectIDs: IDs });
    }
  };

  const toggleSelect = (id: any) => {
    const { selectIDs } = state;

    if (selectIDs.includes(id)) {
      setState({ ...state, selectIDs: selectIDs.filter((e) => e !== id) });
    } else {
      selectIDs.push(id);
      setState({ ...state, selectIDs });
    }
  };

  const headerName = (id: string | number, headerRows: Array<ITableHeader>) => {
    let name = '';
    let h = headerRows.find((th) => {
      return th.id == id;
    });

    headerRows.forEach((th) => {
      if (th.id == id) {
        name = th.name;
      }
    });
    return name;
  };

  const tableColumnType = (row: any, item: any) => {
    let elem;
    switch (item) {
      case 'merchantLoanName':
        elem = (
          <div className="title-block">
            <span className="title-block-item">{row[item]}</span>
            <p>{row.merchantReference}</p>
          </div>
        );
        break;

      case 'dateCreated':
        elem = (
          <div className="title-blocks">
            <span className="title-block-item">{row[item]}</span>
          </div>
        );
        break;

      case 'customerName':
        elem = (
          <div className="title-block">
            <span className="title-block-item">{row[item]}</span>
          </div>
        );
        break;

      case 'staffName':
        elem = (
          <div className="title-block">
            <span className="title-block-item">{row[item]}</span>
          </div>
        );
        break;

      case 'status':
        elem = <Status customer status={row[item]} />;
        break;

      case 'view':
        elem = (
          <Link to={row.view} style={{ textDecoration: 'none' }}>
            <Button colour="blue" outline size="small">
              View Profile
            </Button>
          </Link>
        );
        break;

      case 'expandMerchant':
        elem =
          row[item] === true ? (
            <>
              <h5 className="mobile-heading">
                <span>Expand Loan Details&nbsp;&nbsp;</span>
              </h5>
              <div className="more-details-controls">
                <ViewLoan actions={row.actions} />
                <a
                  onClick={(e) => rowExpand(e, row)}
                  className={'show-hide ' + (row.ID == state.rowOpen && 'open')}
                >
                  <IconChevron />
                </a>
              </div>
              &nbsp;
            </>
          ) : (
            <></>
          );
        break;
      case 'actions':
        elem = <ViewWithActions actions={row.actions} />;
        break;

      default:
        // show default render of table column
        elem = <h5>{row[item]}</h5>;
        break;
    }
    return elem;
  };

  let isOpen = false;

  return (
    <SCTable cellPadding={0} cellSpacing={0} className={className}>
      <TableHeader tableHeaders={tableHeaders} checkAll={checkAll} sortById={sortById} />

      <tbody>
        {state.data.map((row: any, parentRowIndex: any) => (
          <>
            {(isOpen = state.rowOpen == row.ID)}
            <tr
              className={
                `${isOpen ? 'row open' : 'row'} ${
                  state.selectIDs.includes(row.ID) ? 'selected' : ''
                }` + `${row['cssClass']}`
              }
              key={row.ID}
            >
              {tableSelect && (
                <td className="select">
                  <Checkbox
                    name="check"
                    value={row.ID}
                    checked={state.selectIDs.includes(row.ID)}
                    handleClick={toggleSelect}
                  />
                </td>
              )}
              {Object.keys(row).map((item) => (
                <>
                  {item !== 'ID' && item !== 'cssClass' && item !== 'merchantReference' && (
                    <td key={item} className={item}>
                      <h6 className="subhead">{headerName(item, tableHeaders)}</h6>
                      {tableColumnType(row, item)}
                    </td>
                  )}
                </>
              ))}
            </tr>
            {row.expandMerchant && (
              <tr key={parentRowIndex} className="full-width">
                <td colSpan={8}>
                  <AnimateHeight duration={500} height={isOpen ? 'auto' : 0} className="details">
                    <div className="inner-merchant">
                      <div>
                        <div className="subTableTitle-merchant">
                          <h4>Loan Details</h4>
                          <span className="otherActions">
                            {state.subData[parentRowIndex].viewActions}
                          </span>
                        </div>

                        <span className="term">
                          {state.subData[parentRowIndex].repaymentFrequency} Loan
                        </span>
                      </div>

                      <table className="subTableHeading">
                        <tbody>
                          <tr>
                            <td className="purchasePrice">
                              <h6>Purchase Price</h6>
                              <h5>
                                ${<Price value={state.subData[parentRowIndex].purchasePrice} />}
                              </h5>
                            </td>
                            <td className="originationFee">
                              <h6>Origination Fee</h6>
                              <h5>
                                ${<Price value={state.subData[parentRowIndex].establishmentFee} />}
                              </h5>
                            </td>
                            <td className="creditAmount">
                              <h6>Credit Amount</h6>
                              <h5>
                                ${<Price value={state.subData[parentRowIndex].creditAmount} />}
                              </h5>
                            </td>
                            <td className="loanSettled">
                              <h6>Loan Settled</h6>
                              <h5>{state.subData[parentRowIndex].loanSettled}</h5>
                            </td>
                            {state.subData[parentRowIndex].settledDate && (
                              <td className="settledDate">
                                <h6>Settlement Date</h6>
                                <h5>{state.subData[parentRowIndex].settledDate}</h5>
                              </td>
                            )}
                            {typeof state.subData[parentRowIndex].MSF !== 'undefined' && (
                              <td className="msf">
                                <h6>MSF</h6>
                                <h5>${<Price value={state.subData[parentRowIndex].MSF} />}</h5>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td className="class-for-gap">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </AnimateHeight>
                </td>
              </tr>
            )}
            {/* Expand for Merchant loans ends*/}
          </>
        ))}
      </tbody>
    </SCTable>
  );
};

export default Table;
