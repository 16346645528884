import React from 'react';
import SCSendLink from './css';

const CustomiseLinkInstructions = () => {
  return (
    <SCSendLink>
      <div className="container-steps">
        <div className="heading">
          <h4>How to share the Quick Application Link</h4>
          <div>Follow these steps to insert your URL or QR code into email, invoice or quote.</div>
        </div>
        <div className="url-steps">
          <h5>URL</h5>
          <div className="steps">
            <ol>
              <li>
                Enter the sale amount, length of term and type of link you want to create. Select
                Quote if you're sending the link to customers before the work is done, or Invoice if
                the work is already complete.
              </li>
              <li>Click 'Generate Link' to create a unique application link and QR code.</li>
              <li>
                Copy and paste the link or QR code (your choice) into either an email to your
                customer or client, or into the notes section of your invoicing/quoting software.
              </li>
              <li>
                Send the email, invoice or quote to your customer to have them complete their
                application.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </SCSendLink>
  );
};

export default CustomiseLinkInstructions;
