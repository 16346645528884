import React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import { useRegion } from '@merchant-portal/util/hooks';
import AuLicenceDetails from './au-licence-details';
import NzLicenceDetails from './nz-licence-details';

export interface LicenceDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
}

const SCLicenceDetails = styled.div`
  .state-expiry-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    ${media.min.medium} {
      flex-direction: row;
    }

    .input-field {
      flex: calc(50% - 0.75em);
      margin-right: 0.75em;
    }

    & > .dropdown {
      ${media.min.medium} {
        margin-right: 0.75em;
      }

      .error {
        text-align: left;
      }
    }
  }

  .date-picker {
    flex: 1;
  }
`;

const LicenceDetails = ({ existingCustomer, editing }: LicenceDetailsProps) => {
  const region = useRegion();

  let RegionLicenceDetails = (
    <AuLicenceDetails existingCustomer={existingCustomer} editing={editing} />
  );

  if (region === 'nz') {
    RegionLicenceDetails = (
      <NzLicenceDetails existingCustomer={existingCustomer} editing={editing} />
    );
  }

  return <SCLicenceDetails id="LicenceSection">{RegionLicenceDetails}</SCLicenceDetails>;
};

export default LicenceDetails;
