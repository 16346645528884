import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ButtonRounded, CreditCard, IconPlus } from '@payright/web-components';
import { prepareCardDetails } from '@merchant-portal/slices/payment-method';
import { RootState } from '../slices';
import {
  ControlledInputField,
  ControlledInputCreditCardExpiry,
  ControlledInputCreditCard,
} from './form-fields';
import Loader from '../components/loader';

type PaymentDetailsMode = 'add' | 'existing';

type PaymentDetailsProps = {
  onChange: any;
  selectedCardId: string;
  mode: string;
  setMode: Function;
} & (PaymentDetailsEwayProps | PaymentDetailsFatZebraProps);

type PaymentDetailsEwayProps = {
  provider: 'eway';
  expiryMonth: any;
  expiryYear: any;
  cardNumber: any;
  setExpiryMonth: Function;
  setExpiryYear: Function;
  setCardNumber: Function;
};

type PaymentDetailsFatZebraProps = {
  provider: 'fat-zebra';
  iframeUrl: string;
};

const SCPaymentDetails = styled.div`
  .payment-methods {
    margin-top: 1em;
    .credit-card {
      margin-bottom: 1em;
    }
  }

  .header {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        color: ${(props) => props.theme.colours.blue.base};
      }
      button {
        font-size: 0.86em;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
    p {
      margin: 2em 0;
    }
  }

  .fat-zebra-iframe {
    width: 100%;
    max-width: 700px;
  }
`;

const PaymentDetailsAddNewCardEway = ({
  handleClick,
  setExpiryMonth,
  expiryMonth,
  setExpiryYear,
  expiryYear,
  existingCards,
}: any) => {
  const { errors } = useFormContext();
  return (
    <>
      <div className="header">
        <div className="header__title">
          <h4>Add New Card</h4>
          {existingCards && !!existingCards.length && (
            <ButtonRounded handleClick={handleClick} colour="blue">
              Use existing card
            </ButtonRounded>
          )}
        </div>
      </div>

      <div className="card-details" data-private>
        <ControlledInputField
          rules={{ required: 'Required' }}
          name="cardHolderName"
          className="cardHolder"
          error={errors.cardHolderName && 'Card holder name is required'}
          type="text"
        >
          Name on Card
        </ControlledInputField>

        <ControlledInputCreditCard
          name="cardNo"
          rules={{ required: 'Required' }}
          error={errors.cardNo && 'Card number required'}
          encryptName={''}
        />

        <div className="expiry-csv">
          <ControlledInputCreditCardExpiry
            name="expiry"
            label={'Expiry'}
            year={expiryYear}
            month={expiryMonth}
            updateMonth={setExpiryMonth}
            updateYear={setExpiryYear}
            rules={{ validate: () => new Date(`${expiryMonth}/01/${expiryYear}`) > new Date() }}
            error={errors.expiry && 'Expiry date cannot be in the past'}
          />
          <ControlledInputField
            rules={{ required: 'Required' }}
            error={errors.csv && 'CSV required'}
            name="csv"
            className="card-csv"
            type="number"
          >
            CSV
          </ControlledInputField>
        </div>
      </div>
    </>
  );
};

const PaymentDetailsAddNewCardFatZebra = ({ handleClick, existingCards, iframeUrl }: any) => {
  return (
    <>
      <div className="header">
        <div className="header__title">
          <h4>Add New Card</h4>
          {existingCards && !!existingCards.length && (
            <ButtonRounded handleClick={handleClick} colour="blue">
              Use existing card
            </ButtonRounded>
          )}
        </div>
      </div>

      <iframe
        className="fat-zebra-iframe"
        src={iframeUrl}
        height="400"
        width="100%"
        title="cloud_payment"
      ></iframe>
    </>
  );
};

const PaymentDetailsUseExistingCard = ({
  existingCards,
  selectedCardId,
  handleClickAddNew,
  handleSelectCard,
  loading,
}: {
  existingCards?: ExistingCards;
  selectedCardId?: string;
  handleClickAddNew: React.MouseEventHandler;
  handleSelectCard: (cardId: string) => void;
  loading: boolean;
}) => {
  return (
    <>
      {existingCards && (
        <>
          <div className="header">
            <div className="header__title">
              <h4>Existing Payment Details</h4>
              <ButtonRounded handleClick={handleClickAddNew} colour="blue" icon={<IconPlus />}>
                Add New Card
              </ButtonRounded>
            </div>
          </div>

          <div className="payment-methods">
            {existingCards &&
              existingCards.map((card) => (
                <CreditCard
                  key={card.id}
                  last4Digits={card.last4digits}
                  cardType={card.type}
                  selected={selectedCardId === card.id}
                  selectCard={() => {
                    handleSelectCard(card.id);
                  }}
                />
              ))}
          </div>
        </>
      )}
    </>
  );
};

type ExistingCards = {
  id: string;
  type: React.ComponentProps<typeof CreditCard>['cardType'];
  last4digits: string;
}[];

const PaymentDetails = (props: PaymentDetailsProps) => {
  const { onChange, selectedCardId, provider, mode, setMode } = props;

  const { loading, paymentMethods } = useSelector((state: RootState) => state.paymentMethod);
  const { loading: loadingPayment } = useSelector((state: RootState) => state.payment);
  const cards = prepareCardDetails(paymentMethods);
  const existingCards: ExistingCards = cards;

  return (
    <SCPaymentDetails>
      <div className="payment-details">
        {loading || (loadingPayment && <Loader text="Loading existing payment details.." />)}
        {mode === 'add' && !loadingPayment && provider === 'eway' && (
          <PaymentDetailsAddNewCardEway
            setExpiryMonth={(props as PaymentDetailsEwayProps).setExpiryMonth}
            expiryMonth={(props as PaymentDetailsEwayProps).expiryMonth}
            setExpiryYear={(props as PaymentDetailsEwayProps).setExpiryYear}
            expiryYear={(props as PaymentDetailsEwayProps).expiryYear}
            cardNumber={(props as PaymentDetailsEwayProps).cardNumber}
            setCardNumber={(props as PaymentDetailsEwayProps).setCardNumber}
            handleClick={() => {
              setMode('existing');
            }}
            existingCards={existingCards}
          />
        )}
        {mode === 'add' && !loadingPayment && provider === 'fat-zebra' && (
          <PaymentDetailsAddNewCardFatZebra
            handleClick={() => {
              setMode('existing');
            }}
            existingCards={existingCards}
            iframeUrl={(props as PaymentDetailsFatZebraProps).iframeUrl}
          />
        )}
        {mode === 'existing' && !loadingPayment && (
          <PaymentDetailsUseExistingCard
            handleClickAddNew={() => {
              setMode('add');
            }}
            handleSelectCard={(cardId) => {
              onChange(cardId);
            }}
            selectedCardId={selectedCardId}
            existingCards={existingCards}
            loading={loading}
          />
        )}
      </div>
    </SCPaymentDetails>
  );
};

export default PaymentDetails;
