import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import { HeaderMerchant, media, Alert, IconDone, IconAttention } from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import { Row, Col } from 'react-flexbox-grid';
import { getPaymentGatewayDetails } from '../../../slices/payment';
import { fetchCheckoutAttributes } from '../../../slices/checkout';
import SCStep3 from './css';
import { fetchCustomerPaymentMethods } from '../../../slices/payment-method';
import { RootState } from '../../../slices';
import FormSection from '../../../components/form-section';
import { useQuery } from '../../../util/hooks';
import Loader from '../../../components/loader';
import EwayForm from '@merchant-portal/components/payment/eway-form';
import FatZebraForm from '@merchant-portal/components/payment/fat-zebra-form';
import getConstants from '../../../util/constants';

const {
  PAYMENT_DETAIL_TEXT,
  SUCCESSFUL_NEW_PLAN_APPROVED_TITLE,
  SUCCESSFUL_NEW_PLAN_APPROVED_BODY,
} = getConstants();

type PaymentProps = {};

const SCPaymentDetails = styled.div`
  background: ${(props) => props.theme.colours.white};

  .alert-container {
    margin-bottom: 2em;
  }
  .payments-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0 8em;
    padding: 30px;

    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    ${media.max.wide} {
      margin: 4em 5em 0 0.1em;
      padding: 1.4em 2em 0 1.8em;
    }

    h4 {
      color: #531dff;
    }
    .message-wrapper {
      padding: 0 0 1.7em;
    }

    .PaymentDetails-IntroText {
      padding: 30px 0 0 0;
    }

    .payment-start-date-wrapper {
      padding: 0 0 1.7em;
    }
    .dateof-firstrepayment {
      margin-top: 30px;
    }
  }
`;

type FormData = {
  selectedPaymentDate: Date | null;
  cardHolderName: string | null;
  csv: string | null;
  expiry: string | null;
  selectedMonthlyDate: string | null;
};

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  const { errorMessage, paymentGateway } = useSelector((state: RootState) => state.payment);

  const reactHookForm = useForm<FormData>({
    defaultValues: {
      selectedPaymentDate: new Date(),
    },
    mode: 'onSubmit',
  });

  const { loading: paymentMethodsLoading } = useSelector((state: RootState) => state.paymentMethod);
  const { loading: paymentLoading } = useSelector((state: RootState) => state.payment);

  const checkout = useSelector((state: RootState) => state.checkout);
  const checkoutId = checkout?.checkout?.id || '';

  const customerId = useSelector((state: RootState) => state.checkout.checkout?.customerId || '');
  const planId = useSelector((state: RootState) => state.checkout.checkout?.planId || '');

  // Load latest checkout resource from server
  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    } else {
      dispatch(fetchCheckoutAttributes(checkoutId));
    }
  }, [checkoutId, dispatch, queryParamCheckoutId]);

  useEffect(() => {
    if (customerId) {
      dispatch(getPaymentGatewayDetails(customerId, checkoutId));
      dispatch(fetchCustomerPaymentMethods(customerId));
    }
  }, [checkoutId, customerId, dispatch]);

  // Display and scroll to alert box when there is an error
  let alertRef: HTMLDivElement | null = null;
  useEffect(() => {
    if (errorMessage !== '') {
      scrollToComponent(alertRef, {
        offset: -25,
        align: 'top',
        duration: 600,
      });
    }
  }, [errorMessage, alertRef]); // Errors object changes on submit

  return (
    <SCStep3>
      <SCPaymentDetails>
        <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
          <HeaderMerchant
            title="New Plan"
            activeStep={3}
            setActiveStep={(index: number) => console.log('Active step clicked: ' + index)}
          />
          <div className="payments-body">
            <Row className="message-wrapper">
              <Col xs={12} md={12} lg={12}>
                <Alert
                  title={SUCCESSFUL_NEW_PLAN_APPROVED_TITLE}
                  body={SUCCESSFUL_NEW_PLAN_APPROVED_BODY}
                  outcome="positive"
                  icon={<IconDone />}
                />
              </Col>
            </Row>
            {errorMessage && (
              <div className="alert-container">
                <div
                  ref={(section: HTMLDivElement) => {
                    alertRef = section;
                  }}
                ></div>
                <Alert title={''} body={errorMessage} outcome="error" icon={<IconAttention />} />
              </div>
            )}
            <FormSection title="Payment Details" text={PAYMENT_DETAIL_TEXT}>
              <FormProvider {...reactHookForm}>
                {paymentMethodsLoading || paymentGateway === null ? (
                  <Loader text="Loading Payment Details.." />
                ) : paymentLoading ? (
                  <Loader text="Adding Card and Processing Payment.." />
                ) : (
                  <>
                    {paymentGateway.serviceProvider === 'Eway' && (
                      <EwayForm
                        successCallback={() => {
                          history.push(`/plans/new/plan-result/${planId}`);
                        }}
                      ></EwayForm>
                    )}

                    {paymentGateway.serviceProvider === 'Cloud_Payments' && (
                      <FatZebraForm
                        iframeUrl={paymentGateway.iframeUrl}
                        successCallback={() => {
                          history.push(`/plans/new/plan-result/${planId}`);
                        }}
                      />
                    )}
                  </>
                )}
              </FormProvider>
            </FormSection>
          </div>
        </LoggedInLayout>
      </SCPaymentDetails>
    </SCStep3>
  );
};

export default Payment;
