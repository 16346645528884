import React from 'react';
import styled from 'styled-components';
import { SelectableCard, media } from '@payright/web-components';
import getConstants from '../../util/constants';

const {
  CUSTOMER_TYPE_NEW_CUSTOMER_TITLE,
  CUSTOMER_TYPE_NEW_CUSTOMER_DESC,
  CUSTOMER_TYPE_EXISTING_CUSTOMER_TITLE,
  CUSTOMER_TYPE_EXISTING_CUSTOMER_DESC,
} = getConstants();

export type FormCustomerType = 'newCustomer' | 'existingCustomer';

type CustomerTypeSelectorProps = {
  value: FormCustomerType;
  handleSelect: (selectedComponentName: FormCustomerType) => void;
};

const SCCustomerTypeSelector = styled.div`
  .selectable-cards-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      margin-bottom: 1em;
    }

    ${media.min.tablet} {
      flex-direction: row;
      column-gap: 15px;
    }
  }
`;

const CustomerTypeSelector = ({ value, handleSelect }: CustomerTypeSelectorProps) => {
  return (
    <SCCustomerTypeSelector>
      <div className="selectable-cards-wrapper">
        <SelectableCard
          selected={value === 'newCustomer'}
          handleSelect={handleSelect}
          title={CUSTOMER_TYPE_NEW_CUSTOMER_TITLE}
          body={CUSTOMER_TYPE_NEW_CUSTOMER_DESC}
          name={'newCustomer' as FormCustomerType}
        />
        <SelectableCard
          selected={value === 'existingCustomer'}
          handleSelect={handleSelect}
          title={CUSTOMER_TYPE_EXISTING_CUSTOMER_TITLE}
          body={CUSTOMER_TYPE_EXISTING_CUSTOMER_DESC}
          name={'existingCustomer' as FormCustomerType}
        />
      </div>
    </SCCustomerTypeSelector>
  );
};

export default CustomerTypeSelector;
