import { NameValuePair } from '@payright/web-components';
import { NextStep } from '@merchant-portal/types/checkout';

// PLAN PREAPPROVAL RESPONSE TYPE
export type PreApprovalData = {
  status: 'NO_NEED_TO_INCREASE_DEPOSIT' | 'DECLINED_APPLICATION' | 'NEED_TO_INCREASE_DEPOSIT';
  approvedDepositAmount: number;
  approvedDepositPercentage: number;
  nextSteps: Array<NextStep>;
};

export type PlanAction =
  | 'activate'
  | 'resend-details'
  | 'deposit-taker'
  | 'zero-deposit'
  | 'application-completer'
  | 'send-link-to-customer';

export enum ApplicationCompletedBy {
  CUSTOMER = 'Customer',
  MERCHANT = 'Merchant',
  ECOMMERCE = 'Ecommerce',
}

export type PlansSuccessResponse = {
  plans: {
    data: Array<{
      id: string;
      name: string;
      customerName: string;
      status: string;
      dateCreated: string;
      merchantReference: string;
      staffName: string;
      depositPaymentStatus: string;
      saleAmount: string;
      depositPaid: string;
      amountSettledToDate: string;
      totalCreditAmount: string;
      msf: string;
      depositPaidPercentage: string;
      terms: string;
      contactId: string;
      finalSettlementDate: string;
      planBlockActivation: boolean;
      merchantSettled: string;
      creditAmount: string;
      checkoutId: string;
      checkoutCode?: string;
    }>;
    meta: PlansListMetaData;
  };
};

export type PlansListMetaData = {
  count: number;
  currentPage: number;
  lastPage: number;
  limit: number;
};

export type PlansListQueryParams = {
  page?: number;
  limit?: number;
  order?: string;
  'filter[status][in]'?: Array<string>;
  'filter[0][or][0][customerFirstName][contains]'?: string;
  'filter[0][or][0][customerLastName][contains]'?: string;
  'filter[0][or][0][planName][contains]'?: string;
  'filter[0][or][0][reference][contains]'?: string;
  'filter[0][or][0][staffFirstName][contains]'?: string;
  'filter[0][or][0][staffLastName][contains]'?: string;
  'filter[createdAt][gte]'?: string;
  'filter[staffId][equals]'?: string;
};

export type PlansListFilterOptions = {
  Active: boolean;
  Approved: boolean;
  Pending: boolean;
  Declined: boolean;
  Closed: boolean;
  Cancelled: boolean;
  Review: boolean;
  'Approved Pending ID': boolean;
  'Approved Statement Requested': boolean;
};

export type PlansListFilterDropdowns = {
  staffMember?: string;
  timeFrames?: string;
};

export interface FilterDropdownOptions {
  /** unique key of the dropdown */
  id: string;
  /** The dropdown label */
  label: string;
  /** Placeholder of the dropdown when nothing is selected */
  placeholder: string;
  /** The list of available options for the user to select from */
  options: Array<NameValuePair>;
  /** initially selected option */
  selected?: string;
}

export type PlanListSettings = {
  options: PlansListFilterOptions;
  dropdowns: PlansListFilterDropdowns;
  searchQuery: string;
};
