import React from 'react';
import { ControlledInputField, ControlledDropdown, ControlledDatePicker } from '../../form-fields';
import { useFormContext } from 'react-hook-form';
import { AuIdentificationLicenseData } from '../../../types/customer';
import getConstants from '../../../util/constants';
import { envIsTrue } from '../../../util/helper';
import { LicenceDetailsProps } from './index';

const {
  IDENTIFICATION_YEARS,
  REGION_STATES,
  LICENCE_VALIDATION_REGEX,
  LICENCE_CARD_NUMBER_VALIDATION_REGEX,
} = getConstants();

const AuLicenceDetails = ({ existingCustomer, editing }: LicenceDetailsProps) => {
  const { errors } = useFormContext<{
    identificationDetails: AuIdentificationLicenseData;
  }>();

  return (
    <div className="details-body">
      <ControlledInputField
        name="identificationDetails.licenseNo"
        rules={{
          required: 'Licence Number is required',
          pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_LICENCE_VALIDATION')
            ? undefined
            : {
                value: LICENCE_VALIDATION_REGEX,
                message: 'Licence Number format invalid',
              },
        }}
        error={(errors.identificationDetails as any)?.licenseNo?.message as string}
      >
        Licence No. *
      </ControlledInputField>

      <ControlledInputField
        name="identificationDetails.licenseCardNo"
        rules={{
          required: 'Card Number is required',
          pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_LICENCE_VALIDATION')
            ? undefined
            : {
                value: LICENCE_CARD_NUMBER_VALIDATION_REGEX,
                message: 'Card Number can contain a maximum of 10 alphanumeric characters only',
              },
        }}
        error={(errors.identificationDetails as any)?.licenseCardNo?.message as string}
      >
        Card No. *
      </ControlledInputField>

      <div className="state-expiry-wrapper">
        <ControlledDropdown
          name="identificationDetails.licenseIssuingState"
          options={REGION_STATES}
          rules={{ required: 'Issuing State/Territory is required' }}
          error={(errors.identificationDetails as any)?.licenseIssuingState?.message as string}
          className="issueDate"
        >
          Issuing State/Territory *
        </ControlledDropdown>
        <ControlledDatePicker
          name="identificationDetails.licenseExpiry"
          readOnly={existingCustomer && !editing}
          rules={{
            required: 'Licence expiry is required',
            validate: (value: any) => {
              const expiry = new Date(value);
              const today = new Date();
              today.setDate(today.getDate() + 1);
              if (expiry.getTime() < today.getTime()) {
                return 'Enter a valid expiry';
              }
            },
          }}
          error={(errors.identificationDetails as any)?.licenseExpiry?.message as string}
          startYear={new Date().getFullYear()}
          endYear={new Date().getFullYear() + IDENTIFICATION_YEARS}
        >
          Expiry *
        </ControlledDatePicker>
      </div>
    </div>
  );
};

export default AuLicenceDetails;
