import { styled, media, Checkbox } from '@payright/web-components';

export const SCMerchantStaff = styled.div`
  background:${(props) => props.theme.colours.grey.altlight};
  padding-bottom:20px;
  h2 {
    color: ${(props) => props.theme.colours.blue.base}
  }

  .staff-list-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;
  

   ${media.min.large} {
     padding:0 20px 0 20px;    
     .row {
        display:table-row;
      }   
   }
   
 
    ${media.min.wide} {      
		thead {
			tr {
				th {
					&:last-of-type {     
						padding: 1rem 1.86rem;
					}
				}
			}
		}
  }
  ${media.max.large} {
    padding: 0rem 1.86rem 0rem 1.86rem;
			tbody{
				tr{
					&.row{
						margin-bottom: 1rem;
						border-bottom-left-radius: 0.4em;
						border-bottom-right-radius: 0.4em;
					}
					td{
						&.fullName{
							order: 2;
							flex-basis: 80%;
							align-items: center;
							display: flex;
						}			
						&.phone{
							order: 2;
							flex-basis: 25%;
							flex-shrink: 1;
						}
						&.email{
							order: 3;
							flex-basis: 50%;
							flex-shrink: 1;
						}
						&.staffStatus{
							order: 4;
							flex-basis: 25%;
							flex-shrink: 1;
						}
						&.view{
							display: none;
						}
					}
				}
			}
    }
 ${media.max.medium} {
      padding: 0rem 1.86rem 0rem 1.86rem;
			tbody{  
				tr{
					td{
						&.phone{
							order: 2;
							flex-basis: 50%;
							flex-shrink: 1;
						}
						&.email{
							display: none;
						}
						&.staffStatus{
							order: 3;
							flex-basis: 50%;
							flex-shrink: 1;
						}
					}
				}
			}
		}
	}

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        ${media.min.large} {
          padding-bottom: 3em;   
        }
        ${media.max.medium} {
          padding-bottom:0.1em;
        }
      }
    }
  }
  .searchHeader {
    margin:2em 0 2em 0;
  }

  .pageingBottom {
    margin:3em auto;
  }

.actions {
    display: flex;
    
    ${media.max.medium} {
       align-items: center;
       position: fixed;
       bottom: 0;
       left: 0;
       max-width:900px;
       width: 100%;
       height: 60px;
        button {
        max-width: unset;
        height: 60px;            
    }    
   }

    ${media.min.medium} {   
    button {
        max-width: unset;
        padding: 0 2.5em;    
    }
  }
}   
`;

export const SCMerchantNoStaff = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;
  text-align: center;
  color: ${(props) => props.theme.colours.blue.base};
  font-size: 1.1em;
  font-weight: bold;
`;
export const SCStatusCheckbox = styled(Checkbox)`
  .checkmark {
    width: 18px;
    height: 18px;
    top: 15px;
  }
  input:checked ~ .checkmark:after {
    top: 2px;
    left: 4px;
  }
  height: 2.2em;
`;

export const SCFilterCheckbox = styled(Checkbox)`
  .checkmark {
    width: 18px;
    height: 18px;
    top: 8px;
  }

  input:checked ~ .checkmark:after {
    top: 2px;
    left: 4px;
  }
  height: 2.2em;
`;

const SCAddStaff = styled.div`
  background:${(props) => props.theme.colours.grey.altlight};
  padding-bottom:20px;
  h2 {
    color: ${(props) => props.theme.colours.blue.base}
    
  }  

  .staff-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;
    
    .message {
      text-align:center;
    
      .title {
         font-size:20px;
         padding:0px 10px 0px 10px;   
      }
    }
    span {
      .error {
      border:1px solid green;
    }
    }
    
    .passwordMessage {
      bottom: 0;
      text-align: left;
      font-size: 0.86em;
      margin-top: 0.5em;
    }  
    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    ${media.max.ewide} {
      padding: 0 1.6em;
    }

     .actions {
        display: flex; 
        justify-content: space-between;
    }
   
    .linkPage {
    color: ${(props) => props.theme.colours.blue};
    text-decoration: none;
    }

   .title-dropdown {
        margin-right: 1.5em;
        border:0px;
        outline:0px;
      }

    .title {
    margin-left: 0.1em;
    h6 {
      color: ${(props) => props.theme.colours.blue};
      margin-bottom: 0.4em;
      font-size: 1.1em;
    }
    .linkPage {
    color: ${(props) => props.theme.colours.blue};
    text-decoration: none;
    }
    svg {
      fill: ${(props) => props.theme.colours.blue.base};
      transform: rotate(90deg);
      margin: 0 0.2em 0.1em 0.1em;    
    
        ${media.min.large} { 
          margin: 0 0.2em 0.1em 0.5em; 
        }        
    }  
  }
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        margin-bottom: 1em;
        
      
        ${media.min.medium} { 
          margin-bottom: 3em;
          padding-left: 10px;
        }      

        .button {
            max-width:1000px; 
            width:200px;
            height:60px;
            font-size:15px;  
            ${media.max.large} {
              max-width:50%
            }
        }
     }
    }
  }
   .details-wrapper-body {
    background: ${(props) => props.theme.colours.white};
    max-width: 1200px;
    width: 100%;
    margin: 2em auto 0;
    padding:20px 10px 10px 10px;
     ${media.min.medium} {
      padding:55px 55px 55px 55px;
      margin:0;
    }  
     .actions {
        display: flex; 
        justify-content: space-between;
        align-items: center;      
        ${media.max.medium} {
          max-width:150px;
          width:100%;
          height: 60px;
          padding-left:12px;
          height:50px;
          font-size:15px;
          bottom: 0;
          left: 0;
          background-color: ${(props) => props.theme.colours.white};
              button:first-of-type {
                margin-right: 4px;
              }
              button:last-of-type {
                margin-left: 4px;
                box-shadow: unset;
              }
              box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.04);
              z-index: 99;         
        }
    }

    h4 {
      color: ${(props) => props.theme.colours.black};
      font-weight: bold;
      line-height: 1.3;
      padding: 30px 0px 10px 0px;
      font-size:20px;
     
     
    }
     .AccountInformation-detail {
        padding: 10px 0px 10px 0px;
      }

    h5 {
        color: #531dff;
        font-weight: bold;
        line-height: 1.89;
        white-space:nowrap;
        padding: 5px 2px 10px 2px;
    }
    h6 {
         font-weight: normal;
         font-size:16px;
         line-height: 1.89;
         white-space:nowrap;
         padding: 5px 2px 10px 2px;
         text-align: right;    
    }

    .label {
          
           padding-top: 10px;
           font-size:15px;
           font-weight:bold;
           text-align: left;
           &.checkbox {         
               text-align: center;
               color: ${(props) => props.theme.colours.black};
           }    
           &.contactStatus {
               max-width:30px;
               width:100%;
           }
          
         }
        .toolTip {
           padding-top: 15px;
          }
    
    
    .checkboxSpacing {
        padding: 20px 0 0px 0;
    }
    
    .no-wrap-div {
      word-break: break-all;
    }

     hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.xlight};
    margin: 3em 1em 1em 0;
   
  }

  checkbox-css {
    position: relative;
    display: flex;
    align-items: center;
    height: 2em;
    border:1px solid green;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }  

  label {
    margin-left: 2em;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 1em;
    .icon {
      margin-right: 0.75em;
    }
  }

  

  &:hover input ~ .checkmark {
  }

  & input:checked ~ .checkmark {
    border-color: ${(props) => props.theme.colours.blue.base};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
    content: '';
    width: 3px;
    height: 6px;
    top: 4px;
    left: 6px;
    border: solid ${(props) => props.theme.colours.blue.base};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    transition: border-color 250ms ${(props) => props.theme.cubicBezier};
  }

  &:hover {
    label {
      color: ${(props) => props.theme.colours.blue.base};
    }
  }
  }
  }
}`;

export default SCAddStaff;
