import { styled, media } from '@payright/web-components';

const SCFeedback = styled.div`
  background:${(props) => props.theme.colours.grey.altlight};
  padding-bottom:20px;

  h2 {
    color: ${(props) => props.theme.colours.blue.base}
  }  

  .feedback-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;
    
    .message {
      text-align:center;
    
      .title {
         font-size:20px;
         padding:0px 10px 0px 10px;   
      }
    }

    span {
      .error {
      border:1px solid green;
    }
  }

  ${media.max.medium} {
    margin: 1.6em auto 0;
  }
  ${media.max.ewide} {
    padding: 0 1.6em;
  }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
        
      margin-bottom: 1em;
      
      ${media.min.medium} { 
        margin-bottom: 3em;
        padding-left: 10px;
      }      
     }
    }
  
  .feedback-wrapper-body {
    background: ${(props) => props.theme.colours.white};
    max-width: 1200px;
    width: 100%;
    margin: 2em auto 0;
    padding: 20px 10px 10px 10px;
    text-align: center;

    ${media.min.medium} {
      padding: 55px 55px 55px 55px;
      margin: 0;
    }  

    h4 {
      color: ${(props) => props.theme.colours.black};
      font-weight: bold;
      line-height: 1.3;
      padding: 30px 0px 30px 0px;
      font-size:20px;
    }

    h5 {
        color: #531dff;
        font-weight: bold;
        line-height: 1.89;
        white-space:nowrap;
        padding: 5px 2px 10px 2px;
    }
    h6 {
         font-weight: normal;
         font-size:16px;
         line-height: 1.89;
         white-space:nowrap;
         padding: 5px 2px 10px 2px;
         text-align: right;    
    }

    .smcx-embed {
      margin: 0 auto;
      height: 850px !important;

      .smcx-iframe-container {
        height: 850px !important;
      }
    }

    hr {
      border-style: solid;
      border-bottom: none;
      border-color: ${(props) => props.theme.colours.grey.xlight};
      margin: 3em 1em 1em 0;
    }
`;

export default SCFeedback;
