import React, { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import StoryblokClient from 'storyblok-js-client';

export type Storyblok = {
  dashboard: any;
  ddoBlockPlanCreationMessage: any;
  ddoBlockLoanCreationMessage: any;
  ddoDueDatePassedImportantMessage: any;
  ddoTmd: any;
  ddoImportantMessage: any;
};

type StoryblokProviderProps = {
  children?: React.ReactNode;
};

export const StoryblokContext = React.createContext<Storyblok | undefined>(undefined);

export const useStoryblok = () => {
  const storyblokContext = useContext(StoryblokContext);
  if (storyblokContext === undefined) {
    throw new Error('useStoryblok must be used within a StoryblokProvider');
  }
  return storyblokContext;
};

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN,
  https: true,
});

export const StoryblokProvider = ({ children }: StoryblokProviderProps) => {
  const [dashboard, setDashboard] = useState<Storyblok>();

  useEffect(() => {
    Storyblok.get('cdn/stories/merchant-portal/dashboard', {
      version: 'draft',
    }).then((res): any => {
      const storeDetail = JSON.parse(localStorage.getItem('merchantStoreInfo') || '{}');

      const announcement = res.data.story.content.announcements;
      const merchant_ddo = res.data.story.content.merchant_ddo;

      const ddoBlockPlanCreationMessage = parse(
        Storyblok.richTextResolver.render(merchant_ddo[0].ddo_block_plan_creation_message)
      );

      const ddoBlockLoanCreationMessage = parse(
        Storyblok.richTextResolver.render(merchant_ddo[0].ddo_block_loan_creation_message)
      );

      const ddoDueDatePassedImportantMessage = parse(
        Storyblok.richTextResolver
          .render(merchant_ddo[0].ddo_due_date_passed_important_message)
          .replaceAll('merchant_name', `${storeDetail.merchantName}`)
      );
      const ddoTmd = parse(Storyblok.richTextResolver.render(merchant_ddo[0].ddo_tmd));
      const ddoImportantMessage = parse(
        Storyblok.richTextResolver
          .render(merchant_ddo[0].ddo_important_message)
          .replaceAll('merchant_name', `${storeDetail.merchantName}`)
      );

      const dashboardData = {
        dashboard: { announcements: announcement, merchant_ddo: merchant_ddo },
        ddoBlockPlanCreationMessage: ddoBlockPlanCreationMessage,
        ddoBlockLoanCreationMessage: ddoBlockLoanCreationMessage,
        ddoDueDatePassedImportantMessage: ddoDueDatePassedImportantMessage,
        ddoTmd: ddoTmd,
        ddoImportantMessage: ddoImportantMessage,
      };

      setDashboard(dashboardData);
    });
  }, []);

  const storyblok: Storyblok = {
    dashboard: dashboard?.dashboard,
    ddoBlockPlanCreationMessage: dashboard?.ddoBlockPlanCreationMessage,
    ddoBlockLoanCreationMessage: dashboard?.ddoBlockLoanCreationMessage,
    ddoDueDatePassedImportantMessage: dashboard?.ddoDueDatePassedImportantMessage,
    ddoTmd: dashboard?.ddoTmd,
    ddoImportantMessage: dashboard?.ddoImportantMessage,
  };

  return <StoryblokContext.Provider value={storyblok}>{children}</StoryblokContext.Provider>;
};
