import React from 'react';
import { Button, Card, IconEmail, IconPhone, IconContact } from '@payright/web-components';
import getConstants from '../../../util/constants';
const { HELPDESK_EMAIL, CONTACT_US_PHONE_NUMBER, CONTACT_US_PHONE_NUMBER_URL } = getConstants();

const HelpdeskCard = () => {
  return (
    <>
      <div className="contact-card">
        <Card>
          <div className="card-top">
            <div className="contact-icon">
              <IconContact />
            </div>
            <div>
              <h4>Helpdesk</h4>
              <h5>
                {HELPDESK_EMAIL}
                <br />
              </h5>
            </div>
          </div>
          <div className="card-bottom">
            <div className="card-bottom-buttons">
              <Button
                type="submit"
                colour="blue"
                className="call"
                icon={<IconPhone />}
                outline
                handleClick={() => {
                  window.open(`tel:${CONTACT_US_PHONE_NUMBER_URL}`);
                }}
              >
                {CONTACT_US_PHONE_NUMBER}
              </Button>
              <Button
                type="submit"
                colour="blue"
                className="email"
                outline
                icon={<IconEmail />}
                handleClick={() => {
                  window.open(`mailto:${HELPDESK_EMAIL}`);
                }}
              >
                Email
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default HelpdeskCard;
