import React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import { PaymentFrequency } from '@merchant-portal/types/customer';

type FeeDetailsProps = {
  accountKeepingFee: number;
};

const SCFeeDetailsContainer = styled.div`
  padding-top: 0.5rem;
  display: grid;
  gap: 0.2rem;
`;

const SCFeeDetails = styled.p`
  color: #6e6e6e;
  line-height: 1.8;
  font-size: 0.75rem;

  ${media.max.tablet} {
    font-size: 0.86em;
  }
`;

const PplusFeeDetails = ({ accountKeepingFee }: FeeDetailsProps) => {
  return (
    <SCFeeDetailsContainer>
      <SCFeeDetails>
        Included in the above repayments is a loan management fee of ${accountKeepingFee.toFixed(2)}{' '}
        per month (or ${((accountKeepingFee * 12) / 26).toFixed(2)} per fortnight).
      </SCFeeDetails>
    </SCFeeDetailsContainer>
  );
};

export default PplusFeeDetails;
