import React, { useEffect } from 'react';
import { logout } from '../slices/auth';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../slices';

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (!auth) {
      history.push('/');
    }
  }, [history, auth]);

  return <div></div>;
};

export default Logout;
