import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  HeaderCustomer,
  InputField,
  SelectableCard,
} from '@payright/web-components';
import SendCustomiseLink from './send-customise-link';
import CustomiseLinkInstructions from './send-customise-link-instructions';
import { CreatePaymentOption, SCCenterContentLayout } from './css';
import { SCDDOMessage } from './css';
import LoggedInLayout from '@merchant-portal/layouts/logged-in-layout';
import { AppDispatch } from '@merchant-portal/util/store';
import { RootState } from '@merchant-portal/slices';
import { getMerchantDDODetails } from '@merchant-portal/slices/merchant';
import Loader from '@merchant-portal/components/loader';
import { useStoryblok } from '@merchant-portal/providers/storyblok-provider';

const CreatePayment = () => {
  const [selectedCardOption, selectedCardOptionHandler] = useState(false);
  const [selectedSocialMedia, selectedSocialMediaHandler] = useState(false);
  const [selectedInvoice, selectedInvoiceHandler] = useState(false);

  const [showCreatePaymentOption, setShowCreatePaymentOption] = useState(false);
  const [selectedPlatformHandler, setSelectedPlatformHandler] = useState(false);
  const [selectedCheckoutOptionHandler, setSelectedCheckoutOptionHandler] = useState(false);

  // If there is some error from DDO updation we need to grab that error and display on the modal
  const { ddoDetailsErrorMessage, DDOLoading } = useSelector((state: RootState) => state.merchant);
  const [showDDOMessage, setShowDDOMessage] = useState(false);

  // Need the state to get the storyblock data
  const [showMerchantDDOBlockPlan, setShowMerchantDDOBlockPlan] = useState<any | null>(null);

  const dispatch: AppDispatch = useDispatch();
  const { ddoBlockPlanCreationMessage } = useStoryblok();

  useEffect(() => {
    checkDDOStatus();
  }, [setShowDDOMessage]);

  //check DDO
  const checkDDOStatus = () => {
    dispatch(getMerchantDDODetails())
      .then((ddoDetails) => {
        if (ddoDetails.shouldBlockLoanCreation) {
          setShowDDOMessage(true);
          setShowCreatePaymentOption(false);
        } else {
          setShowDDOMessage(false);
          setShowCreatePaymentOption(true);
        }
      })
      .catch((errorMessage: string) => {
        console.log(errorMessage);
      });
  };

  return (
    <SCCenterContentLayout>
      <LoggedInLayout activePage="/quick-links">
        <HeaderCustomer
          title="Quick Application Links"
          subtitle="Our quick copy and paste application links can be used before a job has started (in a quote) or after work is complete (in an invoice)."
        ></HeaderCustomer>
      </LoggedInLayout>
      {showCreatePaymentOption && (
        <CreatePaymentOption>
          <div>
            <div className="top">
              <div className="title">
                <h2>Create your application link</h2>
              </div>
            </div>
            <CustomiseLinkInstructions />
            {/* link generation form */}
            {<SendCustomiseLink />}
          </div>
          {/* link instructions */}
        </CreatePaymentOption>
      )}
      {showDDOMessage && (
        <SCDDOMessage>
          {DDOLoading ? (
            <Loader />
          ) : (
            <div className="container-steps">{ddoBlockPlanCreationMessage}</div>
          )}
        </SCDDOMessage>
      )}
    </SCCenterContentLayout>
  );
};

export default CreatePayment;
