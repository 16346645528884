import React, { PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AccessItems, showHideItemsAndMenu } from '@merchant-portal/util/helper';

type AuthRouteProps = PropsWithChildren<
  {
    guard: keyof AccessItems | (keyof AccessItems)[];
    component: React.ComponentType<any>;
  } & RouteProps
>;

export const AuthRoute = ({ guard, component: Component, ...rest }: AuthRouteProps) => {
  const { accessItems } = showHideItemsAndMenu();
  const guards = Array.isArray(guard) ? guard : [guard];
  const hasAccess = guards.reduce((prev, curr) => prev || accessItems[curr], false);

  return (
    <Route
      {...rest}
      render={(props) => (hasAccess ? <Component {...props} /> : <Redirect to="/access-denied" />)}
    />
  );
};
