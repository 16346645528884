import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../util/store';
import { CustomerDetailsData } from '../components/customer-data/customer-details-block';
import { AddressDetailsData } from '../components/customer-data/address-details';
import { EmploymentDetailsData } from '../components/customer-data/employment-details';
import { IdentificationDetailsData } from '../components/customer-data/identification-details';
import MerchantAPI, { CustomerSearchParams, CustomerSearchResponse } from '../util/merchant-api';
import {
  CustomerResource,
  IdentificationType,
  AccountDetailsData,
} from '@merchant-portal/types/customer';
import { ProductLine } from '@merchant-portal/util/constants/enums';

type InitialState = {
  loading: boolean;
  hasErrors: boolean;
  submitSucceeded: boolean;
  /** The Sugar entity id for cu_Customers. Null for new customers. */
  customerId: string | null;
  customerNumber: string | null;
  customerDetails: CustomerDetailsData;
  customerAddressDetails: AddressDetailsData;
  employmentDetails: EmploymentDetailsData;
  identificationType: IdentificationType;
  identificationDetails: IdentificationDetailsData;
  errorMessage?: string | null;
  customerTypeSelection: 'newCustomer' | 'existingCustomer';
  mode: 'data-entry' | 'customer-search';
  customerMatch?: boolean;
  existingCustomerState: {
    customerSearchResponse: CustomerSearchResponse | null;
    verification: {
      requestId: string | null;
      confirmed: boolean;
    };
  };
  planDetails?: {
    id: string;
    planNumber: string;
  } | null;
  accountDetails?: AccountDetailsData;
};

type CustomerPaymentDateData = {
  paymentDetails: {
    paymentStartDate: string;
  };
};

export type FormDataExistingCustomer = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
};

export const initialState: InitialState = {
  loading: false,
  hasErrors: false,
  customerId: null,
  customerNumber: null,
  submitSucceeded: false,
  customerDetails: {
    firstName: '',
    middleName: '',
    lastName: '',
    title: '',
    email: '',
    phone: '',
    confirmEmail: '',
    dateOfBirth: '',
    consentPromoMaterial: false,
    isIdVerified: false,
  },
  employmentDetails: {
    employmentType: 'Full time',
    employerPhone: '',
    employerName: '',
    timeInJob: '',
    incomeFrequency: 'weekly',
    incomeAmount: null,
    otherIncomeFrequency: 'weekly',
    otherIncomeAmount: 0,
  },
  customerAddressDetails: {
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    residentialStatus: '',
    residentialTimeAtAddress: '',
    residentialTimeAtPreviousAddress: '',
  },
  identificationType: 'license',
  identificationDetails: {
    identificationType: 'license',
    licenseNo: '',
    licenseCardNo: '',
    licenseExpiry: '',
    licenseIssuingState: '',
    licenseVersionNumber: '',
  },
  existingCustomerState: {
    customerSearchResponse: null,
    verification: {
      requestId: null,
      confirmed: false,
    },
  },
  errorMessage: null,
  customerTypeSelection: 'newCustomer',
  mode: 'data-entry',
  customerMatch: false,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer: () => initialState,

    setMode: (state, action: PayloadAction<'data-entry' | 'customer-search'>) => {
      return { ...state, mode: action.payload };
    },
    customerTypeSelection: (state, { payload }) => {
      state.loading = true;
      state.hasErrors = false;

      state.customerTypeSelection = payload;
    },
    customerSearchBegin: (state) => {
      return {
        ...state,
        errorMessage: null,
        hasErrors: false,
        loading: true,
      };
    },
    customerSearchSuccess: (
      state,
      action: PayloadAction<{
        customerId: string | null;
        errorMessage: string | null;
        customerMatch: boolean;
        customerDetails: CustomerDetailsData;
      }>
    ) => {
      const { customerId, errorMessage, customerMatch, customerDetails } = action.payload;

      return {
        ...state,
        loading: false,
        hasErrors: errorMessage ? true : false,
        customerId: customerId,
        errorMessage: errorMessage,
        customerMatch: customerMatch,
        customerDetails: customerDetails,
      };
    },
    customerSearchSuccessExisting: (state, action: PayloadAction<CustomerSearchResponse>) => {
      const { errors } = action.payload;

      return {
        ...state,
        loading: false,
        hasErrors: errors.length > 0 ? true : false,
        errorMessage: null,
        existingCustomerState: {
          ...state.existingCustomerState,
          customerSearchResponse: action.payload,
          verification: {
            requestId: null,
            confirmed: false,
          },
        },
      };
    },
    customerSearchFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    updateCustomerBegin: (state) => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    updateCustomerSuccess: (state, action: PayloadAction<CustomerResource>) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        submitSucceeded: true,
        customerId: action.payload.id || null,
        customerNumber: action.payload.customerNumber || null,
        customerDetails: action.payload.customerDetails || initialState.customerDetails,
        customerAddressDetails:
          action.payload.addressDetails || initialState.customerAddressDetails,
        identificationType:
          action.payload.identificationDetails?.identificationType ||
          initialState.identificationType,
        identificationDetails:
          action.payload.identificationDetails || initialState.identificationDetails,
        employmentDetails: action.payload.employmentDetails || initialState.employmentDetails,
        customerMatch: true,
        accountDetails: action.payload.accountDetails,
      };
    },
    updateCustomerFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCustomerByCheckoutIdBegin: (state) => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    getCustomerByCheckoutIdSuccess: (state, action: PayloadAction<CustomerResource>) => {
      return {
        ...state,
        loading: false,
        customerId: action.payload.id || null,
        customerNumber: action.payload.customerNumber || null,
        customerDetails: action.payload.customerDetails || initialState.customerDetails,
        customerAddressDetails:
          action.payload.addressDetails || initialState.customerAddressDetails,
        identificationType:
          action.payload.identificationDetails?.identificationType ||
          initialState.identificationType,
        identificationDetails:
          action.payload.identificationDetails || initialState.identificationDetails,
        employmentDetails: action.payload.employmentDetails || initialState.employmentDetails,
        customerMatch: true,
        accountDetails: action.payload.accountDetails,
      };
    },
    getCustomerByCheckoutIdFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    requestVerificationCodeBegin: (state) => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    requestVerificationCodeSuccess: (state, action: PayloadAction<{ requestId: string }>) => {
      return {
        ...state,
        loading: false,
        existingCustomerState: {
          ...state.existingCustomerState,
          verification: {
            requestId: action.payload.requestId,
            confirmed: false,
          },
        },
      };
    },
    requestVerificationCodeFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    confirmVerificationCodeBegin: (state) => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    confirmVerificationCodeSuccess: (state) => {
      return {
        ...state,
        loading: false,
        existingCustomerState: {
          ...state.existingCustomerState,
          verification: {
            ...state.existingCustomerState.verification,
            confirmed: true,
          },
        },
      };
    },
    confirmVerificationCodeFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },

    getPreLoadCustomerDataNewCustomer: (state, action: PayloadAction<FormDataExistingCustomer>) => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
        customerDetails: {
          ...state.customerDetails,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          dateOfBirth: action.payload.dateOfBirth,
        },
      };
    },
    getPreLoadCustomerDataNewCustomerSuccess: (state) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
      };
    },
    getPreLoadCustomerDataNewCustomerFailure: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: 'Failed to return customer data',
      };
    },
    clearCustomerSearchError: (state) => {
      return {
        ...state,
        errorMessage: null,
        hasErrors: false,
      };
    },
  },
});

export const {
  resetCustomer,
  setMode,
  customerTypeSelection,
  updateCustomerBegin,
  updateCustomerSuccess,
  updateCustomerFailure,
  getPreLoadCustomerDataNewCustomer,
  clearCustomerSearchError,
} = customerSlice.actions;

const {
  customerSearchBegin,
  customerSearchSuccess,
  customerSearchSuccessExisting,
  customerSearchFailure,
  getCustomerByCheckoutIdBegin,
  getCustomerByCheckoutIdSuccess,
  getCustomerByCheckoutIdFailure,
  requestVerificationCodeBegin,
  requestVerificationCodeSuccess,
  requestVerificationCodeFailure,
  confirmVerificationCodeBegin,
  confirmVerificationCodeSuccess,
  confirmVerificationCodeFailure,
  getPreLoadCustomerDataNewCustomerSuccess,
  getPreLoadCustomerDataNewCustomerFailure,
} = customerSlice.actions;

export default customerSlice.reducer;

// -------------------------
// - Thunks - HANDLING THE CUSTOMER SELECTION
// -------------------------
// TODO: Maybe not required anymore?
export const doCustomerTypeSelection =
  (customerType: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(customerTypeSelection(customerType));
    } catch (err: any) {
      // dispatch(logoutFailure(err));
    }
  };

// -------------------------
// - Thunks - HANDLING THE CUSTOMER SEARCH
// -------------------------
export const customerSearch =
  (
    customerDetails: CustomerDetailsData | CustomerSearchParams,
    customerTypeSelection: 'existingCustomer' | 'newCustomer',
    productLine: ProductLine = ProductLine.BNPL, // default product type = BNPL
    loanAmount: number | null = null
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(customerSearchBegin());

      const response: CustomerSearchResponse = await MerchantAPI.customerSearch(
        customerDetails,
        productLine,
        loanAmount
      );
      const { isExistingCustomer, canCustomerProceed, customerPartial } = response;

      let customerMatch = false;
      let errors = response.errors.length > 0 ? response.errors[0].message : null;

      if (customerTypeSelection === 'newCustomer') {
        // Is not an existing customer, and has passed fraud checks, return true
        customerMatch = !isExistingCustomer && canCustomerProceed;

        // If existing customer, append meaningful error message prepend to original errors received.
        if (isExistingCustomer === true) {
          // Override error message with existing customer error text, if there are no errors found.
          errors =
            'The details entered belongs to an existing customer. Please select "Existing Payright Customer" above.';
          customerMatch = false;
        }

        dispatch(
          customerSearchSuccess({
            customerId: customerPartial?.id ? customerPartial.id : null,
            errorMessage: errors,
            customerMatch,
            customerDetails: customerDetails as CustomerDetailsData,
          })
        );
      }

      if (customerTypeSelection === 'existingCustomer') {
        customerMatch = isExistingCustomer && canCustomerProceed;

        // Save the response - this response is used to conditionally hide/show the next steps in the form
        dispatch(customerSearchSuccessExisting(response));

        // If any errors are found, display the error message on the main error alert box
        if (errors?.length) {
          dispatch(
            customerSearchFailure({
              errorMessage: errors,
            })
          );
        }
      }
    } catch (err: any) {
      dispatch(
        customerSearchFailure({
          errorMessage: 'An unexpected error occurred while trying to verify the customer details',
        })
      );
    }
  };

export const saveCustomerDetails =
  (
    customer: CustomerResource,
    productLine: ProductLine,
    checkoutId?: string
  ): AppThunk<Promise<CustomerResource>> =>
  async (dispatch) => {
    dispatch(updateCustomerBegin());

    let result = null;

    const saveCustomerObj = {
      ...customer,
      productLine,
      checkoutId,
    };

    try {
      if (customer.id === null || typeof customer.id === 'undefined') {
        // Create
        result = await MerchantAPI.customerCreation(saveCustomerObj);
      } else {
        result = await MerchantAPI.updateCustomer(customer.id, saveCustomerObj);
      }
      dispatch(updateCustomerSuccess(result));
      return result;
    } catch (err: any) {
      dispatch(
        updateCustomerFailure({
          errorMessage:
            'An unexpected error has occurred while attempting to save the customer details',
        })
      );
      throw err;
    }
  };

export const doUpdatePaymentDate =
  (formData: CustomerPaymentDateData, customerId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const result = await MerchantAPI.updateCustomerPaymentDetails(customerId, formData);
      dispatch(updateCustomerSuccess(result));
    } catch (err: any) {
      const errorMessage =
        typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
          ? err.response.data.message
          : 'Something went wrong when paying the deposit';
      dispatch(
        updateCustomerFailure({
          errorMessage: errorMessage,
        })
      );
    }
  };

export const getCustomerByCheckoutId =
  (checkoutId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(getCustomerByCheckoutIdBegin());
      const currentCheckout = await MerchantAPI.getCheckoutCustomer(checkoutId);
      dispatch(getCustomerByCheckoutIdSuccess(currentCheckout));
    } catch (error: any) {
      dispatch(getCustomerByCheckoutIdFailure(error.message));
    }
  };

export const requestVerificationCode =
  (customerId: string): AppThunk<Promise<string>> =>
  async (dispatch) => {
    try {
      dispatch(requestVerificationCodeBegin());
      const verificationCodeResponse = await MerchantAPI.requestVerificationCode(customerId);
      dispatch(requestVerificationCodeSuccess({ requestId: verificationCodeResponse.requestId }));

      return verificationCodeResponse.requestId;
    } catch (error: any) {
      dispatch(
        requestVerificationCodeFailure({
          errorMessage: 'Error occurred while attempting to send verification SMS to customer',
        })
      );
      throw error;
    }
  };

export const confirmVerificationCode =
  (customerId: string, requestId: string, code: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch(confirmVerificationCodeBegin());

      const confirmResponse = await MerchantAPI.confirmVerificationCode(
        customerId,
        requestId,
        code
      );
      dispatch(confirmVerificationCodeSuccess());
      return confirmResponse;
    } catch (error: any) {
      dispatch(confirmVerificationCodeFailure({ errorMessage: error.message }));
      return false;
    }
  };

export const loadCustomerUsingVerifiedRequestId =
  (customerId: string, requestId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCustomerByCheckoutIdBegin());
      const customerDetails = await MerchantAPI.getCustomer(customerId, requestId);
      dispatch(getCustomerByCheckoutIdSuccess(customerDetails));
      dispatch(setMode('data-entry'));
    } catch (error: any) {
      dispatch(getCustomerByCheckoutIdFailure(error.message));
    }
  };

export const preLoadCustomerDataToNewCustomer =
  (formData: FormDataExistingCustomer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPreLoadCustomerDataNewCustomer(formData));
      dispatch(getPreLoadCustomerDataNewCustomerSuccess());
    } catch (error: any) {
      dispatch(getPreLoadCustomerDataNewCustomerFailure(error.message));
    }
  };

export const updateCustomerPromoConsent =
  (customerId: string, consentPromoMaterial: CustomerResource): AppThunk =>
  async (dispatch) => {
    try {
      const result = await MerchantAPI.updateCustomer(customerId, consentPromoMaterial);
      dispatch(updateCustomerSuccess(result));
    } catch (error: any) {
      dispatch(
        requestVerificationCodeFailure({
          errorMessage: 'Error occurred while attempting to send verification SMS to customer',
        })
      );
      throw error;
    }
  };
