import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from '../slices';
import LogRocket from 'logrocket';
import { initialState as authInitialState } from '../slices/auth';
import { initialState as checkoutInitialState } from '../slices/checkout';
import MerchantAPI, {
  StoreSuccessResponse,
  RatesSuccessResponse,
  LoggedInStaffPermissionSuccessResponse,
} from '../util/merchant-api';
import AppAPI from './app-api';
import plansApi from './plans-api';

// Check localStorage is there are any existing auth values. Preload the redux state with if it
// existing auth values exist
export const getPersistedLoginState = (): {
  auth?: typeof authInitialState;
  checkout?: typeof checkoutInitialState;
} => {
  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');

  const ratesData: RatesSuccessResponse =
    JSON.parse(localStorage.getItem('merchantRates') || '{}') || authInitialState.merchantRates;
  const storeConfigData: StoreSuccessResponse['storeConfig'] = JSON.parse(
    localStorage.getItem('merchantStoreInfo') || '{}'
  );
  const userData: LoggedInStaffPermissionSuccessResponse = JSON.parse(
    localStorage.getItem('user') || '{}'
  );

  if (access_token) {
    MerchantAPI.setAuthToken(access_token);
    AppAPI.setAuthToken(access_token);
    plansApi.setAuthToken(access_token);

    return {
      auth: {
        ...authInitialState,
        auth: {
          access_token: access_token,
          refresh_token: refresh_token,
        },
        hasErrors: false,
        errorMessage: null,
        storeConfig: storeConfigData,
        merchantRates: ratesData,
        user: userData,
      },
      checkout: {
        ...checkoutInitialState,
      },
    };
  } else {
    return {};
  }
};

const getAllMiddleware = () => {
  const defaultMiddleware = getDefaultMiddleware<RootState>();

  const logRocketMiddleware = LogRocket.reduxMiddleware({
    stateSanitizer: function (state: RootState) {
      return {
        ...state,
        // Do not log plans state as it can be huge
        plans: undefined,
      };
    },
  });

  // Important: type definition must be set here otherwise TypeScript can't infer the redux related types properly
  const allMiddleware: typeof defaultMiddleware = [...defaultMiddleware, logRocketMiddleware];

  return allMiddleware;
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: getPersistedLoginState(),
  middleware: getAllMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;

// useSelector with type definitions attached to it
export function useSelector<TSelected = unknown>(
  selector: (state: RootState) => TSelected
): TSelected {
  return useReduxSelector(selector);
}

// useDispatch with type definitions attached to it
export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
