import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { RootState } from '../slices';
import { media, Button, Alert, IconAttention } from '@payright/web-components';
import {
  customerSearch,
  loadCustomerUsingVerifiedRequestId,
  requestVerificationCode,
  confirmVerificationCode,
  preLoadCustomerDataToNewCustomer,
} from '../slices/customer';
import Spinner from './spinner';
import FormSection from './form-section';
import { ControlledInputField, ControlledDatePicker } from './form-fields';
import { useQuery } from '../util/hooks';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from '../util/store';
import getConstants from '../util/constants';
import { ApplicationCompletedBy } from '../types/plan';
import { useConfig } from '@merchant-portal/providers/config-provider';

// Yup is a JavaScript schema builder for value parsing and validation
// TODO: upgrade RHF and remove references to @hookform/resolvers/yup/dist
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd';
import { existingCustSearchValidationSchema } from '../util/validation/form-validation';
import { ProductLine } from '../util/constants/enums';

const {
  DOB_YEARS,
  NEW_CUSTOMER_DETAIL,
  LABEL_SEND_TO_CUSTOMER_TO_COMPLETE,
  LABEL_COMPLETER_ON_CUSTOMER_BEHALF,
  MESSAGE_SEND_APPLICATION_LINK_TO_CUSTOMER,
  CONTACT_US_PHONE_NUMBER,
} = getConstants();

type ExistingCustomerSearchProps = {
  handleChangeMode: Function;
  formSubmitCustomerLed: Function;
  productLine?: ProductLine;
  loanAmount?: number;
};

const SCLinkButton = styled.button`
  font-size: 1em;
  color: ${(props) => props.theme.colours.blue.base};
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const SCExistingCustomerSearch = styled.div`
  .input-field,
  .date-picker {
    margin-bottom: 2.45em;
  }

  ${media.min.tablet} {
    .details-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .date-picker {
      width: 100%;
    }
  }

  ${media.min.large} {
    .input-field,
    .date-picker {
      flex-basis: calc(50% - 0.75em);
    }
  }
`;

const SCMessageBox = styled.div`
  b,
  strong {
    font-weight: bold;
  }

  .section-content {
    background-color: ${(props) => props.theme.colours.secondary3};
    border-radius: 5px 5px 0 0;
    padding: 1.5em;

    ${media.min.large} {
      padding: 2.66em;
    }
  }
  .button-actiongroup-wrapper {
    &.button-actiongroup-wrapper--full-width {
      width: 100%;
      max-width: 100%;
      padding-bottom: 0;
    }

    .send-to-customer,
    .complete-on-cutomer-behalf {
      flex-grow: 1;
      button {
        width: 100%;
        max-width: 100%;
        height: 90px;
        ${media.min.large} {
          height: 50px;
        }
      }
    }

    .complete-on-cutomer-behalf {
      margin-left: 20px;
    }
  }

  .section-footer {
    background-color: #dde8f2;
    border-radius: 0 0 5px 5px;
    padding: 2.06em 2.66em;
    line-height: 1.86;
  }

  .input-field {
    margin-bottom: 1.33em;
  }

  .verify-button {
    max-width: 160px;
    margin-left: 1em;
    float: right;
  }

  .title {
    color: ${(props) => props.theme.colours.blue.base};
    margin-bottom: 1em;
  }
  .message {
    line-height: 1.86;

    &--resend {
      padding-top: 1em;
      clear: right;
      ${media.min.medium} {
        padding-top: 0;
        clear: none;
      }
    }
  }
  .form {
    margin-top: 2.66em;
  }

  .message-send-to-customer {
    display: flex;
    justify-content: center;
    color: #531dff;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 2em;
  }

  .customer-contact-details-wrapper {
    margin: 0px auto;
    max-width: 350px;
    width: 350px;
    padding: 10px;
    justify-content: center;

    .details {
      color: #531dff;
      font-size: 1em;
      font-weight: bold;
      margin-top: 1em;
      flex: 0 1 auto;
      align-items: flex-start;
      justify-content: flex-start;
      align-self: center;
      display: flex;
      white-space: nowrap;
      padding: 5px 15px 5px 65px;
    }
  }
`;

const ExistingCustomerSearch = ({
  handleChangeMode,
  productLine,
  loanAmount,
  formSubmitCustomerLed,
}: ExistingCustomerSearchProps) => {
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const queryParamCheckoutId = query.get('checkoutId'); // The checkout id in the URL

  const config = useConfig();

  const isPayrightPlusProduct = productLine === ProductLine.PPLUS;

  // RETURNS A PART OF THE STATE - CUSTOMER
  const globalFormState = useSelector((state: RootState) => state.customer);

  const { loading, existingCustomerState, customerDetails } = useSelector(
    (state: RootState) => state.customer
  );

  const { loading: loadingCheckout, errorMessage } = useSelector((state: RootState) =>
    isPayrightPlusProduct ? state.payrightPlus : state.checkout
  );

  const [customerFoundAction, setCustomerFoundAction] = useState<
    'show-options' | 'customer-led' | 'merchant-led'
  >('show-options');

  type FormData = {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
  };

  const reactHookForm = useForm<FormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
    },
    resolver: yupResolver(existingCustSearchValidationSchema),
  });

  const customerLedOnly = useSelector(
    (state: RootState) => state.auth.storeConfig?.customerLedOnly
  );

  const formSubmitHandler = (formData: FormData) => {
    setCustomerFoundAction('show-options');

    dispatch(customerSearch(formData, 'existingCustomer', productLine, loanAmount));
  };

  const { setValue, errors, handleSubmit, formState, watch, getValues } = reactHookForm;
  const email = watch('email');
  const firstName = watch('firstName');

  const isFormSubmitComplete = () => formState.isSubmitted && !loading;

  const verificationCodeForm = useForm<{ verificationCode: string }>({
    defaultValues: {
      verificationCode: '',
    },
  });

  const { errors: vcFormErrors, handleSubmit: vcFormHandleSubmit } = verificationCodeForm;

  const reactHookFormCustomerLed = useForm({
    shouldFocusError: true,
  });

  const { handleSubmit: handleSubmitCustomerLed } = reactHookFormCustomerLed;

  // Changing the formSubmitCustomerLed as a prop
  // Create a plan and customer for customer led
  const formSubmitHandlerCustomerLed = () => {
    if (queryParamCheckoutId) {
      const customerId = existingCustomerState?.customerSearchResponse?.customerPartial?.id;
      formSubmitCustomerLed({
        checkoutId: queryParamCheckoutId,
        customerId,
      });
    }
  };

  const vcFormSubmitHandler = (formData: { verificationCode: string }) => {
    const customerId = existingCustomerState.customerSearchResponse?.customerPartial?.id;
    const requestId = existingCustomerState.verification.requestId;

    if (customerId && requestId) {
      dispatch(confirmVerificationCode(customerId, requestId, formData.verificationCode)).then(
        (result) => {
          if (result === true) {
            dispatch(loadCustomerUsingVerifiedRequestId(customerId, requestId));
          }
          // else, the error is dispatched in redux
        }
      );
    }
  };

  const triggerRequestVerificationCode = () => {
    const customerId = existingCustomerState?.customerSearchResponse?.customerPartial?.id;
    if (customerId) {
      dispatch(requestVerificationCode(customerId))
        .then(() => {
          setCustomerFoundAction('merchant-led');
        })
        .catch((err) => {
          // error is dispatched in redux
        });
    }
  };

  useEffect(() => {
    Object.keys(customerDetails).forEach((key) => {
      setValue(`${key}`, Reflect.get(customerDetails, key));
    });
  }, [customerDetails, setValue, dispatch]);

  return (
    <SCExistingCustomerSearch>
      <FormSection title="Customer Details" text={NEW_CUSTOMER_DETAIL}>
        {errorMessage && (
          <div className="alert-container">
            <Alert
              title={'Application Initiation error'}
              body={errorMessage}
              outcome="error"
              icon={<IconAttention />}
            />
          </div>
        )}
        <FormProvider {...reactHookForm}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <div className="details-body">
              <ControlledInputField
                name="firstName"
                className="first-name"
                required={true}
                error={errors.firstName?.message as string}
              >
                First Name *
              </ControlledInputField>

              <ControlledInputField
                name="lastName"
                className="last-name"
                required={true}
                error={errors.lastName?.message as string}
              >
                Last Name *
              </ControlledInputField>
              <ControlledInputField
                name="email"
                className="email"
                required={true}
                error={errors.email?.message as string}
                type="email"
              >
                Email *
              </ControlledInputField>
              <ControlledDatePicker
                name="dateOfBirth"
                error={errors.dateOfBirth?.message as string}
                startYear={new Date().getFullYear() - 18}
                endYear={new Date().getFullYear() - 18 - DOB_YEARS}
              >
                Date of birth *
              </ControlledDatePicker>
            </div>

            <Button
              className="btn-search-customer"
              disabled={loading}
              type="submit"
              maxWidth={'100%'}
              colour="primary"
            >
              {loading ? <Spinner /> : 'Search'}
            </Button>
          </form>
        </FormProvider>

        <hr />

        {isFormSubmitComplete() &&
          existingCustomerState?.customerSearchResponse?.isExistingCustomer === false && (
            <>
              <SCMessageBox>
                <div className="section-content">
                  <h5 className="title">No account found with those credentials</h5>
                  <p className="message">
                    We have no record of a customer with these matching credentials.
                  </p>
                  <div className="form">
                    <Button
                      iconPosition="right"
                      icon={loading ? <Spinner /> : undefined}
                      disabled={loading}
                      handleClick={() => {
                        const exCustomerFormData = getValues();
                        // auto-switch user to "new customer" form
                        handleChangeMode('newCustomer');
                        // after auto-switch, pre-populate new customer form fields
                        dispatch(preLoadCustomerDataToNewCustomer(exCustomerFormData));
                      }}
                    >
                      Create New Customer
                    </Button>
                  </div>
                </div>
              </SCMessageBox>
              <hr />
            </>
          )}

        {isFormSubmitComplete() &&
          existingCustomerState?.customerSearchResponse?.isExistingCustomer === true &&
          existingCustomerState?.customerSearchResponse?.canCustomerProceed && (
            <>
              <SCMessageBox>
                {customerFoundAction === 'show-options' && (
                  <div className="section-content">
                    <h5 className="title" id="title">
                      Success! We found an account for this customer
                    </h5>
                    <div className="button-actiongroup-wrapper button-actiongroup-wrapper--full-width">
                      {customerLedOnly || config.forceCustomerLed || isPayrightPlusProduct ? (
                        <div className="send-to-customer">
                          <Button
                            handleClick={() => {
                              setCustomerFoundAction('customer-led');
                            }}
                          >
                            {LABEL_SEND_TO_CUSTOMER_TO_COMPLETE}
                          </Button>
                        </div>
                      ) : (
                        <>
                          <div className="send-to-customer">
                            <Button
                              handleClick={() => {
                                setCustomerFoundAction('customer-led');
                              }}
                            >
                              {LABEL_SEND_TO_CUSTOMER_TO_COMPLETE}
                            </Button>
                          </div>
                          <div className="complete-on-cutomer-behalf">
                            <Button handleClick={triggerRequestVerificationCode}>
                              {LABEL_COMPLETER_ON_CUSTOMER_BEHALF}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {customerFoundAction === 'customer-led' && (
                  <div className="section-content">
                    <form onSubmit={handleSubmitCustomerLed(formSubmitHandlerCustomerLed)}>
                      <div className="message-send-to-customer">
                        {MESSAGE_SEND_APPLICATION_LINK_TO_CUSTOMER}
                      </div>

                      <div className="customer-contact-details-wrapper">
                        <div className="details">Email: {email}</div>
                        <div className="details">
                          Mobile No:{' '}
                          {
                            existingCustomerState?.customerSearchResponse?.customerPartial
                              ?.phoneMobile
                          }
                        </div>
                        <div className="button-actiongroup-wrapper button-actiongroup-wrapper--full-width">
                          <div className="send-to-customer">
                            <Button
                              handleClick={() => {
                                setCustomerFoundAction('show-options');
                              }}
                              outline
                              colour="blue"
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="complete-on-cutomer-behalf">
                            <Button
                              type="submit"
                              icon={loadingCheckout ? <Spinner /> : undefined}
                              disabled={loadingCheckout}
                            >
                              Send
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {customerFoundAction === 'merchant-led' && (
                  <>
                    <div className="section-content">
                      <p className="message">
                        {
                          <>
                            We have sent the customer an SMS containing the verification code to
                            access their details. The associated mobile no is{' '}
                            <b>
                              {
                                existingCustomerState?.customerSearchResponse?.customerPartial
                                  ?.phoneMobile
                              }
                            </b>
                            .
                            <br />
                            Please ask customer for the code here to access the customer
                            credentials.
                          </>
                        }
                      </p>
                      <div className="form">
                        <FormProvider {...verificationCodeForm}>
                          <form onSubmit={vcFormHandleSubmit(vcFormSubmitHandler)}>
                            <ControlledInputField
                              name="verificationCode"
                              rules={{ required: 'Verification Code is required' }}
                              className="verification-code"
                              placeholder="Verification code"
                              error={vcFormErrors.verificationCode?.message as string}
                            ></ControlledInputField>
                            <Button
                              iconPosition="right"
                              className="verify-button"
                              icon={loading ? <Spinner /> : undefined}
                              disabled={loading}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </form>
                        </FormProvider>

                        <p className="message message--resend">
                          {
                            <>
                              Customer did not receive code?{' '}
                              <SCLinkButton onClick={triggerRequestVerificationCode}>
                                Send again
                              </SCLinkButton>
                            </>
                          }
                        </p>
                        <div style={{ clear: 'both' }} />
                      </div>
                    </div>
                    <div className="section-footer">
                      If the customer mobile number has changed, please ask the customer to&nbsp;
                      <strong>contact Payright on {CONTACT_US_PHONE_NUMBER}</strong>.
                    </div>
                  </>
                )}
              </SCMessageBox>
              <hr />
            </>
          )}
      </FormSection>
    </SCExistingCustomerSearch>
  );
};

export default ExistingCustomerSearch;
