import React, { useState } from 'react';
import { Button, ModalGeneric, ModalWrapper } from '@payright/web-components';
import { ControlledInputField } from '../form-fields';
import { useFormContext } from 'react-hook-form';
import Spinner from '../spinner';
import SCVerificationCode from './css';
import SummaryPageTextBlock from './SummaryPageTextBlock';
import CustomerTermsAndCondition from '../customer-termsAndCondition';
import MarketingConsent from './marketing-consent';
import { RootState } from '../../slices';
import { useDispatch, useSelector } from 'react-redux';
import getConstants from '../../util/constants';
import { useRegion } from '@merchant-portal/util/hooks';
import { cancelCheckout } from '@merchant-portal/slices/plans';
import { useHistory } from 'react-router-dom';
import {
  CANCEL_POPUP_ABORT_CANCEL,
  CANCEL_POPUP_CANCEL_PLAN,
  CANCEL_POPUP_TEXT,
  CANCEL_POPUP_TITLE,
} from '@merchant-portal/util/constants/defaults';

const { MERCHANT_MARKETING_CONSENT_TEXT, MERCHANT_MARKETING_CONSENT_TEXT_NZ } = getConstants();

const VerificationCode = ({
  loadingValidationApproval,
  customerId,
}: {
  loadingValidationApproval?: boolean;
  verificationCode: string;
  updateVerificationCode: React.ChangeEventHandler<HTMLInputElement>;
  editStatus: boolean;
  customerId: string;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { errors } = useFormContext();
  const [termsAndConditionSelection, setTermsAndConditionSelection] = useState<boolean>(false);
  const globalFormState = useSelector((state: RootState) => state.customer);
  const region = useRegion();
  const idVerified = useSelector((state: RootState) => state.customer.customerDetails.isIdVerified);
  const checkoutId = useSelector((state: RootState) => state.checkout.checkout?.id);
  const [displayCancelPopup, setDisplayCancelPopup] = useState(false);

  const cancellationClickHandler = () => {
    if (checkoutId) {
      dispatch(cancelCheckout(checkoutId));
      history.push(`/plans`);
    }
  };

  return (
    <SCVerificationCode>
      <div className="content">
        <SummaryPageTextBlock />
        {region === 'nz' ? (
          <MarketingConsent
            customerId={customerId}
            checkPromoConsent={globalFormState.customerDetails.consentPromoMaterial}
            merchantMarketingConsentText={MERCHANT_MARKETING_CONSENT_TEXT_NZ}
          />
        ) : (
          !idVerified && (
            <MarketingConsent
              customerId={customerId}
              checkPromoConsent={false}
              merchantMarketingConsentText={MERCHANT_MARKETING_CONSENT_TEXT}
            />
          )
        )}
        <CustomerTermsAndCondition
          selected={termsAndConditionSelection}
          handleClick={() => {
            setTermsAndConditionSelection(!termsAndConditionSelection);
          }}
          termsAndConditionText="Please confirm that you (the Merchant) have read the Repayment Summary and Customer Disclosure and Consent sections to the customer and the customer consents to each of the above statements."
        />
        <p>
          You're seconds away from an instant decision – just enter the customer's{' '}
          <span className="bold">Verification Code</span> below and hit Submit.
        </p>
        <div className="section-action-loansubmit">
          <ControlledInputField
            name="verificationcode"
            rules={{ required: 'Required' }}
            // readOnly={existingCustomer && !editing}
            className="first-name"
            error={errors.verificationcode && 'Verification Code is required'}
          >
            Verification Code
          </ControlledInputField>
        </div>
      </div>
      <Button
        className="submit-button"
        withShadow
        size="big"
        iconPosition="right"
        disabled={loadingValidationApproval || !termsAndConditionSelection}
        icon={loadingValidationApproval ? <Spinner /> : undefined}
      >
        Submit
      </Button>
      <Button
        className="cancel-button-secondary"
        type="button"
        maxWidth="100%"
        outline
        colour="blue"
        withShadow
        size="big"
        iconPosition="right"
        handleClick={() => {
          setDisplayCancelPopup(true);
        }}
      >
        Cancel
      </Button>
      <ModalWrapper
        open={displayCancelPopup}
        handleClose={() => setDisplayCancelPopup(false)}
        background="dark"
      >
        <ModalGeneric
          title={CANCEL_POPUP_TITLE}
          text={CANCEL_POPUP_TEXT}
          contactClassName="cancel-modal-content"
          primaryButton={
            <Button type="button" handleClick={() => cancellationClickHandler()}>
              {CANCEL_POPUP_CANCEL_PLAN}
            </Button>
          }
          secondaryButton={
            <Button
              outline
              type="button"
              colour="blue"
              handleClick={() => setDisplayCancelPopup(false)}
            >
              {CANCEL_POPUP_ABORT_CANCEL}
            </Button>
          }
        />
      </ModalWrapper>
    </SCVerificationCode>
  );
};

export default VerificationCode;
