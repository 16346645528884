import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  IconTickAlt,
  ImageWithIcon,
  IconMegaSearch,
  HeaderMerchant,
  media,
  MessageBox,
  ModalWrapper,
  ModalGeneric,
  IconFace01,
  IconFace02,
} from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import planResultHappyFace from '../../../assets/images/plan-result-happy-face.png';
import CenterContentLayout from '../../../layouts/center-content-layout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../slices';
import { fetchPlanOutcome, resetCheckout } from '../../../slices/checkout';
import Loader from '../../../components/loader';
import { updatePlanStatusAndRefresh, displayPlanDetails } from '../../../slices/plans';
import Spinner from '../../../components/spinner';
import { PlanStatus } from '../../../util/merchant-api';
import { showHideItemsAndMenu } from '../../../util/helper';
import getConstants from '../../../util/constants';

const {
  SUCCESSFUL_PLAN_ACTIVATION,
  REQUEST_FAILURE,
  PLAN_ACTIVATION_MODAL_TITLE,
  PLAN_ACTIVATION_CONFIRMATION,
  APPROVED_PAID_MESSAGE,
  REVIEW_MESSAGE,
  DECLINED_MESSAGE,
  APPROVED_PENDING_ID_MESSAGE,
  APPROVED_PENDING_ID_MESSAGE_2,
  APPROVED_BANK_STATEMENTS_REQUIRED,
  HEADER_MESSAGE_REVIEW,
  HEADER_MESSAGE_PENDING,
  HEADER_MESSAGE_REQUEST,
  HEADER_MESSAGE_DECLINED,
  HEADER_MESSAGE_PAYMENT_PLAN,
} = getConstants();

const SCPlanResult = styled.div`
  .icon,
  .header,
  .message,
  .action {
    margin: 2em 0;
    text-align: center;
  }

  ${media.min.medium} {
    .icon {
      margin-top: 6em;
    }
  }

  .header {
    margin: 2em 0;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      h4 {
        color: ${(props) => props.theme.colours.blue.base};
      }
    }
  }

  p {
    margin: 2em 0;
    font-size: 1.2em;
  }

  /*
  .action > button {
    margin: 0 auto;
  } */

  .actions {
    display: flex;
    padding-bottom: 30px;
    ${media.max.medium} {
      button:first-of-type {
        margin-right: 0px;
      }
      button:last-of-type {
        margin-left: 4px;
        box-shadow: unset;
      }
    }

    ${media.min.medium} {
      button {
        max-width: unset;
        padding: 0 2.5em;
        &:first-of-type {
          margin-right: 1.66em;
        }
      }
    }
  }
`;

const renderPlanResultMessage = ({
  icon,
  header,
  message,
  action,
  customerNumber,
  planNumber,
}: {
  icon: React.ReactNode;
  header: string;
  message: React.ReactNode;
  action: React.ReactNode;
  customerNumber?: string | null;
  planNumber?: string | null;
}) => (
  <SCPlanResult>
    {icon && <div className="icon">{icon}</div>}
    {header && (
      <div className="header">
        <div className="header__title">
          <h4>{header}</h4>
        </div>
      </div>
    )}
    {message && <div className="message">{message}</div>}
    {customerNumber && (
      <div className="message">
        <p>
          Your customer's number is <span className="bold">{customerNumber}</span>
        </p>
      </div>
    )}
    {planNumber && (
      <div className="message">
        <p>
          The customer’s plan number is <span className="bold">{planNumber}</span>
        </p>
      </div>
    )}
    {action && <div className="actions">{action}</div>}
  </SCPlanResult>
);

const PlanResult = () => {
  let { planId } = useParams<{ planId: string }>();
  const checkout = useSelector((state: RootState) => state.checkout);
  const dispatch = useDispatch();
  useEffect(() => {
    if (planId) {
      dispatch(displayPlanDetails(planId));
    }
  }, [dispatch, planId]);

  const { submitSucceeded, errorMessage, planDetails } = useSelector(
    (state: RootState) => state.plans
  );

  const paymentTransactionId = useSelector(
    (state: RootState) => state.payment.payment?.transaction_id
  );
  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const planLoading = useSelector((state: RootState) => state.plans.loading);
  const { loading } = checkout;
  const approvalStatus = planDetails.status; // we need to get the status from the plan as that is newer than the checkout
  const newCustomerNumber = checkout?.checkout?.customerNumber;
  const newPlanNumber = checkout?.checkout?.planNumber;
  const [showPlanActivationModal, setShowPlanActivationModal] = useState(false);

  let showMessage;
  const { accessItems } = showHideItemsAndMenu();

  // Method to close Message Modal and redirect
  const handleCloseMessageModal = () => {
    setShowPlanActivationModal(false);
    if (errorMessage === null) history.push('/plans');
  };

  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={SUCCESSFUL_PLAN_ACTIVATION}
        message=""
        handleOnClick={handleCloseMessageModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={REQUEST_FAILURE}
        message=""
        handleOnClick={handleCloseMessageModal}
        error={true}
      />
    );
  }

  useEffect(() => {
    if (!approvalStatus) {
      dispatch(fetchPlanOutcome(planId));
    }
  }, [dispatch, planId, approvalStatus, newCustomerNumber, newPlanNumber]);

  const history = useHistory();

  const mapPlanOutCome = () => {
    if (approvalStatus === PlanStatus.DECLINED) {
      const planResult = {
        ...planResultMessages['declined'],
      };
      return renderPlanResultMessage(planResult);
    } else if (approvalStatus === PlanStatus.REVIEW) {
      const planResult = {
        ...planResultMessages['review'],
        planNumber: newPlanNumber,
      };
      return renderPlanResultMessage(planResult);
    } else if (approvalStatus === PlanStatus.APPROVED) {
      const planResult = {
        ...planResultMessages['approved_paid'],
        planNumber: newPlanNumber,
      };
      return renderPlanResultMessage(planResult);
    } else if (approvalStatus === PlanStatus.APPROVED_PENDING_ID) {
      const planResult = {
        ...planResultMessages['approvedPendingId'],
      };
      return renderPlanResultMessage(planResult);
    } else if (approvalStatus === PlanStatus.APPROVED_STATEMENT_REQUESTED) {
      const planResult = {
        ...planResultMessages['approvedStatementsRequested'],
        planNumber: newPlanNumber,
      };
      return renderPlanResultMessage(planResult);
    }
  };

  // Override progress bar cursor styling for planResultMessages
  const cursorNotAllowed = '.progressbar li { cursor: not-allowed !important; }';

  const planResultMessages = {
    approved_paid: {
      icon: <ImageWithIcon image={planResultHappyFace} icon={<IconTickAlt />} />,
      header: HEADER_MESSAGE_PAYMENT_PLAN as string,
      message: (
        <>
          <p>
            {/** Deposit and receipt number will show only if merchant is only application completer and transaction Id is available. Transcation Id check is to avoid to display the message in case of zero deposit*/}
            {storeConfig?.applicationCompleter &&
              !storeConfig?.depositTaker &&
              paymentTransactionId !== '' &&
              `Your Deposit Payment of $${planDetails.depositPaid} was successful. Your Receipt number is ${paymentTransactionId}. `}
            {APPROVED_PAID_MESSAGE}
          </p>
        </>
      ),
      action: (
        <>
          <Button
            handleClick={() => {
              dispatch(resetCheckout());
              history.push(`/plans`);
            }}
          >
            Return to plans and activate later
          </Button>

          {!planDetails.planBlockActivation &&
            (accessItems.activatePlans || accessItems.administrator) && (
              <Button handleClick={() => setShowPlanActivationModal(true)}>Activate Plan</Button>
            )}
        </>
      ),
    },
    review: {
      icon: <IconMegaSearch width={60} height={60} />,
      header: HEADER_MESSAGE_REVIEW as string,
      message: REVIEW_MESSAGE,
      action: <Button handleClick={() => history.push(`/plans`)}>Return to plans</Button>,
    },
    declined: {
      icon: <IconFace01 width={60} height={60} />,
      header: HEADER_MESSAGE_DECLINED as string,
      message: (
        <>
          <style>{cursorNotAllowed}</style>
          <p>{DECLINED_MESSAGE}</p>
        </>
      ),
      action: <Button handleClick={() => history.push(`/plans`)}>Return to plans</Button>,
    },
    approvedPendingId: {
      icon: <IconFace02 width={60} height={60} />,
      header: HEADER_MESSAGE_PENDING as string,
      message: (
        <>
          <p>{APPROVED_PENDING_ID_MESSAGE}</p>
          <p>{APPROVED_PENDING_ID_MESSAGE_2}</p>
          <p>
            The physical ID verification process can be found{' '}
            <a href="https://payright.com.au/wp-content/uploads/Payright_Merchant-Operating-Guide.pdf">
              here
            </a>
            .
          </p>
        </>
      ),
      action: <Button handleClick={() => history.push(`/plans`)}>Return to plans</Button>,
    },
    approvedStatementsRequested: {
      icon: <IconMegaSearch width={60} height={60} />,
      header: HEADER_MESSAGE_REQUEST as string,
      message: (
        <>
          <p>{APPROVED_BANK_STATEMENTS_REQUIRED}</p>
        </>
      ),
      action: <Button handleClick={() => history.push(`/plans`)}>Return to plans</Button>,
    },
  };

  return (
    <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
      <HeaderMerchant
        title="New Plan"
        activeStep={3}
        setActiveStep={(index: number) => console.log('Active step clicked: ' + index)}
      />

      <>
        <CenterContentLayout>
          {loading ? <Loader text="Loading Plan Outcome.." /> : mapPlanOutCome()}
          <div>
            <ModalWrapper
              open={showPlanActivationModal}
              handleClose={() => setShowPlanActivationModal(false)}
              background="dark"
            >
              {!submitSucceeded && !errorMessage ? (
                <ModalGeneric
                  title={PLAN_ACTIVATION_MODAL_TITLE}
                  text={PLAN_ACTIVATION_CONFIRMATION}
                  primaryButton={
                    <Button
                      handleClick={() => dispatch(updatePlanStatusAndRefresh('activate', planId))}
                      icon={planLoading ? <Spinner /> : undefined}
                      disabled={planLoading}
                    >
                      Activate
                    </Button>
                  }
                  secondaryButton={
                    <Button
                      outline
                      colour="blue"
                      handleClick={() => setShowPlanActivationModal(false)}
                    >
                      Cancel
                    </Button>
                  }
                ></ModalGeneric>
              ) : (
                <div style={{ textAlign: 'center' }}>{showMessage}</div>
              )}
            </ModalWrapper>
          </div>
        </CenterContentLayout>
      </>
    </LoggedInLayout>
  );
};

export default PlanResult;
