import styled from 'styled-components';
import { media } from '@payright/web-components';

export const SCMobileCheckout = styled.div`
  .checkout {
    .field {
      margin-bottom: 2em;
    }

    .mandatory-field-label {
      color: ${(props) => props.theme.colours.blue.base};
      margin-bottom: 1em;
    }

    .red-field-label {
      color: ${(props) => props.theme.colours.red.base};
    }

    .tooltip {
      display: inline;
      vertical-align: middle;
    }

    .custom-error {
      text-align: center;
      color: ${(props) => props.theme.colours.red.base};
      margin-bottom: 1em;
    }
    .actions {
      button {
        max-width: 100%;
      }
      .button-clear-form {
        margin-bottom: 1em;
        height: 45px;
        ${media.min.large} {
          max-width: 48%;
          height: 52px;
        }
      }
      .button-submit {
        height: 45px;
        ${media.min.large} {
          margin-left: 1em;
          max-width: 50%;
          height: 52px;
        }
      }
      .button-search {
        max-width: 100%;
        height: 52px;
      }
    }
    .checkout-heading {
      padding-left: 0.5em;
      font-size: 1.4em;
      font-weight: bold;
    }
    .checkout-text {
      padding-bottom: 2em;
      padding-top: 1em;
      padding-left: 0.5em;
      font-size: 1.2em;
      font-weight: bold;
    }
    .loader {
      text-align: center;
      p {
        font-size: 1.3em;
        margin-bottom: 1em;
      }
      a {
        color: red;
        font-size: 1.2em;
      }
    }

    .parent-container {
      display: flex;
      ${media.min.large} {
        padding: 0em 3em 3em 3em;
      }
      width: 100%;
      flex-direction: column;
      .modal-div {
        font-size: 10px;
      }
      .modal-inner {
        p {
          font-size: 1.2em;
          max-width: 550px;
        }
        .content {
          padding: 0px;
        }
      }
    }

    .purchase-child-container {
      display: flex;
      width: 100%;
      .image-div {
        display: none;
        ${media.min.large} {
          width: 50%;
          display: block;
        }
      }
      .purchase-text {
        padding-top: 2.5em;
        ${media.min.large} {
          width: 50%;
        }

        .purchase-complete-title {
          font-size: 1.8em;
          color: ${(props) => props.theme.colours.blue.base};
          font-weight: bold;
        }
        .purchase-complete-message {
          font-size: 1.3em;
          font-weight: bold;
        }
      }
    }

    .plan-summary-child-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 2em 2em 2em 2em;

      ${media.min.large} {
        flex-direction: row;
        flex-wrap: wrap;
      }
      hr {
        border-style: solid;
        border-bottom: none;
        border-color: ${(props) => props.theme.colours.grey.light};
        margin: 0 0 10px 0;
        width: 100%;
        order: 3;
      }

      .view-plan {
        text-align: center;
        font-size: 1.5em;
        font-weight: bold;
        padding: 0em 0.4em 0em 0.4em;
        ${media.min.large} {
          padding: 0em 1em 0em 1em;
        }
        a {
          text-decoration: none;
          padding-bottom: 0em;
        }
      }

      .div-button {
        width: 100%;
        padding-top: 20px;
        button {
          max-width: 100%;
          height: 40px;
        }
      }
      .plan-summary-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0em 1em 1em 1em;
        ${media.min.large} {
          padding: 0em 2em 2em 2em;
        }

        .plan-summary {
          width: 100%;
          display: flex;
          line-height: 2.5em;
          font-size: 1.2em;
          font-weight: bold;
          ${media.min.large} {
            width: 50%;
            font-size: 1.3em;
          }
          &.plan-summary-heading {
            order: 1;
            flex-basis: 20%;
            flex-grow: 1;
            font-size: 1.2em;
            padding-bottom: 1em;
            ${media.min.large} {
              font-size: 1.6em;
            }
          }
          &.plan-result-heading {
            order: 2;
            flex-grow: 1;
            flex-basis: 20%;
            justify-content: flex-end;
            padding-bottom: 1em;
            font-size: 1.2em;
            h6 {
              font-size: 0.75em;
            }
            color: ${(props) => props.theme.colours.grey.dark};
            ${media.min.large} {
              padding-top: 15px;
              justify-content: flex-end;
              flex-basis: 40%;
            }
          }
          &.sale-amount-title {
            order: 4;
            flex-basis: 50%;
            flex-grow: 1;
          }
          &.sale-amount-value {
            order: 5;
            flex-basis: 50%;
            flex-grow: 1;
            justify-content: flex-end;
          }
          &.deposit-amount-title {
            order: 6;
            flex-basis: 50%;
            flex-grow: 2;

            ${media.min.large} {
              flex-basis: 50%;
            }
          }
          &.deposit-amount-value {
            order: 7;
            flex-basis: 50%;
            flex-grow: 2;
            justify-content: flex-end;

            span {
              color: ${(props) => props.theme.colours.blue.base};
              padding-left: 5px;
            }
          }
          &.amount-paid-store-title {
            order: 8;
            flex-basis: 50%;
            flex-grow: 2;
            ${media.min.large} {
              flex-basis: 50%;
            }
          }
          &.amount-paid-store-value {
            order: 9;
            flex-basis: 30%;
            flex-grow: 1;
            justify-content: flex-end;
          }
        }
      }
    }
  }
`;
