import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../util/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../util/store';
import { RootState } from '../../slices';
import Loader from '../../components/loader';
import { createCheckoutForLegacyPlans, resetCheckout } from '../../slices/checkout';
import { useEffect } from 'react';
import { result } from 'lodash';
import { HeaderMerchant, media, Alert, IconInfo, Button } from '@payright/web-components';
import LoggedInLayout from '../../layouts/logged-in-layout';
import styled from 'styled-components';

const SCResumePlan = styled.div`
  background: ${(props) => props.theme.colours.white};
  max-width: 1200px;
  margin-bottom: 1em;

  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.altlight};
    margin: 2.23em 0 2em;
  }

  ${media.min.large} {
    max-width: 1400px;
    width: 100;
  }
  .message-body {
    margin-top: 4em;
    .alert {
      display: flex;
      align-content: center;
      max-width: 350px;
      width: 350px;
      margin: auto;
      ${media.min.large} {
        max-width: 800px;
        width: 100%;
        margin-top: 8em;
        margin-bottom: 6em;
      }
    }
    .section-button {
      max-width: 200px;
      width: 350px;
      padding-bottom: 30px;
      margin: auto;
      ${media.min.large} {
        max-width: 200px;
        width: 100%;
        flex-basis: calc(75% - 110px);
        padding-bottom: 5em;
      }
    }
    .message-wrapper {
      padding: 0 0 1.7em;
      margin: auto;
      max-width: 350px;
      width: 350px;
      ${media.min.large} {
        max-width: 800px;
        width: 100px;
      }
    }
  }
`;

const ResumePlan = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();

  const queryParamCheckoutId = query.get('checkoutId');
  const queryPlanId = query.get('planId');
  const queryPage = query.get('page');

  // checking the parameters
  if (!queryPlanId && !queryParamCheckoutId && !queryPage) {
    history.push('/plans');
  }

  const { errorMessage, loading } = useSelector((state: RootState) => state.checkout);

  // Redirection happens here
  useEffect(() => {
    dispatch(resetCheckout());

    if (queryParamCheckoutId !== '' && queryParamCheckoutId !== null) {
      history.push(genertedRedirectUrl(queryParamCheckoutId, queryPlanId, queryPage));
    } else {
      dispatch(createCheckoutForLegacyPlans(queryPlanId))
        .then((checkoutId) => {
          history.push(genertedRedirectUrl(checkoutId, queryPlanId, queryPage));
        })
        .catch((errorMessage) => {
          console.log(errorMessage);
        });
    }
  }, [result, dispatch]);

  return (
    <SCResumePlan>
      <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
        <HeaderMerchant
          title="New Plan"
          activeStep={0}
          setActiveStep={(index: number) => console.log('Active step clicked: ' + index)}
        />
        <div className="message-body">
          {errorMessage ? (
            <>
              <div className="message-wrapper">
                <div className="alert">
                  <Alert
                    title={'Checkout Failed'}
                    body={
                      <>
                        <p>{errorMessage}</p>
                      </>
                    }
                    outcome="error"
                    icon={<IconInfo />}
                  />
                </div>
              </div>

              <hr />
              <div className="section-button">
                <Button
                  iconPosition="right"
                  type="submit"
                  colour="primary"
                  handleClick={() => {
                    history.push('/plans');
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          ) : loading ? (
            <Loader />
          ) : (
            ''
          )}
        </div>
      </LoggedInLayout>
    </SCResumePlan>
  );
};

const genertedRedirectUrl = (checkoutId: string, planId: string | null, page: string | null) => {
  // Plan-Result has different URL. We are making the url for plan-result here
  let redirectURL = '';
  if (page === 'plan-result' && planId) {
    redirectURL = `/plans/new/${page}/${planId}`;
  } else {
    redirectURL = `/plans/new/${page}?checkoutId=${checkoutId}`;
  }
  return redirectURL;
};
export default ResumePlan;
