import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  IconExport,
  Button,
  media,
  PayrightPlusCalculator,
  PayrightPlusCalculatorData,
  Header,
} from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import FormSection from '../../../components/form-section';
import { RootState } from '../../../slices';
import { createCheckout, fetchCheckoutAttributes } from '../../../slices/pplus-checkout';
import { useQuery } from '../../../util/hooks';
import getConstants from '../../../util/constants';
import { MerchantReference } from '@merchant-portal/components/merchant-reference';
// TODO: upgrade RHF and remove references to @hookform/resolvers/yup/dist
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd';
import { ControlledInputField } from '@merchant-portal/components/form-fields';
import LabelWithTooltip from '@merchant-portal/components/label-with-tooltip';
import { purchaseDetailsValidationSchema } from '@merchant-portal/util/validation/form-validation';
import PplusFeeDetails from '@merchant-portal/components/pplus-fee-details';

const { LOAN_PURCHASE_DETAIL, PAYMENT_FREQUENCIES } = getConstants();

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: top;

  ${media.max.tablet} {
    font-size: 0.86em;
  }

  a {
    color: black;
    font-size: 11px;
  }

  .ref {
    text-decoration: underline;
  }
`;

const SCMerchantLoanCreate = styled.div`
  background: ${(props) => props.theme.colours.white};
  .purchasedetails-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;

    ${media.max.medium} {
      margin: 1.6em auto 0;
    }

    ${media.max.ewide} {
      padding: 0 1.6em;
    }

    h4 {
      color: #531dff;
      font-weight: bold;
      line-height: 1.3;
    }

    .loan-attributes {
      display: grid;
      row-gap: 2rem;
      padding-top: 2rem;
      padding-bottom: 2rem;

      ${media.min.medium} {
        grid-template-columns: repeat(2, minmax(10px, 1fr));
        column-gap: 1rem;
      }
    }

    .actions {
      display: flex;
      padding-bottom: 30px;

      ${media.max.medium} {
        button:first-of-type {
          margin-right: 0px;
        }
      }

      ${media.min.medium} {
        button {
          max-width: unset;
          padding: 0 2.5em;
        }
      }
    }
  }
`;

type FormData = {
  paymentDetails: PayrightPlusCalculatorData;
  purposeOfLoan: string;
  merchantRef?: string;
};

const PurchaseDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const loggedInUser = useSelector((state: RootState) => state.auth);
  const formState = useSelector((state: RootState) => state.payrightPlus);
  const checkoutId = formState.checkout?.id;
  const queryParamCheckoutId = query.get('checkoutId');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const merchantRates =
    JSON.parse(localStorage.getItem('payrightPlusRates') || '{}') || loggedInUser.payrightPlusRates;

  const reactHookForm = useForm({
    defaultValues: {
      paymentDetails: {
        ...formState.paymentDetails,
        paymentPeriod:
          loggedInUser?.storeConfig
            ?.defaultTerm /** TODO change the default term to pplus config */,
      },
      purposeOfLoan: formState.checkout?.attributes.purposeOfLoan,
    },
    resolver: yupResolver(purchaseDetailsValidationSchema),
  });

  const { handleSubmit, watch, setValue, errors } = reactHookForm;
  const selectedPaymentFrequency =
    watch('paymentDetails').paymentFrequency || PAYMENT_FREQUENCIES.name['Fortnightly'];

  useEffect(() => {
    if (formState.paymentDetails) {
      setValue('paymentDetails', formState.paymentDetails);
    }
  }, [formState.paymentDetails, setValue, errors]);

  // This ensures that refreshing the page, retrieving the checkout in the background, updates the values in the form
  if (formState.checkout) {
    setValue('purposeOfLoan', formState.checkout?.attributes.purposeOfLoan);
    setValue('merchantRef', formState.checkout?.merchantReference);
  }

  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [checkoutId, dispatch, queryParamCheckoutId]);

  const formSubmitHandler = (formData: FormData) => {
    if (!checkoutId) {
      const {
        originationFee,
        paymentFrequency,
        paymentPeriod,
        loanAmount,
        totalNumberOfRepayments,
        interestBearingRepaymentsAmount,
      } = formData.paymentDetails;

      dispatch(
        createCheckout({
          paymentDetails: {
            originationFee: originationFee,
            paymentFrequency: paymentFrequency,
            paymentPeriod: paymentPeriod,
            loanAmount: loanAmount,
            numberOfRepayments: totalNumberOfRepayments ?? 0,
            repaymentsAmount: interestBearingRepaymentsAmount ?? '0',
          },
          purposeOfLoan: formData.purposeOfLoan,
          merchantReference: formData.merchantRef,
          successCallback: (createdCheckoutId) => {
            history.push(`/loans/new/customer-details?checkoutId=${createdCheckoutId}`);
          },
        })
      );
    } else {
      history.push(`/loans/new/customer-details?checkoutId=${checkoutId}`);
    }
  };

  useEffect(() => {
    // If a checkout already exists, don't allow the user to modify details
    setIsReadOnly(!!checkoutId);
  }, [checkoutId]);

  const minRegulatedLoanAmount =
    loggedInUser?.storeConfig?.minRegulatedLoanAmount === 0
      ? undefined
      : loggedInUser?.storeConfig?.minRegulatedLoanAmount;
  const maxRegulatedLoanAmount =
    loggedInUser?.storeConfig?.maxRegulatedLoanAmount === 0
      ? undefined
      : loggedInUser?.storeConfig?.maxRegulatedLoanAmount;

  return (
    <SCMerchantLoanCreate>
      <LoggedInLayout contentMarginTop={0} activePage="/loans/new">
        <Header title="New Loan" />
        <div className="purchasedetails-body">
          <FormSection title="Purchase Details" text={LOAN_PURCHASE_DETAIL}>
            <FormProvider {...reactHookForm}>
              <form onSubmit={handleSubmit(formSubmitHandler)}>
                <Controller
                  name="paymentDetails"
                  rules={{
                    validate: (paymentDetails) =>
                      paymentDetails.loanAmount !== 0 && paymentDetails.paymentTerm !== 'N/A',
                  }}
                  render={({ onChange, value }) => (
                    <>
                      <PayrightPlusCalculator
                        type="merchant"
                        rates={merchantRates.rates}
                        paymentFrequencies={PAYMENT_FREQUENCIES}
                        establishmentFees={merchantRates.establishmentFees}
                        otherFees={merchantRates.otherFees}
                        updatePaymentDetails={onChange}
                        paymentDetails={value}
                        readOnly={isReadOnly}
                        interestDetails={merchantRates.interestDetails}
                        minRegulatedLoanAmount={minRegulatedLoanAmount}
                        maxRegulatedLoanAmount={maxRegulatedLoanAmount}
                        onValidationChange={setIsValid}
                      />
                      <PplusFeeDetails
                        accountKeepingFee={merchantRates.otherFees.monthlyAccountKeepingFee}
                      />
                    </>
                  )}
                />
                <div className="loan-attributes">
                  <MerchantReference readOnly={isReadOnly} />

                  {/* Purpose of Loan Input */}
                  <Container>
                    <ControlledInputField
                      name="purposeOfLoan"
                      placeholder="sporting goods"
                      error={errors.purposeOfLoan?.message as string}
                      readOnly={isReadOnly}
                    >
                      <LabelWithTooltip
                        tooltipContent={
                          'Brief description of goods / services being purchased by this customer, e.g. sporting goods, home improvement items, beauty treatment.'
                        }
                      >
                        {' '}
                        Description of Goods / Services *{' '}
                      </LabelWithTooltip>
                    </ControlledInputField>
                  </Container>
                </div>
                <div className="actions">
                  <Button
                    disabled={formState.loading || !isValid}
                    type="submit"
                    colour="primary"
                    icon={<IconExport />}
                  >
                    Complete Customer Details
                  </Button>
                </div>
              </form>
            </FormProvider>
          </FormSection>
        </div>
      </LoggedInLayout>
    </SCMerchantLoanCreate>
  );
};

export default PurchaseDetails;
