import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import { RootState } from '@merchant-portal/slices';
import { AppDispatch } from '@merchant-portal/util/store';
import { doPayment, doUpdatePaymentStatus } from '@merchant-portal/slices/payment';
import { doMakeCardActive, getActiveCard } from '@merchant-portal/slices/payment-method';
import { doUpdatePaymentDate } from '@merchant-portal/slices/customer';

import PaymentDetails from '@merchant-portal/components/payment-details';
import PaymentStartDate from '@merchant-portal/components/payment/payment-start-date';
import ConfirmationMessage from './confirmation-message';
import SaveButton from './save-button';

type FatZebraFormProps = {
  iframeUrl: string;
  successCallback?: () => void;
};

type FormData = {
  selectedPaymentDate: Date | null;
  selectedMonthlyDate: string | null;
};

const FatZebraForm = ({ iframeUrl, successCallback }: FatZebraFormProps) => {
  const dispatch: AppDispatch = useDispatch();

  const checkout = useSelector((state: RootState) => state.checkout);
  const customer = useSelector((state: RootState) => state.customer);
  const repeatCustomer = customer?.accountDetails?.isReturnCustomer;
  const { loading: loadingPayment } = useSelector((state: RootState) => state.payment);
  const { paymentMethods } = useSelector((state: RootState) => state.paymentMethod);
  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const checkoutId = checkout.checkout?.id || '';
  const planId = checkout.checkout?.planId || '';
  const customerId = checkout.checkout?.customerId || '';

  const depositAmount = checkout.checkout?.attributes?.depositPayable || 0;
  const depositTaker = storeConfig?.depositTaker || false;

  const activeCardId = getActiveCard(paymentMethods);
  const [selectedCardId, setSelectedCardId] = useState<string>(activeCardId);
  const [clickChangeCard, setClickChangeCard] = useState<boolean>(false);
  const [mode, setMode] = useState<'add' | 'existing'>('add');

  const handleCardChange = (cardId: string) => {
    setSelectedCardId(cardId);
    setClickChangeCard(true);
  };

  useEffect(() => {
    if (Object.keys(paymentMethods).length === 0) {
      setMode('add');
    } else {
      setMode('existing');
    }
  }, [paymentMethods]);

  const formSubmitHandler = async (formData: any) => {
    try {
      if (mode === 'existing' && clickChangeCard) {
        await dispatch(doMakeCardActive(selectedCardId, customerId));
      }

      if (!depositTaker && depositAmount > 0) {
        await dispatch(doPayment(customerId, planId));
      }

      dispatch(doUpdatePaymentStatus(planId, depositAmount, depositTaker));

      /* Only set the payment start date for new customers */
      if (repeatCustomer === false) {
        const formattedDate = formData.selectedPaymentDate
          ? moment(formData.selectedPaymentDate).format('YYYY-MM-DD')
          : formData.selectedMonthlyDate;

        const paymentDateData = {
          checkoutId,
          paymentDetails: {
            paymentStartDate: formattedDate,
          },
        };

        dispatch(doUpdatePaymentDate(paymentDateData, customerId));
      }

      if (typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // error messages are dispatched via redux
    }
  };

  const { handleSubmit } = useFormContext<FormData>();

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <PaymentDetails
        provider="fat-zebra"
        onChange={handleCardChange}
        selectedCardId={selectedCardId}
        mode={mode}
        setMode={setMode}
        iframeUrl={iframeUrl}
      />

      {mode === 'existing' && (
        <>
          <PaymentStartDate></PaymentStartDate>
          <hr />
          <ConfirmationMessage depositAmount={depositAmount} depositTaker={depositTaker} />
          <hr />
          <SaveButton
            loading={loadingPayment}
            depositAmount={depositAmount}
            depositTaker={depositTaker}
          />
        </>
      )}
    </form>
  );
};

export default FatZebraForm;
