import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../util/store';
import MerchantAPI, {
  LoginSuccessResponse,
  RatesSuccessResponse,
  StoreSuccessResponse,
  LoggedInStaffPermissionSuccessResponse,
  PayrightPlusRatesSuccessResponse,
} from '../util/merchant-api';
import LogRocket from 'logrocket';
import AppAPI from '@merchant-portal/util/app-api';
import PlansAPI from '@merchant-portal/util/plans-api';
import { envIsTrue } from '@merchant-portal/util/helper';
import { getMerchantDDODetailsSuccess } from './merchant';

type InitialState = {
  loading?: boolean;
  hasErrors?: boolean;
  auth: object | null;
  errorMessage?: string | null;
  merchantRates: RatesSuccessResponse;
  payrightPlusRates: PayrightPlusRatesSuccessResponse | null;
  user: LoggedInStaffPermissionSuccessResponse;
  storeConfig: StoreSuccessResponse['storeConfig'] | null;
};

export const initialState: InitialState = {
  loading: false,
  hasErrors: false,
  auth: {
    access_token: null,
    refresh_token: null,
  },
  errorMessage: null,
  merchantRates: {
    rates: [],
    establishmentFees: [],
    otherFees: {
      monthlyAccountKeepingFee: 0,
      paymentProcessingFee: 0,
    },
  },
  payrightPlusRates: {
    rates: [],
    establishmentFees: [],
    otherFees: {
      interestRatePercentage: 0,
      paymentProcessingFee: 0,
      monthlyAccountKeepingFee: 0,
    },
    interestDetails: {
      hasInterestFree: false,
      interestFreePeriod: 0,
      monthlyInterestFreeFlatRate: 0,
      fortnightlyInterestFreeFlatRate: 0,
    },
  },
  storeConfig: null,
  user: {
    permissions: [],
    id: '',
    passwordStatus: '',
    firstName: '',
    lastName: '',
    email: '',
    merchantId: '',
  },
};

type LoginPayload = {
  storeConfig: StoreSuccessResponse['storeConfig'];
  rates: RatesSuccessResponse;
  payrightPlusRates: PayrightPlusRatesSuccessResponse | null;
  access_token: string;
  refresh_token: string;
  user: LoggedInStaffPermissionSuccessResponse;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginBegin: (state) => ({ ...state, loading: true, errorMessage: null, hasErrors: false }),
    loginSuccess: (state, action: PayloadAction<LoginPayload>) => ({
      ...state,

      auth: {
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
      },
      merchantRates: action.payload.rates,
      payrightPlusRates: action.payload.payrightPlusRates,
      storeConfig: action.payload.storeConfig,
      user: action.payload.user,
      loading: false,
    }),
    loginFailure: (state, action: PayloadAction<{ errorMessage: string }>) => ({
      ...state,
      hasErrors: true,
      loading: false,
      errorMessage: action.payload.errorMessage,
    }),
    logoutBegin: (state) => ({ ...state, loading: true, errorMessage: null, hasErrors: false }),
    logoutSuccess: (state) => ({ ...state, auth: null, loading: false, hasErrors: false }),
    logoutFailure: (state, action: PayloadAction<{ errorMessage: string }>) => ({
      ...state,
      hasErrors: true,
      loading: false,
      errorMessage: action.payload.errorMessage,
    }),
  },
});

const { loginBegin, loginSuccess, loginFailure, logoutBegin, logoutSuccess, logoutFailure } =
  authSlice.actions;

export default authSlice.reducer;

// -------------------------
// - Thunks - HANDLING THE LOGIN
// -------------------------
export const login =
  (username: string, password: string): AppThunk =>
  async (dispatch) => {
    // DISPATCH THE GET AUTH REDUCER
    dispatch(loginBegin());
    try {
      // DISPATCH THE LOGIN API CALL
      const authentication: LoginSuccessResponse = await MerchantAPI.login(username, password);

      // CALL IT IN THE LOCAL STORAGE
      localStorage.setItem('access_token', authentication.access_token);
      localStorage.setItem('refresh_token', authentication.refresh_token);

      // SET AUTH TOKEN IN APP API

      AppAPI.setAuthToken(authentication.access_token);
      PlansAPI.setAuthToken(authentication.access_token);

      // DISPATCH FETCHING THE RATES
      const rates: RatesSuccessResponse = await MerchantAPI.rates();

      let payrightPlusRates: PayrightPlusRatesSuccessResponse | null = null;

      if (envIsTrue('REACT_APP_FEATURE_PPLUS')) {
        payrightPlusRates = await MerchantAPI.payrightPlusRates();
      }

      // Dispatch fetching the user details and permissions
      const loggedInStaff: LoggedInStaffPermissionSuccessResponse =
        await MerchantAPI.loggedInStaffPermission();

      // Response contains too much details -- we reduce it to only what we want
      const loggedInStaffReduced = {
        id: loggedInStaff.id,
        firstName: loggedInStaff.firstName,
        lastName: loggedInStaff.lastName,
        email: loggedInStaff.email,
        merchantId: loggedInStaff.merchantId,
        permissions: loggedInStaff.permissions,
        passwordStatus: loggedInStaff.passwordStatus,
      };

      const ddoDetails = await MerchantAPI.getMerchantDDODetails();

      // DISPATCH FETCHING THE STORE INFORMATION
      const merchantStore: StoreSuccessResponse = await MerchantAPI.store();

      const auth = {
        access_token: authentication.access_token,
        refresh_token: authentication.refresh_token,
      };

      // COMBINING THE AUTH & RATES
      const authCombination = {
        ...auth,
        rates,
        payrightPlusRates,
        ...merchantStore,
        user: loggedInStaffReduced,
      };

      // @@ TODO - NEED TO LOOK IN TO SECURITY LATER ON (Encrptying local storage)
      if (authCombination && merchantStore.storeConfig.storeStatus === 'Active') {
        LogRocket.identify(loggedInStaff.id, {
          name: loggedInStaff.firstName + ' ' + loggedInStaff.lastName,
          email: loggedInStaff.email,
          mechantId: loggedInStaff.merchantId,
        });

        localStorage.setItem('merchantRates', JSON.stringify(rates));
        localStorage.setItem('payrightPlusRates', JSON.stringify(payrightPlusRates));
        localStorage.setItem('loggedInStaffPermission', JSON.stringify(loggedInStaff.permissions));
        localStorage.setItem('ddoDetails', JSON.stringify(ddoDetails));
        dispatch(getMerchantDDODetailsSuccess({ ddoDetails }));
        localStorage.setItem(
          'loggedInStaffPasswordStatus',
          JSON.stringify(loggedInStaff.passwordStatus)
        );
        localStorage.setItem('user', JSON.stringify(loggedInStaffReduced));
        localStorage.setItem('merchantStoreInfo', JSON.stringify(merchantStore.storeConfig));
        dispatch(loginSuccess(authCombination));
      }
    } catch (e: any) {
      dispatch(loginFailure({ errorMessage: e.message }));
    }
  };

// -------------------------
// - Thunks - HANDLING THE LOGOUT
// -------------------------
export const logout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(logoutBegin());
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('merchantRates');
    localStorage.removeItem('payrightPlusRates');
    localStorage.removeItem('merchantStoreInfo');
    localStorage.removeItem('loggedInStaffPermission');
    localStorage.removeItem('loggedInStaffPasswordStatus');
    localStorage.removeItem('user');
    dispatch(logoutSuccess());
  } catch (err: any) {
    dispatch(logoutFailure(err));
  }
};

export const sessionExpired = (): AppThunk => async (dispatch) => {
  dispatch(logout());
  dispatch(loginFailure({ errorMessage: 'Your session has expired. Please log in again.' }));
};

// -------------------------
// - Thunks - HANDLING UNAUTHORIZED USER AND FORCE LOGOUT
// -------------------------
export const bootUserIfNotLoggedIn = (): AppThunk => async (dispatch) => {
  try {
    const access_token = localStorage.getItem('access_token');

    // Check if access_token acquired, from successful login.
    if (access_token) {
      // axios interceptor will fire the appropriate sessionExpired action if a 401 error is received
      await MerchantAPI.ping();
    }
  } catch (err) {
    // Do nothing on unexected error
  }
};
