import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export enum PreApprovalCheckoutStep {
  CUSTOMER_CANCELLED = 'checkout:customer-cancelled',
  CUSTOMER_ADJUSTED_PAY_DIFFERENCE = 'checkout:customer-adjusted-pay-difference',
  MERCHANT_ACTION_REQUIRED = 'checkout:merchant-action-required',
  CUSTOMER_ACTION_REQUIRED = 'checkout:customer-action-required',
  PAYMENT_DETAILS = 'checkout:payment-details',
  COMPLETE = 'checkout:complete',
}

export type AppApiResponse<T> = {
  data: T;
};

/* TODO: move this to the appropriate file */
export enum ProductType {
  PREMIUM_LIMIT = 'BNPL - Premium',
  PRE_APPROVAL = 'BNPL - Pre-Approved Merchant',
  OTHER = 'BNPL - Other',
}

export type AppPreApprovalData = {
  id: string | null;
  step: PreApprovalCheckoutStep;
  term: number | null;
  merchantReference: string | null;
  customerId: string;
  productType: ProductType;
};

export type CreatePremiumLimitPayload = {
  saleAmount: number;
  customerId: string;
  checkoutCode: string;
  merchantReference: string;
};

/** class to call the app api */
class AppAPI {
  axiosInstance: AxiosInstance;

  constructor() {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_MOBILE_APP_API_URL,
      timeout: 40000,
      headers: {
        common: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    this.axiosInstance = axios.create(axiosConfig);
  }

  setAuthToken = (authToken: string) => {
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  };

  // Search the plan by checkout code
  getPreApprovalByCheckoutCodeOrPlanId = async (
    checkoutCode: string
  ): Promise<AppPreApprovalData> => {
    try {
      const url = `/api/v1/merchants/me/pre-approvals/${checkoutCode}`;
      const response = await this.axiosInstance.get<AppApiResponse<AppPreApprovalData>>(url);
      return {
        ...response.data.data,
      };
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  createPremiumLimitPlan = async (payload: CreatePremiumLimitPayload) => {
    try {
      const url = `/api/v1/merchants/me/pre-approvals`;
      const response = await this.axiosInstance.post<AppApiResponse<AppPreApprovalData>>(
        url,
        payload
      );

      return response.data.data;
    } catch (error: any) {
      throw error;
    }
  };

  // Sets the checkout back to "checkout:merchant-action-required"
  merchantCancelCheckout = async (planId: string): Promise<AppPreApprovalData> => {
    try {
      const url = `/api/v1/merchants/me/pre-approvals/${planId}/reset-checkout`;
      const response = await this.axiosInstance.post<AppApiResponse<AppPreApprovalData>>(url);

      return response.data.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  updateProposedSaleAmount = async (
    saleAmount: string,
    merchantReference: string,
    planId: string
  ): Promise<AppPreApprovalData> => {
    try {
      const url = `/api/v1/merchants/me/pre-approvals/${planId}`;
      const response = await this.axiosInstance.put<AppApiResponse<AppPreApprovalData>>(url, {
        saleAmount,
        merchantReference,
      });

      return response.data.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };
}

export default new AppAPI();
