import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../slices';
import SCPlanDetails from './plan-detail-css';
import {
  Tabs,
  Button,
  InputField,
  IconChevron,
  ModalWrapper,
  ModalGeneric,
  MessageBox,
} from '@payright/web-components';
import LoggedInLayout from '../../layouts/logged-in-layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { displayPlanDetails, updatePlanStatusAndRefresh } from '../../slices/plans';
import getConstants from '../../util/constants';
import Loader from '../../components/loader';
import { showHideItemsAndMenu } from '../../util/helper';
import { PlansDetailSuccessResponse } from '../../util/merchant-api';
import { CustomerResourceBasic } from '@merchant-portal/types/customer';

const {
  PLAN_APPROVED_STATUS,
  SUCCESSFUL_PLAN_ACTIVATION,
  PLAN_ACTIVATION_CONFIRMATION,
  REQUEST_FAILURE,
} = getConstants();

const PlanDetail = (plan: any) => {
  const dispatch = useDispatch();
  const { errorMessage, planDetails, planCustomer, loading, submitSucceeded } = useSelector(
    (state: RootState) => state.plans
  );
  const [showPlanActivationModal, setShowPlanActivationModal] = useState(false);
  const { accessItems } = showHideItemsAndMenu();
  let showMessage;

  // Method to close Plan Activation Modal after message display
  const handleClosePlanActivationModal = () => {
    setShowPlanActivationModal(false);
    dispatch(displayPlanDetails(plan.match.params.id));
  };

  useEffect(() => {
    dispatch(displayPlanDetails(plan.match.params.id));
  }, [dispatch, plan.match.params.id]);

  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={SUCCESSFUL_PLAN_ACTIVATION}
        message=""
        handleOnClick={handleClosePlanActivationModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={REQUEST_FAILURE}
        message=""
        handleOnClick={handleClosePlanActivationModal}
        error={true}
      />
    );
  }

  const renderPlanDetailsTab = (planDetails: PlansDetailSuccessResponse) => {
    return (
      <div className="details-wrapper-body">
        <Grid fluid>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <h4>Plan Breakdown</h4>
            </Col>
            <Col md={3} xs={12} lg={8}>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Repayment Frequency</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>{planDetails.repaymentFrequency}</h6>
                    </Col>
                  </Row>

                  <Row end="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Repayment Period</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>{planDetails.terms} Months</h6>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>No. of Repayments</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>{planDetails.numberOfRepayments}</h6>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Repayment Amount</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>${planDetails.repaymentAmount}</h6>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={6} lg={5} xsOffset={1}>
                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Sale Amount</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>${planDetails.saleAmount}</h6>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Deposit</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>${planDetails.depositPaid}</h6>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>
                        Loan amount
                        <br />
                        per repayment
                      </h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>${planDetails.loanAmountPerPayment}</h6>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Establishment Fee</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>${planDetails.establishmentFee}</h6>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={12} lg={12}>
                      <div className="border-row"></div>
                    </Col>
                  </Row>

                  <Row start="xs">
                    <Col xs={12} md={6} lg={6}>
                      <h5>Total Credit Required</h5>
                    </Col>
                    <Col xs={9} md={6} lg={3} xsOffset={3}>
                      <h6>${planDetails.totalCreditAmount}</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="gap-div"></div>
        </Grid>
      </div>
    );
  };

  const renderPlanCustomerTab = (planCustomer: CustomerResourceBasic) => {
    return (
      <div className="details-wrapper-body">
        <Grid fluid>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <h4>Customer Details</h4>
            </Col>

            <Col md={3} xs={11} lg={9}>
              <Row>
                <Col xs={12} md={6} lg={11} xsOffset={1}>
                  <Row start="xs">
                    <Col xs={12} md={6} lg={5}>
                      <h6 className="label">Customer Number</h6>
                      <InputField
                        name="customerNumber"
                        value={planCustomer.customerNumber}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={11} xsOffset={1}>
                  <Row start="xs">
                    <Col xs={12} md={6} lg={2}>
                      <h6 className="label">Title</h6>
                      <InputField
                        name="title"
                        value={planCustomer.customerDetails?.title}
                        readOnly={true}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                      <h6 className="label">First Name</h6>
                      <InputField
                        name="firstName"
                        value={planCustomer.customerDetails?.firstName}
                        readOnly={true}
                      />
                    </Col>

                    {planCustomer.customerDetails?.middleName !== '' ? (
                      [
                        <Col xs={12} md={6} lg={3}>
                          <h6 className="label">Middle Name</h6>
                          <InputField
                            name="middleName"
                            value={planCustomer.customerDetails?.middleName}
                            readOnly={true}
                          />
                        </Col>,

                        <Col xs={12} md={6} lg={3}>
                          <h6 className="label">Last Name</h6>
                          <InputField
                            name="lastName"
                            value={planCustomer.customerDetails?.lastName}
                            readOnly={true}
                          />
                        </Col>,
                      ]
                    ) : (
                      <Col xs={12} md={6} lg={6}>
                        <h6 className="label">Last Name</h6>
                        <InputField
                          name="lastName"
                          value={planCustomer.customerDetails?.lastName}
                          readOnly={true}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col xs={12} md={6} lg={11} xsOffset={1}>
                  <Row start="xs">
                    <Col xs={12} md={6} lg={5}>
                      <h6 className="label">Email</h6>
                      <InputField
                        name="email"
                        value={planCustomer.customerDetails?.email}
                        readOnly={true}
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <h6 className="label">Customer Mobile Number</h6>
                      <InputField
                        name="mobileNumber"
                        value={planCustomer.customerDetails?.phone}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  return (
    <SCPlanDetails>
      <LoggedInLayout activePage="/plans" contentMarginTop={2}>
        <div className="plan-detail-body">
          <div className="view-all-plans">
            <h6>
              <IconChevron />
              <Link to="/plans" className="linkPage">
                <span>View all Plans</span>
              </Link>
            </h6>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="header">
                <h2>Plan Details</h2>
                {planDetails.status.toLowerCase() === PLAN_APPROVED_STATUS &&
                planDetails.depositPaymentStatus !== '' &&
                !planDetails.planBlockActivation &&
                (accessItems.activatePlans || accessItems.administrator) ? (
                  <h6>
                    <Button
                      className="button"
                      handleClick={() => setShowPlanActivationModal(true)}
                      colour="blue"
                    >
                      Activate Plan
                    </Button>
                  </h6>
                ) : (
                  ''
                )}
              </div>
              <div>
                <ModalWrapper
                  open={showPlanActivationModal}
                  handleClose={() => setShowPlanActivationModal(false)}
                  background="dark"
                >
                  {!submitSucceeded && !errorMessage ? (
                    <ModalGeneric
                      title={'Plan Activation'}
                      text={PLAN_ACTIVATION_CONFIRMATION}
                      primaryButton={
                        <Button
                          handleClick={() =>
                            dispatch(updatePlanStatusAndRefresh('activate', plan.match.params.id))
                          }
                        >
                          Activate
                        </Button>
                      }
                      secondaryButton={
                        <Button
                          outline
                          colour="blue"
                          handleClick={() => setShowPlanActivationModal(false)}
                        >
                          Cancel
                        </Button>
                      }
                    ></ModalGeneric>
                  ) : (
                    <div className="message">{showMessage}</div>
                  )}
                </ModalWrapper>
              </div>

              <div>
                <Tabs
                  tabs={[
                    {
                      label: 'Plan Details',
                      children: renderPlanDetailsTab(planDetails),
                    },
                    {
                      label: 'Customer Details',
                      children: renderPlanCustomerTab(planCustomer),
                    },
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </LoggedInLayout>
    </SCPlanDetails>
  );
};

export default PlanDetail;
