import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@merchant-portal/util/store';
import MerchantAPI, { PaymentMethodsResponse } from '../util/merchant-api';
import _ from 'lodash';

const initialState = {
  loading: true,
  hasErrors: false,
  paymentMethods: {},
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    resetPaymentMethod: () => initialState,
    getPaymentMethodsSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        paymentMethods: payload,
      };
    },
    getPaymentMethodsFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    makeCardActiveSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        paymentMethods: payload,
      };
    },
    startFetchingPaymentMethods: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    makeCardActiveFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  resetPaymentMethod,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  makeCardActiveSuccess,
  makeCardActiveFailure,
  startFetchingPaymentMethods,
} = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;

// ---------------------------------------------
// - Thunks - HANDLING THE FETCH PAYMENT METHODS
// ----------------------------------------------
export const fetchCustomerPaymentMethods =
  (customerId: any): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startFetchingPaymentMethods({}));
    try {
      const response: PaymentMethodsResponse = await MerchantAPI.getPaymentMethodsList(customerId);
      dispatch(getPaymentMethodsSuccess(response));
    } catch (err: any) {
      const errorMessage =
        typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
          ? err.response.data.message
          : 'Something went wrong when fetching payment methods';
      dispatch(
        getPaymentMethodsFailure({
          errorMessage: errorMessage,
        })
      );
    }
  };

export const prepareCardDetails = (paymentMethods: any) => {
  if (_.isEmpty(paymentMethods)) return [];

  let cardList = paymentMethods.map((method: any) => {
    return { id: method.id, type: method.cardProvider, last4digits: method.cardNumber.slice(-4) };
  });

  return cardList;
};

export const getActiveCard = (paymentMethods: any) => {
  if (_.isEmpty(paymentMethods)) return '';

  const activeCard = paymentMethods.find((method: any) => method.enabled === true);
  return _.isEmpty(activeCard) ? '' : activeCard.id;
};

export const doMakeCardActive =
  (paymentMethodId: string, customerId: any): AppThunk<Promise<PaymentMethodsResponse>> =>
  async (dispatch) => {
    try {
      const response: PaymentMethodsResponse = await MerchantAPI.makeCardActive(
        customerId,
        paymentMethodId
      );

      dispatch(makeCardActiveSuccess(response));
      return response;
    } catch (err: any) {
      const errorMessage =
        typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
          ? err.response.data.message
          : 'Something went wrong when fetching payment methods';
      dispatch(
        makeCardActiveFailure({
          errorMessage: errorMessage,
        })
      );
      throw err;
    }
  };
