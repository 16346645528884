import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { PaymentCalculatorData } from '@payright/web-components';
import { CustomerResource, CustomerResourceBasic, CustomerDetailsData } from '../types/customer';
import { cloudCheckRequestDTO } from '../types/payments';
import { PreApprovalData, PlanAction, ApplicationCompletedBy } from '../types/plan';
import { NextStep } from '../types/checkout';
import { ProductLine } from '@merchant-portal/util/constants/enums/index';
import { PaymentFrequency } from '@payright/web-components';
import { LoanAction, LoansListMetaData } from '@merchant-portal/types/loans';

export type LoginSuccessResponse = {
  access_token: string;
  refresh_token: string;
};

export type LegacyPlansCheckoutData = {
  checkoutId: string;
};

// TODO: This is not the exacty response of /api/v1/user ! It does not contain the whole definition
export type LoggedInStaffPermissionSuccessResponse = {
  permissions: Array<any>;
  passwordStatus: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  merchantId: string;
};

export type ResetPasswordSuccess = {
  temporaryPassword: string;
  password: string;
};
export type ForgotPasswordRequestedSuccessResponse = {
  userName: string;
  email: string;
};

// VERIFICATION CODE RESPONSE TYPE
export type VerifyPlanResponse = {
  planId: string;
  status: boolean;
};

export enum PlanStatus {
  DECLINED = 'Declined',
  APPROVED = 'Approved',
  APPROVED_PENDING_ID = 'Approved_Pending_ID',
  APPROVED_STATEMENT_REQUESTED = 'Approved_Statement_Requested',
  REVIEW = 'Review',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  UNDEFINED = '',
}

export enum LoanStatus {
  DECLINED = 'Declined',
  APPROVED = 'Approved',
  APPROVED_PENDING_ID = 'Approved_Pending_ID',
  APPROVED_STATEMENT_REQUESTED = 'Approved_Statement_Requested',
  REVIEW = 'Review',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  UNDEFINED = '',
}

// PLAN APPROVAL RESPONSE TYPE
export type ApprovalResponse = {
  approvalStatus: PlanStatus;
};

export type CreateCheckoutParams = {
  attributes: {
    saleAmount: number;
    terms: number | string;
    depositPaid?: number | string | null;
    depositPaidPercentage: number | string;
    paymentPeriod: number | string;
    minimumDepositPercentage?: number | null;
    purposeOfLoan?: string;
  };
  merchantReference?: string;
};

export type CreatePplusCheckoutParams = {
  attributes: {
    saleAmount: number;
    terms: PaymentFrequency;
    paymentPeriod: number;
    purposeOfLoan: string;
    depositPaid: 0;
    depositPaidPercentage: 0;
    depositPayable: 0;
    minimumDepositPercentage: 0;
    paymentDetails: {
      depositAmount: 0;
      depositPercent: 0;
      establishmentFee: number;
      loanAmount: number;
      saleAmount: number;
      paymentFrequency: PaymentFrequency;
      paymentPeriod: number;
      numberOfRepayments: number;
      repaymentsAmount: string | number;
      totalAmount: number;
    };
  };
  merchantReference?: string;
};

export type CustomerSearchParams = Required<
  Pick<CustomerDetailsData, 'firstName' | 'lastName' | 'dateOfBirth'>
> &
  Partial<Pick<CustomerDetailsData, 'email' | 'phone'>>;

/// only going to be used in this file
type CheckOutAttributes = {
  depositPaidPercentage: number;
  depositPayable: number;
  paymentPeriod: number;
  saleAmount: number;
  terms: string;
  paymentDetails: PaymentCalculatorData;
  planPreApprovalDetails: PreApprovalData;
};

export type CheckoutSuccessResponse = {
  id?: string;
  merchantReference?: string;
  status?: string;
  createdAt?: string;
  expiresAt?: string | null;
  link?: string;
  customerId?: string | null;
  customerNumber?: string | null;
  planId?: string | null;
  planNumber?: string | null;
  nextSteps?: Array<string>;
  attributes?: CheckOutAttributes;
  approvalStatus?: PlanStatus;
  planPreApprovalDetails?: PreApprovalData;
};

type BaseCheckoutResource = {
  id: string;
  merchantReference: string;
  status: string;
  nextSteps?: Array<string>;
  customerId: string | null;
  customerNumber?: string | null;
  createdAt?: string;
  expiresAt?: string | null;
  link?: string;
};

export type CheckoutResource = BaseCheckoutResource & {
  approvalStatus?: PlanStatus;
  attributes: CheckOutAttributes;
  planId: string | null;
  planNumber?: string | null;
};

export type PplusCheckoutAttributesResource = {
  saleAmount: number;
  depositPercent: number;
  depositAmount?: number;
  paymentFrequency: PaymentFrequency;
  paymentPeriod: number;
  establishmentFee: number;
  purposeOfLoan: string;
};

export type PplusCheckoutResource = BaseCheckoutResource & {
  attributes: PplusCheckoutAttributesResource;
  loanId: string | null;
  loanNumber?: string | null;
};

//export type CheckoutSuccessResponse = CheckoutResource;
export type CheckoutUpdateSuccessResponse = CheckoutResource;

// CURRENT CHECKOUT RESPONSE TYPE
export type CurrentCheckoutResponse = CheckoutResource;

// A checkout row in the DB, with attributes related to mobile checkout
export type MobileCheckoutData = {
  planId: string;
  saleAmount: number;
  amountPaidInStore?: number;
  merchantReference: string;
};

export type CustomerAttachSuccessResponse = {
  id: string;
  name: string;
  contactId: string;
  customerId: string;
  customerName: string;
  saleAmount: string;
  status: string;
  depositPaymentStatus: string;
  dateCreated: string;
  merchantReference: string;
};

export type MerchantRate = {
  minimumSaleAmount: number;
  minimumDepositPercentage: number;
  term: number;
  minimumPurchase: number;
  maximumPurchase: number;
  rate00: number | null;
  rate05: number | null;
  rate10: number | null;
  rate15: number | null;
  rate20: number | null;
  rate25: number | null;
  rate30: number | null;
  rate35: number | null;
  rate40: number | null;
  rate45: number | null;
  rate50: number | null;
};

export type RatesSuccessResponse = {
  rates: Array<MerchantRate>;
  establishmentFees: Array<{
    initialEstFee: number;
    repeatEstFee: number;
    term: number;
  }>;
  otherFees: {
    monthlyAccountKeepingFee: number;
    paymentProcessingFee: number;
  };
};

export type PayrightPlusRatesSuccessResponse = {
  rates: Array<{
    term: number;
    depositPercentage: number;
    minLoanAmount: number;
    maxLoanAmount: number;
    minSaleAmount: number;
    maxSaleAmount: number;
  }>;
  establishmentFees: Array<{
    minLoan: number;
    maxLoan: number;
    term: number;
    fee: number;
  }>;
  otherFees: {
    interestRatePercentage: number;
    paymentProcessingFee: number;
    monthlyAccountKeepingFee: number;
  };
  interestDetails: {
    hasInterestFree: boolean;
    interestFreePeriod: number;
    monthlyInterestFreeFlatRate: number;
    fortnightlyInterestFreeFlatRate: number;
  };
};

export type StoreSuccessResponse = {
  storeConfig: {
    id: string;
    name: string;
    merchantId: string;
    merchantName: string;
    email: string | null;
    defaultTerm: number;
    depositTaker: boolean;
    fundingSource: string;
    applicationCompleter: boolean;
    storeNumber: string;
    storeStatus: string;
    paymentsProcessedBySugar: boolean;
    customerLedOnly: boolean;
    merchantIndustry: string | null;
    accountManager: {
      id: string;
      name: string;
      email: string;
      phoneWork: string;
    } | null;
    showMobileCheckout: boolean;
    capacityOffsetOn: boolean;
    bsoOffsetValue: number;
    storeDescription: string;
    activationDatetime: string;
    hasBnpl: boolean;
    hasRegulatedProduct: boolean;
    minRegulatedLoanAmount: number;
    maxRegulatedLoanAmount: number;
  };
};

export type PlansSuccessResponse = Array<{
  id: string;
  name: string;
  customerName: string;
  status: string;
  dateCreated: string;
  merchantReference: string;
  staffName: string;
  depositPaymentStatus: string;
  saleAmount: string;
  depositPaid: string;
  amountSettledToDate: string;
  totalCreditAmount: string;
  msf: string;
  depositPaidPercentage: string;
  terms: string;
  contactId: string;
  finalSettlementDate: string;
  planBlockActivation: boolean;
  merchantSettled: string;
  creditAmount: string;
  checkoutId: string;
  checkoutCode?: string;
  applicationCompletedBy: string;
  items?: Array<any>;
  shippingAddress?: string;
}>;

export type MerchantLoanData = Array<{
  id: string;
  name: string;
  customerName: string;
  status: string;
  dateCreated: string;
  merchantReference: string;
  staffName: string;
  saleAmount: string;
  amountSettledToDate: string;
  totalCreditAmount: string;
  msf: string;
  terms: string;
  contactId: string;
  finalSettlementDate: string;
  planBlockActivation: boolean;
  merchantSettled: string;
  creditAmount: string;
  checkoutId: string;
  checkoutCode?: string;
  applicationCompletedBy: string;
  items?: Array<any>;
  shippingAddress?: string;
  establishmentFeeOutstanding: string;
  paymentStartDate: string;
  consentApproval: boolean;
  repaymentFrequency: string;
  establishmentFee: string;
}>;

export type LoansSuccessResponse = {
  loans: MerchantLoanData;
  metadata: LoansListMetaData;
};

export type PlansStatsSuccessResponse = {
  plans: {
    total: string;
    pending: string;
    pendingSumTotal: string;
    cancelled: string;
    cancelledSumTotal: string;
    approved: string;
    approvedSumTotal: string;
  };
};

export type ExportPlanSuccessResponse = {
  exportCSVURL: string;
};

export type PlansDetailSuccessResponse = {
  depositPaymentStatus: string;
  numberOfRepayments: string;
  establishmentFee: string;
  repaymentFrequency: string;
  loanAmountPerPayment: string;
  repaymentAmount: string;
  depositPaid: string;
  totalCreditAmount: string;
  terms: string;
  saleAmount: string;
  customerId: string;
  customerNumber?: string | null;
  name?: string;
  status: PlanStatus;
  depositPaidPercentage: string;
  planBlockActivation: boolean;
};

export type LoanDetailsSuccessResponse = {
  id: string;
  depositPaymentStatus: string;
  numberOfRepayments: string;
  establishmentFee: string;
  repaymentFrequency: string;
  loanAmountPerPayment: string;
  repaymentAmount: string;
  depositPaid: string;
  totalCreditAmount: string;
  terms: string;
  saleAmount: string;
  customerId: string;
  customerNumber?: string | null;
  name?: string;
  status: LoanStatus;
  depositPaidPercentage: string;
  loanBlockActivation: boolean;
  loanPurpose: string;
  loanManagementFee: string;
  interestFreeRepayments: string;
  interestBearingRepayments: string;
  interestFreeRepaymentAmount: string;
  interestBearingRepaymentAmount: string;
  establishmentFeeOutstanding: string;
  paymentStartDate: string;
  consentApproval: string;
};

export type StaffSuccessResponse = Array<{
  id: string;
  fullName: string;
  phoneMobile: string;
  phoneWork: string;
  email: string;
  contactStatus: string;
}>;

export type StaffCreationSuccessResponse = {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  userName?: string;
  password?: string;
  id?: string;
};

export type StaffDetailSuccessResponse = {
  id: string;
  firstName: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  userName?: string;
  email?: string;
  permissions: Array<any>;
  contactStatus: string;
  canAlterPermissions: boolean;
};

export type StaffUpdateSuccessResponse = {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  permissions?: Array<any>;
  id?: string;
  contactStatus?: string;
};

export type ProfileUpdateSuccessResponse = {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export type ProfileDetailSuccessResponse = {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export type CustomerSearchResponse = {
  customerPartial: { id: string; phoneMobile: string } | null;
  errors: Array<{ code: number; message: string }>;
  isExistingCustomer: boolean;
  canCustomerProceed: boolean;
};

export type PaymentGatewayDetails = {
  serviceProvider: 'Eway' | 'Cloud_Payments';
  apiUrl: string;
  paymentCurrency: string;
  iframeUrl: string;
};

export type PaymentMethodsResponse = Array<{
  id: string;
  name: string;
  paymentMethodType: string;
  cardProvider: string;
  cardNumber: string;
  fundingSource: string;
}>;

export type MakePaymentResponse = {
  status: string;
  message: string;
  responseCode: string;
  transactionId: number;
};
type MakePaymentData = {
  customerId: string;
  paymentType: string;
  planId: string;
};

export type AddCardData = {
  card_holder: string;
  customer_id: string;
  card_type: string;
  payment_provider: string;
  number: string;
  card_expiry: string;
  cvn: string;
  customer_number: string;
  payment_collected_by_sugar: boolean;
  customer_first_name: string;
  customer_last_name: string;
  checkoutId?: string;
};

export type DDODetailsResponse = {
  shouldBlockLoanCreation: boolean;
  canAcceptDDO: boolean;
};

type UpdateCustomerData = {
  paymentDetails: {
    paymentStartDate: string;
  };
};

type AcceptPreApprovaldata = {
  nextSteps: Array<NextStep>;
};
class MerchantAPI {
  axiosInstance: AxiosInstance;

  constructor() {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_SLAPJOUST_API_URL,
      //timeout: 800000,
      headers: {
        common: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    this.axiosInstance = axios.create(axiosConfig);
  }

  setAuthToken = (authToken: string) => {
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  };

  clearAuthToken = () => {
    delete this.axiosInstance.defaults.headers.common['Authorization'];
  };

  login = async (username: string, password: string): Promise<LoginSuccessResponse> => {
    try {
      const response = await this.axiosInstance.post(
        process.env.REACT_APP_SLAPJOUST_API_URL + `/oauth/token`,
        {
          grant_type: 'password',
          client_id: process.env.REACT_APP_SLAPJOUST_CLIENT_ID,
          client_secret: process.env.REACT_APP_SLAPJOUST_CLIENT_SECRET,
          username: username,
          password: password,
          scope: '',
          user_type: 'Merchant',
        }
      );

      if (response.data.access_token && response.data.refresh_token) {
        this.setAuthToken(response.data.access_token);
        return {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        };
      } else {
        throw new Error('Unexpected error. Please try again.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw new Error('Unexpected error. Please try again.');
    }
  };

  rates = async (): Promise<RatesSuccessResponse> => {
    try {
      const url = `/api/v1/rates`;
      const response = await this.axiosInstance.get<RatesSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch the rates.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  loggedInStaffPermission = async (): Promise<LoggedInStaffPermissionSuccessResponse> => {
    try {
      const url = `/api/v1/user`;
      const response = await this.axiosInstance.get<LoggedInStaffPermissionSuccessResponse>(url);

      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch the contact details.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  store = async (): Promise<StoreSuccessResponse> => {
    try {
      const url = `/api/v1/store`;
      const response = await this.axiosInstance.get(url);
      if (response.data) {
        return {
          storeConfig: {
            ...response.data,
          },
        };
      } else {
        throw new Error('Unable to fetch the store information .');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  plans = async (): Promise<PlansSuccessResponse> => {
    try {
      const url = `/api/v1/plans`;
      const response = await this.axiosInstance.get<PlansSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch plans');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  loans = async (params?: any) => {
    try {
      const url = `/api/v1/pplus/loans`;
      const response = await this.axiosInstance.get<LoansSuccessResponse>(url, {
        params,
      });
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to fetch loans');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  plansStatistics = async (): Promise<PlansStatsSuccessResponse> => {
    try {
      const url = `/api/v1/plans/statistics`;
      const response = await this.axiosInstance.get(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch plans statistics');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  updatedPlans = async (action: PlanAction, planId: string): Promise<PlansSuccessResponse> => {
    try {
      const url = `/api/v1/plans/${planId}`;
      const response = await this.axiosInstance.put<PlansSuccessResponse>(url, {
        action,
      });
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to activate the plans');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  activateLoan = async (
    action: LoanAction,
    loanId: string
  ): Promise<LoanDetailsSuccessResponse> => {
    try {
      const url = `/api/v1/pplus/loans/${loanId}/activate`;
      const response = await this.axiosInstance.post<LoanDetailsSuccessResponse>(url, {
        action,
      });
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to activate the loan');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  exportPlans = async (
    planStatus: string,
    clientTimeZone: string,
    filterSelectedDropDown: {
      staffMember?: string;
      timeFrames?: string;
    }
  ): Promise<ExportPlanSuccessResponse> => {
    try {
      let filterUrl;

      if (planStatus.length > 0) {
        filterUrl = `status=${planStatus}&timeZone=${clientTimeZone}`;
      }

      if (filterSelectedDropDown.timeFrames) {
        const settledDate = filterSelectedDropDown.timeFrames;
        filterUrl = filterUrl + `&settledDate=${settledDate}`;
      }
      if (filterSelectedDropDown.staffMember) {
        const staffMember = filterSelectedDropDown.staffMember;
        filterUrl = filterUrl + `&staffMember=${staffMember}`;
      }

      const url = `api/v1/plans/export?${filterUrl}`;

      const response = await this.axiosInstance.get(url, {
        responseType: 'blob',
        timeout: 30000,
        headers: {
          'Content-Type': 'text/csv',
        },
      });
      if (response.data) {
        return {
          exportCSVURL: window.URL.createObjectURL(new Blob([response.data])),
        };
      } else {
        throw new Error('Unable to activate the plans');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  planDetails = async (planId: string): Promise<PlansDetailSuccessResponse> => {
    try {
      const url = `/api/v1/plans/${planId}`;
      const response = await this.axiosInstance.get<PlansDetailSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch plan detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  loanDetails = async (loanId: string): Promise<LoanDetailsSuccessResponse> => {
    try {
      const url = `/api/v1/pplus/loans/${loanId}`;
      const response = await this.axiosInstance.get<LoanDetailsSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch plan detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getPlanStatus = async (planId: string): Promise<PlanStatus> => {
    try {
      const url = `/api/v1/plans/${planId}`;
      const response = await this.axiosInstance.get(url);
      return response.data.status;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  staff = async (): Promise<StaffSuccessResponse> => {
    try {
      const url = `/api/v1/staff`;
      const response = await this.axiosInstance.get<StaffSuccessResponse>(url);

      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch staff list');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  staffCreation = async (newStaffInfo: any): Promise<StaffCreationSuccessResponse> => {
    try {
      const url = `/api/v1/staff`;
      const response = await this.axiosInstance.post<StaffCreationSuccessResponse>(
        url,
        newStaffInfo
      );
      if (response.data.id) {
        return {
          id: response.data.id,
        };
      } else {
        throw new Error('Unable to create Staff.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  staffDetails = async (contactId: string): Promise<StaffDetailSuccessResponse> => {
    try {
      const url = `/api/v1/staff/${contactId}`;
      const response = await this.axiosInstance.get<StaffDetailSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch staff detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  updateStaff = async (
    contactId: string,
    updatedStaffInfo: any
  ): Promise<StaffUpdateSuccessResponse> => {
    try {
      const url = `/api/v1/staff/${contactId}`;
      const response = await this.axiosInstance.put<StaffUpdateSuccessResponse>(
        url,
        updatedStaffInfo
      );
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to update staff detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  myProfileUpdate = async (updatedProfileInfo: any): Promise<ProfileUpdateSuccessResponse> => {
    try {
      const url = `/api/v1/user`;
      const response = await this.axiosInstance.put<ProfileUpdateSuccessResponse>(
        url,
        updatedProfileInfo
      );
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to update the profile');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  myProfileDetails = async (): Promise<ProfileDetailSuccessResponse> => {
    try {
      const url = `/api/v1/user`;
      const response = await this.axiosInstance.get<ProfileDetailSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch the profile detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  resetPassword = async (tempAndNewpassword: {
    password: string;
    temporaryPassword: string;
  }): Promise<ResetPasswordSuccess> => {
    try {
      const url = `/api/v1/user/reset-password`;
      const response = await this.axiosInstance.post<ResetPasswordSuccess>(url, tempAndNewpassword);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to update the password');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getPlanCustomer = async (planId: string): Promise<CustomerResourceBasic> => {
    try {
      const url = `/api/v1/plans/${planId}/customer`;
      const response = await this.axiosInstance.get<CustomerResourceBasic>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch customer detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getLoanCustomer = async (loanId: string): Promise<CustomerResourceBasic> => {
    try {
      const url = `/api/v1/pplus/loans/${loanId}/customer`;
      const response = await this.axiosInstance.get<CustomerResourceBasic>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch customer detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  requestVerificationCode = async (
    customerId: string
  ): Promise<{
    requestId: string;
    phoneNumberHint: string;
  }> => {
    try {
      const url = `/api/v1/customers/${customerId}/request-verification-code`;
      const response = await this.axiosInstance.post(url);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to request verification code');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  confirmVerificationCode = async (
    customerId: string,
    requestId: string,
    code: string
  ): Promise<boolean> => {
    try {
      const url = `/api/v1/customers/${customerId}/confirm-verification-code`;
      await this.axiosInstance.post(url, { requestId, code });
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        throw new Error(
          'The verification code that was entered does not match with the code that was sent to the customer'
        );
      }
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getCustomer = async (customerId: string, requestId: string): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/customers/${customerId}/${requestId}`;
      const response = await this.axiosInstance.get<CustomerResource>(url);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to fetch customer detail');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  createCheckout = async (checkoutParams: CreateCheckoutParams): Promise<CheckoutResource> => {
    try {
      const url = `/api/v1/checkouts`;
      const response = await this.axiosInstance.post<CheckoutResource>(url, checkoutParams);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to create new sale.');
      }
    } catch (error: any) {
      throw error;
    }
  };

  updateCheckout = async (
    checkoutId: string,
    checkoutParams: CreateCheckoutParams
  ): Promise<CheckoutUpdateSuccessResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}`;
      const response = await this.axiosInstance.put<CheckoutUpdateSuccessResponse>(
        url,
        checkoutParams
      );

      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update the checkout.');
      }
    } catch (error: any) {
      throw error;
    }
  };

  customerCreation = async (
    customerDetailsDataSet: CustomerResource
  ): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/customers`;
      const response = await this.axiosInstance.post<CustomerResource>(url, customerDetailsDataSet);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to create customer record.');
      }
    } catch (error: any) {
      throw error;
    }
  };

  customerSearch = async (
    customerDetails: Required<Pick<CustomerDetailsData, 'firstName' | 'lastName' | 'dateOfBirth'>> &
      Partial<Pick<CustomerDetailsData, 'email' | 'phone'>>,
    productLine: ProductLine,
    loanAmount: number | null
  ): Promise<CustomerSearchResponse> => {
    try {
      const url = `/api/v1/customer-search`;
      const response = await this.axiosInstance.get<CustomerSearchResponse>(url, {
        params: {
          firstName: customerDetails.firstName,
          lastName: customerDetails.lastName,
          dateOfBirth: customerDetails.dateOfBirth,
          email: customerDetails.email,
          phoneMobile: customerDetails.phone,
          productLine,
          loanAmount,
        },
      });

      return response.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  customerToCheckout = async (
    customerId: string,
    checkoutId: string,
    applicationCompletedBy: ApplicationCompletedBy
  ): Promise<CheckoutResource> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId} `;
      const response = await this.axiosInstance.post<CheckoutResource>(url, {
        applicationCompletedBy: applicationCompletedBy,
      });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          `${error.response.status} - ${error.response.data.status}: ${error.response.data.message}`
        );
      }

      throw new Error(error.message);
    }
  };

  getCurrentCheckout = async (checkoutId: string): Promise<CurrentCheckoutResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/`;
      const response = await this.axiosInstance.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  getCheckoutCustomer = async (checkoutId: string): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer`;
      const response = await this.axiosInstance.get<CustomerResource>(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  sendApplicationLink = async (checkoutId: string): Promise<void> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/send-application-link`;
      await this.axiosInstance.post(url);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  sendVerificationCode = async (checkoutId: string): Promise<void> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/send-verification-code`;
      await this.axiosInstance.post(url);
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          `${error.response.status} - ${error.response.data.status}: ${error.response.data.message}`
        );
      }

      throw new Error(error.message);
    }
  };

  getPaymentMethodsList = async (customerId: string): Promise<PaymentMethodsResponse> => {
    try {
      const url = `/api/v1/customers/${customerId}/payment-methods`;
      const response = await this.axiosInstance.get<PaymentMethodsResponse>(url);
      return response.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getPaymentGatewayDetails = async (
    customerId: string,
    checkoutId: string
  ): Promise<PaymentGatewayDetails> => {
    try {
      const url = `/api/v1/customers/${customerId}/payment-gateway/${checkoutId}`;
      const response = await this.axiosInstance.get<PaymentGatewayDetails>(url);
      return response.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  /// ADDING A CARD FOR CLOUD PAYMENTS
  addCardCloudPayments = async (cloudCheckRequest: cloudCheckRequestDTO): Promise<any> => {
    try {
      const url = `/api/v1/payment-methods/Cloud_Payment`;
      await this.axiosInstance.post<any>(url, cloudCheckRequest);
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  makePayment = async (paymentDataSet: MakePaymentData): Promise<MakePaymentResponse> => {
    try {
      const url = `/api/v1/payments`;

      const response = await this.axiosInstance.post(url, paymentDataSet);
      if (response.data.status) {
        return response.data;
      } else {
        throw new Error('Unable to make payment');
      }
    } catch (error: any) {
      throw error;
    }
  };

  updateCustomer = async (
    customerId: string,
    customerResource: CustomerResource
  ): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/customers/${customerId}`;

      const response = await this.axiosInstance.put<CustomerResource>(url, customerResource);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update customer');
      }
    } catch (error: any) {
      throw error;
    }
  };

  updateCustomerPaymentDetails = async (
    customerId: string,
    updateCustomerDataSet: UpdateCustomerData
  ): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/customers/${customerId}`;

      const response = await this.axiosInstance.put(url, updateCustomerDataSet);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update customer');
      }
    } catch (error: any) {
      throw error;
    }
  };

  makeCardActive = async (
    customerId: string,
    paymentMethodId: string
  ): Promise<PaymentMethodsResponse> => {
    try {
      const url = `/api/v1/payment-methods/${paymentMethodId}`;
      const paymentMethodData = {
        customerId: customerId,
      };

      const response = await this.axiosInstance.put(url, paymentMethodData);

      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to make payment');
      }
    } catch (error: any) {
      throw error;
    }
  };
  verifyPlanSubmission = async (
    planId: string,
    verificationCode: string
  ): Promise<VerifyPlanResponse> => {
    try {
      const url = `/api/v1/plans/${planId}/verify/${verificationCode}/`;
      const response = await this.axiosInstance.get(url);
      return response.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  addCard = async (paymentMethodData: AddCardData): Promise<PaymentMethodsResponse> => {
    try {
      const url = `/api/v1/payment-methods/Eway`; // TODO get paymentMethod from component

      const response = await this.axiosInstance.post(url, paymentMethodData);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to make payment');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  planApproval = async (planId: string, customerId: string): Promise<ApprovalResponse> => {
    try {
      const url = `api/v1/plan-approval`;
      const response = await this.axiosInstance.post(url, {
        planId: planId,
        customerId: customerId,
      });
      return {
        approvalStatus: response.data.planStatus,
      };
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  runPlanPreApprovalCheck = async (
    checkoutId: string,
    isCheckoutUpdated: boolean,
    isExistingCustomer: boolean
  ): Promise<PreApprovalData> => {
    try {
      // TODO: Need to change API to use checkout ID instead of customer id and plan ID
      const url = `api/v1/checkouts/${checkoutId}/run-pre-approval?isCheckoutUpdated=${isCheckoutUpdated}&isExistingCustomer=${isExistingCustomer}`;
      const response = await this.axiosInstance.get(url);

      return response.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  acceptPreApproval = async (
    checkoutId: string,
    depositAmount: number,
    depositPercentage: number
  ): Promise<AcceptPreApprovaldata> => {
    try {
      // TODO: Need to change API to use checkout ID instead of customer id and plan ID
      const url = `api/v1/checkouts/${checkoutId}/accept-pre-approval`;
      const response = await this.axiosInstance.patch(url, {
        depositAmount,
        depositPercentage,
      });

      return response.data;
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  forgotPassword = async (
    userCredentials: any
  ): Promise<ForgotPasswordRequestedSuccessResponse> => {
    try {
      const url = `/api/v1/user/forgot-password`;
      const response = await this.axiosInstance.post<ForgotPasswordRequestedSuccessResponse>(
        url,
        userCredentials
      );
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to send the temporary password');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  ping = async (): Promise<number> => {
    try {
      const url = `/api/v1/ping`;
      const response = await this.axiosInstance.get<{ id: string }>(url);
      return response.status;
    } catch (error: any) {
      if (error.isAxiosError) {
        return error.response.status;
      }
      throw error;
    }
  };

  createCheckoutLinks = async (checkoutParams: CreateCheckoutParams): Promise<any> => {
    try {
      const url = `/api/v1/checkouts`;
      const response = await this.axiosInstance.post(url, checkoutParams);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to create new sale.');
      }
    } catch (error: any) {
      throw error;
    }
  };

  updatePaymentStatus = (paymentStatus: string) => {};

  createOrUpdateMobileCheckout = async (
    payload: MobileCheckoutData
  ): Promise<MobileCheckoutData> => {
    try {
      const url = `/api/v1/checkouts/mobile`;
      const response = await this.axiosInstance.post<MobileCheckoutData>(url, payload);

      if (response.data.planId) {
        return response.data;
      } else {
        throw new Error('Unable to create mobile checkout.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };
  createCheckoutDataForLegacyPlans = async (
    planId: string | null
  ): Promise<LegacyPlansCheckoutData> => {
    try {
      const url = `/api/v1/checkouts/legacy-plan`;
      const response = await this.axiosInstance.post<LegacyPlansCheckoutData>(url, {
        planId: planId,
      });

      if (response.data.checkoutId) {
        return response.data;
      } else {
        throw new Error('Unable to create a checkout.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  updateMerchantDDOAcceptanceInfo = async (): Promise<DDODetailsResponse> => {
    try {
      const url = `/api/v1/merchant/accept-ddo`;
      const response = await this.axiosInstance.put<DDODetailsResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to save the merchant acceptance date');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getMerchantDDODetails = async (): Promise<DDODetailsResponse> => {
    try {
      const url = `/api/v1/merchant/ddo-details`;
      const response = await this.axiosInstance.get<DDODetailsResponse>(url);
      if (response) {
        return response.data;
      } else {
        throw new Error("Unable to fetch merchant's ddo detail");
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  cancelCheckout = async (checkoutId: string): Promise<boolean> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/cancel`;
      const response = await this.axiosInstance.put<boolean>(url);
      if (response) {
        return response.data;
      } else {
        throw new Error('Unable to cancel plan');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  // PPlus utils
  payrightPlusRates = async (): Promise<PayrightPlusRatesSuccessResponse> => {
    try {
      const url = `/api/v1/pplus/rates`;
      const response = await this.axiosInstance.get<PayrightPlusRatesSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch the rates.');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  createPplusCheckout = async (
    checkoutParams: CreatePplusCheckoutParams
  ): Promise<PplusCheckoutResource> => {
    try {
      const url = `/api/v1/pplus/checkouts`;
      const response = await this.axiosInstance.post<PplusCheckoutResource>(url, checkoutParams);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to create new loan.');
      }
    } catch (error: any) {
      throw error;
    }
  };
  getCurrentPplusCheckout = async (checkoutId: string): Promise<PplusCheckoutResource> => {
    try {
      const url = `/api/v1/pplus/checkouts/${checkoutId}/`;
      const response = await this.axiosInstance.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  getPplusCheckoutCustomer = async (checkoutId: string): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/pplus/checkouts/${checkoutId}/customer`;
      const response = await this.axiosInstance.get<CustomerResource>(url);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  customerToPplusCheckout = async (
    customerId: string,
    checkoutId: string,
    isNewCustomer: boolean
  ): Promise<PplusCheckoutResource> => {
    try {
      const url = `/api/v1/pplus/checkouts/${checkoutId}/customer/${customerId} `;
      const response = await this.axiosInstance.post<PplusCheckoutResource>(url, {
        isNewCustomer: isNewCustomer,
      });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          `${error.response.status} - ${error.response.data.status}: ${error.response.data.message}`
        );
      }

      throw new Error(error.message);
    }
  };

  sendPplusApplicationLink = async (checkoutId: string): Promise<void> => {
    try {
      const url = `/api/v1/pplus/checkouts/${checkoutId}/send-application-link`;
      await this.axiosInstance.post(url);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  sendPplusVerificationCode = async (checkoutId: string): Promise<void> => {
    try {
      const url = `/api/v1/pplus/checkouts/${checkoutId}/send-verification-code`;
      await this.axiosInstance.post(url);
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          `${error.response.status} - ${error.response.data.status}: ${error.response.data.message}`
        );
      }

      throw new Error(error.message);
    }
  };

  resendPplusVerificationCode = async (checkoutId: string): Promise<void> => {
    try {
      const url = `/api/v1/pplus/loans/${checkoutId}/resend-verification-code`;
      await this.axiosInstance.post(url);
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          `${error.response.status} - ${error.response.data.status}: ${error.response.data.message}`
        );
      }

      throw new Error(error.message);
    }
  };
  // End of PPlus related utils
}

export default new MerchantAPI();
