import React, { useEffect } from 'react';
import LoggedInLayout from '../../layouts/logged-in-layout';
import SCFeedback from './css';

const Feedback = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1XI3dS4EDnPHbgSMsjHaBlebTvj6WEyY63Avsn2km3B.js';
    script.async = true;

    document.getElementById('smcx-sdk')?.appendChild(script);

    return () => {
      try {
        document.getElementById('smcx-sdk')?.removeChild(script);
      } catch {}
    };
  }, []);

  return (
    <SCFeedback>
      <LoggedInLayout activePage="/feedback">
        <div className="feedback-body">
          <div className="title"></div>
          <div className="header">
            <h2>Feedback</h2>
          </div>
          <div className="feedback-wrapper-body">
            <h4>We would like to get your feedback!</h4>
            <div id="smcx-sdk"></div>
          </div>
        </div>
      </LoggedInLayout>
    </SCFeedback>
  );
};

export default Feedback;
