import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  IconTickAlt,
  ImageWithIcon,
  PlanStatus,
  MessageBox,
  ModalWrapper,
  ModalGeneric,
} from '@payright/web-components';
import { useHistory, Link } from 'react-router-dom';
import planResultHappyFace from '../../assets/images/plan-result-happy-face.png';
import { useSelector, useDispatch } from '@merchant-portal/util/store';
import {
  fetchPlanDetailsFromMobileCheckout,
  updateMobileCheckout,
} from '@merchant-portal/slices/plans';
import Loader from '@merchant-portal/components/loader';
import getConstants from '@merchant-portal/util/constants';
import Spinner from '@merchant-portal/components/spinner';
import { showHideItemsAndMenu } from '@merchant-portal/util/helper';

const {
  SUCCESSFUL_PLAN_ACTIVATION,
  REQUEST_FAILURE,
  PLAN_ACTIVATION_MODAL_TITLE,
  PLAN_ACTIVATION_CONFIRMATION,
} = getConstants();

interface CompleteProps {
  resetHandler: Function;
}

const Complete = ({ resetHandler }: CompleteProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { preApproval, mobileCheckoutResult } = useSelector((state) => state.mobileCheckout);
  const { errorMessage, planDetails, loading, submitSucceeded } = useSelector(
    (state) => state.plans
  );

  const planId = preApproval?.id;
  const [showPlanActivationModal, setShowPlanActivationModal] = useState(false);

  let showMessage;
  const { accessItems } = showHideItemsAndMenu();

  useEffect(() => {
    if (planId) {
      dispatch(fetchPlanDetailsFromMobileCheckout(planId));
    }
  }, [dispatch, planId, preApproval]);

  // Method to close Message Modal and redirect
  const handleCloseMessageModal = () => {
    setShowPlanActivationModal(false);
    if (errorMessage === null) history.push('/plans');
  };

  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={SUCCESSFUL_PLAN_ACTIVATION}
        message=""
        handleOnClick={handleCloseMessageModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={REQUEST_FAILURE}
        message=""
        handleOnClick={handleCloseMessageModal}
        error={true}
      />
    );
  }
  return (
    <>
      <div className="parent-container">
        <div className="purchase-child-container">
          <div className="image-div">
            <ImageWithIcon
              image={planResultHappyFace}
              icon={<IconTickAlt />}
              iconBackgroundColour="#5cbe8f"
            />
          </div>
          <div className="purchase-text">
            <div className="purchase-complete-title">Purchase Complete</div>
            <div className="purchase-complete-message">
              Once the customer has their purchase or your service has been complete, you can
              activate the plan.
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '10px' }}>
          <ModalWrapper
            open={showPlanActivationModal}
            handleClose={() => setShowPlanActivationModal(false)}
            background="dark"
          >
            {!submitSucceeded && !errorMessage ? (
              <ModalGeneric
                title={PLAN_ACTIVATION_MODAL_TITLE}
                text={PLAN_ACTIVATION_CONFIRMATION}
                primaryButton={
                  <Button
                    handleClick={() => {
                      planId && dispatch(updateMobileCheckout('activate', planId));
                    }}
                    icon={loading ? <Spinner /> : undefined}
                    disabled={loading}
                  >
                    Activate
                  </Button>
                }
                secondaryButton={
                  <Button
                    outline
                    colour="blue"
                    handleClick={() => setShowPlanActivationModal(false)}
                  >
                    Cancel
                  </Button>
                }
              ></ModalGeneric>
            ) : (
              <div style={{ textAlign: 'center' }}>{showMessage}</div>
            )}
          </ModalWrapper>
        </div>
        {loading || !planId ? (
          <Loader />
        ) : (
          <>
            <div className="plan-summary-child-container">
              <Card>
                <div className="plan-summary-wrapper">
                  <div className="plan-summary plan-summary-heading">Plan Summary</div>
                  <div className="plan-summary plan-result-heading">
                    <PlanStatus status={planDetails.status.toLowerCase()} />
                  </div>
                  <hr />

                  <div className="plan-summary sale-amount-title">Sale amount</div>
                  <div className="plan-summary sale-amount-value">
                    ${parseFloat(planDetails.saleAmount).toFixed(2)}
                  </div>

                  <div className="plan-summary deposit-amount-title">Deposit amount</div>
                  <div className="plan-summary deposit-amount-value">
                    ${parseFloat(planDetails.depositPaid).toFixed(2)}{' '}
                    <span>{planDetails.depositPaidPercentage}%</span>
                  </div>

                  <div className="plan-summary amount-paid-store-title">Amount paid up front</div>
                  <div className="plan-summary amount-paid-store-value">
                    $
                    {mobileCheckoutResult.amountPaidInStore
                      ? mobileCheckoutResult.amountPaidInStore.toFixed(2)
                      : 0}
                  </div>
                </div>

                <div className="view-plan">
                  <hr />
                  <Link to={`/plan-detail/${mobileCheckoutResult.planId}`}>View Plan</Link>
                </div>
              </Card>
              {!planDetails.planBlockActivation &&
                planDetails.status === 'Approved' &&
                (accessItems.activatePlans || accessItems.administrator) && (
                  <div className="div-button">
                    <Button
                      type="submit"
                      colour="blue"
                      size="medium"
                      handleClick={() => setShowPlanActivationModal(true)}
                    >
                      {' '}
                      Activate Plan{' '}
                    </Button>
                  </div>
                )}
              <div className="div-button">
                <Button
                  type="submit"
                  outline
                  colour="blue"
                  size="medium"
                  handleClick={() => resetHandler()}
                >
                  {' '}
                  Activate Later{' '}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Complete;
