import React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../slices';

const SCCustomerDetailsMinified = styled.div`
  .customerdetailsmini-row {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .notice-for-customers {
    line-height: 1.8;

    h5 {
      margin: 0.5em 0;
    }
  }
  background-color: ${(props) => props.theme.colours.secondary3};
  padding: 2em 1.66em;
  margin-bottom: 1em;

  label {
    font-size: 16.95px;
    font-weight: 700;
    color: ${(props) => props.theme.colours.plum.base};
    flex: 1;
    padding: 5px;
  }
  span {
    padding: 5px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: left;
    ${media.max.medium} {
      .hide-mobile {
        display: none;
      }
    }
    h4 {
      color: #531dff;
    }
  }

  ///

  ${media.max.large} {
    .customerdetailsmini-row {
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .item-1 {
      height: 40px;
      margin-bottom: 10px;
      width: 48%;
      display: flex;
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
    }
    .item-2 {
      height: 40px;
      margin-bottom: 10px;
      width: 48%;
      display: flex;
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
    }
  }

  ${media.max.tablet && media.max.small} {
    .customerdetailsmini-row {
      display: flex;
      flex-direction: column;
    }
    .item-1 {
      height: 40px;
      width: 98%;
      display: flex;
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
    }
    .item-2 {
      height: 40px;
      width: 98%;
      display: flex;
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
    }
    span {
      text-align: right;
    }
  }
`;

const SCNotice = styled.div`
  background-color: ${(props) => props.theme.colours.blue.bg};
  border-radius: 5px;

  padding: 2em 1.66em;
  margin-bottom: 1em;

  h5,
  div,
  span,
  p {
    color: ${(props) => props.theme.colours.blue.base};
  }

  .notice-for-customers {
    line-height: 1.8;

    h5 {
      margin: 0.5em 0;
    }
  }
`;

const CustomerDetailsMini = () => {
  const currentCustomer = useSelector((state: RootState) => state.customer.customerDetails);
  const CustomerName = `${currentCustomer.title} ${currentCustomer.firstName} ${currentCustomer.middleName} ${currentCustomer.lastName}`;
  const customerDob = new Date(currentCustomer.dateOfBirth).toLocaleString('en-AU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return (
    <>
      <SCCustomerDetailsMinified>
        <div className="heading">
          <h4>Customer Details</h4>
          {/* <ButtonRounded colour="blue">
          Edit <span className="hide-mobile">&nbsp;details</span>
        </ButtonRounded> */}
        </div>

        <div className="customerdetailsmini-row">
          <div className="item-1">
            <label>Name:</label>
            <span>{CustomerName}</span>
          </div>
          <div className="item-2">
            <label>Email:</label>
            <span>{currentCustomer.email}</span>
          </div>
        </div>
        <div className="customerdetailsmini-row">
          <div className="item-1">
            <label>Mobile No:</label>
            <span>{currentCustomer.phone}</span>
          </div>
          <div className="item-2">
            <label>Date of Birth:</label>
            <span>{customerDob}</span>
          </div>
        </div>
        <br />
      </SCCustomerDetailsMinified>
      <SCNotice>
        <div className="notice-for-customers">
          <h5>Important:</h5>
          The <span className="bold">Payments Summary</span> and{' '}
          <span className="bold">Customer Disclosure and Consent</span> sections below contain
          important information for the customer. Before submitting the application, you must read
          these sections aloud to the customer and confirm their understanding.
        </div>
      </SCNotice>
    </>
  );
};
export default CustomerDetailsMini;
