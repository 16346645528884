import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { AppThunk } from '../util/store';
import { getCustomerByCheckoutId } from './customer';
import MerchantAPI, {
  CheckoutResource,
  CheckoutSuccessResponse,
  CurrentCheckoutResponse,
  PlansDetailSuccessResponse,
  ApprovalResponse as ApprovalPayload,
  PlanStatus,
  LegacyPlansCheckoutData,
} from '../util/merchant-api';
import { loading, loadingFunctionInterface } from '../util/loadingFunctionInterface';
import { PaymentCalculatorData } from '@payright/web-components';
import { PreApprovalData, ApplicationCompletedBy } from '@merchant-portal/types/plan';
import { NextStep } from '@merchant-portal/types/checkout';
import getConstants from '../util/constants';
const { CONTACT_US_PHONE_NUMBER } = getConstants();

type InitialState = {
  loading?: boolean;
  activeRequests: Array<checkoutRequestId>;
  loadingValidationApproval?: boolean;
  hasErrors?: boolean;
  paymentDetails?: PaymentCalculatorData;
  paymentDetailsEdit?: boolean;
  errorMessage?: string | null;
  checkout?: CheckoutSuccessResponse | null;
  checkoutValidationStatus: boolean;
  hasErrorsUpdateCheckout?: boolean;
};

export type CheckOutRequest = {
  attributes: {
    saleAmount: number;
    terms: number | string;
    depositPaid?: number | string | null;
    depositPaidPercentage: number | string;
    paymentPeriod: number | string;
    minimumDepositPercentage?: number | null;
  };
  merchantReference?: string;
};

export type AcceptDepositBumpingSuccessResponse = {
  nextSteps: Array<string>;
};

export const initialState: InitialState = {
  loading: false,
  activeRequests: [],
  loadingValidationApproval: false,
  hasErrors: false,
  paymentDetails: {
    saleAmount: 0,
    depositPercent: 10,
    paymentFrequency: 'Fortnightly',
    paymentPeriod: 12,
    establishmentFee: 0,
  },
  paymentDetailsEdit: false,
  errorMessage: null,
  checkout: null,
  checkoutValidationStatus: false,
};

type checkoutRequestId =
  | 'createCheckout'
  | 'attachCustomer'
  | 'updateCheckout'
  | 'sendApplicationLink'
  | 'sendVerificationCode'
  | 'getCurrentCheckoutSession'
  | 'getCheckoutValidation'
  | 'getPlanApproval'
  | 'getPlanOutcome'
  | 'runPlanPreApproval'
  | 'acceptDepositBumping'
  | 'updatePlanStatus';

const startLoading: loadingFunctionInterface<checkoutRequestId> = (
  activeRequests: Array<checkoutRequestId>,
  requestId: checkoutRequestId
): loading<checkoutRequestId> => {
  activeRequests = [...activeRequests, requestId];
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const finishLoading: loadingFunctionInterface<checkoutRequestId> = (
  activeRequests: Array<checkoutRequestId>,
  requestId: checkoutRequestId
): loading<checkoutRequestId> => {
  activeRequests = activeRequests.filter((item) => item !== requestId);
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetCheckout: () => initialState,
    updateSaleDetails(
      state,
      action: PayloadAction<{
        paymentDetails: PaymentCalculatorData;
      }>
    ) {
      return {
        ...state,
        paymentDetails: action.payload.paymentDetails,
      };
    },
    createCheckoutBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    createCheckoutSuccess(
      state,
      action: PayloadAction<{
        checkout: CheckoutResource;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: action.payload.checkout,
        hasErrorsUpdateCheckout: false,
      };
    },
    createCheckoutFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
        hasErrorsUpdateCheckout: false,
      };
    },
    createCheckoutForLegacyPlansSuccess: (state) => {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        submitSucceeded: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    createCheckoutForLegacyPlansFailure: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    attachCustomerBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    attachCustomerSuccess(
      state,
      action: PayloadAction<{
        checkout: CheckoutResource;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: action.payload.checkout,
      };
    },
    attachCustomerFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    updateCheckoutBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'updateCheckout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    updateCheckoutSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'updateCheckout');
      return {
        ...state,
        paymentDetailsEdit: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrorsUpdateCheckout: false,
      };
    },
    updateCheckoutFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'updateCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
        hasErrorsUpdateCheckout: true,
      };
    },
    sendApplicationLinkBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'sendApplicationLink');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendApplicationLinkSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'sendApplicationLink');
      return {
        ...state,
        paymentDetailsEdit: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrorsUpdateCheckout: false,
      };
    },
    sendApplicationLinkFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'sendApplicationLink');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
        hasErrorsUpdateCheckout: true,
      };
    },
    sendVerificationCodeBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendVerificationCodeSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendVerificationCodeFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCurrentCheckoutSessionBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getCurrentCheckoutSession');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getCurrentCheckoutSessionSuccess(state, action: PayloadAction<CurrentCheckoutResponse>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckoutSession');
      const { payload } = action;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          ...payload,
        },
        paymentDetails: payload.attributes.paymentDetails,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrorsUpdateCheckout: false,
      };
    },
    getCurrentCheckoutSessionFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckoutSession');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCheckoutValidationBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getCheckoutValidation');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loadingValidationApproval: true,
      };
    },
    getCheckoutValidationSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'getCheckoutValidation');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loadingValidationApproval: false,
      };
    },
    getCheckoutValidationFailure(state, action: PayloadAction<string>) {
      const loadingState = finishLoading(state.activeRequests, 'getCheckoutValidation');
      return {
        ...state,
        hasErrors: true,
        errorMessage: action.payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loadingValidationApproval: false,
      };
    },
    getPlanApprovalBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getPlanApproval');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loadingValidationApproval: true,
      };
    },
    getPlanApprovalSuccess(state, action: PayloadAction<ApprovalPayload>) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanApproval');
      return {
        ...state,
        checkout: {
          ...state.checkout,
          approvalStatus: action.payload.approvalStatus,
        },
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loadingValidationApproval: false,
      };
    },
    getPlanApprovalFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanApproval');
      return {
        ...state,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        loadingValidationApproval: false,
      };
    },
    getPlanOutcomeBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getPlanOutcome');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    getPlanOutcomeSuccess(state, action: PayloadAction<PlansDetailSuccessResponse>) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanOutcome');
      return {
        ...state,
        checkout: {
          ...state.checkout,
          approvalStatus: action.payload.status,
          customerNumber: action.payload.customerNumber,
          planNumber: action.payload.name,
        },
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    getPlanOutcomeFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanOutcome');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    runPlanPreApprovalBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'runPlanPreApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    runPlanPreApprovalSuccess: (state, action: PayloadAction<PreApprovalData>) => {
      const loadingState = finishLoading(state.activeRequests, 'runPlanPreApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        checkout: {
          ...state.checkout,
          nextSteps: action.payload.nextSteps,
          planPreApprovalDetails: action.payload,
        },
      };
    },
    runPlanPreApprovalFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'runPlanPreApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    acceptDepositBumpingBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'acceptDepositBumping');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    acceptDepositBumpingSuccess: (
      state,
      action: PayloadAction<AcceptDepositBumpingSuccessResponse>
    ) => {
      const loadingState = finishLoading(state.activeRequests, 'acceptDepositBumping');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        checkout: {
          ...state.checkout,
          nextSteps: action.payload.nextSteps,
        },
      };
    },
    acceptDepositBumpingFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'acceptDepositBumping');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    updatePlanStatusBegin: (state) => {
      const loadingState = startLoading(state.activeRequests, 'updatePlanStatus');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    updatePlanStatusSuccess: (state) => {
      const loadingState = finishLoading(state.activeRequests, 'updatePlanStatus');
      return {
        ...state,
        submitSucceeded: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    updatePlanStatusFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'updatePlanStatus');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

/// exporting the checkout slice actions
export const { resetCheckout, updateSaleDetails } = checkoutSlice.actions;

const {
  createCheckoutBegin,
  createCheckoutSuccess,
  createCheckoutFailure,
  updateCheckoutBegin,
  updateCheckoutSuccess,
  updateCheckoutFailure,
  getCurrentCheckoutSessionBegin,
  getCurrentCheckoutSessionSuccess,
  getCurrentCheckoutSessionFailure,
  getCheckoutValidationBegin,
  getCheckoutValidationSuccess,
  getCheckoutValidationFailure,
  getPlanApprovalBegin,
  getPlanApprovalSuccess,
  getPlanApprovalFailure,
  getPlanOutcomeBegin,
  getPlanOutcomeSuccess,
  getPlanOutcomeFailure,
  runPlanPreApprovalBegin,
  runPlanPreApprovalSuccess,
  runPlanPreApprovalFailure,
  acceptDepositBumpingBegin,
  acceptDepositBumpingSuccess,
  acceptDepositBumpingFailure,
  attachCustomerBegin,
  attachCustomerSuccess,
  attachCustomerFailure,
  updatePlanStatusBegin,
  updatePlanStatusSuccess,
  updatePlanStatusFailure,
  sendApplicationLinkBegin,
  sendApplicationLinkSuccess,
  sendApplicationLinkFailure,
  sendVerificationCodeBegin,
  sendVerificationCodeSuccess,
  sendVerificationCodeFailure,
  createCheckoutForLegacyPlansSuccess,
  createCheckoutForLegacyPlansFailure,
} = checkoutSlice.actions;

/// exporting the checkout slice reducers
export default checkoutSlice.reducer;

// -------------------------
// - Thunks - HANDLING THE CHECKOUT CREATION
// -------------------------
export const createCheckout =
  ({
    paymentDetails,
    merchantReference,
    successCallback,
  }: {
    paymentDetails: PaymentCalculatorData;
    merchantReference?: string;
    successCallback?: (checkoutId: string) => void;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCheckoutBegin());

      const paymentFrequency = paymentDetails.paymentFrequency;

      const createCheckoutParams = {
        attributes: {
          saleAmount: paymentDetails.saleAmount,
          terms: paymentFrequency,
          depositPaidPercentage: paymentDetails.depositPercent,
          depositPayable: paymentDetails.depositAmount,
          paymentPeriod: paymentDetails.paymentPeriod,
          paymentDetails: paymentDetails,
        },
        merchantReference: merchantReference,
      };

      const checkout: CheckoutResource = await MerchantAPI.createCheckout(createCheckoutParams);

      dispatch(updateSaleDetails({ paymentDetails: paymentDetails }));

      dispatch(
        createCheckoutSuccess({
          checkout: checkout,
        })
      );
      if (successCallback && checkout.id) {
        successCallback(checkout.id);
      }
    } catch (err: any) {
      dispatch(createCheckoutFailure({ errorMessage: err.message }));
    }
  };

// -------------------------
// - Thunks - UPDATE CHECKOUT
// -------------------------
export const updateCheckout =
  ({
    paymentDetails,
    checkoutIdentifier,
  }: {
    paymentDetails: PaymentCalculatorData;
    checkoutIdentifier: string;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateCheckoutBegin());

      const paymentFrequency = paymentDetails.paymentFrequency;

      const updateCheckoutParams = {
        attributes: {
          saleAmount: paymentDetails.saleAmount,
          terms: paymentFrequency,
          depositPaidPercentage: paymentDetails.depositPercent,
          depositPayable: paymentDetails.depositAmount,
          paymentPeriod: paymentDetails.paymentPeriod,
          paymentDetails: paymentDetails,
        },
      };

      await MerchantAPI.updateCheckout(checkoutIdentifier, updateCheckoutParams);
      dispatch(updateSaleDetails({ paymentDetails: paymentDetails }));
      dispatch(updateCheckoutSuccess());
      dispatch(fetchCheckoutAttributes(checkoutIdentifier));
    } catch (err: any) {
      dispatch(updateCheckoutFailure({ errorMessage: err.message }));
    }
  };

// -------------------------
// - Thunks - FETCHING THE CHECKOUT ATTRIBUTES TO RE-INTIALISE THE CHECKOUT STATE ON PAGE REFRESH
// -------------------------
export const fetchCheckoutAttributes =
  (checkoutId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCurrentCheckoutSessionBegin());
      const currentCheckout: CurrentCheckoutResponse = await MerchantAPI.getCurrentCheckout(
        checkoutId
      );

      if (currentCheckout.customerId !== null) {
        await dispatch(getCustomerByCheckoutId(currentCheckout.id));
      }

      dispatch(getCurrentCheckoutSessionSuccess(currentCheckout));
    } catch (error: any) {
      dispatch(getCurrentCheckoutSessionFailure(error.message));
    }
  };

/// METHOD TRIGGER ON VALIDATION
export const checkoutValidateApproval =
  (planId: string, customerId: string, validationCode: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCheckoutValidationBegin());
      const currentCheckoutVerification = await MerchantAPI.verifyPlanSubmission(
        planId,
        validationCode
      );

      LogRocket.track('Verifying plan validation code');

      if (currentCheckoutVerification.status) {
        /// successful validation
        dispatch(getCheckoutValidationSuccess());
        dispatch(getPlanApprovalBegin());

        try {
          const planApproval = await MerchantAPI.planApproval(planId, customerId);
          dispatch(getPlanApprovalSuccess(planApproval));
          LogRocket.track('Plan approval complete with status ' + planApproval.approvalStatus);
        } catch (error) {
          // If planApproval fails due to timeout error or 409 error, poll the server to retrieve the latest plan status until
          // it has changed to something other than "Pending". Then run the usual flow

          LogRocket.track('Plan approval HTTP response error');

          const getPlanStatus = () => MerchantAPI.getPlanStatus(planId);

          const retryMultipleTimes = (
            getPlanStatus: () => Promise<PlanStatus>,
            retries = 6,
            delay = 5000
          ) =>
            new Promise<PlanStatus>((resolve, reject) => {
              getPlanStatus()
                .then((planStatus) => {
                  if (planStatus !== 'Pending') {
                    resolve(planStatus);
                    return;
                  } else {
                    throw new Error('Plan status is still in Pending state');
                  }
                })
                .catch((error) => {
                  setTimeout(() => {
                    if (retries === 0) {
                      reject(error);
                      return;
                    }

                    // Retry
                    retryMultipleTimes(getPlanStatus, retries - 1, delay).then(resolve, reject);
                  }, delay);
                });
            });

          retryMultipleTimes(getPlanStatus)
            .then((result) => {
              dispatch(getPlanApprovalSuccess({ approvalStatus: result }));
              LogRocket.track('Plan approval complete after retry with status ' + result);
            })
            .catch(() => {
              LogRocket.track('Plan approval did not complete after numerous retries');
              dispatch(
                getPlanApprovalFailure({
                  errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance on ${CONTACT_US_PHONE_NUMBER}.`,
                })
              );
            });
        }
      } else {
        dispatch(getCheckoutValidationFailure('Recheck the validation code'));
        LogRocket.track('Validation code check failed');
      }
    } catch (error: any) {
      dispatch(getCheckoutValidationFailure(error.message));
    }
  };

// METHOD TO FETCH THE PLAN OUTCOME
export const fetchPlanOutcome =
  (plandId: string): AppThunk =>
  async (dispatch) => {
    dispatch(getPlanOutcomeBegin());
    try {
      const planOutcome: PlansDetailSuccessResponse = await MerchantAPI.planDetails(plandId);
      dispatch(getPlanOutcomeSuccess(planOutcome));
    } catch (error: any) {
      dispatch(getPlanOutcomeFailure(error.message));
    }
  };

export const runPlanPreApprovalCheck =
  ({
    checkoutId,
    isCheckoutUpdated,
    isExistingCustomer,
    onSuccessHandleRedirection,
  }: {
    checkoutId: string;
    isCheckoutUpdated: boolean;
    isExistingCustomer: boolean;
    onSuccessHandleRedirection: (
      nextSteps: Array<NextStep>,
      depositPercentage: number,
      depositAmount: number
    ) => void;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(runPlanPreApprovalBegin());
    try {
      const response = await MerchantAPI.runPlanPreApprovalCheck(
        checkoutId,
        isCheckoutUpdated,
        isExistingCustomer
      );
      dispatch(runPlanPreApprovalSuccess(response));

      onSuccessHandleRedirection(
        response.nextSteps,
        response.approvedDepositPercentage,
        response.approvedDepositAmount
      );
    } catch (error: any) {
      dispatch(runPlanPreApprovalFailure(error.message));
    }
  };

export const acceptDepositBumping =
  ({
    checkoutId,
    depositAmount,
    depositPercentage,
    onSuccessHandleRedirection,
  }: {
    checkoutId: string;
    depositAmount: number;
    depositPercentage: number;
    onSuccessHandleRedirection: (nextSteps: Array<NextStep>) => void;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(acceptDepositBumpingBegin());
    try {
      const response = await MerchantAPI.acceptPreApproval(
        checkoutId,
        depositAmount,
        depositPercentage
      );
      dispatch(acceptDepositBumpingSuccess(response));
      onSuccessHandleRedirection(response.nextSteps);
    } catch (error: any) {
      dispatch(acceptDepositBumpingFailure(error.message));
    }
  };

// Attaches a customer id to a checkout. This results in a plan being created
export const attachCustomer =
  (
    checkoutId: string,
    customerId: string,
    applicationCompletedBy: ApplicationCompletedBy
  ): AppThunk<Promise<string | null>> =>
  async (dispatch, getState) => {
    const checkout = getState().checkout?.checkout || null;

    if (checkout?.planId !== null && checkout?.customerId !== null) {
      console.log('Skip attaching');
      return checkout?.planId as string;
    }

    dispatch(attachCustomerBegin());
    LogRocket.track('Attaching Customer');

    try {
      const result = await MerchantAPI.customerToCheckout(
        customerId,
        checkoutId,
        applicationCompletedBy
      );

      dispatch(attachCustomerSuccess({ checkout: result }));
      LogRocket.track('Attach Customer Successful');

      console.log('Attached: ', result);
      return result.planId;
    } catch (err: any) {
      dispatch(attachCustomerFailure({ errorMessage: err.message }));
      LogRocket.track(`Attaching Customer Failed - Error: ${err.message}`);
      throw new Error(err);
    }
  };

// Sends a link to the customer to collect payment details (when Application Completer = No)
export const sendLinkToCustomer =
  (planId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updatePlanStatusBegin());
      await MerchantAPI.updatedPlans('send-link-to-customer', planId);
      dispatch(updatePlanStatusSuccess());
    } catch (e: any) {
      dispatch(updatePlanStatusFailure({ errorMessage: e.message }));
    }
  };

export const createCheckoutForLegacyPlans =
  (planId: string | null): AppThunk<Promise<string>> =>
  async (dispatch) => {
    try {
      dispatch(createCheckoutBegin());
      const response: LegacyPlansCheckoutData = await MerchantAPI.createCheckoutDataForLegacyPlans(
        planId
      );
      dispatch(createCheckoutForLegacyPlansSuccess());
      if (response.checkoutId) {
        dispatch(fetchCheckoutAttributes(response.checkoutId));
      }
      return response.checkoutId;
    } catch (e: any) {
      const errorMessage = `An unexpected error occured while trying to update a checkout. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}`;
      dispatch(
        createCheckoutForLegacyPlansFailure({
          errorMessage: errorMessage,
        })
      );
      throw new Error(errorMessage);
    }
  };

// Customer led scenario - send application link to customer
export const sendApplicationLink =
  (checkoutId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(sendApplicationLinkBegin());
      await MerchantAPI.sendApplicationLink(checkoutId);
      dispatch(sendApplicationLinkSuccess());
    } catch (e: any) {
      dispatch(sendApplicationLinkFailure({ errorMessage: e.message }));
    }
  };

// Send verification code sms
export const sendVerificationCode =
  (checkoutId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(sendVerificationCodeBegin());
      await MerchantAPI.sendVerificationCode(checkoutId);
      dispatch(sendVerificationCodeSuccess());
    } catch (e: any) {
      dispatch(sendVerificationCodeFailure({ errorMessage: e.message }));
    }
  };

// Attaches a customer id to a checkout. This results in a plan being created
export const createCheckoutLinks =
  ({
    paymentDetails,
    merchantReference,
    showAllAvailableTerms,
    quickLinkType,
    successCallback,
  }: {
    paymentDetails: any;
    merchantReference?: string;
    showAllAvailableTerms?: boolean;
    quickLinkType?: string;
    successCallback?: (checkoutId: string) => void;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(createCheckoutBegin());

    try {
      const createCheckoutParams = {
        attributes: {
          saleAmount: paymentDetails.saleAmount,
          terms: paymentDetails.paymentFrequency,
          paymentPeriod: paymentDetails.paymentPeriod,
          type: paymentDetails.type,
          depositPaidPercentage: '',
          paymentDetails: paymentDetails,
          expiresAt: '',
          redirectUrl: '',
          showAllAvailableTerms: showAllAvailableTerms,
          quickLinkType: quickLinkType,
        },
        merchantReference: merchantReference,
        applicationCompletedBy: paymentDetails.applicationCompletedBy,
      };

      const checkout: CheckoutResource = await MerchantAPI.createCheckout(createCheckoutParams);
      dispatch(updateSaleDetails({ paymentDetails: paymentDetails }));

      dispatch(
        createCheckoutSuccess({
          checkout: checkout,
        })
      );
      if (successCallback && checkout.id) {
        successCallback(checkout.id);
      }
    } catch (err: any) {
      dispatch(createCheckoutFailure({ errorMessage: err.message }));
      throw new Error(err);
    }
  };
