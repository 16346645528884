import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Button,
  IconTickAlt,
  ImageWithIcon,
  HeaderMerchant,
  media,
} from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import planResultHappyFace from '../../../assets/images/plan-result-happy-face.png';
import CenterContentLayout from '../../../layouts/center-content-layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../../slices';
import Loader from '../../../components/loader';
import getConstants from '../../../util/constants';

const { SUCCESSFUL_PLAN_SENT_TO_CUSTOMER } = getConstants();

const SCPlanResult = styled.div`
  .icon,
  .header,
  .message,
  .action {
    margin: 2em 0;
    text-align: center;
  }

  ${media.min.large} {
    .icon {
      margin-top: 6em;
    }
  }

  .header {
    margin: 2em 0;

    &__title {
      max-width: 500px;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      font-size: 1.2em;

      h4 {
        color: ${(props) => props.theme.colours.blue.base};
      }
    }
  }

  p {
    margin: auto;
    font-size: 1.2em;
    max-width: 500px;
    width: 500px;
    align-items: center;
    justify-content: center;
  }

  .actions {
    display: flex;
    padding-bottom: 30px;
    margin: auto;
    font-size: 1em;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 200px;
  }
`;

const renderPlanResultMessage = ({
  icon,
  header,
  message,
  action,
}: {
  icon: React.ReactNode;
  header: string;
  message: React.ReactNode;
  action: React.ReactNode;
}) => (
  <SCPlanResult>
    {icon && <div className="icon">{icon}</div>}
    {header && (
      <div className="header">
        <div className="header__title">
          <h4>{header}</h4>
        </div>
      </div>
    )}
    {message && <div className="message">{message}</div>}
    {action && <div className="actions">{action}</div>}
  </SCPlanResult>
);

const PlanSentToCustomer = () => {
  const checkout = useSelector((state: RootState) => state.checkout);
  const planLoading = useSelector((state: RootState) => state.plans.loading);
  const { loading } = checkout;

  const history = useHistory();

  const mapPlanOutCome = () => {
    const planResult = {
      ...planResultMessage,
    };
    return renderPlanResultMessage(planResult);
  };

  const planResultMessage = {
    icon: <ImageWithIcon image={planResultHappyFace} icon={<IconTickAlt />} />,
    header: 'Plan has been sent to customer',
    message: (
      <>
        <p>{SUCCESSFUL_PLAN_SENT_TO_CUSTOMER}</p>
      </>
    ),
    action: (
      <>
        <Button handleClick={() => history.push(`/plans`)}>View all Plans</Button>
      </>
    ),
  };

  return (
    <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
      <HeaderMerchant
        title="New Plan"
        activeStep={3}
        setActiveStep={(index: number) => console.log('Active step clicked: ' + index)}
      />
      {planLoading ? (
        <Loader />
      ) : (
        <>
          <CenterContentLayout>
            {loading ? <Loader text="Loading Plan Outcome.." /> : mapPlanOutCome()}
          </CenterContentLayout>
        </>
      )}
    </LoggedInLayout>
  );
};

export default PlanSentToCustomer;
