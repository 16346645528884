import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoggedInLayout from '../../layouts/logged-in-layout';
import { IconAdd, MerchantStaffTable, Button, Pagination, Search } from '@payright/web-components';
import { displayStaff } from '../../slices/staff';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';
import { paging } from '../../util/paging';
import { SCMerchantStaff, SCMerchantNoStaff } from './css';
import Loader from '../../components/loader';
import { showHideItemsAndMenu } from '../../util/helper';
import getConstants from '../../util/constants';

const { RECORDS_PER_PAGE, LOAD_PER_PAGE, STAFF_NOT_FOUND } = getConstants();

const staffHeaders = [
  { name: 'Staff Name', id: 'fullName', sortable: false },
  { name: 'Mobile No.', id: 'phone', sortable: false },
  { name: 'Email Address', id: 'email', sortable: false },
  { name: 'Status', id: 'staffStatus', sortable: false },
  { name: 'Actions', id: 'view', sortable: false },
];

const Staff = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [staffPerPage, setStaffPerPage] = useState(RECORDS_PER_PAGE);
  const [loadPerPage, setLoadPerPage] = useState(LOAD_PER_PAGE);
  const { merchantStaff, loading } = useSelector((state: RootState) => state.staff);
  const { accessItems } = showHideItemsAndMenu();
  let paginationComponent;

  const showAction = !accessItems.administrator ? 'viewProfile' : 'updateProfile';

  useEffect(() => {
    dispatch(displayStaff());
  }, [dispatch]);

  const convertMerchantStaffToArray = Object.values(merchantStaff);

  // Method to get the plan list, if searchitem will be null then it will return all plans
  const staffList = returnSearchResult(convertMerchantStaffToArray, searchItem);

  // Create new object as per web-component table requirment
  const restructuredStaff = staffList.map((staff, index) => {
    return {
      ID: index,
      fullName: staff.fullName,
      phone: staff.phoneMobile !== '' ? staff.phoneMobile : staff.phoneWork,
      email: staff.email,
      staffStatus: staff.contactStatus.toLowerCase(),
      [showAction]: `/staff/update/${staff.id}`,
    };
  });

  // show the plan list with paging
  const showStaff = paging(currentPage, staffPerPage, restructuredStaff);

  // handle the search box
  const handleSearchOnChange = (searchValue: string) => {
    setSearchItem(searchValue);
  };

  // set the current page number
  const handlePageChange = (selectedPage: any) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  // set the total number of plans on page
  const handleLoadMore = () => {
    setLoadPerPage(loadPerPage);
    setStaffPerPage(staffPerPage + loadPerPage);
  };

  // Set the pagination component
  if (showStaff.totalPage > 1) {
    paginationComponent = (
      <Pagination
        pageCount={showStaff.totalPage}
        handlePageChange={handlePageChange}
        handleLoadMore={handleLoadMore}
      />
    );
  }

  return (
    <SCMerchantStaff>
      <LoggedInLayout activePage="/staff">
        <div className="staff-list-body">
          <div className="header">
            <h2>Staff</h2>
            {accessItems.administrator && (
              <div className="actions">
                <Button
                  withShadow
                  icon={<IconAdd />}
                  handleClick={() => {
                    history.push('/staff/new');
                  }}
                  maxWidth={'100%'}
                >
                  Add Staff Member
                </Button>
              </div>
            )}
          </div>
          <div className="searchHeader">
            <Search placeholder="Search Staff" inputChangeHandler={handleSearchOnChange} />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {staffList.length > 0 ? (
                [
                  <MerchantStaffTable
                    tableHeaders={staffHeaders}
                    tableRows={showStaff.recordsPerPage}
                    tableSelect={false}
                  />,
                  <div className="pageingBottom">{paginationComponent}</div>,
                ]
              ) : (
                <SCMerchantNoStaff>{STAFF_NOT_FOUND}</SCMerchantNoStaff>
              )}
            </>
          )}
        </div>
      </LoggedInLayout>
    </SCMerchantStaff>
  );
};

/** Function to return the plan list */
const returnSearchResult = (
  staffList: Array<{
    id: string;
    fullName: string;
    phoneMobile: string;
    phoneWork: string;
    email: string;
    contactStatus: string;
  }>,
  searchItem: string
) => {
  if (searchItem !== '') {
    return staffList.filter((staff) => {
      return (
        staff.fullName.toLowerCase().includes(searchItem.toLowerCase()) ||
        staff.phoneMobile.toLowerCase().includes(searchItem.toLowerCase()) ||
        staff.email.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
  } else {
    return staffList;
  }
};

export default Staff;
