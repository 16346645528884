// This file contains helper functions to perform various types of checks.
// Such as 'empty object', get 'typeof' and more.

// Object Checks

// Example JSON.parse('{}')
export function isEmptyObject(obj: Object) {
  let name;
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      // object is not empty
      return false;
    }
  }
  // object is empty
  return true;
}

// TypeOf Checks (https://www.typescriptlang.org/docs/handbook/basic-types.html)

// Example: obj = 21
export function isNumber(obj: any) {
  return typeof obj === 'number';
}

// Example obj = 'hello world'
export function isString(obj: any) {
  return typeof obj === 'string';
}

// Example obj = false | true
export function isBoolean(obj: any) {
  return typeof obj === 'boolean';
}

// Example obj = undeclaredVariable
export function isUndefined(obj: any) {
  return typeof obj === 'undefined';
}
