import React, { useState } from 'react';
import styled from 'styled-components';
import {
  PayrightPlusCalculatorData,
  IconNext,
  PayrightPlusCalculator,
  media,
  Button,
  ModalWrapper,
  ModalGeneric,
} from '@payright/web-components';
import { useDispatch, useSelector } from 'react-redux';
import { SCModalMerchantDDO } from '../css';
import Loader from '@merchant-portal/components/loader';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { RootState } from '../../../slices';
import { getMerchantDDODetails } from '@merchant-portal/slices/merchant';
import { initNewLoan, updateSaleDetails } from '../../../slices/pplus-checkout';
import { AppDispatch } from '@merchant-portal/util/store';
import PplusFeeDetails from '../../../components/pplus-fee-details';
import getConstants from '../../../util/constants';
import { isEmptyObject, isUndefined } from '../../../util/helper/checks';
import { showHideItemsAndMenu } from '../../../util/helper';
import { FeatureFlags, useFeatures } from '@merchant-portal/providers/features-provider';
import MerchantDDOConsent from '@merchant-portal/pages/merchant-ddo-consent';
import { useStoryblok } from '@merchant-portal/providers/storyblok-provider';
import SCSpinner from '@merchant-portal/components/spinner';

const { PAYMENT_FREQUENCIES } = getConstants();

const SCErrorMessage = styled.p`
  margin: 1.5em 0 1em;
  color: red;
  line-height: 1.8;
  font-size: 0.94rem;
  font-weight: bold;

  ${media.max.tablet} {
    font-size: 0.86em;
  }
`;

const PayrightPlus = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const formState = useSelector((state: RootState) => state.payrightPlus);
  const loggedInUser = useSelector((state: RootState) => {
    return state.auth;
  });
  const [showDDOModal, setShowDDOModal] = useState(false);
  const { DDOLoading } = useSelector((state: RootState) => state.merchant);

  const { ddoBlockLoanCreationMessage } = useStoryblok();

  const { flagsConfig } = useFeatures();
  const canCreateCheckoutAndPlan = flagsConfig[FeatureFlags.CHECKOUT_AND_PLAN_CREATION];

  const { accessItems } = showHideItemsAndMenu();

  const [isValid, setIsValid] = useState(false);

  const userHasPermissionsToCreateLoan = accessItems.createLoan;

  type FormData = {
    paymentDetails: PayrightPlusCalculatorData;
    merchantRef?: string;
  };

  const initializeNewLoan = (formData: FormData) => {
    // Ensure checkout is reset, we do not create checkout
    // for purchase-details form pre-fill from loan calculator
    dispatch(initNewLoan());
    // Prepare payload for sending pre-fill purchase-details
    dispatch(
      updateSaleDetails({
        paymentDetails: formData.paymentDetails,
      })
    );
    // Redirect user over to purchase-details with pre-filled form fields
    history.push(`/loans/new/purchase-details`);
  };

  const newLoanSubmitHandler = (formData: FormData) => {
    //show popup if a merchant's ddo details are blank, and if the end date has passed
    dispatch(getMerchantDDODetails())
      .then((ddoDetails) => {
        if (ddoDetails.shouldBlockLoanCreation) {
          setShowDDOModal(true);
        } else {
          setShowDDOModal(false);
          initializeNewLoan(formData);
        }
      })
      .catch((errorMessage: string) => {
        console.log(errorMessage);
      });
  };
  /* End Form Event Handlers */

  const reactHookForm = useForm<FormData>({
    defaultValues: {
      paymentDetails: {
        ...formState.paymentDetails,
        paymentPeriod: loggedInUser?.storeConfig?.defaultTerm,
      },
    },
  });

  // Close the modal pop up and set the DDO Consent is false
  const handleCloseModal = () => {
    setShowDDOModal(false);
  };

  const { handleSubmit, watch } = reactHookForm;
  const selectedPaymentFrequency = watch('paymentDetails').paymentFrequency;

  // Get 'merchant rates', and create local storage fallback. Lastly, empty object will be handled next.
  const merchantRates =
    JSON.parse(localStorage.getItem('payrightPlusRates') || '{}') || loggedInUser.payrightPlusRates;

  // We handle empty object here, advise user to 'reset login session'.
  const merchantRatesCheck = !isUndefined(merchantRates) && !isEmptyObject(merchantRates);
  const minRegulatedLoanAmount =
    loggedInUser?.storeConfig?.minRegulatedLoanAmount === 0
      ? 2001
      : loggedInUser?.storeConfig?.minRegulatedLoanAmount;
  const maxRegulatedLoanAmount =
    loggedInUser?.storeConfig?.maxRegulatedLoanAmount === 0
      ? undefined
      : loggedInUser?.storeConfig?.maxRegulatedLoanAmount;

  return (
    <div className="checkout">
      {/* DDO STUFF */}
      <div>
        <ModalWrapper
          open={showDDOModal}
          handleClose={() => setShowDDOModal(true)}
          background="dark"
        >
          <SCModalMerchantDDO>
            {DDOLoading ? (
              <Loader />
            ) : (
              <ModalGeneric
                text={ddoBlockLoanCreationMessage}
                content={
                  <MerchantDDOConsent
                    closeModalProp={handleCloseModal}
                    showConsent={false}
                    showConfirm={false}
                  />
                }
                className="merchantDDO"
              ></ModalGeneric>
            )}
          </SCModalMerchantDDO>
        </ModalWrapper>
      </div>
      {/* DDO STUFF Ends*/}
      <div className="checkout-heading">PayrightPlus</div>
      <div className="checkout-text">
        This calculator is for loans over ${minRegulatedLoanAmount}, and will include an interest
        rate (see calculator below).
      </div>
      <hr />

      {merchantRatesCheck && canCreateCheckoutAndPlan ? (
        <FormProvider {...reactHookForm}>
          <form onSubmit={handleSubmit(newLoanSubmitHandler)}>
            <Controller
              name="paymentDetails"
              rules={{
                validate: (paymentDetails) =>
                  paymentDetails.loanAmount !== 0 &&
                  paymentDetails.depositPercent !== 'N/A' &&
                  paymentDetails.paymentTerm !== 'N/A',
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PayrightPlusCalculator
                  type="merchant"
                  rates={merchantRates.rates}
                  paymentFrequencies={PAYMENT_FREQUENCIES}
                  establishmentFees={merchantRates.establishmentFees}
                  otherFees={{
                    ...merchantRates.otherFees,
                    interestRatePercentage: merchantRates.interestDetails.minInterestRatePercentage,
                  }}
                  updatePaymentDetails={onChange}
                  paymentDetails={value}
                  interestDetails={merchantRates.interestDetails}
                  minRegulatedLoanAmount={minRegulatedLoanAmount}
                  maxRegulatedLoanAmount={maxRegulatedLoanAmount}
                  onValidationChange={setIsValid}
                />
              )}
            />
            <PplusFeeDetails accountKeepingFee={merchantRates.otherFees.monthlyAccountKeepingFee} />
            {userHasPermissionsToCreateLoan && (
              <>
                <br />
                <div className="actions">
                  <Button
                    disabled={formState.loading || !isValid}
                    type="submit"
                    colour="primary"
                    icon={DDOLoading ? <SCSpinner /> : <IconNext />}
                    size="medium"
                    iconPosition="right"
                  >
                    Start Application
                  </Button>
                </div>
              </>
            )}
          </form>
        </FormProvider>
      ) : (
        <>
          {canCreateCheckoutAndPlan && (
            <SCErrorMessage>
              Error: Failed to load 'loan calculator'. Please reset your login session.
            </SCErrorMessage>
          )}
        </>
      )}
    </div>
  );
};

export default PayrightPlus;
