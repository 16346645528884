import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from './pages/login';
import Plans from './pages/plans/plans-list';
import Loans from './pages/loans/loans-list';
import Rates from './pages/rates';
import Staff from './pages/staff/staff';
import AddStaff from './pages/staff/add-staff';
import UpdateStaff from './pages/staff/update-staff';
import PlanDetail from './pages/plans/plan-detail';
import Dashboard from './pages/dashboard';
import Profile from './pages/profile';
import ForgotPassword from './pages/forgot-password';
import Logout from './pages/logout';
import SentLinkToCustomer from './pages/create-product/new-plan/sent-link-to-customer';
import Feedback from './pages/feedback';
import { useScrollToTop } from '@payright/web-components';
import CreatePayment from './pages/retail-tools/create-payment';
import {
  SaleDetails,
  CustomerDetails,
  Summary,
  Payment,
  PlanResult,
  PlanSentToCustomer,
  CloudPayments,
} from './pages/create-product/new-plan';
import ResumePlan from './pages/plans/resume';
import {
  LoanPurchaseDetails,
  LoanCustomerDetails,
  LoanApplicationSentToCustomer,
} from './pages/create-product/new-loan';
import MaintenancePage from '@merchant-portal/pages/maintenance';
import AccessDeniedPage from '@merchant-portal/pages/access-denied';
import LoanDetail from '@merchant-portal/pages/loans/loan-detail';
import { AuthRoute } from '@merchant-portal/util/guards';

const App = () => {
  useScrollToTop();

  return (
    <Switch>
      <Route path="/" exact component={LoginPage}></Route>
      <Route path="/dashboard" exact component={Dashboard}></Route>
      <Route path="/quick-links" exact component={CreatePayment}></Route>
      <AuthRoute guard={['viewPlans', 'createPlan']} path="/plans" exact component={Plans} />
      <AuthRoute guard={['viewLoans', 'createLoan']} path="/loans" exact component={Loans} />
      <AuthRoute
        guard={['viewPlans', 'createPlan']}
        path="/plan-detail/:id"
        component={PlanDetail}
      />
      <AuthRoute
        guard={['viewLoans', 'createLoan']}
        path="/loan-detail/:id"
        component={LoanDetail}
      />
      <AuthRoute guard="viewStaff" path="/staff" exact component={Staff} />
      <AuthRoute guard="addNewStaff" path="/staff/new" exact component={AddStaff} />
      <AuthRoute guard="viewStaff" path="/staff/update/:id" exact component={UpdateStaff} />
      <AuthRoute guard="viewRates" path="/rates" exact component={Rates} />
      <AuthRoute
        guard="createPlan"
        path="/plans/new"
        exact
        component={() => <Redirect to="/plans/new/sale-details" />}
      />
      <AuthRoute guard="createPlan" path="/plans/new/sale-details" component={SaleDetails} />
      <AuthRoute
        guard="createPlan"
        path="/plans/new/customer-details"
        component={CustomerDetails}
      />
      <AuthRoute guard="createPlan" path="/plans/new/summary" component={Summary} />
      <AuthRoute guard="createPlan" path="/plans/new/payment" component={Payment} />
      <AuthRoute
        guard="createPlan"
        path="/plans/new/plan-sent-to-customer"
        component={PlanSentToCustomer}
      />
      <AuthRoute guard="createPlan" path="/plans/new/plan-result/:planId" component={PlanResult} />
      <AuthRoute
        guard="createLoan"
        path="/loans/new"
        exact
        component={() => <Redirect to="/loans/new/purchase-details" />}
      />
      <AuthRoute
        guard="createLoan"
        path="/loans/new/purchase-details"
        component={LoanPurchaseDetails}
      />
      <AuthRoute
        guard="createLoan"
        path="/loans/new/customer-details"
        component={LoanCustomerDetails}
      />
      <AuthRoute
        guard="createLoan"
        path="/loans/new/application-sent-to-customer"
        component={LoanApplicationSentToCustomer}
      />
      <Route path="/profile" exact component={Profile} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <AuthRoute
        guard="createPlan"
        path="/plans/new/sent-link-to-customer"
        exact
        component={SentLinkToCustomer}
      />

      <Route path="/feedback" exact component={Feedback} />
      <Route path="/cloud_payments/:checkoutId" exact component={CloudPayments} />
      <AuthRoute guard="createPlan" path="/plans/resume" component={ResumePlan} />
      <Route path="/maintenance" component={MaintenancePage} />
      <Route path="/access-denied" exact component={AccessDeniedPage} />
      <Route path="*">Page not found</Route>
    </Switch>
  );
};

export default App;
