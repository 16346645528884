import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { PlansSuccessResponse } from '@merchant-portal/types/plan';

class PlansAPI {
  axiosInstance: AxiosInstance;

  constructor() {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_PLANS_API_URL,
      //timeout: 800000,
      headers: {
        common: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    this.axiosInstance = axios.create(axiosConfig);
  }

  setAuthToken = (authToken: string) => {
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  };

  plans = async (params?: any): Promise<PlansSuccessResponse> => {
    try {
      const url = `/api/v1/plans`;

      const response = await this.axiosInstance.get<PlansSuccessResponse>(url, {
        params,
      });

      if (response.data.plans) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch plans');
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };
}

export default new PlansAPI();
