import moment from 'moment';
import React from 'react';

/**
 * DEV NOTE!!!!
 *
 * There is a Region interface inside ./constants/index.ts which contains all
 * these modules.
 * IF YOU ADD, REMOVE OR MODIFY A MODULES NAME YOU MUST UPDATE THE INTERFACE!
 */

export const REGION_STATES = [
  { name: 'Victoria', value: 'VIC' },
  { name: 'New South Wales', value: 'NSW' },
  { name: 'Queensland', value: 'QLD' },
  { name: 'Australian Capital Territory', value: 'ACT' },
  { name: 'South Australia', value: 'SA' },
  { name: 'Northern Territory', value: 'NT' },
  { name: 'Western Australia', value: 'WA' },
  { name: 'Tasmania', value: 'TAS' },
];

export const RESIDENTIAL_STATUS = [
  { name: 'Boarding', value: 'Boarding' },
  { name: 'Employer Provided', value: 'Employer Provided' },
  { name: 'Living with Children/Other Family', value: 'Living with Children' },
  { name: 'Living with Parents (Rent Free)', value: 'Living with Family' },
  { name: 'Own with Mortgage', value: 'Own with Mortgage' },
  { name: 'Own without Mortgage', value: 'Own without Mortgage' },
  { name: 'Renting', value: 'Renting' },
  { name: 'Other', value: 'Other' },
];

export const TIME_IN_ADDRESS = [
  { name: 'Less than 6 months', value: 'Less than 6 mnths' },
  { name: '6 - 12 months', value: '6 12 mnths' },
  { name: '1 - 2 years', value: '1 2 years' },
  { name: '2 - 3 years', value: '2 3 years' },
  { name: '3 - 4 years', value: '3 4 years' },
  { name: '4 - 5 years', value: '4 5 years' },
  { name: 'Greater than 5 years', value: 'Greater than 5 years' },
];

export const CUSTOMER_TITLES = [
  { name: 'Mr.', value: 'Mr.' },
  { name: 'Ms.', value: 'Ms.' },
  { name: 'Mrs.', value: 'Mrs.' },
  { name: 'Miss', value: 'Miss' },
];

export const TIMEFRAMES = [
  { name: 'This month', value: 30 },
  { name: 'Last month', value: 60 },
  { name: '3 months', value: 90 },
  { name: '6 months', value: 180 },
];

export const TIME_IN_JOB = [
  { name: 'Less than 6 months', value: 'Less than 6 mnths' },
  { name: '6 - 12 months', value: '6 12 mnths' },
  { name: '1 - 2 years', value: '1 2 years' },
  { name: '2 - 3 years', value: '2 3 years' },
  { name: '3 - 4 years', value: '3 4 years' },
  { name: '4 - 5 years', value: '4 5 years' },
  { name: 'Greater than 5 years', value: 'Greater than 5 years' },
];

// Employment details

export const EMPLOYMENT_TYPES = [
  { name: 'Full Time Employment', value: 'Full time' },
  { name: 'Part Time Employment', value: 'Part Time' },
  { name: 'Casual Employment', value: 'Casual' },
  { name: 'Self Employed', value: 'Self Employed' },
  { name: 'Self Funded Retiree', value: 'Self Funded Retiree' },
  { name: 'Age Pension', value: 'Aged Pension' },
  { name: 'Carers Payment', value: 'Carers Pension' },
  { name: 'Disability Support Pension', value: 'Disability Pension' },
  { name: 'Student', value: 'Student' },
  { name: 'Other', value: 'Other' },
  { name: 'Unemployed', value: 'Unemployed' },
];

export const BUSINESS_NUMBER = 'ABN';
export const HELPDESK_EMAIL = 'admin@payright.com.au';
export const TELL_YOUR_CUSTOMER_LINK = 'https://payright.com.au';
export const PAYRIGHT_MERCHANT_V1 = 'https://payrightmerchant.payright.com.au/';
export const LABEL_STATE = 'State / Territory';
export const LABEL_SUBURB = 'Suburb / Town';

export const INCOME_FREQUENCIES = [
  { name: 'Weekly', value: 'weekly' },
  { name: 'Fortnightly', value: 'fortnightly' },
  { name: 'Monthly', value: 'monthly' },
];

export const MERCHANT_PLANS_TABLE_HEADERS = [
  { name: 'Plan ID / Ref', id: 'merchantPlanName', sortable: true },
  { name: 'Date Created', id: 'dateCreated', sortable: true },
  { name: 'Customer Name', id: 'customerName', sortable: true },
  { name: 'Sales Person', id: 'staffName', sortable: true },
  { name: 'Status', id: 'status', sortable: true },
  { name: '', id: 'actions', sortable: false },
  { name: '', id: 'depositStatus', sortable: false },
  { name: 'Actions', id: 'expandPlans', sortable: false },
];

export const MERCHANT_LOANS_TABLE_HEADERS = [
  { name: 'Loan ID / Ref', id: 'merchantPlanName', sortable: true },
  { name: 'Date Created', id: 'dateCreated', sortable: true },
  { name: 'Customer Name', id: 'customerName', sortable: true },
  { name: 'Sales Person', id: 'staffName', sortable: true },
  { name: 'Status', id: 'status', sortable: true },
  { name: '', id: 'actions', sortable: false },
  // Drop down arrow disabled for now since this would add another "column"
  { name: 'Actions', id: 'expandLoans', sortable: false },
];

export const PAYMENT_FREQUENCIES = [
  { name: 'Fortnightly', value: 'Fortnightly' },
  { name: 'Monthly', value: 'Monthly' },
];

export const PAYMENT_PERIODS = [
  { name: '2 months', value: 2 },
  { name: '3 months', value: 3 },
  { name: '6 months', value: 6 },
  { name: '9 months', value: 9 },
  { name: '12 months', value: 12 },
  { name: '17 months', value: 17 },
  { name: '18 months', value: 18 },
  { name: '24 months', value: 24 },
  { name: '30 months', value: 30 },
  { name: '36 months', value: 36 },
  { name: '48 months', value: 48 },
  { name: '60 months', value: 60 },
];

export const CUSTOMISE_LINK_TYPE = [
  { name: 'Select Type', value: '' },
  { name: 'Invoice', value: 'invoice' },
  { name: 'Quote', value: 'quote' },
];

export const DEPOSIT_PERCENTAGES = [
  { name: '10%', value: 10 },
  { name: '15%', value: 15 },
  { name: '20%', value: 20 },
  { name: '25%', value: 25 },
  { name: '30%', value: 30 },
];

export const MONTHLY_PAYMENT_DATE_SELECTION = [
  { value: `${moment().format('YYYY-MM-')}01`, name: '1st' },
  { value: `${moment().format('YYYY-MM-')}02`, name: '2nd' },
  { value: `${moment().format('YYYY-MM-')}03`, name: '3rd' },
  { value: `${moment().format('YYYY-MM-')}04`, name: '4th' },
  { value: `${moment().format('YYYY-MM-')}05`, name: '5th' },
  { value: `${moment().format('YYYY-MM-')}06`, name: '6th' },
  { value: `${moment().format('YYYY-MM-')}07`, name: '7th' },
  { value: `${moment().format('YYYY-MM-')}08`, name: '8th' },
  { value: `${moment().format('YYYY-MM-')}09`, name: '9th' },
  { value: `${moment().format('YYYY-MM-')}10`, name: '10th' },
  { value: `${moment().format('YYYY-MM-')}11`, name: '11th' },
  { value: `${moment().format('YYYY-MM-')}12`, name: '12th' },
  { value: `${moment().format('YYYY-MM-')}13`, name: '13th' },
  { value: `${moment().format('YYYY-MM-')}14`, name: '14th' },
  { value: `${moment().format('YYYY-MM-')}15`, name: '15th' },
  { value: `${moment().format('YYYY-MM-')}16`, name: '16th' },
  { value: `${moment().format('YYYY-MM-')}17`, name: '17th' },
  { value: `${moment().format('YYYY-MM-')}18`, name: '18th' },
  { value: `${moment().format('YYYY-MM-')}19`, name: '19th' },
  { value: `${moment().format('YYYY-MM-')}20`, name: '20th' },
  { value: `${moment().format('YYYY-MM-')}21`, name: '21st' },
  { value: `${moment().format('YYYY-MM-')}22`, name: '22nd' },
  { value: `${moment().format('YYYY-MM-')}23`, name: '23rd' },
  { value: `${moment().format('YYYY-MM-')}24`, name: '24th' },
  { value: `${moment().format('YYYY-MM-')}25`, name: '25th' },
  { value: `${moment().format('YYYY-MM-')}26`, name: '26th' },
  { value: `${moment().format('YYYY-MM-')}27`, name: '27th' },
  { value: `${moment().format('YYYY-MM-')}28`, name: '28th' },
];

// Validation
export const TITLE_VALIDATION_REGEX = /^[a-zA-Z ,.'-]+$/;
export const FIRSTNAME_VALIDATION_REGEX = /^[a-zA-Z ,.'-]+$/;
export const MIDDLENAME_VALIDATION_REGEX = /^[a-zA-Z ,.'-]+$/;
export const LASTNAME_VALIDATION_REGEX = /^[a-zA-Z ,.'-]+$/;
export const JOBTITLE_VALIDATION_REGEX = /^[a-zA-Z -]+$/;
export const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
export const PHONE_VALIDATION_REGEX =
  /^\(?(?:\+?61|0)4\)?(?:[ -]?\d){7}[0-9]$|^[01][1-35-9](?:[ -]?\d){7}[0-9]$|^[2-9](?:[ -]?\d){6}[0-9]$|^13(?:[ -]?\d){4}$|^1300(?:[ -]?\d){6}$|^180[1-9](?:[ -]?\d){4}$|^1800(?:[ -]?\d){6}$/;
export const MOBILE_VALIDATION_REGEX = /^\(?(?:\+?61|0)4\)?(?:[ -]?\d){7}[0-9]$/;
export const LICENCE_VALIDATION_REGEX = /^[0-9a-zA-Z]{4,9}$/;
export const LICENCE_CARD_NUMBER_VALIDATION_REGEX = /^[0-9a-zA-Z]{0,10}$/;
export const CHECKOUT_CODE_REGEX = /^[1-9A-NP-Z]{8}$/;

// Phone no validation text

export const MOBILE_NUMBER_FORMAT_VALIDATION = 'Please use format 0412 345 678';
export const PHONE_NUMBER_FORMAT_VALIDATION = 'Please enter a valid phone number';

//Mobile checkout validation
export const CHECKOUT_CODE_FORMAT_VALIDATION =
  'Checkout code is not valid. Please ensure it is at least 8 characters long.';

// Contact Us Phone no
export const CONTACT_US_PHONE_NUMBER_URL = '1300338496';
export const CONTACT_US_PHONE_NUMBER = '1300 338 496';

export const RECORDS_PER_PAGE = 10;
export const LOAD_PER_PAGE = 10;
export const PLAN_APPROVED_STATUS = 'approved';
export const LOAN_APPROVED_STATUS = 'approved';
export const PLAN_PENDING_STATUS = 'pending';
export const LOAN_PENDING_STATUS = 'pending';
export const PLAN_NOT_FOUND = 'No Plans Found';
export const LOAN_NOT_FOUND = 'No Loans Found';
export const PLAN_ACTIVATE = 'Activate';
export const LOAN_ACTIVATE = 'Activate';
export const RESEND_VERIFICATION_CODE = 'Resend verification';
export const RESEND_APPLICATION_LINK = 'Resend application link';
export const RESEND_CREDIT_SCHEDULE = 'Resend credit schedule';
export const STAFF_NOT_FOUND = 'Staff Not Found';
export const RATES_NOT_FOUND = 'Rates Not Found';
export const CONTACT_STATUS_WITH_COMPANY = 'Current';
export const CONTACT_STATUS_LEFT_COMPANY = 'Left Company';
export const PLAN_ACTIVATION_MODAL_TITLE = 'Plan Activation';
export const LOAN_ACTIVATION_MODAL_TITLE = 'Loan Activation';

/** Plan Status */

export const APPROVED_PENDING_ID = 'approved_pending_id';
export const READABLE_APPROVED_PENDING_ID = 'Approved Pending ID';

export const APPROVED_STATEMENT_REQUESTED = 'approved_statement_requested';
export const READABLE_APPROVED_STATEMENT_REQUESTED = 'Approved Statement Requested';

/** Options for Plan Filter */

export const PLAN_FILTER_OPTIONS = {
  Active: false,
  Approved: false,
  Pending: false,
  Declined: false,
  Closed: false,
  Cancelled: false,
  Review: false,
  [READABLE_APPROVED_PENDING_ID]: false,
  [READABLE_APPROVED_STATEMENT_REQUESTED]: false,
};

/** Password Status */

export const PASSWORD_STATUS = 'User Change Required';

/** Card Modes */

export const EXISTING_CARD = 'existing';
export const ADD_CARD = 'add';

/** Add/Subtract Years */

export const IDENTIFICATION_YEARS = 10;
export const DOB_YEARS = 100;

/** Staff User Name info text */

export const STAFF_USER_NAME_INFO_TEXT = 'This can be either a work or personal email address.';

/** Messages */

export const SUCCESSFUL_PLAN_ACTIVATION = 'Good News! Plan has been activated';
export const SUCCESSFUL_LOAN_ACTIVATION = 'Good News! Loan has been activated';
export const PLAN_ACTIVATION_CONFIRMATION =
  'IMPORTANT INFORMATION: Activation should not occur unless you have agreed on the terms and delivery of the purchase with the customer. Following activation, the customer will receive a welcome email and their payments will commence. Are you sure you want to activate this purchase?';
export const LOAN_ACTIVATION_CONFIRMATION =
  'IMPORTANT INFORMATION: Activation should not occur unless you have agreed on the terms and delivery of the purchase with the customer. Following activation, the customer will receive a welcome email and their payments will commence. Are you sure you want to activate this purchase?';
export const REQUEST_FAILURE = 'Oops..! Request failure';
export const SUCCESSFUL_STAFF_CREATION = 'A new staff member has been created';
export const SUCCESSFUL_STAFF_UPDATE = 'The profile has been successfully updated';
export const UNSUCCESSFUL_STAFF_UPDATE = 'Unable to update the details due to unexpected error';
export const UNSUCCESSFUL_UPDATE = 'Unable to update the password due to unexpected error';
export const PLAN_FILTER_MESSAGE =
  'These plans are currently filtered. To reset the filters and search, ';
export const LOAN_FILTER_MESSAGE =
  'These loans are currently filtered. To reset the filters and search, ';
export const SUCCESSFUL_NEW_PLAN_APPROVED_TITLE = 'This plan has been approved! One last step';
export const SUCCESSFUL_NEW_PLAN_APPROVED_BODY =
  'Please complete the customer’s payment details below to finalise their plan.';
export const SUCCESSFUL_RESEND_VERIFICATION_CODE =
  "The code has now been sent to the customer's nominated email and mobile number";
export const RESEND_VERIFICATION_CODE_CONFIRMATION =
  "Are you sure you want to resend the verification code to the customer's email and mobile number?";
export const SUCCESSFUL_RESEND_APPLICATION_LINK =
  "The application link has now been sent to the customer's nominated email and mobile number";
export const RESEND_APPLICATION_LINK_CONFIRMATION =
  "Are you sure you want to resend the application link to the customer's email and mobile number?";
export const SUCCESSFUL_RESEND_CREDIT_SCHEDULE =
  'The credit schedule has been sent to the customer';
export const RESEND_CREDIT_SCHEDULE_CONFIRMATION =
  "Are you sure you want to resend the credit schedule to the customer's email address?";

// Forgot Password / reset passowrd

export const PASSWORD_SUCCESSFUL_TITLE = 'Password reset successful';
export const PASSWORD_NEXT_LOGIN = 'Please use this new password on your next login';
export const SUCCESSFUL_FORGOT_PASSWORD_TITLE = 'Email & SMS has been sent!';
export const SUCCESSFUL_FORGOT_PASSWORD_MESSAGE =
  'A temporary password has been sent to your registered mobile number. Use that password with your user name to generate new password.';
export const FAILURE_FORGOT_PASSWORD_MESSAGE =
  'Invalid user name/email. Please try again or contact us at admin@payright.com.au';
export const MESSAGE_SEND_APPLICATION_LINK_TO_CUSTOMER =
  'A link will be sent to the customer to complete this application';
export const LABEL_SEND_TO_CUSTOMER_TO_COMPLETE = 'Send to Customer to complete';
export const LABEL_COMPLETER_ON_CUSTOMER_BEHALF = 'Complete on Customer Behalf';
export const SUCCESSFUL_PLAN_SENT_TO_CUSTOMER =
  "We have just sent the customer an SMS containing a link and 'Verification Code' to set up the payment details and pay deposit if required. Please ask them to check their phone now.";

/** New Plan Form */

export const CUSTOMER_TYPE_NEW_CUSTOMER_TITLE = 'New Payright Customer';
export const CUSTOMER_TYPE_NEW_CUSTOMER_DESC =
  'This person has never used Payright before and needs to be setup in the system';
export const CUSTOMER_TYPE_EXISTING_CUSTOMER_TITLE = 'Existing Payright Customer';
export const CUSTOMER_TYPE_EXISTING_CUSTOMER_DESC =
  'This person has used Payright before and has an existing account';
export const MERCHANT_MARKETING_CONSENT_TEXT =
  'Tick this box if the customer consents to receiving promotional materials from Payright.';
// TODO: Move to nz.ts when that is working
export const MERCHANT_MARKETING_CONSENT_TEXT_NZ =
  'Please untick if the customer does NOT wish to receive promotional emails and other electronic communications from Payright';

/** Cancel Plan Text */

export const CANCEL_POPUP_TITLE = 'Cancel the Application';
export const CANCEL_POPUP_TEXT = 'Are you sure you want to cancel this application ?';
export const CANCEL_POPUP_CANCEL_PLAN = 'Yes, I want to';
export const CANCEL_POPUP_ABORT_CANCEL = "No. I don't want to";

/** Side Panel Text For New Plan */

export const NEW_CUSTOMER_DETAIL =
  'Please ensure the customer’s name is entered exactly as it appears on the ID they are supplying for the application (including middle name).';
export const SALE_DETAIL =
  'Enter the total amount of the sale, followed by the deposit and payment details to calculate the repayment total at the bottom of the calculator.';
export const SUMMARY_DETAIL =
  'To make sure this application is processed as quickly as possible, please check all the details below are correct. You can edit any details you need to on this screen';
export const CUSTOMER_IDENTIFICATION = (
  <>
    <p>
      The ID supplied must be a current Australian Passport or an Australian Driver's Licence. The
      ID must not be expired.
    </p>
    <p>
      Recently-issued Australian Driver's Licences now have an additional 'Card Number' for security
      purposes.
    </p>
    <p>
      The 'Card Number' on your Driver's Licence must be entered as well as your Licence Number.
    </p>
    <p>
      Unsure?{' '}
      <a href="/images/au-driver-licence-user-guide.png" target="_blank">
        Click here for images.
      </a>
    </p>
  </>
);
export const MARKETING_CHECKBOX_TEXT =
  'Please uncheck if the customer does NOT wish to receive promotional emails & other electronic communications from Payright.';
export const PAYMENT_DETAIL_TEXT =
  'Please add the customer’s card they would like their deposit, fees and repayments to be charged to.';

/** ToolTip Text */

export const TOOLTIP_PLAN_DETAIL = 'View Plan Details';

/** Header Messages */

export const HEADER_MESSAGE_REVIEW = 'This application is being reviewed.';
export const HEADER_MESSAGE_DECLINED =
  'Unfortunately, the application can’t be approved at this time.';
export const HEADER_MESSAGE_PENDING = 'Your customer’s application has been conditionally approved';
export const HEADER_MESSAGE_REQUEST = 'Conditionally Approved - Bank Statements Required';
export const HEADER_MESSAGE_PAYMENT_PLAN = 'Payment plan has been arranged.';

/** Plan Result Messages */

export const APPROVED_PAID_MESSAGE =
  "Congratulations, this completes the application. Just a reminder the customer's repayments will not commence until the plan is activated. Thank you for choosing Payright.";
export const REVIEW_MESSAGE = (
  <>
    <p>
      We're reviewing your customer's application and may need them to confirm some details. If
      required, they'll receive an SMS with next steps.
    </p>
    <p>
      This process usually only takes a few minutes but may take up to 24 hours. There is no need to
      call us, rather please keep an eye on your plans tab for an application outcome (you may need
      to refresh your screen).
    </p>
  </>
);

export const DECLINED_MESSAGE =
  'Based on the information provided, this application is currently not eligible for a Payright plan. We’ve sent your customer an email to notify them.';
export const APPROVED_PENDING_ID_MESSAGE =
  'That’s odd, our system couldn’t verify your customer’s identity.';
export const APPROVED_PENDING_ID_MESSAGE_2 =
  'We may need you to physically verify your customers identity. If that happens, your customer will contact you.';
export const APPROVED_BANK_STATEMENTS_REQUIRED =
  'We require bank statement data to complete the approval of this application. We have sent the customer an SMS and an email with instructions on how to proceed. Once the data is received the final outcome will be advised.';

// PLAN LIST PAGE LIMIT
export const PLAN_LIST_PAGE_LIMIT = 10;

/** Sugar plan status for pre-approval */
export const SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL = 'Approve_Pre Approval';
/** Rename Pre approval status for web-componet */
export const RENAME_SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL = 'approved_pre_approval';
export const LESS_THAN_6_MONTHS = 'Less than 6 mnths';
export const UNSUCCESSFUL_DDO_UPDATE = 'Unable to update the DDO acceptance.';

/** Side Panel Text For New Loan */
export const LOAN_PURCHASE_DETAIL =
  "Enter the total purchase amount, repayment frequency and repayment period to calculate what your customer's repayment amount will be.";
export const APPLICATION_SENT_TO_CUSTOMER = 'Application has been sent to the customer';
export const SUCCESSFUL_LOAN_SENT_TO_CUSTOMER =
  "We have just sent your customer an email and SMS containing a link and 'Verification Code' to start their application.";
