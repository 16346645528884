import { combineReducers } from 'redux';
import authReducer from './auth';
import customerReducer from './customer';
import plansReducer from './plans';
import loansReducer from './loans';
import checkoutReducer from './checkout';
import staffReducer from './staff';
import paymentMethodReducer from './payment-method';
import paymentReducer from './payment';
import profileReducer from './profile';
import forgotPasswordReducer from './forgot-password';
import mobileCheckoutReducer from './mobile-checkout';
import merchantReducer from './merchant';
import payrightPlusReducer from './pplus-checkout';

export type RootState = ReturnType<typeof rootReducer>;

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  customer: customerReducer,
  plans: plansReducer,
  loans: loansReducer,
  checkout: checkoutReducer,
  staff: staffReducer,
  paymentMethod: paymentMethodReducer,
  payment: paymentReducer,
  forgotPassword: forgotPasswordReducer,
  mobileCheckout: mobileCheckoutReducer,
  merchant: merchantReducer,
  payrightPlus: payrightPlusReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logoutSuccess') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
