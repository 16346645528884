import React, { useEffect, useState } from 'react';
import { Button, Card, IconGreenCheck, IconActivateAlt, Price } from '@payright/web-components';
import { RootState } from '../../../slices';
import { displayPlansStatistics } from '../../../slices/plans';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@merchant-portal/components/loader';
import { showHideItemsAndMenu } from '@merchant-portal/util/helper';

const StatsCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state: RootState) => state.auth);
  const { stats, loading: planLoading } = useSelector((state: RootState) => state.plans);
  const { accessItems } = showHideItemsAndMenu();

  /* Begin Form / Button Event Handlers  */
  const redirectToPlans = (planStatus: string) => {
    history.push('/plans?search=' + planStatus);
  };

  useEffect(() => {
    dispatch(displayPlansStatistics());
  }, [loggedInUser, dispatch]);

  const dummyTotal = 'X',
    dummyTotalAmount = 'XXXX.XX';

  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      {accessItems.viewPlans && (
        <div className="stats-card">
          <Card>
            {planLoading ? (
              <Loader marginTop="0" />
            ) : (
              <>
                <div className="card-controls">
                  <label className="toggleSwitch large">
                    <input
                      type="checkbox"
                      onChange={(event) => setIsChecked(event.currentTarget.checked)}
                      checked={isChecked}
                    />
                    <span>
                      <span>Hide</span>
                      <span>Show</span>
                    </span>
                    <div className="circle"></div>
                  </label>
                </div>
                <div className="card-top">
                  <div className="stats-count">
                    <IconGreenCheck />
                    <h4>
                      <span className={isChecked ? 'hideText' : ''}>
                        {isChecked ? dummyTotal : stats.plans.approved} Approved
                      </span>
                    </h4>
                    {/* <p>Ready to Activate</p> */}
                  </div>
                  <div className="stats-divider"></div>
                  <div className="stats-sum">
                    <h4>
                      <span className={isChecked ? 'hideText' : ''}>
                        $
                        {isChecked ? (
                          dummyTotalAmount
                        ) : (
                          <Price value={stats.plans.approvedSumTotal} bold={true} />
                        )}
                      </span>
                    </h4>
                    <p>Total Value</p>
                  </div>
                </div>
                <div className="card-bottom">
                  <Button
                    handleClick={() => redirectToPlans('Approved')}
                    colour="blue"
                    className="card-bottom-button"
                    outline
                  >
                    View Plans
                  </Button>
                </div>
              </>
            )}
          </Card>
        </div>
      )}

      {accessItems.viewPlans && (
        <div className="stats-card">
          <Card>
            {planLoading ? (
              <Loader marginTop="0" />
            ) : (
              <>
                <div className="card-controls">
                  <label className="toggleSwitch large">
                    <input
                      type="checkbox"
                      onChange={(event) => setIsChecked(event.currentTarget.checked)}
                      checked={isChecked}
                    />
                    <span>
                      <span>Hide</span>
                      <span>Show</span>
                    </span>
                    <div className="circle"></div>
                  </label>
                </div>
                <div className="card-top">
                  <div className="stats-count">
                    <IconActivateAlt />
                    <h4>
                      <span className={isChecked ? 'hideText' : ''}>
                        {isChecked ? dummyTotal : stats.plans.pending} Incomplete
                      </span>
                    </h4>
                    <p>Pending</p>
                  </div>
                  <div className="stats-divider"></div>
                  <div className="stats-sum">
                    <h4>
                      <span className={isChecked ? 'hideText' : ''}>
                        $
                        {isChecked ? (
                          dummyTotalAmount
                        ) : (
                          <Price value={stats.plans.pendingSumTotal} bold={true} />
                        )}
                      </span>
                    </h4>
                    <p>Total Value</p>
                  </div>
                </div>
                <div className="card-bottom">
                  <Button
                    handleClick={() => redirectToPlans('Pending')}
                    colour="blue"
                    className="card-bottom-button"
                    outline
                  >
                    View plans
                  </Button>
                </div>
              </>
            )}
          </Card>
        </div>
      )}
    </>
  );
};

export default StatsCard;
