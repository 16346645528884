import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { addnewStaffInfo } from '../../slices/staff';
import SCAddStaff, { SCFilterCheckbox } from './css';
import { Button, IconChevron, MessageBox, ModalWrapper, IconInfo } from '@payright/web-components';
import LoggedInLayout from '../../layouts/logged-in-layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ControlledInputField } from '../../components/form-fields';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';
import Tooltip from 'react-tooltip-lite';
import getConstants from '../../util/constants';

// Yup is a JavaScript schema builder for value parsing and validation
// TODO: upgrade RHF and remove references to @hookform/resolvers/yup/dist
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd';
import { generalStaffInfoValidationSchema } from '../../util/validation/form-validation';

// Import default 'base' staff type
import { Staff, SugarStaffPermission } from '../../types/staff';
import { StaffPermissionLabelMaker } from './utils/StaffPermissionLabelMaker';

const { SUCCESSFUL_STAFF_CREATION, REQUEST_FAILURE, STAFF_USER_NAME_INFO_TEXT } = getConstants();

export type StaffAdd = Staff & {
  salutation: string;
};

const staffPermissions: { [key in SugarStaffPermission]: boolean } = {
  'Activate Plans': false,
  'Create Plan': false,
  'Export Plans And View Plan MSF': false,
  'View Other Staff Plans': false,
  'View Plans': false,
  'View Rates': false,
  'View Staff': false,
  Administrator: false,
};

const AddStaff = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const staffPermissionLabelMaker = useRef(new StaffPermissionLabelMaker());

  const reactHookForm = useForm<StaffAdd>({
    resolver: yupResolver(generalStaffInfoValidationSchema),
  });

  const [permissions, setPermissions] = useState(staffPermissions);

  const { handleSubmit, errors, watch, control } = reactHookForm;
  const { errorMessage, submitSucceeded } = useSelector((state: RootState) => state.staff);
  const [showMessageModal, setShowMessageModal] = useState(false);

  let showMessage;

  // Select permissions, if there is any change
  const handleUpdateFilter = (filterName: SugarStaffPermission) => {
    permissions[filterName] = !permissions[filterName];
    setPermissions({ ...permissions });
  };

  // Method to close Plan Activation Modal after message display
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    if (errorMessage === null) history.push('/staff');
  };

  // Form submit
  const onSubmit = (data: StaffAdd) => {
    let selectedValues = Object.keys(permissions).filter(
      (obj) => permissions[obj as SugarStaffPermission] === true
    );
    data.permissions = selectedValues as SugarStaffPermission[];
    dispatch(addnewStaffInfo(data));
  };

  useEffect(() => {
    if (submitSucceeded || errorMessage !== null) {
      setShowMessageModal(true);
    }
  }, [submitSucceeded, history, errorMessage]);

  // Show successful and unsuccessful message
  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={SUCCESSFUL_STAFF_CREATION}
        message=""
        handleOnClick={handleCloseMessageModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={REQUEST_FAILURE}
        message={errorMessage}
        handleOnClick={handleCloseMessageModal}
        error={true}
      />
    );
  }

  return (
    <SCAddStaff>
      <LoggedInLayout activePage="/staff">
        <div className="staff-body">
          <div className="title">
            <h6>
              <IconChevron />
              <Link to="/staff" className="linkPage">
                <span>View all Staff</span>
              </Link>
            </h6>
          </div>
          <div className="header">
            <h2>Add Staff Member</h2>
          </div>
          <FormProvider {...reactHookForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <ModalWrapper
                  open={showMessageModal}
                  handleClose={() => setShowMessageModal(false)}
                  background="dark"
                >
                  <div className="message">{showMessage}</div>
                </ModalWrapper>
              </div>
              <div className="details-wrapper-body">
                <Grid fluid>
                  <Row>
                    <Col xs={12} md={3} lg={3}>
                      <h4>Basic Details</h4>
                    </Col>

                    <Col md={9} xs={11} lg={9}>
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <Row start="xs">
                            <Col xs={12} md={5} lg={6}>
                              <h6 className="label">First Name *</h6>
                              <ControlledInputField
                                name="firstName"
                                error={errors.firstName?.message as string}
                              ></ControlledInputField>
                            </Col>

                            <Col xs={12} md={5} lg={5}>
                              <h6 className="label">Last Name *</h6>
                              <ControlledInputField
                                name="lastName"
                                error={errors.lastName?.message as string}
                              ></ControlledInputField>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={9} lg={12}>
                          <Row start="xs">
                            <Col xs={12} md={9} lg={6}>
                              <h6 className="label">Job Title</h6>
                              <ControlledInputField
                                name="jobTitle"
                                error={errors.jobTitle?.message as string}
                              ></ControlledInputField>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={12} lg={12}>
                          <hr />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3} lg={3}>
                      <h4>Contact Details</h4>
                    </Col>

                    <Col md={9} xs={11} lg={9}>
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <Row start="xs">
                            <Col xs={12} md={6} lg={6}>
                              <h6 className="label">Email Address *</h6>
                              <ControlledInputField
                                name="email"
                                error={errors.email?.message as string}
                                className="email"
                                type="email"
                              ></ControlledInputField>
                            </Col>
                            <Col xs={12} md={6} lg={5}>
                              <h6 className="label">Mobile No. *</h6>
                              <ControlledInputField
                                name="phoneMobile"
                                error={errors.phoneMobile?.message as string}
                              ></ControlledInputField>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={9} lg={12}>
                          <Row start="xs">
                            <Col xs={12} md={9} lg={6}>
                              <h6 className="label">Work Phone No.</h6>
                              <ControlledInputField
                                name="phoneWork"
                                error={errors.phoneWork?.message as string}
                              ></ControlledInputField>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={12} lg={12}>
                          <hr />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3} lg={3}>
                      <h4>Credentials</h4>
                    </Col>

                    <Col md={9} xs={11} lg={9}>
                      <Row>
                        <Col xs={11} md={9} lg={8}>
                          <Row start="xs">
                            <Col xs={4} md={3} lg={2}>
                              <h6 className="label">Username</h6>
                            </Col>
                            <Col xs={2} md={1} lg={1}>
                              <Tooltip content={STAFF_USER_NAME_INFO_TEXT} className="toolTip">
                                <IconInfo />
                              </Tooltip>
                            </Col>
                          </Row>

                          <Row start="xs">
                            <Col xs={12} md={9} lg={9}>
                              <ControlledInputField
                                name="userName"
                                rules={{ required: 'Required' }}
                                error={errors.userName && 'User Name is required'}
                              ></ControlledInputField>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={12} lg={12}>
                          <Row start="xs">
                            <Col xs={12} md={6} lg={6}>
                              <h6 className="label">Password</h6>
                              <ControlledInputField
                                name="password"
                                rules={{
                                  required: true,
                                  minLength: 8,
                                  pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*[\]"';:_\-<>., =+/\\]).{8,}$/,
                                }}
                                error={
                                  errors.password &&
                                  'Password strength does not meet the requirements'
                                }
                                type="password"
                              ></ControlledInputField>
                              <div className="passwordMessage">
                                <ul>
                                  <li>- Minimum 8 characters</li>
                                  <li>- 1 upper case letter</li>
                                  <li>- 1 lower case letter</li>
                                  <li>- 1 special character</li>
                                </ul>
                              </div>
                            </Col>
                            <Col xs={12} md={6} lg={5}>
                              <h6 className="label">Confirm Password</h6>
                              <ControlledInputField
                                name="confirmPassword"
                                rules={{
                                  required: 'Required',
                                  validate: (value: string) => value === watch('password'),
                                }}
                                error={
                                  errors.confirmPassword &&
                                  'Confirm Password should be same as Password'
                                }
                                type="password"
                              ></ControlledInputField>
                              {!errors.confirmPassword && (
                                <div className="passwordMessage">
                                  Confirm Password should match with Password.
                                </div>
                              )}
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12} md={12} lg={12}>
                          <hr />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3} lg={3}>
                      <h4>Permissions</h4>
                    </Col>

                    <Col md={9} xs={3} lg={9}>
                      <Row>
                        {Object.keys(staffPermissions).map((key) => (
                          <Col xs={12} md={6} lg={6}>
                            <Controller
                              name={key}
                              control={control}
                              label={key}
                              render={({ value }) => {
                                return (
                                  <SCFilterCheckbox
                                    name={key}
                                    value={value}
                                    checked={permissions?.[key as SugarStaffPermission] ?? false}
                                    handleClick={handleUpdateFilter}
                                  >
                                    <h6 className="label checkbox">
                                      {staffPermissionLabelMaker.current.getLabel(
                                        key as SugarStaffPermission
                                      )}
                                    </h6>
                                  </SCFilterCheckbox>
                                );
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Col xs={12} md={6} lg={12}>
                        <hr />
                      </Col>
                    </Col>
                  </Row>
                  <Row end="xs">
                    <Col xs={12} md={6} lg={9}>
                      <Row end="xs">
                        {' '}
                        <Col xs={6} md={6} lg={3}>
                          <div>
                            <Button
                              className="actions"
                              outline
                              colour="blue"
                              handleClick={() => history.push('/staff')}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                          <div>
                            <Button className="actions">Save Details</Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </form>
          </FormProvider>
        </div>
      </LoggedInLayout>
    </SCAddStaff>
  );
};

export default AddStaff;
