import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, IconTickAlt, ImageWithIcon, Header, media } from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import planResultHappyFace from '../../../assets/images/plan-result-happy-face.png';
import CenterContentLayout from '../../../layouts/center-content-layout';
import { useSelector } from 'react-redux';
import { RootState } from '../../../slices';
import Loader from '../../../components/loader';
import getConstants from '../../../util/constants';

const { APPLICATION_SENT_TO_CUSTOMER, SUCCESSFUL_LOAN_SENT_TO_CUSTOMER } = getConstants();

const SCLoanResult = styled.div`
  .icon,
  .header,
  .message,
  .action {
    margin: 2em 0;
    text-align: center;
  }

  ${media.min.large} {
    .icon {
      margin-top: 6em;
    }
  }

  .header {
    margin: 2em 0;

    &__title {
      max-width: 500px;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      font-size: 1.2em;

      h4 {
        color: ${(props) => props.theme.colours.blue.base};
      }
    }
  }

  p {
    margin: auto;
    font-size: 1.2em;
    max-width: 500px;
    width: 500px;
    align-items: center;
    justify-content: center;
  }

  .actions {
    display: flex;
    padding-bottom: 30px;
    margin: auto;
    font-size: 1em;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 200px;
  }
`;

const renderLoanResultMessage = ({
  icon,
  header,
  message,
  action,
}: {
  icon: React.ReactNode;
  header: string;
  message: React.ReactNode;
  action: React.ReactNode;
}) => (
  <SCLoanResult>
    {icon && <div className="icon">{icon}</div>}
    {header && (
      <div className="header">
        <div className="header__title">
          <h4>{header}</h4>
        </div>
      </div>
    )}
    {message && <div className="message">{message}</div>}
    {action && <div className="actions">{action}</div>}
  </SCLoanResult>
);

const ApplicationSentToCustomer = () => {
  const checkout = useSelector((state: RootState) => state.payrightPlus);
  const { loading } = checkout;

  const history = useHistory();

  const mapLoanOutCome = () => {
    const loanResult = {
      ...loanResultMessage,
    };
    return renderLoanResultMessage(loanResult);
  };

  const loanResultMessage = {
    icon: <ImageWithIcon image={planResultHappyFace} icon={<IconTickAlt />} />,
    header: APPLICATION_SENT_TO_CUSTOMER || '',
    message: (
      <>
        <p>{SUCCESSFUL_LOAN_SENT_TO_CUSTOMER}</p>
      </>
    ),
    action: (
      <>
        <Button handleClick={() => history.push(`/loans`)}>View all Loans</Button>
      </>
    ),
  };

  return (
    <LoggedInLayout contentMarginTop={0} activePage="/loans/new">
      <Header title="New Loan" />
      <CenterContentLayout>
        {loading ? <Loader text="Loading.." /> : mapLoanOutCome()}
      </CenterContentLayout>
    </LoggedInLayout>
  );
};

export default ApplicationSentToCustomer;
