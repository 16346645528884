import React, { useEffect } from 'react';
import { cardCardCloudPayments } from '../../../slices/payment';
import { cloudCheckRequestDTO } from '../../../types/payments';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../../util/hooks';

import { AppDispatch } from '@merchant-portal/util/store';

const CloudPayments = (checkout: any) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();

  const checkoutId = checkout.match.params.checkoutId;

  // TODO VALIDATE THE HMAC TO ENSURE THE DATA IS COMING THROUGH CLOUD PAYMENTS
  const cloudPaymentsDto: cloudCheckRequestDTO = {
    payment_provider: 'Fat Zebra',
    checkoutId: checkoutId,
    token: query.get('token'),
    card_holder: query.get('card_holder'),
    country: query.get('country'),
    cardNumber: query.get('card_number'),
    card_expiry: query.get('card_expiry'),
    card_type: query.get('card_type'),
  };

  useEffect(() => {
    dispatch(cardCardCloudPayments(cloudPaymentsDto)).then(() => {
      history.push(`/plans/new/payment/?checkoutId=${checkoutId}`);
    });
  }, [dispatch, cloudPaymentsDto, checkoutId, history]);

  return <></>;
};

export default CloudPayments;
