import React from 'react';
import styled from 'styled-components';
import { Alert, Button, IconAttention, media } from '@payright/web-components';
import { useHistory } from 'react-router-dom';
import LoggedInLayout from '@merchant-portal/layouts/logged-in-layout';
import { Col, Row } from 'react-flexbox-grid';

const SCAccessDeniedPage = styled.div`
  .access-denied-body {
    margin-top: 60px;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .access-denied-content {
    display: grid;
    grid-auto-flow: row;
    row-gap: 2rem;

    max-width: 900px;

    padding-left: 26px;
    padding-right: 26px;
    padding-top: 10px;
    padding-bottom: 10px;

    ${media.min.medium} {
      padding-left: 71px;
      padding-right: 71px;
      padding-top: 55px;
      padding-bottom: 55px;
    }
  }

  .content {
    margin-top: 0px;
  }
`;

const AccessDeniedPage = () => {
  const history = useHistory();

  return (
    <SCAccessDeniedPage>
      <LoggedInLayout activePage="/access-denied">
        <div className="access-denied-body">
          <div className="access-denied-content">
            <Alert
              title="Access Denied"
              body={
                <p>
                  You don’t have access to this page, please request access from your store
                  administrator.
                </p>
              }
              outcome="error"
              icon={<IconAttention />}
            />
            <Row end="xs">
              <Col xs={12} md={6} lg={9}>
                <Row end="xs">
                  {' '}
                  <Col xs={6} md={6} lg={3}>
                    <div>
                      <Button
                        className="actions"
                        outline
                        colour="blue"
                        handleClick={() => history.goBack()}
                      >
                        Back
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} md={6} lg={3}>
                    <div>
                      <Button className="actions" handleClick={() => history.push('/')}>
                        Go To Dashboard
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </LoggedInLayout>
    </SCAccessDeniedPage>
  );
};

export default AccessDeniedPage;
