import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '@merchant-portal/slices';
import SCForgotDetails from './css';
import { useForm, FormProvider } from 'react-hook-form';
import { ControlledInputField } from '../../components/form-fields';
import { Button, IconError, Alert, IconLogo, MessageBox } from '@payright/web-components';
import Spinner from '../../components/spinner';
import { tempPasswordAndStatusUpdate } from '../../slices/forgot-password';
import Loader from '../../components/loader';
import getConstants from '../../util/constants';

const {
  SUCCESSFUL_FORGOT_PASSWORD_TITLE,
  SUCCESSFUL_FORGOT_PASSWORD_MESSAGE,
  FAILURE_FORGOT_PASSWORD_MESSAGE,
  EMAIL_VALIDATION_REGEX,
} = getConstants();

export type forgotPassword = {
  userName: string;
  email: string;
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const reactHookForm = useForm<forgotPassword>({});
  const { handleSubmit, errors } = reactHookForm;
  const { errorMessage, submitSucceeded, loading } = useSelector(
    (state: RootState) => state.forgotPassword
  );
  let showMessage;
  const history = useHistory();

  // Form submit
  const onSubmit = (data: any) => {
    dispatch(tempPasswordAndStatusUpdate(data));
  };

  // Show successful and unsuccessful message
  if (errorMessage) {
    showMessage = (
      <Alert
        title=""
        body={FAILURE_FORGOT_PASSWORD_MESSAGE}
        outcome="negative"
        icon={<IconError />}
      />
    );
  }

  return (
    <SCForgotDetails>
      <div className="forgot-password-wrapper">
        <div className="forgot-password-header">
          <div className="logo">
            <IconLogo width={180} height={74} />
          </div>
          <h4>Forgot Password</h4>
        </div>
        <div className="alerts">{showMessage}</div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {submitSucceeded ? (
              <div>
                <MessageBox
                  title={SUCCESSFUL_FORGOT_PASSWORD_TITLE}
                  message={SUCCESSFUL_FORGOT_PASSWORD_MESSAGE}
                  handleOnClick={() => history.push('/')}
                />
              </div>
            ) : (
              <div className="forgot-password-body">
                <FormProvider {...reactHookForm}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ControlledInputField
                      name="userName"
                      rules={{
                        required: 'Username is required',
                      }}
                      error={errors.userName && 'User Name is required'}
                      placeholder="User Name"
                    >
                      User Name
                    </ControlledInputField>
                    <ControlledInputField
                      name="email"
                      rules={{ required: true, pattern: EMAIL_VALIDATION_REGEX }}
                      error={errors.email && 'Email is required & in a valid format'}
                      className="email"
                      type="email"
                      placeholder="Email"
                    >
                      Email
                    </ControlledInputField>
                    <div className="buttonClass">
                      <Button
                        outline
                        colour="blue"
                        withShadow
                        maxWidth="50%"
                        type="submit"
                        handleClick={() => history.push('/')}
                      >
                        Cancel
                      </Button>
                      <span className="filler"></span>
                      <Button
                        type="submit"
                        withShadow
                        maxWidth="50%"
                        iconPosition="right"
                        icon={loading ? <Spinner /> : undefined}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            )}
          </>
        )}
      </div>
    </SCForgotDetails>
  );
};

export default ForgotPassword;
