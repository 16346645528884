import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../util/store';
import MerchantAPI, {
  StaffSuccessResponse,
  StaffCreationSuccessResponse,
  StaffDetailSuccessResponse,
} from '../util/merchant-api';
import getConstants from '../util/constants';
const { CONTACT_US_PHONE_NUMBER } = getConstants();

type InitialState = {
  loading?: boolean;
  hasError?: boolean;
  submitSucceeded?: boolean;
  errorMessage?: string | null;
  merchantStaff: StaffSuccessResponse;
  staffDetails: StaffDetailSuccessResponse;
};

export const initialState: InitialState = {
  loading: false,
  hasError: false,
  errorMessage: null,
  merchantStaff: [],

  submitSucceeded: false,
  staffDetails: {
    id: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneMobile: '',
    phoneWork: '',
    userName: '',
    email: '',
    permissions: [],
    contactStatus: '',
    canAlterPermissions: false
  },
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    getStaffBegin: (state) => {
      return {
        ...state,
        loading: true,
        submitSucceeded: false,
      };
    },

    getStaffSuccess: (state, action: PayloadAction<StaffSuccessResponse>) => {
      return {
        ...state,
        loading: false,
        merchantStaff: action.payload,
        hasErrors: false,
        errorMessage: null,
      };
    },
    getStaffFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
        submitSucceeded: false,
      };
    },
    addNewStaffMemberSuccess: (state) => {
      return {
        ...state,
        loading: false,
        submitSucceeded: true,
        hasErrors: false,
      };
    },
    updateStaffMemberSuccess: (state) => {
      return {
        ...state,
        loading: false,
        submitSucceeded: true,
        hasErrors: false,
      };
    },

    getStaffDetailSuccess: (state, action: PayloadAction<StaffDetailSuccessResponse>) => {
      return {
        ...state,
        loading: false,
        staffDetails: action.payload,
        hasErrors: false,
      };
    },
  },
});

const {
  getStaffBegin,
  getStaffSuccess,
  getStaffFailure,
  addNewStaffMemberSuccess,
  updateStaffMemberSuccess,
  getStaffDetailSuccess,
} = staffSlice.actions;
export default staffSlice.reducer;

// Thunks to show the list on staff page

export const displayStaff = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getStaffBegin());

    const fetchStaff: StaffSuccessResponse = await MerchantAPI.staff();
    dispatch(getStaffSuccess(fetchStaff));
  } catch (e: any) {
    console.error(e);
    dispatch(getStaffFailure({ errorMessage: e.message }));
  }
};

// Thunks to show add the staff member

export const addnewStaffInfo =
  (newStaffInfo: any): AppThunk =>
  async (dispatch) => {
    try {
      const addNewStaff: StaffCreationSuccessResponse = await MerchantAPI.staffCreation(
        newStaffInfo
      );

      if (addNewStaff.id) {
        dispatch(addNewStaffMemberSuccess());
      } else {
        dispatch(
          getStaffFailure({
            errorMessage: `An unexpected error occured while trying to add a member. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}.`,
          })
        );
      }
    } catch (e: any) {
      dispatch(getStaffFailure({ errorMessage: e.message }));
    }
  };

//Thunk to update staff

export const updateStaffDetails =
  (staffId: string, updateStaffInfo: any): AppThunk =>
  async (dispatch) => {
    try {
      await MerchantAPI.updateStaff(staffId, updateStaffInfo);
      dispatch(updateStaffMemberSuccess());
    } catch (e: any) {
      dispatch(getStaffFailure({ errorMessage: e.message }));
    }
  };

// Thunk to display staff detail
export const displayStaffDetails =
  (staffId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getStaffBegin());
      const fetchStaffDetails: StaffDetailSuccessResponse = await MerchantAPI.staffDetails(staffId);
      dispatch(getStaffDetailSuccess(fetchStaffDetails));
    } catch (e: any) {
      dispatch(getStaffFailure({ errorMessage: e.message }));
    }
  };
