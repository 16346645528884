import React, { useState, useEffect } from 'react';
import LoggedInLayout from '../../layouts/logged-in-layout';
import {
  Card,
  HeaderCustomer,
  PaymentCalculatorData,
  ModalGeneric,
  ModalWrapper,
  IconError,
  Alert,
} from '@payright/web-components';
import Dashboard, { SCModalPassword, SCModalMerchantDDO } from './css';
import Loader from '../../components/loader';

import ResetPassword from '../../pages/resetPassword';
import MerchantDDOConsent from '../../pages/merchant-ddo-consent';
import { useStoryblok } from '@merchant-portal/providers/storyblok-provider';

import NewsWidget from '@merchant-portal/components/dashboard/news';
import ContactCard from './cards/contact-card';
import StatsCard from './cards/stats-card';
import HelpdeskCard from './cards/helpdesk-card';
import PaymentPlanToolsCard from './cards/payment-plan-tools-card';
import getConstants from '../../util/constants';
import CtaCard from './cards/cta-card';
// import QuickCheckoutCard from './cards/quickcheckout-card';
// import { envIsTrue } from '../../util/helper';
// import { useRegion } from '@merchant-portal/util/hooks';
// import styled from 'styled-components';
// import { SCCard } from '@payright/web-components/dist/src/components/Cards';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';

// Storyblok story components
import AnnouncementCards from '@merchant-portal/components/storyblok/announcement-cards';
import { useConfig } from '@merchant-portal/providers/config-provider';
import { DDODetailsResponse } from '@merchant-portal/util/merchant-api';
import { getMerchantDDODetailsSuccess } from '@merchant-portal/slices/merchant';

const { UNSUCCESSFUL_DDO_UPDATE } = getConstants();

const { PASSWORD_STATUS, TELL_YOUR_CUSTOMER_LINK } = getConstants();

type FormData = {
  paymentDetails: PaymentCalculatorData;
  merchantRef?: string;
};

const DashBoard = () => {
  // const query = useQuery();
  const { ddoImportantMessage, ddoDueDatePassedImportantMessage } = useStoryblok();

  // If there is some error from DDO updation we need to grab that error and display on the modal
  const { ddoUpdateErrorMessage, DDOLoading, ddoDetails } = useSelector(
    (state: RootState) => state.merchant
  );
  const dispatch = useDispatch();

  // Message for the error on the DDO
  let showMessage;
  if (ddoUpdateErrorMessage) {
    showMessage = (
      <Alert
        title={UNSUCCESSFUL_DDO_UPDATE}
        body={ddoUpdateErrorMessage}
        outcome="negative"
        icon={<IconError />}
      />
    );
  }
  // state for the Error box
  const [showMessageModal, setShowMessageModal] = useState(false);
  // State for the DDO modal
  const [showMerchantDDOModal, setShowMerchantDDOModal] = useState(false);

  const storeDetail = JSON.parse(localStorage.getItem('merchantStoreInfo') || '{}');

  const loggedInStaffPasswordStatus = JSON.parse(
    localStorage.getItem('loggedInStaffPasswordStatus') || '{}'
  );
  const config = useConfig();

  // Grab the item for the DDO Consent
  useEffect(() => {
    if (!!ddoDetails) {
      setShowMerchantDDOModal(ddoDetails.canAcceptDDO);
      return;
    }

    const ddo: DDODetailsResponse = JSON.parse(localStorage.getItem('ddoDetails') || '{}');

    dispatch(getMerchantDDODetailsSuccess({ ddoDetails: ddo }));
    setShowMerchantDDOModal(ddo.canAcceptDDO === true);
  }, []);

  // Close the DDO modal pop up
  const handleCloseModal = () => {
    setShowMerchantDDOModal(false);
  };

  useEffect(() => {
    if (loggedInStaffPasswordStatus === PASSWORD_STATUS) {
      setShowMessageModal(true);
    }
  }, [showMessageModal, loggedInStaffPasswordStatus]);

  /* Being prepare & send awesome welcome message */
  const staffFirstName = JSON.parse(localStorage.getItem('user') || '{}')?.firstName;
  const welcome = 'Hey ' + staffFirstName + ',';
  /* End prepare & send awesome welcome message */

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1XI3dS4EDnPHbgSMsjHaBkfs_2BEEbAacvw_2Fc2RFLBQMI.js';
    script.async = true;

    document.getElementById('smcx-sdk')?.appendChild(script);

    return () => {
      try {
        document.getElementById('smcx-sdk')?.removeChild(script);
      } catch {}
    };
  }, []);

  const { loading } = useSelector((state: RootState) => state.auth);

  // Get the story block stuff
  let storyBlokImportantMessageText;

  if (ddoDetails?.shouldBlockLoanCreation) {
    storyBlokImportantMessageText = ddoDueDatePassedImportantMessage;
  } else {
    storyBlokImportantMessageText = ddoImportantMessage;
  }

  return (
    <Dashboard>
      <LoggedInLayout activePage="/dashboard">
        <HeaderCustomer title={welcome} subtitle="Welcome to Payright"></HeaderCustomer>
        {/* DDO STUFF */}
        <div>
          <ModalWrapper
            open={showMerchantDDOModal}
            handleClose={() => setShowMerchantDDOModal(true)}
            background="dark"
          >
            <SCModalMerchantDDO>
              <div className="alerts">{showMessage}</div>

              {DDOLoading ? (
                <Loader />
              ) : (
                <ModalGeneric
                  text={storyBlokImportantMessageText}
                  content={
                    <MerchantDDOConsent
                      merchantName={`${storeDetail.merchantName}`}
                      closeModalProp={handleCloseModal}
                    />
                  }
                  className="merchantDDO"
                ></ModalGeneric>
              )}
            </SCModalMerchantDDO>
          </ModalWrapper>
        </div>
        {/* DDO STUFF Ends*/}

        <div>
          <ModalWrapper
            open={showMessageModal}
            handleClose={() => setShowMessageModal(false)}
            background="dark"
          >
            <SCModalPassword>
              <ModalGeneric
                title={'Generate New Password'}
                content={<ResetPassword closeModalProp={() => setShowMessageModal(false)} />}
                contactClassName="contact"
              ></ModalGeneric>
            </SCModalPassword>
          </ModalWrapper>
        </div>

        <div className="dashboard-body">
          <div className="heading">
            <h4>Announcements</h4>
          </div>

          {!loading && <AnnouncementCards />}

          <div className="heading">
            <h4>Actions</h4>
          </div>

          <div className="dashboard-row dashboard-row--actions">
            <div className="dashboard-col">
              {/* <QuickCheckoutCard /> */} {/* Temporary commented  */}
              <StatsCard />
              <HelpdeskCard />
              <ContactCard />
            </div>

            <div className="dashboard-col">
              <PaymentPlanToolsCard storeDetail={storeDetail} />
            </div>
          </div>

          <hr />

          <div className="dashboard-row bottom">
            <div className="dashboard-col left">
              <CtaCard
                cardTitle="Tell your customers about Payright"
                buttonHref={TELL_YOUR_CUSTOMER_LINK + '/im-a-business/resources'}
                buttonLabel="Learn more"
              />

              <div className="poll">
                <Card title="Poll">
                  <div id="smcx-sdk"></div>
                </Card>
              </div>
            </div>
            <div className="dashboard-col">
              <NewsWidget />
            </div>
          </div>
        </div>
      </LoggedInLayout>
    </Dashboard>
  );
};

export default DashBoard;
