import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '@merchant-portal/util/store';
import MerchantAPI, { DDODetailsResponse } from '../util/merchant-api';

type InitialState = {
  DDOLoading?: boolean;
  hasError?: boolean;
  submitSucceeded?: boolean;
  ddoUpdateErrorMessage?: string | null;
  ddoDetailsErrorMessage?: string | null;
  ddoDetails: DDODetailsResponse | undefined;
};

export const initialState: InitialState = {
  DDOLoading: false,
  hasError: false,
  ddoUpdateErrorMessage: null,
  ddoDetailsErrorMessage: null,
  submitSucceeded: false,
  ddoDetails: undefined,
};

const MerchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    updateMerchantDDOBegin: (state) => {
      return {
        ...state,
        DDOLoading: true,
        submitSucceeded: false,
        hasErrors: false,
      };
    },

    updateMerchantDDOSuccess: (
      state,
      action: PayloadAction<{ ddoDetails: DDODetailsResponse }>
    ) => {
      return {
        ...state,
        DDOLoading: false,
        submitSucceeded: true,
        hasErrors: true,
        ddoDetails: action.payload.ddoDetails,
      };
    },

    updateMerchantDDOUpdateFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        DDOLoading: false,
        ddoUpdateErrorMessage: action.payload.errorMessage,
        submitSucceeded: false,
      };
    },

    getMerchantDDODetailsBegin: (state) => {
      return {
        ...state,
        DDOLoading: true,
        hasErrors: false,
      };
    },

    getMerchantDDODetailsSuccess: (
      state,
      action: PayloadAction<{ ddoDetails: DDODetailsResponse }>
    ) => {
      return {
        ...state,
        DDOLoading: false,
        hasErrors: true,
        ddoDetails: action.payload.ddoDetails,
      };
    },

    getMerchantDDODetailsFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        DDOLoading: false,
        ddoDetailsErrorMessage: action.payload.errorMessage,
      };
    },
  },
});

const {
  updateMerchantDDOBegin,
  updateMerchantDDOSuccess,
  updateMerchantDDOUpdateFailure,
  getMerchantDDODetailsBegin,
  getMerchantDDODetailsFailure,
} = MerchantSlice.actions;
export default MerchantSlice.reducer;

export const { getMerchantDDODetailsSuccess } = MerchantSlice.actions;

export const updateMerchantDDOAcceptance =
  (): AppThunk<Promise<DDODetailsResponse>> => async (dispatch) => {
    try {
      dispatch(updateMerchantDDOBegin());
      const ddoDetails: DDODetailsResponse = await MerchantAPI.updateMerchantDDOAcceptanceInfo();
      dispatch(updateMerchantDDOSuccess({ ddoDetails }));
      localStorage.setItem('ddoDetails', JSON.stringify(ddoDetails));
      return ddoDetails;
    } catch (e: any) {
      dispatch(updateMerchantDDOUpdateFailure({ errorMessage: e.message }));
      throw new Error(e.message);
    }
  };

export const getMerchantDDODetails =
  (): AppThunk<Promise<DDODetailsResponse>> => async (dispatch) => {
    try {
      dispatch(getMerchantDDODetailsBegin());
      const ddoDetails: DDODetailsResponse = await MerchantAPI.getMerchantDDODetails();
      dispatch(getMerchantDDODetailsSuccess({ ddoDetails }));
      localStorage.setItem('ddoDetails', JSON.stringify(ddoDetails));
      return ddoDetails;
    } catch (e: any) {
      dispatch(getMerchantDDODetailsFailure({ errorMessage: e.message }));
      throw new Error(e.message);
    }
  };
