import React from 'react';
import styled from 'styled-components';
import { Loader as PayrightLoader } from '@payright/web-components';

type LoaderProps = {
  text?: any;
  marginTop?: string;
};

const SCLoaderWrapper = styled.div<LoaderProps>`
  text-align: center;
  margin-top: ${(props) => props.marginTop || '6em'};
`;

const Loader = ({ text, marginTop }: LoaderProps) => {
  return (
    <SCLoaderWrapper marginTop={marginTop}>
      <PayrightLoader text={text} />
    </SCLoaderWrapper>
  );
};

export default Loader;
