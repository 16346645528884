import React from 'react';
import styled from 'styled-components';
import { ModalWrapper, MessageBox, IconHandGroup } from '@payright/web-components';

type ModalPayDifferenceProps = {
  show: boolean;
  closeHandler: () => void;
};

const SCModalPayDifference = styled.div`
  text-align: center;

  .modal-content {
    max-width: 30rem;
  }

  .title {
    text-align: left;
  }

  .message .messagebox-content {
    display: flex;
    flex-direction: column;
    text-align: left;

    .icon-wrapper {
      background-color: white;
      display: inline-block;
      padding: 2em;
      margin: 0 auto;
      box-shadow: 0 4px 8px 0 rgb(66 77 96 / 10%);
      border-radius: 50%;
    }

    .message-text {
      margin-top: 2em;
    }
  }
`;

const ModalPayDifference = ({ show, closeHandler }: ModalPayDifferenceProps) => {
  return (
    <SCModalPayDifference>
      <ModalWrapper open={show} handleClose={closeHandler} background="dark">
        <div className="modal-content">
          <MessageBox
            title={'Customer would like to pay part of their purchase up front'}
            message={
              <div className="messagebox-content">
                <div className="icon-wrapper">
                  <IconHandGroup width="50" height="50" />
                </div>

                <div className="message-text">
                  Customer would like to pay part of their purchase up front. Once paid, update the
                  sale amount and amount paid up front and send the checkout to the customer again.
                </div>
              </div>
            }
            handleOnClick={closeHandler}
          />
        </div>
      </ModalWrapper>
    </SCModalPayDifference>
  );
};

export default ModalPayDifference;
