import React, { useEffect, useRef } from 'react';
import { Controller, ValidationRules, useFormContext } from 'react-hook-form';
import moment from 'moment';
import {
  InputField,
  Dropdown,
  DropdownWithInput,
  DatePicker,
  SelectableCard,
  InputCreditCardExpiry,
  InputCreditCard,
} from '@payright/web-components';

type FieldProps = {
  name: string;
  rules?: ValidationRules;
  children?: React.ReactNode;
  defaultValue?: string;
};

export const ControlledInputField = ({
  name,
  rules,
  ...rest
}: FieldProps & React.ComponentProps<typeof InputField>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Controller
      name={name}
      render={({ onChange, value }) => {
        return (
          <InputField
            name={name}
            handleInputChange={onChange}
            value={value}
            inputRef={inputRef}
            {...rest}
          />
        );
      }}
      rules={rules}
      // onChangeName="handleInputChange"
      // rules={rules}
      // onFocus={() => {
      //   if (inputRef.current) {
      //     inputRef.current.focus();
      //   }
      // }}
      // as={<InputField inputRef={inputRef} {...rest} />}
    />
  );
};
ControlledInputField.defaultProps = {
  type: 'text',
};

export const ControlledDropdown = ({
  name,
  rules,
  ...rest
}: FieldProps & React.ComponentProps<typeof Dropdown>) => {
  /*
    // Previous version could scroll onto view when focused
      <Controller
      onFocus={() => {
        if (selectRef.current) {
          selectRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }}
      rules={rules}
      name={name}
      onChangeName="handleSelect"
      as={<Dropdown selectRef={selectRef} {...rest} />}
   */
  return (
    <Controller
      name={name}
      render={({ onChange, value }) => <Dropdown handleSelect={onChange} value={value} {...rest} />}
      rules={rules}
    />
  );
};

export const ControlledDatePicker = ({
  name,
  rules,
  children,
  startYear,
  endYear,
  ...rest
}: FieldProps & React.ComponentProps<typeof DatePicker>) => {
  // Convert JSX to string so it can be used as the label prop
  let label = '';
  React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      label += child;
    }
  });
  return (
    // <Controller
    //   name={name}
    //   rules={rules}
    //   onChange={([e]) => {
    //     if (e instanceof Date) {
    //       return moment(e).format('YYYY-MM-DD');
    //     }

    //     return '';
    //   }}
    //   onChangeName="handleDateChange"
    //   as={<DatePicker label={label} {...rest} startYear={startYear} endYear={endYear} />}
    // />

    <Controller
      name={name}
      rules={rules}
      render={({ onChange, value }) => (
        // TODO DatePicker using onChange causing isDirty = true.
        <DatePicker
          value={value}
          handleDateChange={(date) => {
            if (date instanceof Date) {
              return onChange(moment(date).format('YYYY-MM-DD'));
            }

            return onChange('');
          }}
          label={label}
          {...rest}
          startYear={startYear}
          endYear={endYear}
        />
      )}
    />
  );
};

export const ControlledDropdownWithInput = ({
  dropdownName,
  dropdownRules,
  inputName,
  inputRules,
  ...rest
}: {
  dropdownName: string;
  dropdownRules?: ValidationRules;
  inputName: string;
  inputRules?: ValidationRules;
} & React.ComponentProps<typeof DropdownWithInput>) => {
  // Wiring up this component with react-hook-form is a bit tricky -- could not
  // use the standard <Controller> method as this component has 2 values and 2 change handlers.
  // Wiring up is done by manually registering 2 form inputs, and using watch() to get and render the values
  // See: Custom Register example in https://react-hook-form.com/advanced-usage/#ControlledmixedwithUncontrolledComponents
  const { setValue, register, watch } = useFormContext();

  useEffect(() => {
    register({ name: dropdownName }, { ...dropdownRules });
    register({ name: inputName }, { ...inputRules });
  }, [register]); // TODO -  missing dependencies workaround.

  const dropdownValue = watch(dropdownName);
  const inputValue = watch(inputName);

  return (
    <DropdownWithInput
      dropdownValue={dropdownValue}
      handleSelect={(option: string) => {
        setValue(dropdownName, option);
      }}
      inputValue={inputValue}
      handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(inputName, event.target.value.replace(/,/g, ''));
      }}
      {...rest}
    />
  );
};

// Not sure if this component is working correctly -- use with caution. Currently not being used anywhere
export const ControlledSelectableCard = ({
  name,
  defaultValue,
  ...rest
}: FieldProps & React.ComponentProps<typeof SelectableCard>) => {
  return (
    // <Controller
    //   valueName="test"
    //   defaultValue={defaultValue}
    //   name={name}
    //   onChangeName="handleInputChange"
    //   as={<SelectableCard {...rest} />}
    // />

    <Controller
      name={name}
      render={({ onChange, onBlur, value, name, ref }) => (
        <SelectableCard handleSelect={onChange} {...rest} />
      )}
    />
  );
};

export const ControlledInputCreditCardExpiry = ({
  name,
  rules,
  ...rest
}: FieldProps & React.ComponentProps<typeof InputCreditCardExpiry>) => {
  return (
    // <Controller
    //   valueName={name}
    //   name={name}
    //   rules={rules}
    //   onChangeName="handleSelect"
    //   as={<InputCreditCardExpiry {...rest} />}
    // />

    <Controller
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value, name, ref }) => (
        <InputCreditCardExpiry handleDateChange={onChange} {...rest} />
      )}
    />
  );
};

export const ControlledInputCreditCard = ({
  name,
  rules,
  ...rest
}: FieldProps & React.ComponentProps<typeof InputCreditCard>) => {
  return (
    // <Controller
    //   valueName={name}
    //   defaultValue={defaultValue}
    //   name={name}
    //   onChangeName="handleInputChange"
    //   rules={rules}
    //   as={<InputCreditCard {...rest} />}
    // />
    <Controller
      name={name}
      rules={rules}
      render={({ onChange, value }) => (
        <InputCreditCard handleInputChange={onChange} value={value} {...rest} />
      )}
    />
  );
};
