import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../slices';
import { createCheckout, fetchCheckoutAttributes } from '../../../slices/checkout';
import {
  IconExport,
  HeaderMerchant,
  Button,
  media,
  PaymentCalculator,
  PaymentCalculatorData,
} from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import FeeDetails from '../../../components/fee-details';
import FormSection from '../../../components/form-section';
import { MerchantReference } from '@merchant-portal/components/merchant-reference';
import { useQuery } from '../../../util/hooks';
import getConstants from '../../../util/constants';

const { SALE_DETAIL, PAYMENT_FREQUENCIES } = getConstants();

const SCMerchantPlanCreate = styled.div`
  background: ${(props) => props.theme.colours.white};
  .saledetailErrorBody {
    padding-bottom: 20px;
  }

  .saledetails-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;

    ${media.max.medium} {
      margin: 1.6em auto 0;
    }

    ${media.max.ewide} {
      padding: 0 1.6em;
    }

    .intro-text {
      padding: 30px 0 0 0;
    }

    h4 {
      color: #531dff;
      font-weight: bold;
      line-height: 1.3;
    }

    .sale-attributes {
      padding-top: 2em;
      padding-bottom: 2em;

      ${media.min.medium} {
        max-width: 45%;
      }
    }

    .actions {
      display: flex;
      padding-bottom: 30px;

      ${media.max.medium} {
        button:first-of-type {
          margin-right: 0px;
        }
      }

      ${media.min.medium} {
        button {
          max-width: unset;
          padding: 0 2.5em;
        }
      }
    }
  }
`;

type FormData = {
  paymentDetails: PaymentCalculatorData;
  merchantRef?: string;
};

const SaleDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const loggedInUser = useSelector((state: RootState) => state.auth);
  const formState = useSelector((state: RootState) => state.checkout);
  const checkoutId = formState.checkout?.id; // The checkout id in Redux
  const queryParamCheckoutId = query.get('checkoutId'); // The checkout id in the URL
  const [isValid, setIsValid] = useState(false);

  // Load latest checkout resource from server
  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [checkoutId, dispatch, queryParamCheckoutId]);

  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  const reactHookForm = useForm<FormData>({
    defaultValues: {
      paymentDetails: {
        ...formState.paymentDetails,
        paymentPeriod: loggedInUser?.storeConfig?.defaultTerm,
      },
    },
  });
  const { handleSubmit, watch, setValue, errors } = reactHookForm;
  const selectedPaymentFrequency = watch('paymentDetails').paymentFrequency;

  // Update form values when data from redux is populated/modified
  const { paymentDetails } = formState;

  useEffect(() => {
    if (paymentDetails) {
      setValue('paymentDetails', paymentDetails);
    }
  }, [paymentDetails, setValue, errors]);

  if (formState.checkout) {
    setValue('merchantRef', formState.checkout?.merchantReference);
  }

  const { rates, establishmentFees, otherFees } = loggedInUser.merchantRates;

  const formSubmitHandler = (formData: FormData) => {
    if (!checkoutId) {
      dispatch(
        createCheckout({
          paymentDetails: formData.paymentDetails,
          merchantReference: formData.merchantRef,
          successCallback: (createdCheckoutId) => {
            history.push(`/plans/new/customer-details?checkoutId=${createdCheckoutId}`);
          },
        })
      );
    } else {
      history.push(`/plans/new/customer-details?checkoutId=${checkoutId}`);
    }
  };

  useEffect(() => {
    // If a checkout already exists, don't allow the user to modify details
    checkoutId ? setIsReadOnly(true) : setIsReadOnly(false);
  }, [checkoutId]);

  return (
    <SCMerchantPlanCreate>
      <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
        <HeaderMerchant title="New Plan" activeStep={0} setActiveStep={() => 0} />
        <div className="saledetails-body">
          <FormSection title="Sale Detail" text={SALE_DETAIL}>
            <FormProvider {...reactHookForm}>
              <form onSubmit={handleSubmit(formSubmitHandler)}>
                <Controller
                  name="paymentDetails"
                  rules={{
                    validate: (paymentDetails) =>
                      paymentDetails.saleAmount !== 0 &&
                      paymentDetails.depositPercent !== 'N/A' &&
                      paymentDetails.paymentTerm !== 'N/A',
                  }}
                  render={({ onChange, value }) => (
                    <PaymentCalculator
                      type="merchant"
                      rates={rates}
                      paymentFrequencies={PAYMENT_FREQUENCIES}
                      establishmentFees={establishmentFees}
                      otherFees={otherFees}
                      updatePaymentDetails={onChange}
                      paymentDetails={value}
                      readOnly={isReadOnly}
                      onValidationChange={setIsValid}
                    />
                  )}
                />

                <FeeDetails
                  accountKeepingFee={otherFees.monthlyAccountKeepingFee}
                  processingFee={otherFees.paymentProcessingFee}
                  paymentFrequency={selectedPaymentFrequency}
                />

                <div className="sale-attributes">
                  <MerchantReference readOnly={isReadOnly} />
                </div>

                <div className="actions">
                  <Button
                    disabled={formState.loading || !isValid}
                    type="submit"
                    colour="primary"
                    icon={<IconExport />}
                  >
                    Complete Customer Details
                  </Button>
                </div>
              </form>
            </FormProvider>
          </FormSection>
        </div>
      </LoggedInLayout>
    </SCMerchantPlanCreate>
  );
};

export default SaleDetails;
