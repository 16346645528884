declare global {
  interface Window {
    eCrypt: any;
  }
}

const encrypt = (value: string) => {
  return typeof window.eCrypt !== 'undefined'
    ? window.eCrypt.encryptValue(value, process.env.REACT_APP_EWAY_ENCRIPTION_KEY)
    : '';
};
export default encrypt;
