import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../util/store';

import MerchantAPI, { MobileCheckoutData } from '../util/merchant-api';

import AppAPI, {
  AppPreApprovalData,
  PreApprovalCheckoutStep,
  CreatePremiumLimitPayload,
} from '../util/app-api';
import getConstants from '../util/constants';
const { CONTACT_US_PHONE_NUMBER } = getConstants();

type InitialState = {
  loading?: boolean;
  hasErrors?: boolean;
  errorMessage?: string | null;
  preApproval: AppPreApprovalData | null; // search the plan in sugar via app-api
  mobileCheckoutResult: MobileCheckoutData; // get the mobile checkout from merchant database
};

export const initialState: InitialState = {
  loading: false,
  hasErrors: false,
  errorMessage: null,
  preApproval: null,
  mobileCheckoutResult: {
    planId: '',
    saleAmount: 0,
    amountPaidInStore: 0,
    merchantReference: '',
  },
};

const mobileCheckoutSlice = createSlice({
  name: 'mobileCheckout',
  initialState,
  reducers: {
    resetMobileCheckout: () => initialState,
    requestBegin: (state) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: null,
      };
    },
    requestFailed: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    },

    requestComplete: (state) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: null,
      };
    },

    preApprovalRetrieved: (state, action: PayloadAction<AppPreApprovalData>) => {
      return {
        ...state,
        preApproval: action.payload,
      };
    },

    createOrUpdateMobileCheckoutSuccess: (state, action: PayloadAction<MobileCheckoutData>) => {
      return {
        ...state,
        mobileCheckoutResult: action.payload,
      };
    },

    moveToStep: (state, action: PayloadAction<PreApprovalCheckoutStep>) => {
      if (!state.preApproval) {
        return state;
      }

      return {
        ...state,
        preApproval: {
          ...state.preApproval,
          step: action.payload,
        },
      };
    },
  },
});

const {
  requestBegin,
  requestFailed,
  requestComplete,
  preApprovalRetrieved,
  createOrUpdateMobileCheckoutSuccess,
} = mobileCheckoutSlice.actions;

export const { resetMobileCheckout, moveToStep } = mobileCheckoutSlice.actions;
export default mobileCheckoutSlice.reducer;

export const startCheckoutByCheckoutCode =
  (checkoutCode: string): AppThunk<Promise<AppPreApprovalData | null>> =>
  async (dispatch) => {
    try {
      dispatch(requestBegin());
      const result: AppPreApprovalData = await AppAPI.getPreApprovalByCheckoutCodeOrPlanId(
        checkoutCode
      );

      if (result.step === PreApprovalCheckoutStep.COMPLETE) {
        dispatch(
          requestFailed({
            errorMessage: 'The customer has already completed the checkout process for this code.',
          })
        );
        return null;
      }

      dispatch(preApprovalRetrieved(result));
      dispatch(requestComplete());
      return result;
    } catch (e: any) {
      dispatch(
        requestFailed({
          errorMessage:
            'Either code is invalid OR there is no pending pre-approval associated with this checkout code.',
        })
      );
      return null;
    }
  };

export const updateProposedSaleAmount =
  (
    saleAmount: string,
    merchantReference: string,
    planId: string
  ): AppThunk<Promise<AppPreApprovalData>> =>
  async (dispatch) => {
    try {
      dispatch(requestBegin());
      const response = await AppAPI.updateProposedSaleAmount(saleAmount, merchantReference, planId);
      dispatch(preApprovalRetrieved(response));
      dispatch(requestComplete());
      return response;
    } catch (e: any) {
      dispatch(
        requestFailed({
          errorMessage: `An unexpected error occured while trying to update a checkout. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}.`,
        })
      );
      throw e;
    }
  };

export const createPremiumLimitPlan =
  (data: CreatePremiumLimitPayload): AppThunk<Promise<AppPreApprovalData>> =>
  async (dispatch) => {
    try {
      dispatch(requestBegin());
      const result = await AppAPI.createPremiumLimitPlan(data);

      if (result.id === null) {
        throw new Error('Unexpected result from server');
      }

      dispatch(preApprovalRetrieved(result));
      dispatch(requestComplete());
      return result;
    } catch (error: any) {
      // Validation Error
      if (error?.response?.status === 400) {
        dispatch(requestComplete());
        // Throw the error to the component level so it can also be handled there (i.e. server side validation error)
        throw error;
      } else {
        // General errors
        dispatch(
          requestFailed({
            errorMessage: `An unexpected error occured while trying to create a checkout. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}.`,
          })
        );

        throw error;
      }
    }
  };

export const createOrUpdateMobileCheckout =
  (mobileCheckoutData: MobileCheckoutData): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch(requestBegin());
      const result: MobileCheckoutData = await MerchantAPI.createOrUpdateMobileCheckout(
        mobileCheckoutData
      );
      if (result.planId) {
        dispatch(createOrUpdateMobileCheckoutSuccess(result));
        dispatch(requestComplete());
        return true;
      } else {
        return false;
      }
    } catch (e: any) {
      dispatch(
        requestFailed({
          errorMessage: `An unexpected error occured while trying to fetch a checkout. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}.`,
        })
      );
      return false;
    }
  };

export const getPreApprovalByPlanId =
  (planId: string): AppThunk<Promise<AppPreApprovalData | null>> =>
  async (dispatch) => {
    try {
      dispatch(requestBegin());
      const result: AppPreApprovalData = await AppAPI.getPreApprovalByCheckoutCodeOrPlanId(planId);
      dispatch(preApprovalRetrieved(result));
      dispatch(requestComplete());
      return result;
    } catch (e: any) {
      dispatch(
        requestFailed({
          errorMessage: `An unexpected error occured while trying to retrieve the latest status of the checkout. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}.`,
        })
      );
      return null;
    }
  };

export const merchantCancelCheckout =
  (planId: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch(requestBegin());
      await AppAPI.merchantCancelCheckout(planId);
      dispatch(requestComplete());
      return true;
    } catch (e: any) {
      dispatch(
        requestFailed({
          errorMessage: `An unexpected error occured while trying to cancel the checkout. To complete the process, please call Payright Customer Support on ${CONTACT_US_PHONE_NUMBER}.`,
        })
      );
      return false;
    }
  };
