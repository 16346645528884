import React, { useEffect } from 'react';
import { getPreApprovalByPlanId } from '@merchant-portal/slices/mobile-checkout';
import { useDispatch } from 'react-redux';
import Loader from '@merchant-portal/components/loader';
import { Link } from 'react-router-dom';

interface PollingLoaderProps {
  planId: string;
  handleCancelCheckout: Function;
}

const PollingLoader = ({ planId, handleCancelCheckout }: PollingLoaderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getPreApprovalByPlanId(planId));
    }, 5000);

    return () => {
      // on dismount
      clearInterval(intervalId);
    };
  }, [dispatch, planId]);

  return (
    <div className="loader">
      <Loader text="Please wait while the customer completes the purchase in app." />
      <Link
        to="#"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          handleCancelCheckout();
        }}
      >
        Cancel Checkout
      </Link>
    </div>
  );
};

export default PollingLoader;
