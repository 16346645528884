import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import _, { isNumber, isString } from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import LoggedInLayout from '../../layouts/logged-in-layout';
import SCMerchantPlans, { SCMerchantNoPlans, SCFilterMessage } from './css';
import {
  displayPlans,
  updatePlanStatusAndRefresh,
  initNewPlan,
  exportPlansAsCSV,
} from '../../slices/plans';
import Loader from '../../components/loader';
import { lowerCaseKeys, showHideItemsAndMenu, clientTimeZone } from '../../util/helper';
import { isEmptyObject } from '../../util/helper/checks';
import getConstants from '../../util/constants';
import { useQuery, usePrevious } from '../../util/hooks';
import { useConfig } from '@merchant-portal/providers/config-provider/config-provider';
import {
  filterDepositPaymentStatus,
  filterDepositPaidStatus,
} from '../../util/helper/web-components';
import {
  PlansListQueryParams,
  PlansListFilterOptions,
  PlansListFilterDropdowns,
  PlanListSettings,
  FilterDropdownOptions,
} from '@merchant-portal/types/plan';
import {
  IconExport,
  IconAdd,
  Button,
  Search,
  MerchantPlansTable,
  Pagination,
  ModalWrapper,
  ModalGeneric,
  MessageBox,
  IconInfo,
  IconActivate,
} from '@payright/web-components';
import { RootState } from '@merchant-portal/slices';
import Tooltip from 'react-tooltip-lite';

import {
  ApplicationCompletedBy,
  SugarPlanStatus,
  DepositPaymentStatues,
} from '../../util/constants/enums';
import { AppDispatch } from '@merchant-portal/util/store';
import { SCModalMerchantDDO } from '@merchant-portal/pages/dashboard/css';
import { useStoryblok } from '@merchant-portal/providers/storyblok-provider';
import MerchantDDOConsent from '@merchant-portal/pages/merchant-ddo-consent';
import { getMerchantDDODetails } from '@merchant-portal/slices/merchant';
import { FeatureFlags, useFeatures } from '@merchant-portal/providers/features-provider';
import SCSpinner from '@merchant-portal/components/spinner';

const {
  PLAN_APPROVED_STATUS,
  PLAN_NOT_FOUND,
  PLAN_ACTIVATE,
  MERCHANT_PLANS_TABLE_HEADERS,
  SUCCESSFUL_PLAN_ACTIVATION,
  PLAN_ACTIVATION_CONFIRMATION,
  REQUEST_FAILURE,
  PLAN_FILTER_OPTIONS,
  TIMEFRAMES,
  TOOLTIP_PLAN_DETAIL,
  PLAN_FILTER_MESSAGE,
  PLAN_PENDING_STATUS,
  RESEND_CREDIT_SCHEDULE,
  RESEND_APPLICATION_LINK,
  RESEND_VERIFICATION_CODE,
  SUCCESSFUL_RESEND_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE_CONFIRMATION,
  SUCCESSFUL_RESEND_APPLICATION_LINK,
  RESEND_APPLICATION_LINK_CONFIRMATION,
  SUCCESSFUL_RESEND_CREDIT_SCHEDULE,
  RESEND_CREDIT_SCHEDULE_CONFIRMATION,
  PLAN_ACTIVATION_MODAL_TITLE,
  APPROVED_PENDING_ID,
  READABLE_APPROVED_PENDING_ID,
  APPROVED_STATEMENT_REQUESTED,
  READABLE_APPROVED_STATEMENT_REQUESTED,
  SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL,
  RENAME_SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL,
} = getConstants();

const PlansList = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();
  const config = useConfig();
  const { flagsConfig } = useFeatures();
  const canCreateCheckoutAndPlan = flagsConfig[FeatureFlags.CHECKOUT_AND_PLAN_CREATION];

  const { ddoBlockPlanCreationMessage } = useStoryblok();

  const { errorMessage, merchantPlans, loading, submitSucceeded, merchantStaff, metadata } =
    useSelector((state: RootState) => state.plans);
  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const planTableStateKey = 'plansTableState';
  const defaultFilterParams: PlansListQueryParams = { page: 1 };
  const defaultInitState = {
    options: { ...(PLAN_FILTER_OPTIONS as PlansListFilterOptions) },
    dropdowns: {},
    searchQuery: '',
    params: defaultFilterParams,
  };

  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedPlanAction, setSelectedPlanAction] = useState('');
  const [selectedPlanId, setselectedPlanId] = useState('');
  const modalMessage = showActionMessage(selectedPlanAction);
  const { accessItems } = showHideItemsAndMenu();

  const querySearchParameter = query.get('search'); // The search url parameter
  const storedSettings: PlanListSettings = JSON.parse(
    localStorage.getItem(planTableStateKey) ?? '{}'
  );

  // initialState contains objects for several useState() initial states below.
  // We're using lazy initial state so we only calc the state when we first load the component
  // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [initialState] = useState<PlanListSettings & { params: PlansListQueryParams }>(() => {
    return getInitialState(querySearchParameter, storedSettings, defaultInitState);
  });

  const [params, setParams] = useState<PlansListQueryParams>(initialState.params);
  const prevParams = usePrevious(params);
  const [sortBy, setSortBy] = useState<string>('DESC');
  const [selectedOptions, setSelectedOptions] = useState<PlansListFilterOptions>(
    initialState.options
  );
  const [selectedDropDowns, setSelectedDropDowns] = useState<PlansListFilterDropdowns>(
    initialState.dropdowns
  );
  const [searchTerm, setSearchTerm] = useState<string>(initialState.searchQuery ?? '');
  const prevSearchTerm = usePrevious(searchTerm);
  const [searchQuery, setSearchQuery] = useState<string>(initialState.searchQuery);

  const prepareStateForLocalStorage = (): string => {
    let data: PlanListSettings = {
      options: selectedOptions,
      dropdowns: selectedDropDowns,
      searchQuery,
    };

    return JSON.stringify(data);
  };

  const [showDDOModal, setShowDDOModal] = useState(false);
  const { DDOLoading } = useSelector((state: RootState) => state.merchant);

  // Reload the displayed plans when ever the filter params change
  useEffect(() => {
    // Prevent duplicate plans-api requests when there is no change to the filter
    if (JSON.stringify(params) === JSON.stringify(prevParams)) {
      return;
    }

    localStorage.setItem(planTableStateKey, prepareStateForLocalStorage());
    dispatch(displayPlans(params));
  }, [params]);

  // Search
  const submitSearch = () => {
    handleSearch();
  };

  // Builds the searchParams and sets the 'params' state if the it's not empty
  const handleSearch = () => {
    const searchParams = mapSearchQueryToFilters(searchQuery);

    if (searchQuery !== '') {
      setParams({
        ...params,
        page: 1,
        ...searchParams,
      });
    } else {
      const cleanParams = _.omit(params, Object.keys(searchParams));
      setParams({
        ...cleanParams,
        page: 1,
      });
    }
  };

  // Sorting
  const handleSorting = (key: string) => {
    const sortOrder = sortBy === 'ASC' ? 'DESC' : 'ASC';
    setSortBy(sortOrder);
    setParams({
      ...params,
      order: `${key}:${sortOrder}`,
    });
  };

  // TODO: 'clear' sort function

  // set the current page number
  const handlePageChange = (selectedPage: any) => {
    setParams({
      ...params,
      page: selectedPage.selected + 1,
    });
  };

  // Filter
  const handleUpdateFilters = (filterValues: any) => {
    var filterParams: any = {};
    const cleanFilterParams = _.omit(params, [
      'filter[status][in]',
      'filter[staffId][equals]',
      'filter[createdAt][gte]',
    ]);

    // Map options into filter params
    if (filterValues?.options && Object.values(filterValues.options).includes(true)) {
      const options = filterValues.options;
      setSelectedOptions(options);

      const optionFilter = mapOptionsToFilters(options);

      filterParams = {
        ...filterParams,
        ...optionFilter,
      };
    } else {
      setSelectedOptions({ ...defaultInitState.options });
      delete filterParams['filter[status][in]'];
    }

    // Map dropdowns into filter params
    if (filterValues?.dropdowns && !_.isEmpty(filterValues.dropdowns)) {
      const dropDownObject = filterValues.dropdowns;
      setSelectedDropDowns(dropDownObject);

      const dropdownFilters = mapDropdownsToFilters(dropDownObject);

      filterParams = {
        ...filterParams,
        ...dropdownFilters,
      };
    } else {
      setSelectedDropDowns({});
      filterParams = _.omit(filterParams, ['filter[staffId][equals]', 'filter[createdAt][gte]']);
    }

    setParams({
      ...cleanFilterParams,
      page: 1,
      ...filterParams,
    });
  };

  // reset the filters
  const handleResetFilters = () => {
    handleUpdateFilters({});
  };

  // reset the filters
  const handleResetFiltersAndSearch = () => {
    handleUpdateFilters({});
    setSearchTerm('');
  };

  // Search
  // - Don't search if the searchTerm if it is equal to previouse
  // - Set the searchQuery via the beginPlanSearch action - this kicks off a useEffect
  // for the search function
  useEffect(() => {
    if (searchTerm === prevSearchTerm) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      setSearchQuery(searchTerm);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    submitSearch();
  }, [searchQuery]);

  let showMessage;

  // Method to open the Action Modal
  const handleOpenActionModal = (planAction: string, planId: string) => {
    setShowActionModal(true);
    setselectedPlanId(planId);
    setSelectedPlanAction(planAction);
  };

  // Method to close the Action Modal
  const handleCloseActionModal = () => {
    setShowActionModal(false);
    dispatch(displayPlans(params));
  };

  // Method to show detail page
  const handleDetailClick = (planId: string) => {
    history.push(`/plan-detail/${planId}`);
  };

  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={modalMessage.successfulMessageText}
        message=""
        handleOnClick={handleCloseActionModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={REQUEST_FAILURE}
        message=""
        handleOnClick={handleCloseActionModal}
        error={true}
      />
    );
  }

  const showStaff = Object.values(merchantStaff).map((staff) => {
    return {
      name: staff.fullName,
      value: staff.id,
    };
  });

  // Method to get the plan list, if searchitem will be null then it will return all plans
  const planList = merchantPlans;

  // Create new array object as per web-component table requirment
  const restructuredPlans = planList.map((plan, i): any => {
    let displayActivateButton;

    let actions: Array<object> = [];

    // Build actions dropdown
    if (plan.status.toLowerCase() === PLAN_PENDING_STATUS) {
      actions.push({
        label: RESEND_VERIFICATION_CODE,
        icon: <IconActivate />,
        handleClick: handleOpenActionModal,
        handleClickId: plan.id,
      });
    }

    // Show 'Activate' button
    if (
      plan.status.toLowerCase() === PLAN_APPROVED_STATUS &&
      plan.depositPaymentStatus !== '' &&
      !plan.planBlockActivation
    ) {
      displayActivateButton = (
        <Button
          colour="green"
          handleClick={() => handleOpenActionModal('Activate', plan.id)}
          className="button-activate"
          disabled={!accessItems.activatePlans && !accessItems.administrator}
        >
          Activate
        </Button>
      );
    }

    // Show disabled 'Activate' button, if 'Approved' and 'deposit payment status is empty'
    if (plan.status.toLowerCase() === PLAN_APPROVED_STATUS && plan.depositPaymentStatus === '') {
      displayActivateButton = (
        <Button colour="red" className="button-activate" disabled={true}>
          Activate
        </Button>
      );
    }

    // Show disabled 'Activate' button, if 'Approved' and 'deposit payment status is either
    // 'Collected by Merchant', 'Collected by Customer', 'Paid to Merchant' or 'Zero Deposit'
    // and planBlockActivation === true
    if (
      plan.status.toLowerCase() === PLAN_APPROVED_STATUS &&
      (plan.depositPaymentStatus === DepositPaymentStatues.COLLECTED_BY_MERCHANT ||
        plan.depositPaymentStatus === DepositPaymentStatues.PAID_BY_CUSTOMER ||
        plan.depositPaymentStatus === DepositPaymentStatues.PAID_TO_MERCHANT ||
        plan.depositPaymentStatus === DepositPaymentStatues.ZERO_DEPOSIT) &&
      plan.planBlockActivation
    ) {
      displayActivateButton = (
        <Button colour="red" className="button-activate" disabled={true}>
          Activate
        </Button>
      );
    }

    const showActions = {
      handleDetailClick: () => {
        handleDetailClick(plan.id);
      },
      activationButton: displayActivateButton,
      otherActions: actions,
      tooltip: TOOLTIP_PLAN_DETAIL,
    };

    return {
      ID: plan.id,
      merchantPlanName:
        plan.applicationCompletedBy === ApplicationCompletedBy.ECOMMERCE
          ? plan.name
          : ResumePlan(
              plan.status,
              plan.name,
              plan.checkoutId,
              plan.id,
              plan.applicationCompletedBy,
              plan.depositPaymentStatus,
              config.forceCustomerLed
            ),
      merchantReference: plan.merchantReference,
      dateCreated: DateTime.fromISO(plan.dateCreated, { zone: clientTimeZone() }).toFormat(
        'yyyy-MM-dd'
      ),
      customerName: plan.customerName,
      staffName: plan.staffName,
      status:
        plan.status === SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL
          ? RENAME_SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL
          : plan.status.toLowerCase(),
      depositStatus: filterDepositPaymentStatus(plan.depositPaymentStatus, plan.status),
      actions: showActions,
      expandMerchant: true,
    };
  });

  // A new map is running to create a new array object for subrows
  const restructuredSubPlanRows = planList.map((plan): any => {
    let buttonLabel = '';
    let viewActions;

    if (plan.status.toLowerCase() === PLAN_APPROVED_STATUS && storeConfig?.applicationCompleter) {
      buttonLabel = RESEND_CREDIT_SCHEDULE;
    } else if (
      plan.status.toLowerCase() === PLAN_APPROVED_STATUS &&
      !storeConfig?.applicationCompleter
    ) {
      buttonLabel = RESEND_APPLICATION_LINK;
    }

    if (buttonLabel) {
      viewActions = (
        <Button
          colour="blue"
          outline
          size="small"
          handleClick={() => handleOpenActionModal(buttonLabel, plan.id)}
          className="button-other-actions"
        >
          {buttonLabel}
        </Button>
      );
    }

    return {
      purchasePrice: plan.saleAmount,
      depositAmount: plan.depositPaid,
      depositPaidPercentage: plan.depositPaidPercentage,
      depositPaidStatus: filterDepositPaidStatus(plan.depositPaymentStatus, plan.status),
      term: plan.terms,
      settledDate: plan.finalSettlementDate,
      planSettled: plan.merchantSettled,
      creditAmount: plan.creditAmount,
      viewActions: viewActions,
      items: typeof plan.items !== 'undefined' ? plan.items : [],
      shippingAddress: plan.shippingAddress,
      ...((accessItems.administrator || accessItems.exportPlansAndViewPlanMsf) && {
        MSF: plan.msf,
        settleToDate: plan.amountSettledToDate,
      }),
    };
  });

  // Method to export the plans
  const exportPlans = () => {
    let planStatuses = params['filter[status][in]'] ?? ([] as string[]);

    if (planStatuses.length === 0) {
      const ObjectKeys = lowerCaseKeys(selectedOptions);
      planStatuses = Object.keys(ObjectKeys).map((optionsKey) => {
        if (optionsKey === 'approved pending id') {
          return APPROVED_PENDING_ID;
        }
        if (optionsKey === 'approved statement requested') {
          return APPROVED_STATEMENT_REQUESTED;
        }
        return optionsKey;
      });
    }

    const statusesString = planStatuses.join(',');

    dispatch(exportPlansAsCSV(statusesString, clientTimeZone(), selectedDropDowns));
  };

  // Close the modal pop up and set the DDO Consent is false
  const handleCloseModal = () => {
    setShowDDOModal(false);
  };

  return (
    <SCMerchantPlans>
      {/* DDO STUFF */}
      <div>
        <ModalWrapper
          open={showDDOModal}
          handleClose={() => setShowDDOModal(true)}
          background="dark"
        >
          <SCModalMerchantDDO>
            {DDOLoading ? (
              <Loader />
            ) : (
              <ModalGeneric
                text={ddoBlockPlanCreationMessage}
                content={
                  <MerchantDDOConsent
                    closeModalProp={handleCloseModal}
                    showConsent={false}
                    showConfirm={false}
                  />
                }
                className="merchantDDO"
              ></ModalGeneric>
            )}
          </SCModalMerchantDDO>
        </ModalWrapper>
      </div>
      {/* DDO STUFF Ends*/}
      <LoggedInLayout activePage="/plans" contentMarginTop={2}>
        <div className="plans-body">
          <div className="header">
            <h2>Plans</h2>

            <div className="actions">
              {planList.length > 0 &&
                (accessItems.exportPlansAndViewPlanMsf || accessItems.administrator) && [
                  <>
                    <Tooltip
                      content="This download will export raw customer plan data into a CSV file"
                      className="icon"
                    >
                      <IconInfo />
                    </Tooltip>
                    <Button outline colour="blue" icon={<IconExport />} handleClick={exportPlans}>
                      Export
                    </Button>
                  </>,
                ]}
              {accessItems.createPlan && canCreateCheckoutAndPlan ? (
                <Button
                  withShadow
                  icon={DDOLoading ? <SCSpinner /> : <IconAdd />}
                  handleClick={() => {
                    //show popup if a merchant's ddo details are blank, and if the end date has passed
                    dispatch(getMerchantDDODetails())
                      .then((ddoDetails) => {
                        if (ddoDetails.shouldBlockLoanCreation) {
                          setShowDDOModal(true);
                        } else {
                          setShowDDOModal(false);
                          dispatch(initNewPlan());
                          history.push('/plans/new');
                        }
                      })
                      .catch((errorMessage: string) => {
                        console.log(errorMessage);
                      });
                  }}
                >
                  New Plan
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>

          <Search
            placeholder="Search Plans"
            searchText={searchTerm}
            inputChangeHandler={(e) => setSearchTerm(e)}
            filterCheckboxes={selectedOptions}
            filterDropdowns={filterDropDown(showStaff, selectedDropDowns)}
            updateFiltersHandler={handleUpdateFilters}
            resetFiltersHandler={handleResetFilters}
          />

          {loading ? (
            <Loader />
          ) : (
            <>
              {/* add the seach params to the show/hide */}
              {Object.values(selectedOptions).includes(true) ||
              JSON.stringify(selectedDropDowns) !== '{}' ||
              searchQuery !== '' ? (
                <SCFilterMessage>
                  {PLAN_FILTER_MESSAGE}
                  <Link to="#" onClick={handleResetFiltersAndSearch}>
                    {''}
                    click here
                  </Link>
                </SCFilterMessage>
              ) : (
                ''
              )}
              {planList.length > 0 ? (
                [
                  <>
                    <div>
                      <ModalWrapper
                        open={showActionModal}
                        handleClose={() => setShowActionModal(false)}
                        background="dark"
                      >
                        {!submitSucceeded && !errorMessage ? (
                          <ModalGeneric
                            title={modalMessage.ModalTitle}
                            text={modalMessage.confirmationMessageText}
                            primaryButton={
                              <Button
                                handleClick={() =>
                                  dispatch(
                                    updatePlanStatusAndRefresh(
                                      selectedPlanAction !== PLAN_ACTIVATE
                                        ? 'resend-details'
                                        : 'activate',
                                      selectedPlanId
                                    )
                                  )
                                }
                              >
                                {selectedPlanAction !== PLAN_ACTIVATE ? 'Resend' : 'Activate'}
                              </Button>
                            }
                            secondaryButton={
                              <Button
                                outline
                                colour="blue"
                                handleClick={() => setShowActionModal(false)}
                              >
                                Cancel
                              </Button>
                            }
                          ></ModalGeneric>
                        ) : (
                          <div className="message">{showMessage}</div>
                        )}
                      </ModalWrapper>
                    </div>

                    <MerchantPlansTable
                      tableRows={restructuredPlans}
                      subTableRows={restructuredSubPlanRows}
                      tableHeaders={MERCHANT_PLANS_TABLE_HEADERS}
                      tableSelect={false}
                      onSortChange={handleSorting}
                    />
                    <div>
                      {metadata.count > 1 && (
                        <Pagination
                          pageCount={metadata.lastPage}
                          handlePageChange={handlePageChange}
                          currentPage={metadata.currentPage - 1}
                        />
                      )}
                    </div>
                  </>,
                ]
              ) : (
                <SCMerchantNoPlans>{PLAN_NOT_FOUND}</SCMerchantNoPlans>
              )}
            </>
          )}
        </div>
      </LoggedInLayout>
    </SCMerchantPlans>
  );
};

const getInitialState = (
  querySearchParameter: string | null,
  storedSettings: PlanListSettings,
  defaultInitState: PlanListSettings & { params: PlansListQueryParams }
): PlanListSettings & { params: PlansListQueryParams } => {
  // Use query string search if it's present
  const queryOptions = { ...defaultInitState.options };
  Object.keys(queryOptions).forEach((obj) => {
    queryOptions[obj as keyof PlansListFilterOptions] = obj === querySearchParameter ? true : false;
  });

  // Use query string 'search' for initial state of search/filter
  if (
    Object.keys(queryOptions).some(
      (key) => queryOptions[key as keyof PlansListFilterOptions] === true
    )
  ) {
    const optionsFilter = mapOptionsToFilters(queryOptions);

    return {
      options: queryOptions,
      dropdowns: {},
      searchQuery: '',
      params: {
        ...defaultInitState.params,
        ...optionsFilter,
      },
    };
  }

  // Restore localStorage filter/search settings
  if (!isEmptyObject(storedSettings)) {
    const safeSettings = validateStoredSettings(storedSettings, defaultInitState);

    const { options, dropdowns, searchQuery } = safeSettings;
    const storedFilters = mapFilters(safeSettings);

    return {
      options,
      dropdowns,
      searchQuery,
      params: {
        ...defaultInitState.params,
        ...storedFilters,
      },
    };
  }

  // Use default
  return defaultInitState;
};

// Todo: create type for options
const mapOptionsToFilters = (options: PlansListFilterOptions): PlansListQueryParams => {
  if (options) {
    const filterOptions = lowerCaseKeys(options);

    Object.keys(filterOptions).forEach((obj) => {
      if (filterOptions[obj] === true) {
        return obj;
      } else {
        delete filterOptions[obj];
      }
    });

    let validKeys: any = Object.keys(filterOptions);

    validKeys = validKeys.map((planFilterStatus: any) => {
      if (planFilterStatus === READABLE_APPROVED_PENDING_ID.toLowerCase()) {
        return APPROVED_PENDING_ID;
      }
      if (planFilterStatus === READABLE_APPROVED_STATEMENT_REQUESTED.toLowerCase()) {
        return APPROVED_STATEMENT_REQUESTED;
      }
      return planFilterStatus;
    });

    return { ['filter[status][in]']: validKeys };
  }

  return {};
};

// Todo: create type for options
const mapDropdownsToFilters = (dropdowns: PlansListFilterDropdowns): PlansListQueryParams => {
  let filterParams: PlansListQueryParams = {};

  if (dropdowns?.staffMember) {
    filterParams['filter[staffId][equals]'] = dropdowns.staffMember;
  }

  if (dropdowns?.timeFrames) {
    var dt = DateTime.now();
    filterParams['filter[createdAt][gte]'] = dt
      .minus({ days: parseInt(dropdowns.timeFrames) })
      .toISODate();
  }

  return filterParams;
};

const mapSearchQueryToFilters = (searchQuery: string): PlansListQueryParams => {
  let searchParams: any = {};
  searchParams['filter[0][or][0][customerFirstName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][customerLastName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][planName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][reference][contains]'] = searchQuery;
  searchParams['filter[0][or][0][staffFirstName][contains]'] = searchQuery;
  searchParams['filter[0][or][0][staffLastName][contains]'] = searchQuery;

  return searchParams;
};

const mapFilters = (state: {
  options: PlansListFilterOptions;
  dropdowns: PlansListFilterDropdowns;
  searchQuery: string;
}): PlansListQueryParams => {
  const { options, dropdowns, searchQuery } = state;

  const optionsFilter = mapOptionsToFilters(options);
  const dropdownFilters = mapDropdownsToFilters(dropdowns);
  const searchFilter = searchQuery !== '' ? mapSearchQueryToFilters(searchQuery) : {};

  return {
    ...optionsFilter,
    ...dropdownFilters,
    ...searchFilter,
  };
};

const validateStoredSettings = (
  storedSettings: PlanListSettings,
  defaultSettings: PlanListSettings
): PlanListSettings => {
  const { options, dropdowns, searchQuery } = storedSettings;

  // SchemaOf<> instead of ObjectSchema<> because of this issue:
  // https://github.com/jquense/yup/issues/1183
  // Need to review in the future
  // Last reviewed: 28/2/22
  const optionsSchema: yup.SchemaOf<PlansListFilterOptions> = yup.object({
    Active: yup.boolean().required(),
    Approved: yup.boolean().required(),
    Pending: yup.boolean().required(),
    Declined: yup.boolean().required(),
    Closed: yup.boolean().required(),
    Cancelled: yup.boolean().required(),
    Review: yup.boolean().required(),
    'Approved Pending ID': yup.boolean().required(),
    'Approved Statement Requested': yup.boolean().required(),
  });

  const dropdownSchema: yup.SchemaOf<PlansListFilterDropdowns> = yup.object({
    staffMember: yup.string().optional(),
    timeFrames: yup
      .string()
      .transform((value) => (isNumber(value) ? value.toString() : value))
      .optional(),
  });

  // Validations
  const validOptions = optionsSchema.isValidSync(options, {
    abortEarly: true,
  })
    ? options
    : defaultSettings.options;

  const validDropdowns = dropdownSchema.isValidSync(dropdowns, {})
    ? dropdowns
    : defaultSettings.dropdowns;

  const validSearchQuery = yup.string().isValidSync(searchQuery)
    ? searchQuery
    : defaultSettings.searchQuery;

  return {
    options: { ...validOptions },
    dropdowns: { ...validDropdowns },
    searchQuery: validSearchQuery,
  };
};

/** Method to create a filter drop down in search */
const filterDropDown = (
  staffList: Array<any>,
  selectedDropDowns: PlansListFilterDropdowns
): Array<FilterDropdownOptions> => {
  return [
    {
      id: 'timeFrames',
      label: 'Settled',
      placeholder: 'All Time',
      options: TIMEFRAMES,
      selected: selectedDropDowns?.timeFrames ?? '',
    },
    {
      id: 'staffMember',
      label: 'Staff Member',
      placeholder: 'All Staff',
      options: staffList,
      selected: selectedDropDowns?.staffMember ?? '',
    },
  ];
};

/** Method to select the message text/title/confirmation on the basis of plan action*/
const showActionMessage = (planAction: string) => {
  let confirmationMessageText = '';
  let successfulMessageText = '';
  let ModalTitle = '';
  switch (planAction) {
    case RESEND_VERIFICATION_CODE:
      confirmationMessageText = RESEND_VERIFICATION_CODE_CONFIRMATION;
      successfulMessageText = SUCCESSFUL_RESEND_VERIFICATION_CODE;
      ModalTitle = RESEND_VERIFICATION_CODE;
      break;
    case RESEND_APPLICATION_LINK:
      confirmationMessageText = RESEND_APPLICATION_LINK_CONFIRMATION;
      successfulMessageText = SUCCESSFUL_RESEND_APPLICATION_LINK;
      ModalTitle = RESEND_APPLICATION_LINK;
      break;
    case RESEND_CREDIT_SCHEDULE:
      confirmationMessageText = RESEND_CREDIT_SCHEDULE_CONFIRMATION;
      successfulMessageText = SUCCESSFUL_RESEND_CREDIT_SCHEDULE;
      ModalTitle = RESEND_CREDIT_SCHEDULE;
      break;
    case PLAN_ACTIVATE:
      confirmationMessageText = PLAN_ACTIVATION_CONFIRMATION;
      successfulMessageText = SUCCESSFUL_PLAN_ACTIVATION;
      ModalTitle = PLAN_ACTIVATION_MODAL_TITLE;
      break;
    default:
  }
  return { confirmationMessageText, successfulMessageText, ModalTitle };
};

/*** Redirect the plan application based on the status */
const ResumePlan = (
  status: any,
  planName: string,
  checkoutId: string,
  planId: string,
  applicationCompletedBy: string,
  depositPaymentStatus: string,
  customerLedOnly: boolean
) => {
  let resumePlanLink;

  const allowResume =
    applicationCompletedBy === ApplicationCompletedBy.MERCHANT &&
    depositPaymentStatus === '' &&
    !customerLedOnly;

  if (status === SugarPlanStatus.APPROVED && allowResume) {
    resumePlanLink = (
      <Link to={`/plans/resume?checkoutId=${checkoutId}&planId=${planId}&page=payment`}>
        {planName}
      </Link>
    );
  } else if (status === SugarPlanStatus.APPROVED_PENDING_ID && allowResume) {
    resumePlanLink = (
      <Link to={`/plans/resume?checkoutId=${checkoutId}&planId=${planId}&page=plan-result`}>
        {planName}
      </Link>
    );
  } else if (status === SugarPlanStatus.PENDING && allowResume) {
    resumePlanLink = (
      <Link to={`/plans/resume?checkoutId=${checkoutId}&planId=${planId}&page=customer-details`}>
        {planName}
      </Link>
    );
  } else {
    resumePlanLink = planName;
  }
  return resumePlanLink;
};

export default PlansList;
