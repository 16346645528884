import React from 'react';
import { Button, Card, IconEmail, IconPhone, IconContact } from '@payright/web-components';
import { StoreSuccessResponse } from '../../../util/merchant-api';

const ContactCard = () => {
  // Retrieve merchant store details, with Payright BDM contact details
  const storeConfigData: StoreSuccessResponse['storeConfig'] = JSON.parse(
    localStorage.getItem('merchantStoreInfo') || '{}'
  );

  if (!storeConfigData.accountManager) {
    return null;
  }

  return (
    <div className="contact-card">
      <Card>
        <div className="card-top">
          <div className="contact-icon">
            <IconContact />
          </div>
          <div>
            <h4>Your Account Manager</h4>
            <h5>
              {storeConfigData.accountManager.name}
              <br />
              {storeConfigData.accountManager.email}
            </h5>
          </div>
        </div>
        <div className="card-bottom">
          {storeConfigData.accountManager.phoneWork !== '' && (
            <Button
              type="submit"
              colour="blue"
              className="call"
              icon={<IconPhone />}
              outline
              handleClick={() => {
                window.open('tel:' + (storeConfigData.accountManager?.phoneWork || ''));
              }}
            >
              {storeConfigData.accountManager.phoneWork}
            </Button>
          )}

          {storeConfigData.accountManager.email !== '' && (
            <Button
              type="submit"
              colour="blue"
              className="email"
              outline
              icon={<IconEmail />}
              handleClick={() => {
                window.open('mailto:' + (storeConfigData.accountManager?.email || ''));
              }}
            >
              Email
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ContactCard;
