import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { updateMyProfileDetails, displayMyProfileDetails } from '../slices/profile';
import SCAddStaff from './staff/css';
import { Button, MessageBox, ModalWrapper } from '@payright/web-components';
import LoggedInLayout from '../layouts/logged-in-layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ControlledInputField } from '../components/form-fields';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';
import Loader from '../components/loader';
import getConstants from '../util/constants';

// Yup is a JavaScript schema builder for value parsing and validation
// TODO: upgrade RHF and remove references to @hookform/resolvers/yup/dist
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd';
import { generalStaffInfoValidationSchema } from '../util/validation/form-validation';

// Import default 'base' staff type
import { Staff } from '../types/staff';

const { SUCCESSFUL_STAFF_UPDATE, UNSUCCESSFUL_STAFF_UPDATE } = getConstants();

const UpdateProfile = (staff: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { errorMessage, profileDetails, submitSucceeded, loading } = useSelector(
    (state: RootState) => state.profile
  );

  const [showMessageModal, setShowMessageModal] = useState(false);
  let showMessage;

  const reactHookForm = useForm<Staff>({
    defaultValues: profileDetails,
    resolver: yupResolver(generalStaffInfoValidationSchema),
  });

  const { setValue, handleSubmit, errors, watch } = reactHookForm;

  // Method to close Message Modal and redirect
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    if (errorMessage === null) history.push('/dashboard'); // Redirect to dashboard
  };

  // Display the messages
  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={SUCCESSFUL_STAFF_UPDATE}
        message=""
        handleOnClick={handleCloseMessageModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={UNSUCCESSFUL_STAFF_UPDATE}
        message=""
        handleOnClick={handleCloseMessageModal}
        error={true}
      />
    );
  }

  // form submit method
  const onSubmit = (data: any) => {
    dispatch(updateMyProfileDetails(data));
  };

  useEffect(() => {
    setShowMessageModal(submitSucceeded || errorMessage !== null);
  }, [submitSucceeded, errorMessage]);

  useEffect(() => {
    dispatch(displayMyProfileDetails());
  }, [dispatch]);

  // Update form values when data from redux is populated/modified
  useEffect(() => {
    Object.keys(profileDetails).forEach((key) => {
      setValue(`${key}`, Reflect.get(profileDetails, key));
    });
  }, [profileDetails, setValue, dispatch]);

  return (
    <SCAddStaff>
      <LoggedInLayout activePage="account">
        <div className="staff-body">
          <div className="title"></div>
          <div className="header">
            <h2>My Profile</h2>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <FormProvider {...reactHookForm}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <ModalWrapper
                    open={showMessageModal}
                    handleClose={() => setShowMessageModal(false)}
                    background="dark"
                  >
                    <div className="message">{showMessage}</div>
                  </ModalWrapper>
                </div>

                <div className="details-wrapper-body">
                  <Grid fluid>
                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Basic Details</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={5} lg={6}>
                                <h6 className="label">First Name *</h6>
                                <ControlledInputField
                                  name="firstName"
                                  error={errors.firstName?.message as string}
                                ></ControlledInputField>
                              </Col>

                              <Col xs={12} md={5} lg={5}>
                                <h6 className="label">Last Name *</h6>
                                <ControlledInputField
                                  name="lastName"
                                  error={errors.lastName?.message as string}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={9} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={9} lg={6}>
                                <h6 className="label">Job Title</h6>
                                <ControlledInputField
                                  name="jobTitle"
                                  error={errors.jobTitle?.message as string}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Contact Details</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={6} lg={6}>
                                <h6 className="label">Email Address *</h6>
                                <ControlledInputField
                                  name="email"
                                  error={errors.email?.message as string}
                                  className="email"
                                  type="email"
                                ></ControlledInputField>
                              </Col>
                              <Col xs={12} md={6} lg={5}>
                                <h6 className="label">Mobile No. *</h6>
                                <ControlledInputField
                                  name="phoneMobile"
                                  error={errors.phoneMobile?.message as string}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={9} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={9} lg={6}>
                                <h6 className="label">Work Phone No.</h6>
                                <ControlledInputField
                                  name="phoneWork"
                                  error={errors.phoneWork?.message as string}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Credentials</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={6} lg={6}>
                                <h6 className="label">Username</h6>
                                <ControlledInputField
                                  name="userName"
                                  readOnly={true}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={6} lg={6}>
                                <h6 className="label">Password</h6>
                                <ControlledInputField
                                  name="password"
                                  rules={{
                                    required: true,
                                    minLength: 8,
                                    pattern:
                                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*[\]"';:_\-<>., =+/\\]).{8,}$/,
                                  }}
                                  error={
                                    errors.password &&
                                    'Password strength does not meet the requirements'
                                  }
                                  type="password"
                                ></ControlledInputField>
                                <div className="passwordMessage">
                                  <ul>
                                    <li>- Minimum 8 characters</li>
                                    <li>- 1 upper case letter</li>
                                    <li>- 1 lower case letter</li>
                                    <li>- 1 special character</li>
                                  </ul>
                                </div>
                              </Col>
                              <Col xs={12} md={6} lg={5}>
                                <h6 className="label">Confirm Password</h6>
                                <ControlledInputField
                                  name="confirmPassword"
                                  rules={{
                                    required: 'Required',
                                    validate: (value: string) => value === watch('password'),
                                  }}
                                  error={
                                    errors.confirmPassword &&
                                    'Confirm Password should be same as Password'
                                  }
                                  type="password"
                                ></ControlledInputField>
                                {!errors.confirmPassword && (
                                  <div className="passwordMessage">
                                    Confirm Password should match with Password.
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row end="xs">
                      <Col xs={12} md={6} lg={9}>
                        <Row end="xs">
                          {' '}
                          <Col xs={6} md={6} lg={3}>
                            <div>
                              <Button
                                className="actions"
                                outline
                                colour="blue"
                                handleClick={() => history.push('/dashboard')}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                          <Col xs={6} md={6} lg={3}>
                            <div>
                              <Button className="actions">Update Details</Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Grid>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </LoggedInLayout>
    </SCAddStaff>
  );
};

export default UpdateProfile;
