/**
 * DEV NOTE!!!!
 *
 * There is a Region interface inside ./constants/index which contains all
 * these modules.
 * IF YOU ADD, REMOVE OR MODIFY A MODULES NAME PLEASE UPDATE THE INTERFACE!
 */

export const REGION_STATES = [
  { name: 'Northland', value: 'NTL' },
  { name: 'Auckland', value: 'AUK' },
  { name: 'Waikato', value: 'WKO' },
  { name: 'Bay of Plenty', value: 'BOP' },
  { name: 'Gisborne', value: 'GIS' },
  { name: "Hawke's Bay", value: 'HKB' },
  { name: 'Taranaki', value: 'TKI' },
  { name: 'Manawatū-Whanganui', value: 'MWT' },
  { name: 'Wellington', value: 'WGN' },
  { name: 'Tasman', value: 'TAS' },
  { name: 'Nelson', value: 'NSN' },
  { name: 'Marlborough', value: 'MBH' },
  { name: 'West Coast', value: 'WTC' },
  { name: 'Canterbury', value: 'CAN' },
  { name: 'Otago', value: 'OTA' },
  { name: 'Southland', value: 'STL' },
];

export const PAYMENT_FREQUENCIES = [
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Fortnightly', value: 'Fortnightly' },
  { name: 'Monthly', value: 'Monthly' },
];

export const BUSINESS_NUMBER = 'NZBN';

// Validation
export const PHONE_VALIDATION_REGEX =
  /^\(?(?:\+?64|0)2\)?(?:[ -]?[0-9]){6,8}[0-9]$|^([09]|[04]|[06]|[07]|[03]|[08]|[05]){2}[0-9]{7,8}$/;
export const MOBILE_VALIDATION_REGEX = /^\(?(?:\+?64|0)2\)?(?:[ -]?[0-9]){6,8}[0-9]$/;
export const LICENCE_VALIDATION_REGEX = /^[a-zA-Z]{2}[0-9]{6}$/;
export const CHECKOUT_CODE_REGEX = /^[1-9A-NP-Z]{8}$/;

// Phone no validation text
export const MOBILE_NUMBER_FORMAT_VALIDATION = 'Please use a mobile number';

// Messages
export const MARKETING_CHECKBOX_TEXT =
  'Please check if the customer wishes to receive promotional emails & other electronic communications from Payright.';

export const HELPDESK_EMAIL = 'admin@payright.co.nz';

// Contact Us Phone no
export const CONTACT_US_PHONE_NUMBER_URL = '0800338496';
export const CONTACT_US_PHONE_NUMBER = '0800 338 496';

export const TELL_YOUR_CUSTOMER_LINK = 'https://payright.co.nz';
export const PAYRIGHT_MERCHANT_V1 = 'https://payrightmerchant.payright.co.nz/';

export const LABEL_STATE = 'Town / City';
export const LABEL_SUBURB = 'Suburb';

/** New Plan Form */

export const MERCHANT_MARKETING_CONSENT_TEXT =
  'The customer consents to receiving promotional material (including emails) from Payright.';

export const PAYMENT_PERIODS = [
  { name: '2 months', value: 2 },
  { name: '3 months', value: 3 },
  { name: '6 months', value: 6 },
  { name: '9 months', value: 9 },
  { name: '12 months', value: 12 },
  { name: '17 months', value: 17 },
  { name: '18 months', value: 18 },
  { name: '24 months', value: 24 },
  { name: '30 months', value: 30 },
  { name: '36 months', value: 36 },
  { name: '48 months', value: 48 },
  { name: '60 months', value: 60 },
];

export const IDENTIFICATION_YEARS = 15;

export const CUSTOMISE_LINK_TYPE = [
  { name: 'Invoice', value: 'invoice' },
  { name: 'Quote', value: 'quote' },
];
// Side Panel Text For New Plan
export const CUSTOMER_IDENTIFICATION =
  'The ID supplied must be a current New Zealand Passport or a New Zealand Driver’s Licence. The ID must not be expired.';

/** Plan Result Messages */
// PLAN LIST PAGE LIMIT
export const PLAN_LIST_PAGE_LIMIT = 10;
/** Sugar plan status for pre-approval */
export const SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL = 'Approve_Pre Approval';
/** Rename Pre approval status for web-componet */
export const RENAME_SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL = 'approved_pre_approval';
