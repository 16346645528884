import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@merchant-portal/util/store';
import { useStoryblok } from '@merchant-portal/providers/storyblok-provider';

import { Card, IconExport } from '@payright/web-components';
import styled from 'styled-components';
import { useRegion } from '@merchant-portal/util/hooks';

import { DateTime } from 'luxon';

import { useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';

// custom css for icon image, left-side of announcement card
const SCCardImage = styled.div`
  .card-image--small {
    color: #531dff;
    max-width: 30px !important;
    max-height: auto !important;
  }
  .card-image--medium {
    width: 50px;
    height: auto;
  }
`;

// provide region hide/show control with storyblok component, 'region' field
const getRegion = useRegion();
const toggleShowRegion = (itemRegion: string) => {
  return getRegion === itemRegion || itemRegion === 'all';
};

// provide first_publish hide/show control with storyblok component, 'first_publish' field
const toggleShowFirstPublish = (firstPublish: string) => {
  const defaultDatetime = DateTime.now().toFormat('yyyy-MM-dd T').toString();
  const convertedFirstPublish = firstPublish ? firstPublish : defaultDatetime;

  return firstPublish
    ? DateTime.fromFormat(convertedFirstPublish, 'yyyy-MM-dd T', { zone: 'UTC' }).diffNow([
        'minutes',
      ]).minutes <= 0
    : true; // `first_publish` is nullable, if null then still show announcement
};

// provide last_publish hide/show control with storyblok component, 'last_publish' field
const toggleShowLastPublish = (lastPublish: string) => {
  return lastPublish
    ? DateTime.fromFormat(lastPublish, 'yyyy-MM-dd T', { zone: 'UTC' }).diffNow(['minutes'])
        .minutes >= 0
    : true; // `last_publish` is nullable, if null then still show announcement
};

const AnnouncementCards = () => {
  const dispatch: AppDispatch = useDispatch();
  const { dashboard } = useStoryblok();
  const { loading } = useSelector((state: RootState) => state.auth);

  const [showAnnouncements, setShowAnnouncements] = useState<any | null>(null);

  const filteredShowAnnouncementsLength =
    dashboard?.announcements.filter((item: any) => {
      return (
        toggleShowRegion(item.region) &&
        toggleShowFirstPublish(item.first_publish) &&
        toggleShowLastPublish(item.last_publish)
      );
    }).length ?? 0;

  const cardElements =
    dashboard && filteredShowAnnouncementsLength > 0 ? (
      dashboard?.announcements
        .filter((item: any) => {
          return (
            toggleShowRegion(item.region) &&
            toggleShowLastPublish(item.last_publish) &&
            toggleShowFirstPublish(item.first_publish)
          );
        })
        .map((item: any, index: number) => (
          <Fragment key={index}>
            <div className="announcement-card">
              <Card>
                <div className="card-icon">
                  <SCCardImage>
                    {item.icon.filename.includes('.svg') ? (
                      <svg id={item.icon.id} viewBox="0 0 24 24" width="24" height="24">
                        <image xlinkHref={item.icon.filename} />
                      </svg>
                    ) : (
                      <img
                        className={item.custom_css}
                        src={item.icon.filename}
                        alt={item.icon.alt}
                      />
                    )}
                  </SCCardImage>
                </div>
                <div className="card-text">
                  <h4>{item.heading}</h4>
                  <p className="main-text">
                    {item.content.content.map((item: any) => {
                      // a sub-map for 'hard-breaks' in rich-text-editor
                      return item.content.map((item: any, index: number) => {
                        if (item.marks) {
                          switch (item.marks[0].type) {
                            case 'link':
                              return (
                                <a
                                  href={item.marks[0].attrs.href}
                                  target={item.marks[0].attrs.target}
                                  key={index}
                                >
                                  {item.text}
                                </a>
                              );
                            case 'bold':
                              return <strong key={index}>{item.text}</strong>;
                            default:
                              return item.text;
                          }
                        }
                        return item.text;
                      });
                    })}
                  </p>
                </div>
              </Card>
            </div>
            <div className="heading"></div>
          </Fragment>
        ))
    ) : (
      <>
        <div className="announcement-card">
          <Card>
            <div className="card-icon">
              <IconExport />
            </div>
            <div className="card-text">
              <h4>Good news, you're up to date!</h4>
              <p className="main-text">There are no new announcements.</p>
            </div>
          </Card>
        </div>
        <div className="heading"></div>
      </>
    );

  return <>{!loading && cardElements}</>;
};

export default AnnouncementCards;
