import React from 'react';
import { ControlledInputField } from '@merchant-portal/components/form-fields';
import { useFormContext } from 'react-hook-form';
import { Button, IconNext, IconInfo } from '@payright/web-components';
import Tooltip from 'react-tooltip-lite';
import Spinner from '@merchant-portal/components/spinner';
import { useSelector, useDispatch } from '@merchant-portal/util/store';
import {
  createOrUpdateMobileCheckout,
  updateProposedSaleAmount,
  resetMobileCheckout,
  createPremiumLimitPlan,
} from '@merchant-portal/slices/mobile-checkout';
import { FormData } from './mobile-checkout-form';
import { ProductType, CreatePremiumLimitPayload } from '@merchant-portal/util/app-api';

type EnterSaleDetailsFormProps = {};

const EnterSaleDetailsForm = (props: EnterSaleDetailsFormProps) => {
  const dispatch = useDispatch();
  const { errors, formState, handleSubmit, setValue, reset, clearErrors, getValues, setError } =
    useFormContext<FormData>();

  const { loading, preApproval } = useSelector((state) => state.mobileCheckout);

  const setPaymentPlanAmount = (saleAmount: number, amountPaidInStore: number) => {
    setValue('paymentPlanAmount', (saleAmount - amountPaidInStore).toFixed(2), {
      shouldValidate: true,
    });
  };

  // Calculate Payment Plan amount
  const onSaleAmountChanged = async (event: any) => {
    const saleAmount = parseFloat(event.target.value);
    const amountPaidInStore = parseFloat(getValues('amountPaidInStore'));

    setPaymentPlanAmount(
      isNaN(saleAmount) ? 0 : saleAmount,
      isNaN(amountPaidInStore) ? 0 : amountPaidInStore
    );
  };

  // Calculate Payment Plan amount
  const onAmountPaidInStoreChanged = async (event: any) => {
    const saleAmount = parseFloat(getValues('saleAmount'));
    const amountPaidInStore = parseFloat(event.target.value);

    setPaymentPlanAmount(
      isNaN(saleAmount) ? 0 : saleAmount,
      isNaN(amountPaidInStore) ? 0 : amountPaidInStore
    );
    return;
  };

  // Form submit - create checkout row, update plan with new sale amounts
  const onSubmit = async (formData: FormData) => {
    if (preApproval?.id === null && preApproval.productType === ProductType.PREMIUM_LIMIT) {
      const payload: CreatePremiumLimitPayload = {
        saleAmount: parseFloat(formData.paymentPlanAmount),
        customerId: preApproval.customerId,
        checkoutCode: formData.checkoutCode,
        merchantReference: formData.merchantReference,
      };

      try {
        const result = await dispatch(createPremiumLimitPlan(payload));

        await dispatch(
          createOrUpdateMobileCheckout({
            planId: result.id as string,
            saleAmount: parseFloat(formData.paymentPlanAmount),
            amountPaidInStore:
              formData.amountPaidInStore === ''
                ? undefined
                : parseFloat(formData.amountPaidInStore),
            merchantReference: formData.merchantReference,
          })
        );
      } catch (e: any) {
        // Server side form validation error
        if (e?.response?.status === 400) {
          setError('paymentPlanAmount', {
            type: 'validate',
            message: e.response.data.error.message,
          });
        }

        // Other general errors caught and handled by redux
      }
    }
    if (preApproval?.id) {
      const planId = preApproval!.id;
      const mobileCheckoutParameters = {
        planId,
        saleAmount: parseFloat(formData.paymentPlanAmount), // Updated sale amount
        amountPaidInStore:
          formData.amountPaidInStore === '' ? undefined : parseFloat(formData.amountPaidInStore),
        merchantReference: formData.merchantReference,
      };
      try {
        await dispatch(
          updateProposedSaleAmount(formData.paymentPlanAmount, formData.merchantReference, planId)
        );
        await dispatch(createOrUpdateMobileCheckout(mobileCheckoutParameters));
      } catch (e) {
        // errors caught and handled by redux
      }
    }
  };

  // clear form
  const resetEntireFormAndState = () => {
    reset(); // Reset the React Hook Form to default values
    clearErrors();
    dispatch(resetMobileCheckout()); // Reset the Redux state
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="field">
            <ControlledInputField
              name="saleAmount"
              prefix="$"
              placeholder=" Enter sale amount"
              handleInputBlur={onSaleAmountChanged}
              error={errors.saleAmount?.message as string}
            >
              Sale amount *
            </ControlledInputField>
          </div>

          <div className="field">
            <ControlledInputField
              name="amountPaidInStore"
              prefix="$"
              placeholder=" Enter amount paid up front"
              error={errors.amountPaidInStore?.message as string}
              handleInputBlur={onAmountPaidInStoreChanged}
            >
              <>
                <div>
                  Amount paid up front (if applicable)
                  <Tooltip
                    content={
                      'If the customer wants to pay an amount up front directly to you during the checkout, you can enter that amount here. The new Payment Plan amount will then be calculated.'
                    }
                    className="tooltip"
                  >
                    {' '}
                    <IconInfo />
                  </Tooltip>
                </div>
                <div className="red-field-label">
                  <strong>Please note:</strong> This is separate from the deposit.
                </div>
              </>
            </ControlledInputField>
          </div>
          <div className="field">
            <ControlledInputField
              name="merchantReference"
              placeholder="For your reference (optional)"
            >
              Merchant reference
            </ControlledInputField>
          </div>

          <div className="field">
            <ControlledInputField
              name="paymentPlanAmount"
              readOnly={true}
              placeholder="$00.00"
              error={errors.paymentPlanAmount?.message as string}
              prefix="$"
            >
              Payment Plan amount
            </ControlledInputField>
          </div>

          <div className="actions">
            <Button
              type="button"
              className="button-clear-form"
              handleClick={resetEntireFormAndState}
              outline
              colour="blue"
            >
              Clear Form
            </Button>
            <Button
              type="submit"
              colour="primary"
              size="medium"
              className="button-submit"
              iconPosition="right"
              icon={loading ? <Spinner /> : <IconNext />}
              disabled={loading || !formState.isDirty}
            >
              Send to customer
            </Button>
          </div>
        </>
      </form>
    </>
  );
};

export default EnterSaleDetailsForm;
