import React from 'react';
import styled from 'styled-components';
import { Alert, IconInfo, media } from '@payright/web-components';
import { useRegion } from '@merchant-portal/util/hooks';

type ConfirmationMessageProps = {
  /** Deposit Taker flag - If true, the merchant collects the deposit manually without using Payright */
  depositTaker: boolean;
  depositAmount: number;
};

const SCMessage = styled.div`
  background-color: ${(props) => props.theme.colours.secondary3};
  border-radius: 10px;
  padding: 1.5em 1em;
  text-align: center;
  font-size: 1em;
  ${media.min.large} {
    font-size: 1.13em;
  }
`;

const ConfirmationMessage = ({ depositTaker, depositAmount }: ConfirmationMessageProps) => {
  // TODO:

  // application completer Y
  // ** deposit-taker Y -- by confirming the deposit arrangements
  // ** deposit-taker N -- the plan deposit of $X.XX will be charged
  // application completer N
  // ** deposit taker Y -- by confirming the deposit arrangements + we have just sent a link
  // ** deposit-taker N -- we have just sent a link

  const region = useRegion();

  return (
    <>
      {depositTaker ? (
        <Alert
          title={`Pay Deposit In Store - PLEASE NOTE or READ TO CUSTOMER`}
          body={
            <>
              <p>
                By confirming the deposit arrangements you acknowledge and confirm your agreement to
                the Credit and Disclosure Statement and Terms & Conditions for your Payright loan
                that have been emailed to you.
              </p>
              {region === 'nz' && (
                <>
                  <p>
                    <span className="bold">Important:</span> The Credit and Disclosure Statement for
                    this Payright Loan has been sent to the applicant's nominated email address.
                    Applicants must read this Statement carefully before paying a deposit or
                    confirming the deposit arrangements as these actions represent the applicant's
                    agreement to the Credit and Disclosure Statement and the Payright Terms and
                    Conditions, (previously provided to the applicant) and establish a binding
                    credit contract between the customer and the Payright.
                  </p>
                  <p>
                    <span className="bold">Note to Merchants:</span> where you are assisting your
                    customer with their Payright application, please ensure applicants are aware of
                    their application status and advice them that payment of a deposit or confirming
                    the deposit arrangements result in a legally binding credit contract between the
                    customer and Payright
                  </p>
                </>
              )}
              <p>
                Payright will not activate this loan until it receives confirmation from the
                Merchant {depositAmount > 0 && `that the deposit of $${depositAmount} is paid`} and
                the customer has agreed to receive the goods or services from the Merchant in
                accordance with the Merchant’s contract terms.
              </p>
            </>
          }
          outcome="error"
          icon={<IconInfo />}
        />
      ) : depositAmount > 0 ? (
        <SCMessage>
          The plan deposit of ${depositAmount} will be charged to the customer’s nominated card as
          soon as you click save
        </SCMessage>
      ) : (
        ''
      )}
    </>
  );
};

export default ConfirmationMessage;
