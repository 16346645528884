import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { updateStaffDetails, displayStaffDetails } from '../../slices/staff';
import SCAddStaff, { SCFilterCheckbox, SCStatusCheckbox } from './css';
import { Button, MessageBox, ModalWrapper, IconInfo, Alert } from '@payright/web-components';
import LoggedInLayout from '../../layouts/logged-in-layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ControlledInputField } from '../../components/form-fields';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@merchant-portal/slices';
import { showHideItemsAndMenu } from '../../util/helper';
import Loader from '../../components/loader';
import Tooltip from 'react-tooltip-lite';
import getConstants from '../../util/constants';

// Yup is a JavaScript schema builder for value parsing and validation
// TODO: upgrade RHF and remove references to @hookform/resolvers/yup/dist
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd';
import { generalStaffInfoValidationSchema } from '../../util/validation/form-validation';

// Import default 'base' staff type
import { Staff, SugarStaffPermission } from '../../types/staff';
import { StaffPermissionLabelMaker } from './utils/StaffPermissionLabelMaker';

const {
  SUCCESSFUL_STAFF_UPDATE,
  UNSUCCESSFUL_STAFF_UPDATE,
  CONTACT_STATUS_WITH_COMPANY,
  CONTACT_STATUS_LEFT_COMPANY,
  STAFF_USER_NAME_INFO_TEXT,
  CONTACT_US_PHONE_NUMBER
} = getConstants();

export type StaffUpdate = Staff & {
  salutation: string;
  contactStatus: string;
  permissions: Array<string>;
};

const staffPermissions: { [key in SugarStaffPermission]: boolean } = {
  'Activate Plans': false,
  'Create Plan': false,
  'Export Plans And View Plan MSF': false,
  'View Other Staff Plans': false,
  'View Plans': false,
  'View Rates': false,
  'View Staff': false,
  Administrator: false,
};

const UpdateStaff = (staff: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const staffPermissionLabelMaker = useRef(new StaffPermissionLabelMaker());

  const { errorMessage, staffDetails, submitSucceeded, loading } = useSelector(
    (state: RootState) => state.staff
  );

  // Check the permission with the existing one
  let permission = staffDetails.permissions;

  // Checked the permission with the pervious one and then update
  Object.keys(staffPermissions).forEach((item) => {
    staffPermissions[item as SugarStaffPermission] = permission.includes(item);
  });

  const staffCurrentStatus = staffDetails.contactStatus === 'active' ? true : false;

  const [permissions, setPermissions] = useState(staffPermissions);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [staffStatus, setStaffStatus] = useState(staffCurrentStatus);
  const { accessItems } = showHideItemsAndMenu();
  const readOnly = !accessItems.administrator;

  let showMessage;

  const reactHookForm = useForm<StaffUpdate>({
    defaultValues: staffDetails,
    resolver: yupResolver(generalStaffInfoValidationSchema),
  });

  const { setValue, handleSubmit, errors, control } = reactHookForm;

  // Method to close Message Modal and redirect
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    if (errorMessage === null) history.push('/staff');
  };

  // Display the messages
  if (submitSucceeded) {
    showMessage = (
      <MessageBox
        title={SUCCESSFUL_STAFF_UPDATE}
        message=""
        handleOnClick={handleCloseMessageModal}
      />
    );
  } else if (errorMessage) {
    showMessage = (
      <MessageBox
        title={UNSUCCESSFUL_STAFF_UPDATE}
        message=""
        handleOnClick={handleCloseMessageModal}
        error={true}
      />
    );
  }

  // Select permissions, if there is any change
  const handleUpdateFilter = (filterName: SugarStaffPermission) => {
    permissions[filterName] = !permissions[filterName];
    setPermissions({ ...permissions });
  };

  // form submit method
  const onSubmit = (data: StaffUpdate) => {
    const selectedPermissions = Object.keys(permissions).filter(
      (obj) => permissions[obj as SugarStaffPermission] === true
    );

    data.permissions = selectedPermissions as SugarStaffPermission[];
    data.contactStatus =
      staffStatus === true ? CONTACT_STATUS_WITH_COMPANY : CONTACT_STATUS_LEFT_COMPANY;
    dispatch(updateStaffDetails(staff.match.params.id, data));
  };

  useEffect(() => {
    setShowMessageModal(submitSucceeded || errorMessage !== null);
  }, [submitSucceeded, errorMessage]);

  useEffect(() => {
    dispatch(displayStaffDetails(staff.match.params.id));
  }, [staff.match.params.id, dispatch]);

  // Update form values when data from redux is populated/modified
  useEffect(() => {
    Object.keys(staffDetails).forEach((key) => {
      setValue(`${key}`, Reflect.get(staffDetails, key));
    });
    setStaffStatus(staffCurrentStatus);
  }, [staffDetails, setValue, dispatch, staffCurrentStatus]);

  //Method to update the status of contact
  const handleUpdateStatus = () => {
    setStaffStatus(!staffStatus);
  };

  return (
    <SCAddStaff>
      <LoggedInLayout activePage="/staff" contentMarginTop={0}>
        <div className="staff-body">
          <div className="header">
            {accessItems.administrator ? <h2>Edit Profile</h2> : <h2>View Profile</h2>}
          </div>
          {loading ? (
            <Loader />
          ) : (
            <FormProvider {...reactHookForm}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <ModalWrapper
                    open={showMessageModal}
                    handleClose={() => setShowMessageModal(false)}
                    background="dark"
                  >
                    <div className="message">{showMessage}</div>
                  </ModalWrapper>
                </div>

                <div className="details-wrapper-body">
                  <Grid fluid>
                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Basic Details</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={5} lg={6}>
                                <h6 className="label">First Name *</h6>
                                <ControlledInputField
                                  name="firstName"
                                  error={errors.firstName?.message as string}
                                  readOnly={readOnly}
                                ></ControlledInputField>
                              </Col>

                              <Col xs={12} md={5} lg={5}>
                                <h6 className="label">Last Name *</h6>
                                <ControlledInputField
                                  name="lastName"
                                  error={errors.lastName?.message as string}
                                  readOnly={readOnly}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={9} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={9} lg={6}>
                                <h6 className="label">Job Title</h6>
                                <ControlledInputField
                                  name="jobTitle"
                                  readOnly={readOnly}
                                  error={errors.jobTitle?.message as string}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Contact Details</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={6} lg={6}>
                                <h6 className="label">Email Address *</h6>
                                <ControlledInputField
                                  name="email"
                                  error={errors.email?.message as string}
                                  className="email"
                                  type="email"
                                  readOnly={readOnly}
                                ></ControlledInputField>
                              </Col>
                              <Col xs={12} md={6} lg={5}>
                                <h6 className="label">Mobile No. *</h6>
                                <ControlledInputField
                                  name="phoneMobile"
                                  error={errors.phoneMobile?.message as string}
                                  readOnly={readOnly}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={9} lg={12}>
                            <Row start="xs">
                              <Col xs={12} md={9} lg={6}>
                                <h6 className="label">Work Phone No.</h6>
                                <ControlledInputField
                                  name="phoneWork"
                                  error={errors.phoneWork?.message as string}
                                  readOnly={readOnly}
                                ></ControlledInputField>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Credentials</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row start="xs">
                              <Col xs={11} md={9} lg={8}>
                                <Row start="xs">
                                  <Col xs={4} md={3} lg={2}>
                                    <h6 className="label">Username</h6>
                                  </Col>
                                  <Col xs={2} md={1} lg={1}>
                                    <Tooltip
                                      content={STAFF_USER_NAME_INFO_TEXT}
                                      className="toolTip"
                                    >
                                      <IconInfo />
                                    </Tooltip>
                                  </Col>
                                </Row>

                                <Row start="xs">
                                  <Col xs={12} md={9} lg={9}>
                                    <ControlledInputField
                                      name="userName"
                                      rules={{ required: 'Required' }}
                                      error={errors.userName && 'User Name is required'}
                                    ></ControlledInputField>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row start="xs">
                              <Col xs={12} md={9} lg={2}>
                                <h6 className="label contactStatus">Account Active</h6>
                              </Col>
                              <Col xs={12} md={9} lg={1}>
                                <Controller
                                  name="staffStatus"
                                  control={control}
                                  label="Account Active"
                                  render={({ onChange, onBlur, value }) => {
                                    return (
                                      <SCStatusCheckbox
                                        name="staffStatus"
                                        checked={staffStatus}
                                        handleClick={handleUpdateStatus}
                                        readOnly={readOnly}
                                        value="true"
                                      ></SCStatusCheckbox>
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={12} md={12} lg={12}>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <h4>Permissions</h4>
                      </Col>

                      <Col md={9} xs={11} lg={9}>
                        <Row>
                          {!staffDetails.canAlterPermissions ? 
                          <Alert title='Cannot change your own permissions' outcome='positiveSmall' icon={<IconInfo />} body={`Please contact your store administrator to change your permissions or call Payright on ${CONTACT_US_PHONE_NUMBER}.`} />
                          : Object.keys(permissions).map((key) => (
                            <Col xs={12} md={6} lg={6}>
                              <Controller
                                name={key}
                                control={control}
                                label={key}
                                render={({ value }) => {
                                  return (
                                    <SCFilterCheckbox
                                      name={key}
                                      value={value}
                                      checked={permissions[key as SugarStaffPermission]}
                                      handleClick={handleUpdateFilter}
                                      readOnly={readOnly}
                                    >
                                      <h6 className="label checkbox">
                                        {staffPermissionLabelMaker.current.getLabel(
                                          key as SugarStaffPermission
                                        )}
                                      </h6>
                                    </SCFilterCheckbox>
                                  );
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      {accessItems.administrator ? (
                        <Col xs={12} md={12} lg={12}>
                          <hr />
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                    {accessItems.administrator ? (
                      <Row end="xs">
                        <Col xs={12} md={6} lg={9}>
                          <Row end="xs">
                            {' '}
                            <Col xs={6} md={6} lg={3}>
                              <div>
                                <Button
                                  className="actions"
                                  outline
                                  colour="blue"
                                  handleClick={() => history.push('/staff')}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Col>
                            <Col xs={6} md={6} lg={3}>
                              <div>
                                <Button className="actions">Update Details</Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                  </Grid>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </LoggedInLayout>
    </SCAddStaff>
  );
};

export default UpdateStaff;
