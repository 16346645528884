import * as React from 'react';
import styled from 'styled-components';
import { IconView } from '@payright/web-components';
import { media } from '@payright/web-components';
import Tooltip from 'react-tooltip-lite';
import { useState } from 'react';

///////////////////////////////////////////////////
//               View with Actions               //
///////////////////////////////////////////////////

export interface Action {
  /** The label to display for an action */
  label: string;
  /** The icon */
  icon: React.ReactElement;
  /** Method to capture the click event */
  handleClick?: Function;
  /** id of a click event */
  handleClickId?: string;
}

interface ViewLoanProps {
  actions: {
    /** Method to capture the detail page */
    handleDetailClick: Function;
    /** Array of possible actions */
    otherActions?: Array<Action>;
    /** Tooltip */
    tooltip?: string;
    /** show the button for activate the loan */
    activationButton?: string;
  };
}

interface ViewLoanState {
  /** True if the Actions pop-up is open */
  actionsOpen: boolean;
}

const SCViewLoan = styled.div`
  display: inline-block;
  .icon {
    border: 1px solid ${(props) => props.theme.colours.blue.base};
    border-radius: 5px;
    width: 2.7em;
    height: 2.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: ${(props) => props.theme.colours.blue.base};
    background-color: transparent;
    margin-right: 0.5em;
  }
  ${media.max.large} {
    .icon {
      display: none;
    }
  }
  ${media.max.wide} {
    .label {
      display: none;
    }
  }
`;

const SIconWrapper = styled.button`
  position: relative;
  cursor: pointer;
  outline: 0;
`;

const ViewLoan = (props: ViewLoanProps) => {
  const [state, setState] = useState<ViewLoanState>({ actionsOpen: false });

  const toggleActionsOpen = () => {
    const actionsOpen = !state.actionsOpen;
    setState({ actionsOpen });
  };

  const { actions } = props;

  return (
    <SCViewLoan>
      <SIconWrapper className="icon" onClick={() => actions.handleDetailClick()}>
        {actions.tooltip ? (
          <Tooltip content={actions.tooltip}>
            <IconView />
          </Tooltip>
        ) : (
          <IconView />
        )}
      </SIconWrapper>
    </SCViewLoan>
  );
};

export default ViewLoan;
