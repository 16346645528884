import React, { useState } from 'react';

import MerchantMarketing from '@merchant-portal/components/merchant-marketing';
import { updateCustomerPromoConsent } from '../../slices/customer';
import { CustomerResource } from '@merchant-portal/types/customer';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../slices';

interface marketingConsentProps {
  customerId: string;
  checkPromoConsent: boolean;
  merchantMarketingConsentText: string;
}

const MarketingConsent = ({
  customerId,
  checkPromoConsent,
  merchantMarketingConsentText,
}: marketingConsentProps) => {
  const [merchantMarketingSelection, setMerchantMarketingSelection] =
    useState<boolean>(checkPromoConsent);
  const dispatch = useDispatch();
  const checkout = useSelector((state: RootState) => state.checkout);

  const checkoutId = checkout?.checkout?.id || '';

  const handleUpdateStatus = () => {
    setMerchantMarketingSelection(!merchantMarketingSelection);

    const promoOptIn: CustomerResource = {
      consentPromoMaterial: !merchantMarketingSelection,
      checkoutId,
    };

    dispatch(updateCustomerPromoConsent(customerId, promoOptIn));
  };

  return (
    <MerchantMarketing
      selected={merchantMarketingSelection || false}
      handleClick={handleUpdateStatus}
      marketingOptInText={merchantMarketingConsentText}
    />
  );
};

export default MarketingConsent;
