import * as defaults from './defaults';
// Todo: Could we load these file dynamically? Have them in a separate folder maybe?
import * as nz from './nz';

// Todo: Update types for each constant
interface Constants {
  REGION_STATES?: any;
  RESIDENTIAL_STATUS?: any;
  TIME_IN_ADDRESS?: any;
  CUSTOMER_TITLES?: any;
  TIMEFRAMES?: any;
  TIME_IN_JOB?: any;
  EMPLOYMENT_TYPES?: any;
  BUSINESS_NUMBER?: string;
  INCOME_FREQUENCIES?: any;
  MERCHANT_PLANS_TABLE_HEADERS?: any;
  PAYMENT_FREQUENCIES?: any;
  PAYMENT_PERIODS?: any;
  DEPOSIT_PERCENTAGES?: any;
  MONTHLY_PAYMENT_DATE_SELECTION?: any;
  TITLE_VALIDATION_REGEX: RegExp;
  FIRSTNAME_VALIDATION_REGEX: RegExp;
  MIDDLENAME_VALIDATION_REGEX: RegExp;
  LASTNAME_VALIDATION_REGEX: RegExp;
  JOBTITLE_VALIDATION_REGEX: RegExp;
  EMAIL_VALIDATION_REGEX: RegExp;
  PHONE_VALIDATION_REGEX: RegExp;
  MOBILE_VALIDATION_REGEX: RegExp;
  LICENCE_VALIDATION_REGEX: RegExp;
  LICENCE_CARD_NUMBER_VALIDATION_REGEX: RegExp;
  RECORDS_PER_PAGE?: any;
  LOAD_PER_PAGE?: any;
  PLAN_APPROVED_STATUS?: any;
  LOAN_APPROVED_STATUS?: any;
  PLAN_PENDING_STATUS?: any;
  LOAN_PENDING_STATUS?: any;
  PLAN_NOT_FOUND?: any;
  PLAN_ACTIVATE?: any;
  LOAN_ACTIVATE?: any;
  RESEND_VERIFICATION_CODE?: any;
  RESEND_APPLICATION_LINK?: any;
  RESEND_CREDIT_SCHEDULE?: any;
  STAFF_NOT_FOUND?: any;
  RATES_NOT_FOUND?: any;
  CONTACT_STATUS_WITH_COMPANY?: any;
  CONTACT_STATUS_LEFT_COMPANY?: any;
  PLAN_ACTIVATION_MODAL_TITLE?: any;
  PLAN_FILTER_OPTIONS?: any;
  PASSWORD_STATUS?: any;
  READABLE_APPROVED_PENDING_ID: string;
  APPROVED_PENDING_ID: string;
  READABLE_APPROVED_STATEMENT_REQUESTED: string;
  APPROVED_STATEMENT_REQUESTED: string;
  APPROVED_BANK_STATEMENTS_REQUIRED?: string;
  EXISTING_CARD?: any;
  ADD_CARD?: any;
  IDENTIFICATION_YEARS?: any;
  DOB_YEARS?: any;
  STAFF_USER_NAME_INFO_TEXT?: any;
  SUCCESSFUL_PLAN_ACTIVATION?: any;
  SUCCESSFUL_LOAN_ACTIVATION?: any;
  PLAN_ACTIVATION_CONFIRMATION?: any;
  LOAN_ACTIVATION_CONFIRMATION?: any;
  LOAN_ACTIVATION_MODAL_TITLE?: any;
  REQUEST_FAILURE?: any;
  SUCCESSFUL_STAFF_CREATION?: any;
  SUCCESSFUL_STAFF_UPDATE?: any;
  UNSUCCESSFUL_STAFF_UPDATE?: any;
  UNSUCCESSFUL_UPDATE?: any;
  PLAN_FILTER_MESSAGE?: any;
  LOAN_FILTER_MESSAGE?: any;
  SUCCESSFUL_NEW_PLAN_APPROVED_TITLE?: any;
  SUCCESSFUL_NEW_PLAN_APPROVED_BODY?: any;
  SUCCESSFUL_RESEND_VERIFICATION_CODE?: any;
  RESEND_VERIFICATION_CODE_CONFIRMATION?: any;
  SUCCESSFUL_RESEND_APPLICATION_LINK?: any;
  RESEND_APPLICATION_LINK_CONFIRMATION?: any;
  SUCCESSFUL_RESEND_CREDIT_SCHEDULE?: any;
  RESEND_CREDIT_SCHEDULE_CONFIRMATION?: any;
  PASSWORD_SUCCESSFUL_TITLE?: any;
  PASSWORD_NEXT_LOGIN?: any;
  SUCCESSFUL_FORGOT_PASSWORD_TITLE?: any;
  SUCCESSFUL_FORGOT_PASSWORD_MESSAGE?: any;
  FAILURE_FORGOT_PASSWORD_MESSAGE?: any;
  MESSAGE_SEND_APPLICATION_LINK_TO_CUSTOMER?: any;
  LABEL_SEND_TO_CUSTOMER_TO_COMPLETE?: any;
  LABEL_COMPLETER_ON_CUSTOMER_BEHALF?: any;
  SUCCESSFUL_PLAN_SENT_TO_CUSTOMER?: any;
  CUSTOMER_TYPE_NEW_CUSTOMER_TITLE?: any;
  CUSTOMER_TYPE_NEW_CUSTOMER_DESC?: any;
  CUSTOMER_TYPE_EXISTING_CUSTOMER_TITLE?: any;
  CUSTOMER_TYPE_EXISTING_CUSTOMER_DESC?: any;
  MERCHANT_MARKETING_CONSENT_TEXT: string;
  MERCHANT_MARKETING_CONSENT_TEXT_NZ: string;
  NEW_CUSTOMER_DETAIL?: any;
  SALE_DETAIL?: any;
  SUMMARY_DETAIL?: any;
  CUSTOMER_IDENTIFICATION?: any;
  MARKETING_CHECKBOX_TEXT?: any;
  PAYMENT_DETAIL_TEXT?: any;
  TOOLTIP_PLAN_DETAIL?: any;
  APPROVED_PAID_MESSAGE?: any;
  HEADER_MESSAGE_REVIEW?: string;
  HEADER_MESSAGE_DECLINED?: string;
  HEADER_MESSAGE_PENDING?: string;
  HEADER_MESSAGE_REQUEST?: string;
  HEADER_MESSAGE_PAYMENT_PLAN?: string;
  REVIEW_MESSAGE?: string | React.ReactNode;
  DECLINED_MESSAGE?: string;
  APPROVED_PENDING_ID_MESSAGE?: any;
  APPROVED_PENDING_ID_MESSAGE_2?: any;
  HELPDESK_EMAIL?: any;
  TELL_YOUR_CUSTOMER_LINK?: any;
  LABEL_STATE?: any;
  LABEL_SUBURB?: any;
  MOBILE_NUMBER_FORMAT_VALIDATION?: any;
  PHONE_NUMBER_FORMAT_VALIDATION?: any;
  CONTACT_US_PHONE_NUMBER_URL: string;
  CONTACT_US_PHONE_NUMBER: string;
  PAYRIGHT_MERCHANT_V1?: any;
  CUSTOMISE_LINK_TYPE?: any;
  CHECKOUT_CODE_REGEX: RegExp;
  CHECKOUT_CODE_FORMAT_VALIDATION?: any;
  PLAN_LIST_PAGE_LIMIT: number;
  SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL: string;
  RENAME_SUGAR_PLAN_STATUS_FOR_PRE_APPROVAL: string;
  LESS_THAN_6_MONTHS: string;
  UNSUCCESSFUL_DDO_UPDATE: string;
  CANCEL_POPUP_TITLE: string;
  CANCEL_POPUP_TEXT: string;
  CANCEL_POPUP_CANCEL_PLAN: string;
  CANCEL_POPUP_ABORT_CANCEL: string;
  LOAN_PURCHASE_DETAIL?: string;
  APPLICATION_SENT_TO_CUSTOMER?: string;
  SUCCESSFUL_LOAN_SENT_TO_CUSTOMER?: string;
}

const getConstants = (): Constants => {
  let constants: Constants = { ...defaults };

  if (!process.env.REACT_APP_REGION) {
    return constants;
  }

  // Todo: make more dynamic for other regions?
  if (process.env.REACT_APP_REGION.toLowerCase() === 'nz') {
    constants = { ...constants, ...nz };
  }

  return constants;
};

export default getConstants;
