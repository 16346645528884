import { styled, media, Checkbox } from '@payright/web-components';

export const SCMerchantDDOConsent = styled.div`
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  margin-left: 0px;

  .consent-text-wrapper {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: 1.2em;
    font-size: 0.9em;
    flex-direction: column;

    .first-check-consent {
      margin-bottom: 10px;
      .checkmark {
        top: -10px;
      }
      & input:checked ~ .checkmark {
        top: -10px;
      }
    }

    .second-check-consent {
      & input:checked ~ .checkmark {
        top: 20px;
      }
      .checkmark {
        top: 20px;
      }

      p {
        margin-top: 45px;
      }
    }

    .third-check-consent {
      margin-top: 30px;

      .checkmark {
        top: 20px;
      }
      & input:checked ~ .checkmark {
        top: 20px;
      }
      p {
        margin-top: 30px;
      }
    }
    .tmd {
      p {
        margin-top: 50px;
        margin-left: 1px;
        font-size: 0.9em;
        font-weight: bold;
      }
    }
  }

  .twoButtonDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .actions {
      margin-left: 15px;
      margin-right: 10px;
      margin-top: 30px;
      max-width: 100%;
      width: 270px;
      margin-bottom: 0px;
    }
    ${media.min.large} {
      .actions {
        margin-left: 1px;
        width: 350px;
      }
    }
  }

  .oneButtonDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .actions {
      margin-left: 15px;
      margin-right: 10px;
      margin-top: 30px;
      max-width: 100%;
      width: 270px;
      margin-bottom: 0px;
    }
    ${media.min.large} {
      .actions {
        margin-left: 1px;
        width: 350px;
      }
    }
  }
`;

export default SCMerchantDDOConsent;
