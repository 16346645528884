import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { HeaderMerchant, media, Alert, IconDone, IconInfo, Button } from '@payright/web-components';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import { Row, Col } from 'react-flexbox-grid';
import { RootState } from '../../../slices';
import { useHistory } from 'react-router-dom';
import getConstants from '@merchant-portal/util/constants';

const { SUCCESSFUL_NEW_PLAN_APPROVED_TITLE } = getConstants();

const SCSentLinkToCustomer = styled.div`
  background:${(props) => props.theme.colours.white};
   max-width: 1200px;
   width: 100%;

   hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey.altlight};
    margin: 2.23em 0 2em;
  }

  ${media.min.large}
  {
    max-width:1400px;
    width:100%;
  }
  .message-body{
    margin-top:4em;
    .alert{
      display:flex;
      align-content:center;
      max-width:350px;
      width:350px;
      margin: auto;
      ${media.min.large}
      {
        max-width:800px;
        width:100%;
      }
    }
     .section-button {
      max-width:350px;
      width:350px;
      padding-bottom:30px;
      margin: auto;
      ${media.min.large}
      {
        max-width:200px;
        width:100%;
        flex-basis: calc(75% - 110px);
        padding-bottom:5em;
      }
    }
    .message-wrapper{
      padding:0 0 1.7em;
      margin: auto;
      max-width:350px;
      width:350px;
      ${media.min.large}
      {
        max-width:800px;
        width:100%;
      }
    }
`;

const SentLinkToCustomer = () => {
  const checkout = useSelector((state: RootState) => state.checkout);
  const history = useHistory();
  const depositAmount = checkout.checkout?.attributes?.depositPayable || 0;

  const { storeConfig } = useSelector((state: RootState) => state.auth);

  return (
    <SCSentLinkToCustomer>
      <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
        <HeaderMerchant
          title="New Plan"
          activeStep={3}
          setActiveStep={(index: number) => console.log('Active step clicked: ' + index)}
        />
        <div className="message-body">
          <Row className="message-wrapper">
            <Col xs={12} md={12} lg={12}>
              <Alert
                title={SUCCESSFUL_NEW_PLAN_APPROVED_TITLE}
                body=""
                outcome="positive"
                icon={<IconDone />}
              />
            </Col>
          </Row>
          {storeConfig?.depositTaker === true && (
            <div className="message-wrapper">
              <div className="alert">
                <Alert
                  title={'Pay Deposit In Store - PLEASE NOTE or READ TO CUSTOMER'}
                  body={
                    <>
                      <p>
                        By confirming the deposit arrangements you acknowledge and confirm your
                        agreement to the Credit and Disclosure Statement and Ts & Cs for your
                        Payright loan that have been emailed to you.
                      </p>
                      <p>
                        Payright will not activate this loan until it receives confirmation from the
                        Merchant{' '}
                        {depositAmount > 0 && `that the deposit of $${depositAmount} is paid`} and
                        the customer has agreed to receive the goods or services from the Merchant
                        in accordance with the Merchant’s contract terms.
                      </p>
                    </>
                  }
                  outcome="error"
                  icon={<IconInfo />}
                />
              </div>
            </div>
          )}

          <div className="message-wrapper">
            <div className="alert">
              <Alert
                title={'IMPORTANT INFORMATION'}
                body={
                  <>
                    <p>
                      We have just sent the customer an SMS containing a link and 'Verification
                      Code' to set up the payment details and pay the deposit if required. Please
                      ask them to check their phone now.
                    </p>
                    <p>
                      Once the deposit is paid we will establish the customers plan or add the new
                      purchase to their account. You can then activate the plan via your portal once
                      you have provided the services.
                    </p>
                  </>
                }
                outcome="error"
                icon={<IconInfo />}
              />
            </div>
          </div>

          <hr />

          <div className="section-button">
            <Button
              iconPosition="right"
              type="submit"
              colour="primary"
              handleClick={() => {
                history.push('/plans');
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </LoggedInLayout>
    </SCSentLinkToCustomer>
  );
};

export default SentLinkToCustomer;
