import React, { useEffect } from 'react';
import { ControlledInputField } from '@merchant-portal/components/form-fields';
import { Button } from '@payright/web-components';
import Spinner from '@merchant-portal/components/spinner';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from '@merchant-portal/util/store';
import { FormData } from './mobile-checkout-form';
import { startCheckoutByCheckoutCode } from '@merchant-portal/slices/mobile-checkout';

type SearchCheckoutCodeFormProps = {
  /** InputField and Submit button will be disabled if readOnly is true */
  readOnly: boolean;
};

const SearchCheckoutCode = ({ readOnly }: SearchCheckoutCodeFormProps) => {
  const dispatch = useDispatch();
  const { watch, setValue, errors, formState, handleSubmit } = useFormContext<FormData>();

  const { loading } = useSelector((state) => state.auth);

  // Form submit - search by checkout code
  const onCheckoutCode = (formData: FormData) => {
    dispatch(startCheckoutByCheckoutCode(formData.checkoutCode.toUpperCase()));
  };

  // Transform checkout code to uppercase letters on keypress
  const checkoutCode = watch('checkoutCode');
  useEffect(() => {
    setValue('checkoutCode', checkoutCode.toUpperCase());
  }, [checkoutCode, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onCheckoutCode)}>
        <div className="mandatory-field-label">* Mandatory field</div>
        <div className="field">
          <ControlledInputField
            name="checkoutCode"
            placeholder="Scan the Customer's Barcode/QR Code or enter the Checkout Code shown."
            error={errors.checkoutCode?.message as string}
            readOnly={readOnly}
          >
            Checkout code*
          </ControlledInputField>
        </div>
        {readOnly === false && (
          <div className="actions">
            <Button
              type="submit"
              colour="primary"
              size="medium"
              className="button-search"
              iconPosition="right"
              icon={loading ? <Spinner /> : undefined}
              disabled={loading || !formState.isDirty}
              maxWidth="100%"
            >
              Verify Checkout Code
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default SearchCheckoutCode;
