import { styled, media } from '@payright/web-components';

const SCPlanDetails = styled.div`
  background: ${(props) => props.theme.colours.grey.altlight};
  padding-bottom: 20px;

  h2 {
    color: ${(props) => props.theme.colours.blue.base};
  }
  .plan-detail-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto;

    .message {
      text-align: center;

      .title {
        font-size: 20px;
        padding: 10px 10px 10px 10px;

        svg {
          fill: ${(props) => props.theme.colours.blue.base};
          margin-right: 0.5em;
          margin-top: 0.1em;
        }
      }
    }
    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    ${media.max.ewide} {
      padding: 0 1.6em;
    }
    .alert {
      margin-bottom: 2em;
    }
    .linkPage {
      color: ${(props) => props.theme.colours.blue};
      text-decoration: none;
    }
    .view-all-plans {
      margin-left: 0.1em;

      h6 {
        color: ${(props) => props.theme.colours.blue};
        margin-bottom: 0.4em;
        font-size: 1.1em;
      }
      svg {
        fill: ${(props) => props.theme.colours.blue.base};
        transform: rotate(90deg);
        margin-right: 1em;
        margin-top: 0.1em;
      }
    }
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3em;

      .button {
        max-width: 1000px;
        width: 200px;
        height: 60px;
        font-size: 15px;

        ${media.max.large} {
          max-width: 50%;
        }
      }
      ${media.max.medium} {
        margin-bottom: 1em;
      }
    }
  }

  .details-wrapper-body {
    background: ${(props) => props.theme.colours.white};
    max-width: 1200px;
    width: 100%;
    margin: 2em auto 0;
    padding: 55px 25px 55px 25px;

    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    h4 {
      color: #531dff;
      font-weight: bold;
      line-height: 1.3;
      padding: 5px 0px 10px 0px;
    }
    h5 {
      color: #531dff;
      font-weight: bold;
      line-height: 1.89;
      white-space: nowrap;
      padding: 5px 2px 10px 2px;
    }
    h6 {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.89;
      white-space: nowrap;
      padding: 5px 2px 10px 2px;
      text-align: right;
    }
    .label {
      padding: 0 0 0 0;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
    .input-field {
      margin-bottom: 20px;
    }
    .border-row {
      margin-left: 10px;
      width: 100%;
      border-bottom: 1px dashed ${(props) => props.theme.colours.grey.light};
    }
    .gap-div {
      height: 100px;
    }
    .no-wrap-div {
      word-break: break-all;
    }
    hr {
      border-style: solid;
      border-bottom: none;
      border-color: ${(props) => props.theme.colours.grey.dark};
      margin: 5em 5em 3em 0;
    }
  }
`;

export default SCPlanDetails;
