import React, { useEffect } from 'react';

// TODO: upgrade RHF and remove references to @hookform/resolvers/yup/dist
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd';
import { useForm, FormProvider, UseFormMethods } from 'react-hook-form';
import { useSelector } from '@merchant-portal/util/store';
import { View } from '../useViewNavigator';
import { RatesSuccessResponse } from '@merchant-portal/util/merchant-api';

import { mobileCheckoutValidationSchema } from './form-validation';
import { AppPreApprovalData } from '@merchant-portal/util/app-api';

import SearchCheckoutCodeForm from './seach-checkout-code-form';
import EnterSaleDetailsForm from './enter-sale-details-form';

export type FormData = {
  checkoutCode: string;
  saleAmount: string;
  amountPaidInStore: string;
  paymentPlanAmount: string;
  merchantReference: string;
};

export type FormContext = {
  view: View;
  term: number | null | undefined;
  rates: RatesSuccessResponse['rates'] | undefined;
};

type MobileCheckoutFormProps = {
  view: View.ENTER_CHECKOUT_CODE | View.ENTER_SALE_DETAILS;
  reactHookForm: UseFormMethods<FormData>;
};

export const useMobileCheckoutForm = (
  view: View,
  plan: AppPreApprovalData | null,
  rates: RatesSuccessResponse['rates']
): UseFormMethods<FormData> => {
  return useForm<FormData, FormContext>({
    defaultValues: {
      checkoutCode: '',
      saleAmount: '',
      amountPaidInStore: '',
      paymentPlanAmount: '00.00',
      merchantReference: '',
    },
    context: { view: view, term: plan?.term, rates: rates },
    resolver: yupResolver(mobileCheckoutValidationSchema),
    shouldUnregister: false,
  });
};

const MobileCheckoutForm = ({ view, reactHookForm }: MobileCheckoutFormProps) => {
  const { preApproval } = useSelector((state) => state.mobileCheckout);
  const { setValue } = reactHookForm;

  // Rehydrate values from Redux
  useEffect(() => {
    if (preApproval) {
      Object.keys(preApproval).forEach((key) => {
        setValue(`${key}`, Reflect.get(preApproval, key) ?? '');
      });
    }
  }, [preApproval, setValue]);

  return (
    <>
      <FormProvider {...reactHookForm}>
        {view === View.ENTER_CHECKOUT_CODE && <SearchCheckoutCodeForm readOnly={false} />}
        {view === View.ENTER_SALE_DETAILS && (
          <>
            <SearchCheckoutCodeForm readOnly={true} />
            <EnterSaleDetailsForm />
          </>
        )}
      </FormProvider>
    </>
  );
};

export default MobileCheckoutForm;
