import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCMerchantRates = styled.div`
  background: ${(props) => props.theme.colours.grey.altlight};
  padding-bottom: 20px;

  h2 {
    color: ${(props) => props.theme.colours.blue.base};
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;

    ${media.max.medium} {
      margin: 0 2em 0 2em;
    }

    ${media.max.large} {
      margin: 0 2em 0 2em;
    }
  }

  .rates-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;

    .errorMessage {
      text-align: center;
      margin: 4em 0 2em 0;
      color: red;
      font-size: 1em;
    }

    thead {
      tr {
        &:first-of-type {
          th:nth-child(4) {
            border-right: 1px solid #c3c7ce;
          }
        }

        th {
          padding: 1em 1.05em !important;
        }
      }
    }

    ${media.min.large} {
      tbody {
        tr.row {
          border-bottom: 1px solid #edeff5;

          td {
            border-right: 1px solid ${(props) => props.theme.colours.grey.light};
            padding: 1em 1em !important;

            &.ratesTerm {
              max-width: 14%;
              width: 14%;
            }

            &.minimumPurchase {
              max-width: 9%;
              width: 9%;
              text-align: right;
            }

            &.maximumPurchase {
              max-width: 9%;
              width: 9%;
              text-align: right;
            }

            &.minimumDepositPercentage {
              max-width: 8%;
              width: 8%;
              text-align: right;
            }

            &.merchantFees {
              max-width: 5.45%;
              width: 5.45%;
              text-align: right;
            }

            &.merchantShowHide {
              border-right: none;
            }
          }
        }
      }
    }

    ${media.max.large} {
      tbody {
        padding: 0 2.5em 0 2.5em;

        tr {
          td {
            &.ratesTerm {
              order: 1;
              flex-basis: 25%;
            }

            &.minimumPurchase {
              order: 2;
              flex-basis: 25%;
            }

            &.maximumPurchase {
              order: 3;
              flex-basis: 25%;
            }

            &.minimumDepositPercentage {
              order: 4;
              flex-basis: 25%;
            }

            &.merchantFees {
              display: none;
            }

            &.merchantShowHide {
              display: flex;
              order: 7;
              flex-basis: 100%;

              .subhead {
                display: none;
              }

              h5 {
                flex-basis: 70%;
                flex-grow: 1;
              }

              a {
                flex-basis: auto;
              }
            }
          }
        }
      }
    }

    ${media.min.large} {
      tbody {
        .row {
          display: table-row;
        }
      }
    }

    ${media.max.medium} {
      tbody {
        padding: 0 2.5em 0 2.5em;

        tr {
          td {
            &.ratesTerm {
              order: 1;
              flex-basis: 50%;
            }

            &.minimumPurchase {
              order: 2;
              flex-basis: 50%;
            }

            &.maximumPurchase {
              order: 3;
              flex-basis: 50%;
            }

            &.minimumDepositPercentage {
              order: 4;
              flex-basis: 50%;
            }
          }
        }
      }
    }
  }
`;

export const SCMerchantNoRates = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;
  text-align: center;
  color: ${(props) => props.theme.colours.blue.base};
  font-size: 1.1em;
  font-weight: bold;
`;

export default SCMerchantRates;
