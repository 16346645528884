import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../slices';
import {
  HeaderMerchant,
  media,
  PaymentSummary,
  Alert,
  IconAttention,
  PaymentCalculator,
  PaymentCalculatorData,
  Button,
} from '@payright/web-components';
import Loader from '../../../components/loader';
import LoggedInLayout from '../../../layouts/logged-in-layout';
import FeeDetails from '../../../components/fee-details';
import VerificationCode from '../../../components/verification-code';
import CustomerDetailsMini from '../../../components/customer-data/customer-details-minified';
import FormSection from '../../../components/form-section';
import { useQuery } from '../../../util/hooks';
import {
  fetchCheckoutAttributes,
  checkoutValidateApproval,
  updateCheckout,
  sendLinkToCustomer,
} from '../../../slices/checkout';

import { useForm, FormProvider, Controller } from 'react-hook-form';
import getConstants from '../../../util/constants';
import { PlanStatus } from '@merchant-portal/util/merchant-api';

const { SUMMARY_DETAIL, PAYMENT_FREQUENCIES } = getConstants();

type SummaryProps = {};

const SCSummaryDetails = styled.div`
  background: ${(props) => props.theme.colours.white};
  h4 {
    color: #531dff;
  }
  padding-bottom: 30px;
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${(props) => props.theme.colours.grey};
    margin: 2.23em 0 3em;
  }
  .summarydetails-body {
    max-width: 1200px;
    width: 100%;
    margin: 4em auto 0;
    ${media.max.medium} {
      margin: 1.6em auto 0;
    }
    ${media.max.wide} {
      padding: 0 1.6em;
    }
  }
  .invalid-validation {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .calButtonSection {
    display: flex;
    padding-bottom: 30px;

    button:first-of-type {
      margin-right: 1.66em;
      colour: red;
    }
    button:last-of-type {
      margin-left: 4px;
      box-shadow: unset;
    }
  }

  .payment-summary {
    margin-bottom: 3em;
  }
`;

const Summary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const loggedInUser = useSelector((state: RootState) => state.auth);
  const checkout = useSelector((state: RootState) => state.checkout);
  const checkoutId = checkout?.checkout?.id || '';
  const planId = checkout.checkout?.planId || '';
  const customerId = checkout.checkout?.customerId || '';
  const approvalStatus = checkout?.checkout?.approvalStatus;
  const customer = useSelector((state: RootState) => state.customer);

  const repeatCustomer = customer?.accountDetails?.isReturnCustomer;

  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const { rates, establishmentFees, otherFees } = loggedInUser.merchantRates;
  const { loading, hasErrors, errorMessage, loadingValidationApproval, hasErrorsUpdateCheckout } =
    checkout;

  const [verificationCode, setVerificationCode] = useState<string>('');

  // Account Keeping Fee and Payment Processing Fee are retrieved from the customer record,
  // falls back to Sugar default config values if not found
  /*
  const otherFees = {
    monthlyAccountKeepingFee:
      customer?.accountDetails?.accountKeepingFee ||
      loggedInUser.merchantRates.otherFees.monthlyAccountKeepingFee,
    paymentProcessingFee:
      customer?.accountDetails?.paymentProcessingFee ||
      loggedInUser.merchantRates.otherFees.paymentProcessingFee,
  };*/

  // react hooks
  const [isEditPaymentDetails, setIsEditPaymentDetails] = useState(false);

  const updateVerificationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
  };

  const editDetailsHandler = () => {
    setIsEditPaymentDetails(true);
  };

  // Load latest checkout resource from server
  const queryParamCheckoutId = query.get('checkoutId');
  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    } else {
      dispatch(fetchCheckoutAttributes(checkoutId));
    }
  }, [checkoutId, dispatch, queryParamCheckoutId]);

  useEffect(() => {
    if (
      approvalStatus === PlanStatus.DECLINED ||
      approvalStatus === PlanStatus.REVIEW ||
      approvalStatus === PlanStatus.APPROVED_PENDING_ID ||
      approvalStatus === PlanStatus.APPROVED_STATEMENT_REQUESTED
    ) {
      history.push(`/plans/new/plan-result/${planId}`);
    }
    if (approvalStatus === 'Approved') {
      if (storeConfig?.applicationCompleter) {
        // collect payment details, maybe collect deposit or not depending on deposit taker flag
        history.push(`/plans/new/payment?checkoutId=${checkoutId}`);
      } else {
        dispatch(sendLinkToCustomer(planId));
        // don't collect payment details, send an email to customer
        history.push(`/plans/new/sent-link-to-customer`);
      }
    }
  }, [approvalStatus, planId, checkoutId, history, storeConfig]);

  type FormData = {
    verificationcode?: string;
  };

  type CalculatorFormData = {
    paymentDetails: PaymentCalculatorData;
  };

  const reactHookForm = useForm<FormData>({
    defaultValues: {
      verificationcode: '',
    },
  });
  const { handleSubmit } = reactHookForm;

  const summarySubmitHandler = (formData: FormData) => {
    /* REMOVE disableEditDetails ITEM SINCE WE'VE PROGRESSED FORWARD */
    localStorage.removeItem('disableEditDetails');

    const verficationCode = formData?.verificationcode || '';
    dispatch(checkoutValidateApproval(planId, customerId, verficationCode));
  };

  /// edit payment form hoo
  const reactHookFormEditPayment = useForm<CalculatorFormData>({
    defaultValues: {
      paymentDetails: {
        ...checkout.paymentDetails,
        paymentPeriod: loggedInUser?.storeConfig?.defaultTerm,
      },
    },
    shouldUnregister: false,
  });

  const { handleSubmit: handleSubmitEditPayment, watch, setValue } = reactHookFormEditPayment;
  const selectedPaymentFrequency = watch('paymentDetails').paymentFrequency;

  // Update form values when data from redux is populated/modified
  const { paymentDetails } = checkout;

  // Toggle 'Edit Details'
  const [toggleEditPaymentDetails, setToggleEditPaymentDetails] = useState(true);
  /*
   * This is for showing/hiding the "Edit details" button based on whether it was an accepted deposit bump or not
   */
  useEffect(() => {
    if (paymentDetails) {
      /*
       * IF DEPOSIT BUMP APPROVED, DISABLE EDIT DETAILS
       * --> WHEN planPreApprovalDetails.status is 'NEED_TO_INCREASE_DEPOSIT' (AKA Deposit Bump)
       * ELSE DO NOTHING
       */
      if (
        checkout.checkout?.attributes?.planPreApprovalDetails?.status === 'NEED_TO_INCREASE_DEPOSIT'
      ) {
        // DISABLE EDIT DETAILS IF DEPOSIT BUMP
        setToggleEditPaymentDetails(false);
      } else {
        // ENABLE EDIT DETAILS IF NOT DEPOSIT BUMP
        setToggleEditPaymentDetails(true);
      }
    }
  }, [checkout.checkout?.attributes?.planPreApprovalDetails?.status]);

  useEffect(() => {
    // Get paymentDetails from Redux checkout state and set the values for the Edit Payment fields in Payment Calculator
    if (paymentDetails) {
      setValue('paymentDetails', paymentDetails);
    }
  }, [paymentDetails, setValue]);

  const discardEditChange = (e: any) => {
    dispatch(fetchCheckoutAttributes(checkoutId)); // dispatching the fresh checkout again to rid off the previous error message
    setIsEditPaymentDetails(false);
  };

  useEffect(() => {
    // Enable payment update block with error message, in case of updates failed
    if (hasErrorsUpdateCheckout) {
      setIsEditPaymentDetails(true);
    }
  }, [dispatch, hasErrorsUpdateCheckout]);

  const formSubmitHandlerEditPayment = (formData: CalculatorFormData) => {
    dispatch(
      updateCheckout({
        paymentDetails: formData.paymentDetails,
        checkoutIdentifier: checkoutId,
      })
    );
    setIsEditPaymentDetails(false);
  };

  return (
    <SCSummaryDetails>
      <LoggedInLayout contentMarginTop={0} activePage="/plans/new">
        <HeaderMerchant
          title="New Plan"
          activeStep={2}
          setActiveStep={(step: number) => {
            if (step === 0) {
              history.push(`/plans/new/sale-details?checkoutId=${checkoutId}`);
            }

            if (step === 1) {
              history.push(`/plans/new/customer-details?checkoutId=${checkoutId}`);
            }
          }}
        />

        <div className="summarydetails-body">
          <FormSection title="Summary Details" text={SUMMARY_DETAIL}>
            {loading ? (
              <Loader text="Loading Payment Summary.." />
            ) : (
              <div>
                <CustomerDetailsMini />
                {/* // TODO - ESTABLISHMENT FEE DYNAMIC PROP (NEED'S TO BE HANDLED IN THE COMPONENT LEVEL) */}
                {isEditPaymentDetails ? (
                  <div>
                    <FormProvider {...reactHookFormEditPayment}>
                      <form onSubmit={handleSubmitEditPayment(formSubmitHandlerEditPayment)}>
                        {errorMessage && hasErrorsUpdateCheckout && (
                          <div className="invalid-validation">
                            <Alert
                              title={'We do not offer finance for this amount'}
                              body={errorMessage || ''}
                              outcome="error"
                              icon={<IconAttention />}
                            />
                          </div>
                        )}
                        <Controller
                          name="paymentDetails"
                          rules={{
                            validate: (paymentDetails) =>
                              paymentDetails.saleAmount !== 0 &&
                              paymentDetails.depositPercent !== 'N/A' &&
                              paymentDetails.paymentTerm !== 'N/A',
                          }}
                          render={({ onChange, onBlur, value, name, ref }) => (
                            <PaymentCalculator
                              type="merchant"
                              rates={rates}
                              paymentFrequencies={PAYMENT_FREQUENCIES}
                              establishmentFees={establishmentFees}
                              otherFees={otherFees}
                              updatePaymentDetails={onChange}
                              paymentDetails={value}
                              repeatCustomer={repeatCustomer}
                            />
                          )}
                        />
                        <FeeDetails
                          accountKeepingFee={otherFees.monthlyAccountKeepingFee}
                          processingFee={otherFees.paymentProcessingFee}
                          paymentFrequency={selectedPaymentFrequency}
                        />
                        <div className="calButtonSection">
                          <Button
                            handleClick={discardEditChange}
                            type="button"
                            colour="blue"
                            outline
                          >
                            Cancel
                          </Button>
                          <Button type="submit" colour="primary">
                            Continue
                          </Button>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                ) : (
                  <div>
                    <div className="payment-summary">
                      <PaymentSummary
                        paymentDetails={paymentDetails}
                        paymentFrequencies={PAYMENT_FREQUENCIES}
                        rates={rates}
                        establishmentFees={establishmentFees}
                        otherFees={otherFees}
                        goBack={editDetailsHandler}
                        isEditEnabled={toggleEditPaymentDetails} // WHEN DEPOSIT BUMPING IS DONE WE DISABLE TO OPTION OF EDITING
                        repeatCustomer={repeatCustomer}
                      >
                        <FeeDetails
                          accountKeepingFee={otherFees.monthlyAccountKeepingFee}
                          processingFee={otherFees.paymentProcessingFee}
                          paymentFrequency={selectedPaymentFrequency}
                        />
                      </PaymentSummary>
                    </div>
                    <FormProvider {...reactHookForm}>
                      <form onSubmit={handleSubmit(summarySubmitHandler)}>
                        {hasErrors && (
                          <div className="invalid-validation">
                            <Alert
                              title={'Invalid validation code entered'}
                              body={errorMessage || ''}
                              outcome="error"
                              icon={<IconAttention />}
                            />
                          </div>
                        )}
                        <VerificationCode
                          verificationCode={verificationCode}
                          updateVerificationCode={updateVerificationCode}
                          loadingValidationApproval={loadingValidationApproval}
                          editStatus={isEditPaymentDetails}
                          customerId={customerId}
                        />
                      </form>
                    </FormProvider>
                  </div>
                )}
              </div>
            )}
          </FormSection>
        </div>
      </LoggedInLayout>
    </SCSummaryDetails>
  );
};

export default Summary;
