import React from 'react';
import styled from 'styled-components';
import { Button } from '@payright/web-components';

import Spinner from '@merchant-portal/components/spinner';

type SaveButtonProps = {
  loading: boolean;
  depositTaker: boolean;
  depositAmount: number;
};

const SCSaveButton = styled.div`
  margin-top: 10px;
`;

const SaveButton = ({ loading, depositTaker, depositAmount }: SaveButtonProps) => {
  const saveButtonText = () => {
    if (depositTaker && depositAmount > 0) return 'Save and confirm deposit payment in store';
    if (depositAmount === 0) return 'Save and continue';
    return 'Save and Pay';
  };

  return (
    <SCSaveButton>
      <Button
        disabled={loading}
        iconPosition="right"
        icon={loading ? <Spinner /> : undefined}
        type="submit"
        maxWidth={'100%'}
        colour="primary"
      >
        {saveButtonText()}
      </Button>
    </SCSaveButton>
  );
};

export default SaveButton;
