import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@payright/web-components';
import Tooltip from 'react-tooltip-lite';

type TStatus =
  | 'active'
  | 'approved'
  | 'review'
  | 'pending'
  | 'declined'
  | 'closed'
  | 'cancelled'
  | 'pending'
  | 'approved_pending_id'
  | 'approved_statement_requested'
  | 'approved_pre_approval'
  | string;

interface StatusProps {
  status: TStatus;
  customer?: boolean;
}

interface SCStatusProps {
  customer?: boolean;
}

const SCStatus = styled.div<SCStatusProps>`
  white-space: nowrap;
  width: auto;
  display: inline-block;
  font-size: 1.1rem;
  span {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    margin-right: 0.8em;
  }
  .active {
    background-color: ${(props) => props.theme.colours.status.active};
  }
  .approved {
    background-color: ${(props) => props.theme.colours.status.approved};
  }
  .review {
    background-color: ${(props) => props.theme.colours.status.review};
  }
  .pending {
    background-color: ${(props) => props.theme.colours.status.pending};
  }
  .declined {
    background-color: ${(props) => props.theme.colours.status.declined};
  }
  .closed {
    background-color: ${(props) => props.theme.colours.status.closed};
  }
  .cancelled {
    background-color: ${(props) => props.theme.colours.status.cancelled};
  }
  .inactive {
    background-color: ${(props) => props.theme.colours.status.cancelled};
  }
  .approved_statement_requested {
    background-color: ${(props) => props.theme.colours.status.approved_statement_requested};
  }
  .approved_pending_id {
    background-color: ${(props) => props.theme.colours.status.approved_pending_id};
  }
  .approved_pre_approval {
    background-color: ${(props) => props.theme.colours.status.approved_pre_approval};
  }
  ${media.max.narrow} {
    h6 {
      font-weight: normal;
      font-size: 0.8rem;
    }
  }
  ${(props) =>
    props.customer &&
    css`
      background-color: ${(props) => props.theme.colours.grey.altlight};
      padding: 0.8rem 1.4rem 0.8rem 0.8rem;
      border-radius: 3rem;
      h6 {
        font-weight: 600;
        color: ${(props) => props.theme.colours.plum.base};
      }
      ${media.max.large} {
        padding: 0.4rem 0.7rem 0.4rem 0.5rem;
        font-size: 0.8em;
      }
      .tooltip {
        vertical-align: top;
      }
    `}
`;

const Status = ({ status, customer }: StatusProps) => {
  let title = '';
  switch (status) {
    case 'active':
      title = 'Active';
      break;
    case 'approved':
      title = 'Approved';
      break;
    case 'review':
      title = 'In Review';
      break;
    case 'pending':
      title = 'Pending';
      break;
    case 'declined':
      title = 'Declined';
      break;
    case 'closed':
      title = 'Closed';
      break;
    case 'cancelled':
      title = 'Cancelled';
      break;
    case 'inactive':
      title = 'Inactive';
      break;
    case 'approved_statement_requested':
      title = 'Appvd Stmt Req';
      break;
    case 'approved_pending_id':
      title = 'Appvd Pending ID';
      break;
    case 'approved_pre_approval':
      title = 'Appvd Pre Approval';
      break;
    default:
      break;
  }

  return (
    <SCStatus customer={customer}>
      <h6>
        <span className={status}></span>
        {displayLoanStatus(title)}
      </h6>
    </SCStatus>
  );
};

const displayLoanStatus = (title: string) => {
  switch (title) {
    case 'Appvd Stmt Req':
      return (
        <Tooltip content="Approved Statement Requested" className="tooltip">
          {title}
        </Tooltip>
      );

    case 'Appvd Pending ID':
      return (
        <Tooltip content="Approved Pending ID" className="tooltip">
          {title}
        </Tooltip>
      );

    case 'Appvd Pre Approval':
      return (
        <Tooltip content="Approved Pre Approval" className="tooltip">
          {title}
        </Tooltip>
      );

    default:
      return title;
  }
};

export default Status;
