import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { RootState } from '../../slices';
import { useSelector } from 'react-redux';
import { media, IconLoaderAlt, Button } from '@payright/web-components';
import { ControlledInputField, ControlledDropdown, ControlledDatePicker } from '../form-fields';
import { useFormContext } from 'react-hook-form';
import { CustomerDetailsData as MerchantApiCustomerDetailsData } from '../../types/customer';
import { envIsTrue, calculateAgeLuxon } from '../../util/helper';
import getConstants from '../../util/constants';

const {
  CUSTOMER_TITLES,
  DOB_YEARS,
  MOBILE_VALIDATION_REGEX,
  MOBILE_NUMBER_FORMAT_VALIDATION,
  EMAIL_VALIDATION_REGEX,
  FIRSTNAME_VALIDATION_REGEX,
  LASTNAME_VALIDATION_REGEX,
} = getConstants();

export type CustomerDetailsData = MerchantApiCustomerDetailsData;

export interface CustomerDetailsProps {
  readOnly: boolean;
  showNextButton: boolean;
}

const SCCustomerDetails = styled.div`
  .details-body {
    display: flex;
    flex-wrap: wrap;
  }

  .btn-search-customer {
    margin-bottom: 3em;
  }

  ${media.max.medium} {
    .input-field,
    .dropdown {
      width: 100%;
      max-width: 100%;
    }
    .title-firstname-wrapper {
      width: 100%;
      & > div:first-of-type {
        margin-bottom: 1.33em;
      }
      .first-name {
        margin-bottom: 1.33em;
      }
      .middle-name {
        margin-bottom: 1.33em;
      }
    }

    .date-picker {
      width: 100%;
    }
    .details-body > div,
    .address-body .input-field,
    .address-body .dropdown {
      margin-bottom: 1.33em;
    }
  }

  ${media.min.medium} {
    .title-firstname-wrapper {
      flex: 60%;
      display: flex;
      .title {
        margin-right: 0.75em;
        .error {
          text-align: left;
        }
      }
      .first-name {
        margin: 0 1.4em 0 1em;
      }
      .middle-name {
        margin-left: 0.32em;
      }
      .last-name {
        margin-left: 0.75em;
        margin-bottom: 1.33em;
      }
    }
    .date-picker {
      width: 100%;
    }

    .email {
      margin-bottom: 1.33em;
    }
    .email-confirm,
    .phone,
    .date-picker {
      margin-bottom: 1.33em;
    }

    hr {
      &#details-hr {
        margin-top: 2.27em;
        margin-bottom: 4.4em;
      }
      margin-top: 1.15em;
      margin-bottom: 2em;
    }
  }

  ${media.min.large} {
    .date-picker {
      width: calc(50% - 0.75em);
      margin-left: 0.75em;
    }

    .input-field {
      flex: calc(50% - 0.75em);
      margin-bottom: 2.46em;
    }

    .phone {
      width: calc(50% - 0.75em);
      margin-right: 0.75em;
    }

    .email {
      margin-right: 0.75em;
    }
  }
`;

const SCSpinner = styled(IconLoaderAlt)`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 1s infinite linear;
`;

const CustomerDetailsBlock = ({ readOnly, showNextButton }: CustomerDetailsProps) => {
  // The context retrieved from FormProvider provider of the parent component
  const { errors, watch } = useFormContext<{
    customerDetails: CustomerDetailsData;
  }>();
  const { loading } = useSelector((state: RootState) => state.customer);

  const sectionRef = useRef<HTMLDivElement>(null);

  // Scroll to block (by ID) when user submits and validation errors
  useEffect(() => {
    if (Object.keys(errors).length > 0 && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  return (
    <SCCustomerDetails id="CustomerSection" ref={sectionRef}>
      <div className="details-body">
        <div className="title-firstname-wrapper">
          <ControlledDropdown
            name="customerDetails.title"
            className="title"
            rules={{ required: 'Title is required' }}
            options={CUSTOMER_TITLES}
            readOnly={readOnly}
            error={errors.customerDetails?.title?.message as string}
            maxWidth="15%"
          >
            Title *
          </ControlledDropdown>

          <ControlledInputField
            name="customerDetails.firstName"
            rules={{
              required: 'First name is required',
              pattern: {
                value: FIRSTNAME_VALIDATION_REGEX,
                message: 'First name is required & must be valid format',
              },
            }}
            readOnly={readOnly}
            className="first-name"
            error={errors.customerDetails?.firstName?.message as string}
            maxWidth="30%"
          >
            First Name *
          </ControlledInputField>

          <ControlledInputField
            name="customerDetails.middleName"
            className="middle-name"
            readOnly={readOnly}
            maxWidth="23%"
          >
            Middle Name
          </ControlledInputField>

          <ControlledInputField
            rules={{
              required: 'Last name is required',
              pattern: {
                value: LASTNAME_VALIDATION_REGEX,
                message: 'Last name is required & must be valid format',
              },
            }}
            name="customerDetails.lastName"
            className="last-name"
            readOnly={readOnly}
            error={errors.customerDetails?.lastName?.message as string}
            maxWidth="23%"
          >
            Last Name *
          </ControlledInputField>
        </div>

        <ControlledInputField
          rules={{
            required: 'Email is required',
            pattern: {
              value: EMAIL_VALIDATION_REGEX,
              message: 'Email is required & in a valid format',
            },
          }}
          name="customerDetails.email"
          error={errors.customerDetails?.email?.message as string}
          readOnly={readOnly}
          className="email"
          type="email"
        >
          Email *
        </ControlledInputField>
        <ControlledInputField
          rules={{
            required: 'Email confirmation is required',
            validate: (value: any) =>
              value === watch('customerDetails.email') || 'Email needs to match',
          }}
          error={errors.customerDetails?.confirmEmail?.message as string}
          name="customerDetails.confirmEmail"
          className="email-confirm"
          type="email"
          readOnly={readOnly}
        >
          Confirm Email *
        </ControlledInputField>

        <ControlledInputField
          rules={{
            required: 'Phone number is required',
            pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_MOBILE_VALIDATION')
              ? undefined
              : {
                  value: MOBILE_VALIDATION_REGEX,
                  message: MOBILE_NUMBER_FORMAT_VALIDATION,
                },
          }}
          error={errors.customerDetails?.phone?.message as string}
          name="customerDetails.phone"
          readOnly={readOnly}
          className="phone"
        >
          Mobile No. *
        </ControlledInputField>

        <ControlledDatePicker
          name="customerDetails.dateOfBirth"
          rules={{
            required: 'Date of birth is required',
            validate: (value: any) => calculateAgeLuxon(value) || 'Must be at least 18 years old',
          }}
          error={errors.customerDetails?.dateOfBirth?.message as string}
          readOnly={readOnly}
          startYear={new Date().getFullYear() - 18}
          endYear={new Date().getFullYear() - 18 - DOB_YEARS}
        >
          Date of birth *(applicants must be 18 and over)
        </ControlledDatePicker>

        {showNextButton && (
          <Button
            className="btn-search-customer"
            disabled={loading}
            iconPosition="right"
            icon={loading ? <SCSpinner /> : undefined}
            type="submit"
            maxWidth={'100%'}
            colour="primary"
          >
            Next
          </Button>
        )}
      </div>
    </SCCustomerDetails>
  );
};

export default CustomerDetailsBlock;
