import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../slices';
import Spinner from '../components/spinner';
import { Button, IconLogo, media } from '@payright/web-components';
import { useForm, FormProvider } from 'react-hook-form';
import { login } from '../slices/auth';
import { ControlledInputField } from '../components/form-fields';
import getConstants from '../util/constants';

const { PAYRIGHT_MERCHANT_V1 } = getConstants();

const SCMerchantLogin = styled.div`
  display: flex;
  justify-content: center;
  ${media.max.medium} {
    padding: 1.6em;
    .login-header {
      border-bottom: 1px solid ${(props) => props.theme.colours.grey.light};
      padding-bottom: 1em;
    }
  }
  ${media.min.medium} {
    align-items: center;
    height: 100vh;
  }
  height: 100%;
  .login-wrapper {
    max-width: 385px;
    width: 100%;
    .login-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3em;
      .logo {
        width: 50%;
        display: flex;
        align-items: center;
        svg {
          width: 170px;
          height: 70px;
        }
        ${media.max.medium} {
          svg {
            width: 115px;
            height: 50px;
          }
        }
      }
      h4 {
        width: 50%;
        color: ${(props) => props.theme.colours.blue.base};
        justify-content: flex-end;
        display: flex;
        align-items: center;
        ${media.max.medium} {
          font-size: 1.26em;
          width: unset;
        }
        ${media.min.medium} {
          border-left: 1px solid ${(props) => props.theme.colours.grey.light};
        }
      }
    }
    .login-body {
      ${media.min.medium} {
        max-width: 365px;
        margin: 0 auto;
      }
      .link-to {
        text-align: center;
        margin-bottom: 1.5em;
        font-weight: bold;
        font-size: 0.86em;
        &.portal-v1 {
          margin-top: 1.5em;
        }
      }
      .input-field {
        margin-bottom: 2em;
      }
      button {
        max-width: 100%;
      }
    }

    .validation-msg-box {
      background-color: #ffd2d3;
      border-radius: 5px;
      padding: 0.68em;
      color: #ff3b3b;
      margin-bottom: 2em;
      width: 100%;
    }
  }
`;

interface LoginProps {}

type FormData = {
  username: string;
  password: string;
};

const Login = (props: LoginProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reactHookForm = useForm<FormData>({
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onSubmit',
  });

  const { handleSubmit, errors } = reactHookForm;
  const { errorMessage, auth, loading } = useSelector((state: RootState) => state.auth);

  const formSubmitHandler = (formData: FormData) => {
    /// validation is complete now we gotta dispatch to the event to the reducer
    dispatch(login(formData.username, formData.password));
  };

  useEffect(() => {
    if (auth) {
      history.push('/dashboard');
    }
  }, [auth, history]);

  return (
    <SCMerchantLogin>
      <div className="login-wrapper">
        <div className="login-header">
          <div className="logo">
            <IconLogo width={180} height={74} />
          </div>
          <h4>Merchant Portal</h4>
        </div>
        <div className="login-body">
          <FormProvider {...reactHookForm}>
            <form onSubmit={handleSubmit(formSubmitHandler)}>
              <div data-testid="error-message">
                {errorMessage && <div className="validation-msg-box">{errorMessage}</div>}
              </div>

              <ControlledInputField
                name="username"
                rules={{ required: 'Username is required' }}
                error={errors.username?.message as string}
                placeholder="Username"
              >
                Username
              </ControlledInputField>

              <ControlledInputField
                name="password"
                rules={{ required: 'Password is required' }}
                error={errors.password?.message as string}
                placeholder="Password"
                type="password"
              >
                Password
              </ControlledInputField>

              <div className="link-to">
                <Link to={'/forgot-password'}>I've forgotten my password</Link>
              </div>
              <Button
                type="submit"
                withShadow
                maxWidth="100%"
                iconPosition="right"
                icon={loading ? <Spinner /> : undefined}
                disabled={loading}
              >
                Login
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </SCMerchantLogin>
  );
};

export default Login;
