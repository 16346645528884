import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Navigation, IconAccount, IconLogout } from '@payright/web-components';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../slices';
import { useHistory } from 'react-router-dom';
import { showHideItemsAndMenu } from '../util/helper';

import MerchantAPI from '@merchant-portal/util/merchant-api';
import PlansAPI from '@merchant-portal/util/plans-api';

import { AppDispatch } from '../util/store';

import { bootUserIfNotLoggedIn, sessionExpired } from '../slices/auth';
import { FeatureFlags, useFeatures } from '@merchant-portal/providers/features-provider';

interface LoggedInLayoutProps {
  /** By default, the content starts 60px below the nav header. Use this to override the value where necessary */
  contentMarginTop?: number;
  children?: React.ReactNode;
  activePage?: string;
}

const SCLoggedInLayout = styled.div<LoggedInLayoutProps>`
  .content {
    margin-top: ${(props) => props.contentMarginTop}px;
  }
  .menu-items {
    .menu-items__list {
      white-space: nowrap;
    }
  }
  .logged-in,
  .not-logged-in {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.6em;
  }
  .not-logged-in {
    h6 {
      margin-right: 1.6em;
    }
    button {
      height: 36px;
      width: 93px;
    }
  }
  .logged-in {
    width: 243px;
    border-left: 1px solid ${(props) => props.theme.colours.secondary3};

    label {
      color: ${(props) => props.theme.colours.plum.base};
      font-weight: bold;
      font-size: 1.13em;
      cursor: pointer;
      user-select: none;
    }
    .account-icon {
      background-image: linear-gradient(35deg, #5431ff, #f93253);
      border-radius: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: ${(props) => props.theme.colours.white};
        }
      }
    }
    .account-info {
      display: flex;
      flex-direction: column;
      margin-left: 1.3em;
      .account-info-links {
        a {
          font-weight: bold;
          font-size: 0.86em;
          margin-right: 0.75em;
        }
      }
    }
  }
`;

// const SCMerchantNavigation = styled(Navigation)``;
// const merchantNotifications = [
//   {
//     text: 'You have 3 plans waiting to be activated',
//     action: { label: 'View Plans', link: '/plans' },
//   },
//   {
//     text: 'You have an overdue payment',
//     action: { label: 'Go to Payments', link: '/payments' },
//   },
//   {
//     text: 'You have 3 plans waiting to be activated',
//   },
// ];

const LoggedInLayout = ({ contentMarginTop = 60, children, activePage }: LoggedInLayoutProps) => {
  const history = useHistory();

  const { flagsConfig } = useFeatures();
  const canCreateCheckoutAndPlan = flagsConfig[FeatureFlags.CHECKOUT_AND_PLAN_CREATION];
  const { auth } = useSelector((state: RootState) => state.auth);

  // helper method to show hide menu
  const menuItems = showHideItemsAndMenu().merchantMenuItems;

  const accountDetailsItems = [
    {
      icon: <IconAccount />,
      iconColour: 'blue',
      label: 'My Profile',
      link: '/profile',
    },
    {
      icon: <IconLogout />,
      label: 'Logout',
      iconColour: 'red',
      labelColour: 'red',
      link: '/logout',
    },
  ];

  const dispatch: AppDispatch = useDispatch();

  // Set default behaviour for MerchantAPI when receiving a 401 - Unauthorized error
  MerchantAPI.axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.isAxiosError && error.response.status === 401) {
        dispatch(sessionExpired());
      }

      return Promise.reject(error);
    }
  );

  // Set default behaviour for PlansAPI when receiving a 401 - Unauthorized error
  PlansAPI.axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.isAxiosError && error.response.status === 401) {
        dispatch(sessionExpired());
      }

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (!auth) {
      history.push('/');
    }
    const timer = setInterval(() => {
      dispatch(bootUserIfNotLoggedIn());
    }, 5 * 1000 * 60); // 5 minute interval

    // Cleanup function - clear the timer on logout (e.g. when this component is unmounted)
    return () => {
      clearInterval(timer);
    };
  }, [auth, history, dispatch]);

  return (
    <SCLoggedInLayout contentMarginTop={contentMarginTop} activePage={activePage}>
      <Navigation
        portalType="merchant"
        menuItems={
          canCreateCheckoutAndPlan ? menuItems : menuItems.filter((item) => item.name != 'New Plan')
        }
        activePage={activePage}
        accountDetailsActions={accountDetailsItems}
        showNotification={false}
        breakpoint="wide"
      ></Navigation>
      <div className="content">{children}</div>
    </SCLoggedInLayout>
  );
};

export default LoggedInLayout;
