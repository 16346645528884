import { styled, media } from '@payright/web-components';

const SCResetPassword = styled.div`
  background: ${(props) => props.theme.colours.grey.altlight};

  h2 {
    color: ${(props) => props.theme.colours.blue.base};
  }
  .messageBox {
    text-align: center;
  }
  .reset-password-body {
    max-width: 500px;
    width: 350px;
    ${media.min.large} {
      max-width: 1200px;
      width: 100%;
    }

    .reset-password-wrapper-body {
      background: ${(props) => props.theme.colours.white};
      max-width: 1200px;
      width: 100%;

      .input-field {
        max-width: 320px;
        width: 320px;

        ${media.min.large} {
          max-width: 500px;
          width: 500px;
        }
      }
      .label {
        padding: 10px 0 10px 0;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
      }
      .actions {
        align-items: center;
        left: 0;
        max-width: 320px;
        width: 320px;
        height: 60px;
        margin-top: 40px;

        ${media.min.large} {
          max-width: 500px;
          width: 500px;
        }
      }
      .alerts {
        max-width: 600px;
        min-width: 300px;
        margin: 20px 15px 15px 15px;
        vertical-align: middle;
      }
      .passwordMessage {
        bottom: 0;
        text-align: left;
        font-size: 0.86em;
        margin-top: 0.5em;
        max-width: 320px;
        width: 320px;
        ${media.min.large} {
          max-width: 500px;
          width: 500px;
        }
      }
    }
  }
`;

export default SCResetPassword;
